import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const uiTemplateVariableActions = {
  setOpenAlertDialog: actionCreator<boolean>('SET_TEMPLATE_VARIABLE_OPEN_ALERT_DIALOG'),
  setOpenDialog: actionCreator<boolean>('SET_TEMPLATE_VARIABLE_OPEN_DIALOG'),
  setOkBtnText: actionCreator<string>('SET_TEMPLATE_VARIABLE_OK_BTN_TEXT'),
  setDisplayDelete: actionCreator<boolean>('SET_TEMPLATE_VARIABLE_DELETE_DISPLAY'),
  setAction: actionCreator<'ADD' | 'UPDATE' | undefined>('SET_TEMPLATE_VARIABLE_ACTION'),
  setIsDisplayCopyBtn: actionCreator<boolean>('SET_TEMPLATE_VARIABLE_IS_DISPLAY_COPY_BTN'),
  setOpenCopyAlertDialog: actionCreator<boolean>('SET_TEMPLATE_VARIABLE_OPEN_COPY_ALERT_DIALOG'),
};
