/** @fileOverview ステートアクションコンポーネント */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import { ChatRule } from 'api/models/ChatRule';
import {
  modificationCommandRegister,
  stateModActionRegister,
} from 'components/form/validator/ChatRuleRegister';
import React, { useCallback } from 'react';
import { useFormContext, FieldError } from 'react-hook-form';
import { StateAction } from 'api/models/StateAction';
import UnbListInlineTextFiled from './UnbListInlineTextFiled';
import { inlineIconButtonProps } from 'components/molecules/UnbInlineIconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useDispatch } from 'react-redux';
import { chatRuleActions } from 'store/domains/chat-rule/action';

type UnbStateActionsProps = {
  chatRule: ChatRule;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

/**
 * ステートアクションコンポーネント
 *
 * @component
 */
const UnbStateActions: React.FC<UnbStateActionsProps> = (props) => {
  const classes = useStyles();
  const { register, errors, triggerValidation } = useFormContext();
  const [currentChatRule, setCurrentChatRule] = React.useState(props.chatRule);

  const dispatch = useDispatch();

  // State Actionの1行のデータ
  const stateActionRows: {
    key: string;
    textFieldPropsArray: TextFieldProps[];
    btns: inlineIconButtonProps[];
  }[] = [];

  const handleStateModActionsChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      // idの最後の-以降からindexを切り出す
      const id = (event.currentTarget as HTMLInputElement).id;
      const index = Number(id.slice(id.lastIndexOf('-') + 1));

      if (currentChatRule.state_actions) {
        currentChatRule.state_actions[index].state_mod_action = event.target.value as string;
      }
      setCurrentChatRule(Object.assign({}, currentChatRule));
      dispatch(chatRuleActions.setCurrentChatRule(Object.assign({}, currentChatRule)));
      triggerValidation('modification_command[' + index + '].name');
    },
    [currentChatRule]
  );

  const handleModificationCommandChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      // idの最後の-以降からindexを切り出す
      const id = (event.currentTarget as HTMLInputElement).id;
      const index = Number(id.slice(id.lastIndexOf('-') + 1));

      if (currentChatRule.state_actions) {
        currentChatRule.state_actions[index].modification_command = event.target.value as string;
      }
      triggerValidation('state_mod_action[' + index + '].name');
      setCurrentChatRule(Object.assign({}, currentChatRule));
      dispatch(chatRuleActions.setCurrentChatRule(Object.assign({}, currentChatRule)));
    },
    [currentChatRule]
  );

  const handleStateModActionsBlur = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      // idの最後の-以降からindexを切り出す
      const id = (event.currentTarget as HTMLInputElement).id;
      const index = Number(id.slice(id.lastIndexOf('-') + 1));

      triggerValidation('modification_command[' + index + '].name');
    },
    []
  );

  const handleModificationCommandBlur = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      // idの最後の-以降からindexを切り出す
      const id = (event.currentTarget as HTMLInputElement).id;
      const index = Number(id.slice(id.lastIndexOf('-') + 1));

      triggerValidation('state_mod_action[' + index + '].name');
    },
    []
  );

  // 初期化
  React.useEffect(() => {
    // 初期値設定
    const newStateActionArray =
      currentChatRule.state_actions === null ||
      currentChatRule.state_actions === undefined ||
      currentChatRule.state_actions.length === 0
        ? [{ state_mod_action: '', modification_command: '' }]
        : currentChatRule.state_actions;

    currentChatRule.state_actions = newStateActionArray;
  }, []);

  const onClickAddStateAction = useCallback(
    (index: number): void => {
      const newArray = currentChatRule.state_actions ? currentChatRule.state_actions?.slice() : [];
      newArray.splice(index + 1, 0, {
        state_mod_action: '',
        modification_command: '',
      } as StateAction);
      currentChatRule.state_actions = newArray;
      setCurrentChatRule(Object.assign({}, currentChatRule));
      dispatch(chatRuleActions.setCurrentChatRule(Object.assign({}, currentChatRule)));
    },
    [currentChatRule]
  );

  const onClickRemoveStateAction = useCallback(
    (index: number): void => {
      // 残りが1つなら削除しない
      const dispRows = currentChatRule.state_actions?.filter(
        (_s: StateAction, index: number) =>
          _s.state_mod_action !== null || _s.modification_command !== null
      );
      if (dispRows?.length === 1) return;

      if (currentChatRule.state_actions && currentChatRule.state_actions[index].state_action_id) {
        // id有り(編集元データ)の物は値をnullを設定
        currentChatRule.state_actions[index].state_mod_action = null;
        currentChatRule.state_actions[index].modification_command = null;
      } else {
        // id無しの物は削除
        const newArray = currentChatRule.state_actions?.filter(
          (_s: StateAction, i: number) => i !== index
        );
        currentChatRule.state_actions = newArray;
      }
      setCurrentChatRule(Object.assign({}, currentChatRule));
      dispatch(chatRuleActions.setCurrentChatRule(Object.assign({}, currentChatRule)));
    },
    [currentChatRule]
  );

  return (
    <div className={classes.row}>
      {currentChatRule.state_actions?.map((item, index: number) => {
        // 値がnullは削除したデータなので非表示
        if (item.state_mod_action === null && item.modification_command === null) {
          return;
        }

        /** State Mod Action */
        const stateModActionProp: TextFieldProps = {
          label: 'State Mod Action',
          inputRef: register(stateModActionRegister(currentChatRule.state_actions, index)),
          error: !!(errors.state_mod_action && (errors.state_mod_action as FieldError[])[index]),
          id: 'state-mod-action-' + index,
          name: 'state_mod_action[' + index + '].name',
          className: classes.formControl,
          helperText:
            errors.state_mod_action &&
            (errors.state_mod_action as { name: FieldError }[])[index] &&
            (errors.state_mod_action as { name: FieldError }[])[index].name?.message
              ? (errors.state_mod_action as { name: FieldError }[])[index].name?.message
              : 'ユニボの記憶を操作',
          margin: 'dense',
          variant: 'outlined',
          value: item.state_mod_action,
          onChange: handleStateModActionsChange,
          onBlur: handleStateModActionsBlur,
        };

        /** Modification Command */
        const modificationCommand = {
          label: 'Modification Command',
          inputRef: register(modificationCommandRegister(currentChatRule.state_actions, index)),
          error: !!(
            errors.modification_command && (errors.modification_command as FieldError[])[index]
          ),
          id: 'modification-command-' + index,
          name: 'modification_command[' + index + '].name',
          className: classes.formControl,
          helperText:
            errors.modification_command &&
            (errors.modification_command as { name: FieldError }[])[index] &&
            (errors.modification_command as { name: FieldError }[])[index].name?.message
              ? (errors.modification_command as { name: FieldError }[])[index].name?.message
              : '記憶操作のコマンド定義',
          margin: 'dense',
          variant: 'outlined',
          value: item.modification_command ? item.modification_command : '',
          onChange: handleModificationCommandChange,
          onBlur: handleModificationCommandBlur,
        };

        /** ボタン */
        const btns = [
          {
            icon: AddIcon,
            onClick: ((): void => onClickAddStateAction(index)) as
              | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
              | undefined,
          },
          {
            icon: RemoveIcon,
            onClick: ((): void => onClickRemoveStateAction(index)) as
              | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
              | undefined,
          },
        ];

        // コンポーネントに渡すデータに追加
        stateActionRows.push({
          key: 'state-actions' + index,
          textFieldPropsArray: [
            stateModActionProp as TextFieldProps,
            modificationCommand as TextFieldProps,
          ],
          btns: btns,
        });
      })}
      <UnbListInlineTextFiled
        title="State Actions"
        titleClassName={classes.inputLabel}
        rows={stateActionRows}
      />
    </div>
  );
};

export default UnbStateActions;
