import React, { ChangeEvent } from 'react';

type UnbHiddenInputFileProps = {
  id: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
};

const UnbHiddenInputFile: React.FC<UnbHiddenInputFileProps> = (props) => {
  return (
    <div hidden>
      <input
        id={props.id}
        type="file"
        name={`input-${props.id}`}
        onChange={props.onChange}
        accept={props.accept}
      />
    </div>
  );
};

export default UnbHiddenInputFile;
