import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeStyle } from 'components/atoms/UnbTypography';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { UnbMenuItemProps } from 'components/atoms/UnbMenuItem';
import { AppState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { commonActions } from 'store/app/actions';
import UnbIconMenu from './UnbIconMenu';

type UnbToolbarProps = {
  text: string;
  variant: ThemeStyle;
  handleLogout: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    parentDiv: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      textAlign: 'start',
    },
  })
);

const UnbToolbar: React.FC<UnbToolbarProps> = (props) => {
  const projectId = useSelector((state: AppState) => state.app.selectedProjectId);
  const userInfo = useSelector((state: AppState) => state.auth.userInfo);
  const dispatch = useDispatch();
  const classes = useStyles();

  // アカウントメニュー
  const handleAccountMenuItem = (action: string): void => {
    //console.log(action);
    if (action === 'LOGOUT') {
      props.handleLogout();
    }
  };
  const items = [{ text: 'Logout', action: 'LOGOUT', onClickHandler: handleAccountMenuItem }];

  // プロジェクト選択メニュー
  const handleprojectMenuItem = (action: string): void => {
    //console.log(action);
    dispatch(commonActions.setSelectedProjectId(Number(action)));
  };
  const projectItems = buildProjectItems();

  function buildProjectItems(): UnbMenuItemProps[] {
    const projectIds = userInfo.projectId;
    if (projectIds) {
      const ret = projectIds.map((id) => {
        return { text: String(id), action: String(id), onClickHandler: handleprojectMenuItem };
      });
      return ret;
    }

    return [];
  }

  return (
    <div className={classes.parentDiv}>
      <Toolbar>
        <Typography variant={props.variant} className={classes.title}>
          {props.text}
        </Typography>
        {/* プロジェクト選択メニュー */}
        <UnbIconMenu
          iconButtonProps={{
            ariaLabel: 'select-project',
            postContent: 'プロジェクト',
            projectId: projectId,
          }}
          items={projectItems}
        />
        {/* アカウントメニュー */}
        <UnbIconMenu
          iconButtonProps={{
            icon: AccountCircle,
            ariaLabel: 'account',
          }}
          items={items}
        />
      </Toolbar>
    </div>
  );
};

export default UnbToolbar;
