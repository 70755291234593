import React from 'react';
import './App.css';
import UnbApp from 'components/pages/UnbApp';
import UnbTheme from 'themes/UnbStyle';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { Provider as StoreProvider } from 'react-redux';
import Store from 'store/index';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={UnbTheme}>
      <SnackbarProvider hideIconVariant>
        <StoreProvider store={Store}>
          <div className="App">
            <UnbApp />
          </div>
        </StoreProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
