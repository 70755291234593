/**
 *
 * @export
 * @interface WordMaster
 */
export interface WordMaster {
  /**
   * 識別子
   * @type {number}
   * @memberof WordMaster
   */
  word_master_id?: number;
  /**
   * データタイプ
   * @type {string}
   * @memberof WordMaster
   */
  word_type?: WordTypeEnum;
  /**
   * データ
   * @type {string}
   * @memberof WordMaster
   */
  value?: string;
  /**
   * 登録日付
   * @type {string}
   * @memberof WordMaster
   */
  created_at?: string;
  /**
   * 更新日付
   * @type {string}
   * @memberof WordMaster
   */
  updated_at?: string;
}

export enum WordTypeEnum {
  INVALIDREQUEST = 'INVALID_REQUEST',
  INVALIDREQUESTREGEX = 'INVALID_REQUEST_REGEX',
  NEGATIVE = 'NEGATIVE',
  NO = 'NO',
  NOISE = 'NOISE',
  NOISEREGEX = 'NOISE_REGEX',
  STOP = 'STOP',
  YES = 'YES',
}

export const createWordMaster = (
  word_master_id: number,
  value?: string,
  updated_at?: string,
  created_at?: string
): WordMaster => {
  value = value === undefined ? '' : value;
  updated_at = updated_at === undefined ? '' : updated_at;
  created_at = created_at === undefined ? '' : created_at;
  return { word_master_id, value, updated_at, created_at };
};
