/** @fileOverview チャットテストアクション */
import actionCreatorFactory from 'typescript-fsa';
import { MultiConversation } from 'api/models/MultiConversation';
import { ChatReply } from 'api/models/ChatReply';
import { postChatV2, execRequest } from 'api/Requests';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import axios, { AxiosResponse } from 'axios';

const actionCreator = actionCreatorFactory();

export const chatToolsActions = {
  postChatV2: actionCreator.async<MultiConversation, ChatReply>('POST_CHAT_V2'),
  addRequestHistory: actionCreator<MultiConversation>('ADD_CHAT_TOOLS_REQ_HISTORY'),
};

export const postChatV2Action = (params: MultiConversation) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<ChatReply>> => {
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    if (isPrivate && projectId !== null && projectId !== undefined) {
      // TODO: params
    } else {
      // params
    }
    dispatch(chatToolsActions.postChatV2.started(params));
    return execRequest(postChatV2, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(chatToolsActions.postChatV2.done({ params: params, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(chatToolsActions.postChatV2.failed({ params: params, error: e }));
        throw e;
      });
  };
};
