import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { UI_CONFIG } from 'constants/UI';

export const UnbPrimary = UI_CONFIG.getAppPrimaryColor();
export const UnbGray = '#ececec';

const UnbTheme = createMuiTheme({
  palette: {
    primary: {
      main: UnbPrimary,
    },
    secondary: {
      main: UnbGray,
    },
    background: {
      default: UnbGray,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(
      ','
    ),
    fontSize: 13,
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: 'white', // Some CSS
      },
      textPrimary: {
        color: UnbPrimary,
      },
      textSecondary: {
        color: 'red',
      },
      containedPrimary: {
        backgroundColor: UnbPrimary,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: UnbPrimary,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
      },
    },
  },
});

export default UnbTheme;
