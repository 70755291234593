import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { searchStartingFlagRegister } from 'components/form/validator/ChatRuleRegister';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    addButton: {
      margin: '0 0 0 auto',
    },
  })
);

type UnbChatRuleSearchBarProps = {
  handleSearch: (data: Record<string, any>) => void;
};

const UnbChatRuleHeadBar: React.FC<UnbChatRuleSearchBarProps> = (props) => {
  const classes = useStyles();
  const [startingFlag, setStartingFlag] = React.useState(2);
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const { handleSubmit, register, setValue } = useFormContext();

  React.useEffect(() => {
    register(searchStartingFlagRegister.custom, searchStartingFlagRegister.rule);
    setValue('starting_flag', 2);
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const val = event.target.value as number;
    setStartingFlag(val);
    setValue('starting_flag', val);
  };
  return (
    <form className={classes.root} onSubmit={handleSubmit(props.handleSearch)}>
      <TextField
        id="head-rule-id"
        label="Rule ID"
        type="search"
        margin="dense"
        variant="outlined"
        name="rule_id"
        inputRef={register}
      />
      <TextField
        id="head-reply"
        label="Reply"
        type="search"
        margin="dense"
        variant="outlined"
        name="reply"
        inputRef={register}
      />
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel id="starting-flag-label" ref={inputLabel}>
          Starting Flag
        </InputLabel>
        <Select
          labelId="starting-flag-label"
          id="head-starting-flag"
          name="starting_flag"
          inputRef={register}
          value={startingFlag}
          onChange={handleChange}
          labelWidth={labelWidth}
        >
          <MenuItem value={2}>全て</MenuItem>
          <MenuItem value={1}>TRUE</MenuItem>
          <MenuItem value={0}>FALSE</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
        startIcon={<SearchIcon />}
      >
        検索
      </Button>
    </form>
  );
};

export default UnbChatRuleHeadBar;
