import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import UnbToolbar from 'components/molecules/UnbToolbar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { UI_CONFIG } from 'constants/UI';

type UnbAppbarProps = {
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'white',
    },
  })
);

const UnbAppbar: React.FC<UnbAppbarProps> = (props) => {
  const classes = useStyles();
  const variant = 'h6';
  const title = UI_CONFIG.getAppTitle();
  const [isRedirectLogout, setRedirectLogout] = useState(false);

  const logout = (): void => {
    setRedirectLogout(true);
  };
  return (
    <AppBar position={props.position} className={classes.appBar}>
      {isRedirectLogout && <Redirect to={'/logout'} />}
      <UnbToolbar text={title} variant={variant} handleLogout={logout} />
    </AppBar>
  );
};

export default UnbAppbar;
