import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { masterWordsActions } from 'store/domains/master-word/action';
import { WordTypeEnum, WordMaster } from 'api/models/WordMaster';
import RequestGetWordMaster from 'api/models/requests/RequestGetWordMaster';

export type MasterWordState = {
  fetchOption: RequestGetWordMaster;
  rows: Array<WordMaster>;
  currentValues: CurrentValueType;
  count: number;
  loading: boolean;
  error?: any;
};

export type CurrentValueType = {
  wordMasterID: number;
  noiseValue: string;
  searchValue: string;
};

export const initialState: MasterWordState = {
  fetchOption: {
    limit: 20,
    page: 0,
    type: WordTypeEnum.NOISE,
    value: '',
  },
  currentValues: {} as CurrentValueType,
  rows: [],
  count: 0,
  loading: false,
};

export const masterWordsReducer = reducerWithInitialState(initialState)
  .case(masterWordsActions.getWordMaster.started, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.getWordMaster.failed, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.getWordMaster.done, (state, payload) => {
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
      loading: false,
      error: null,
    };
  })
  .case(masterWordsActions.setFetchOption, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.limit,
        page: payload.page,
        type: payload.type,
        value: payload.value,
      },
    };
  })
  .case(masterWordsActions.setSearchParams, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.fetchOption.limit,
        page: payload.fetchOption.page,
        type: payload.fetchOption.type,
        value: payload.fetchOption.value,
      },
      currentValues: payload.currentValues,
    };
  })
  .case(masterWordsActions.setLoading, (state, payload) => {
    return { ...state, loading: payload };
  })
  .case(masterWordsActions.setCurrentValues, (state, payload) => {
    return {
      ...state,
      currentValues: {
        noiseValue: payload.noiseValue,
        searchValue: payload.searchValue,
        wordMasterID: payload.wordMasterID,
      },
    };
  })
  .case(masterWordsActions.updateWordMaster.started, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.updateWordMaster.done, (state, payload) => {
    const rows = state.rows.slice();
    const target = rows.findIndex(
      (r: WordMaster) => r.word_master_id === payload.result.result.word_master_id
    );
    (rows[target] as WordMaster).value = payload.result.result.value;
    (rows[target] as WordMaster).word_type = payload.result.result.word_type;
    (rows[target] as WordMaster).updated_at = payload.result.result.updated_at;
    (rows[target] as WordMaster).created_at = payload.result.result.created_at;
    return {
      ...state,
      rows: rows,
    };
  })
  .case(masterWordsActions.updateWordMaster.failed, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.addWordMaster.started, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.addWordMaster.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows;
    const wordMaster: WordMaster = {
      word_master_id: result.word_master_id,
      value: result.value,
      word_type: result.word_type,
      created_at: result.created_at,
      updated_at: result.updated_at,
    };
    const newRows = rows.concat([wordMaster]);
    return {
      ...state,
      rows: newRows,
    };
  })
  .case(masterWordsActions.addWordMaster.failed, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.deleteWordMaster.started, (state, payload) => {
    return state;
  })
  .case(masterWordsActions.deleteWordMaster.done, (state, payload) => {
    const currentValues = state.currentValues;
    const deletedRows = state.rows.filter(
      (r: WordMaster) => r.word_master_id !== currentValues.wordMasterID
    );
    return {
      ...state,
      rows: deletedRows,
    };
  })
  .case(masterWordsActions.deleteWordMaster.failed, (state, payload) => {
    return state;
  });
