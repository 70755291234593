import {
  createStyles,
  Dialog,
  DialogProps,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import RequestPatchTemplateVariable from 'api/models/requests/RequestPatchTemplateVariable';
import RequestPostTemplateVariable from 'api/models/requests/RequestPostTemplateVariable';
import { TemplateVariable } from 'api/models/TemplateVariable';
import { AxiosError } from 'axios';
import UnbStyledTableCell from 'components/atoms/UnbStyledTableCell';
import UnbStyledTableRow from 'components/atoms/UnbStyledTableRow';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import UnbEditDialogActions from 'components/organisms/UnbEditDialogActions';
import UnbTableToolbar from 'components/organisms/UnbTableToolbar';
import UnbTemplateVariableDialogContent from 'components/organisms/UnbTemplateVariableDialogContent';
import UnbScrollableFormContent from 'components/organisms/UnbScrollableFormContent';
import UnbTemplateVariableHeadBar from 'components/organisms/UnbTemplateVariableHeadBar';
import UnbSimpleAlertDialog from 'components/templates/UnbSimpleAlertDialog';
import { getNoticeErrorMessage, LabelText, NoticeMessages } from 'constants/Messages';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  addTemplateVariableAction,
  deleteTemplateVariableAction,
  getTemplateVariableAction,
  searchTemplateVariableAction,
  templateVariableActions,
  updateTemplateVariableAction,
} from 'store/domains/template-variable/action';
import { uiTemplateVariableActions } from 'store/uis/template-variable/action';
import {
  setError,
  generateTemplateVariableAndQuery as generateAndQuery,
  generateTemplateVariableOrQuery as generateOrQuery,
} from 'utils/ComponentUtil';
import UnbEditDialogTitle from 'components/molecules/UnbEditDialogTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '80vh',
      overflowX: 'auto',
    },
    container: {
      maxHeight: '60vh',
    },
  })
);

interface Column {
  id:
    | 'template_variable_id'
    | 'template_id'
    | 'name'
    | 'v1'
    | 'v2'
    | 'v3'
    | 'v4'
    | 'v5'
    | 'v6'
    | 'created_at'
    | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatNumber?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'template_variable_id', label: 'ID', minWidth: 100 },
  { id: 'template_id', label: 'Template ID', minWidth: 150 },
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'v1', label: 'Value 1', minWidth: 150 },
  { id: 'v2', label: 'Value 2', minWidth: 150 },
  { id: 'v3', label: 'Value 3', minWidth: 150 },
  { id: 'v4', label: 'Value 4', minWidth: 150 },
  { id: 'v5', label: 'Value 5', minWidth: 150 },
  { id: 'v6', label: 'Value 6', minWidth: 150 },
  { id: 'updated_at', label: 'Updated At', minWidth: 170, align: 'right' },
  { id: 'created_at', label: 'Created At', minWidth: 170, align: 'right' },
];

const TemplateVariableComponent: React.FC = () => {
  const classes = useStyles();
  const themes = useTheme();
  const methods = useForm<TemplateVariable>({ mode: 'onChange' });
  const searchMethods = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const currentValue = useSelector((state: AppState) => state.templateVariable.currentValue);
  const rows = useSelector((state: AppState) => state.templateVariable.rows);
  const count = useSelector((state: AppState) => state.templateVariable.count);
  const fetchOption = useSelector((state: AppState) => state.templateVariable.fetchOption);
  const searchParams = useSelector((state: AppState) => state.templateVariable.searchParams);
  const loading = useSelector((state: AppState) => state.templateVariable.loading);
  const action = useSelector((state: AppState) => state.uiTemplateVariable.action);
  const openAlertDialog = useSelector(
    (state: AppState) => state.uiTemplateVariable.openAlertDialog
  );
  const openDialog = useSelector((state: AppState) => state.uiTemplateVariable.openDialog);
  const okBtnText = useSelector((state: AppState) => state.uiTemplateVariable.okBtnText);
  const displayDelete = useSelector((state: AppState) => state.uiTemplateVariable.displayDelete);
  const openCopyAlertDialog = useSelector(
    (state: AppState) => state.uiTemplateVariable.openCopyAlertDialog
  );
  const isDisplayCopyBtn = useSelector(
    (state: AppState) => state.uiTemplateVariable.isDisplayCopyBtn
  );
  const { enqueueSnackbar } = useSnackbar();
  const [closeAlertDialog, setCloseAlertDialog] = React.useState(false);

  useEffect(() => {
    const fn = async (): Promise<void> => {
      fetchData(searchParams);
      const t = document.getElementsByClassName('MuiTableContainer-root')[0];
      if (t) {
        t.scrollTop = 0;
      }
    };
    fn();
  }, [fetchOption, searchParams]);

  const fetchData = async (data: Record<string, string>): Promise<void> => {
    dispatch(templateVariableActions.setLoading(true));

    if (data.template_id === '' && data.name === '' && data.value === '') {
      await asyncDispatch(getTemplateVariableAction());
      dispatch(templateVariableActions.setLoading(false));
    } else {
      try {
        await asyncDispatch(
          searchTemplateVariableAction(generateAndQuery(data), generateOrQuery(data))
        );
        dispatch(templateVariableActions.setLoading(false));
      } catch (e) {
        dispatch(templateVariableActions.setLoading(false));
        const axiosErr = e as AxiosError;
        console.log(axiosErr.response);
        const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_SEARCH);
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  const updateUIStates = (
    currentVal: TemplateVariable,
    isOpenDialog: boolean,
    isOpenAlertDialog: boolean,
    isDisplayDelete: boolean,
    okBtnText: string
  ): void => {
    dispatch(templateVariableActions.setCurrentValue(currentVal));
    dispatch(uiTemplateVariableActions.setOpenDialog(isOpenDialog));
    dispatch(uiTemplateVariableActions.setOpenAlertDialog(isOpenAlertDialog));
    dispatch(uiTemplateVariableActions.setDisplayDelete(isDisplayDelete));
    dispatch(uiTemplateVariableActions.setOkBtnText(okBtnText));
  };

  const handleAlertOk = useCallback(async () => {
    dispatch(templateVariableActions.setLoading(true));
    dispatch(uiTemplateVariableActions.setOpenAlertDialog(false));
    try {
      if (currentValue.template_variable_id !== undefined) {
        await asyncDispatch(deleteTemplateVariableAction(currentValue.template_variable_id));
        enqueueSnackbar(NoticeMessages.COMPLETE_DELETE, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(NoticeMessages.ERROR_DELETE, { variant: 'error' });
    } finally {
      dispatch(templateVariableActions.setLoading(false));
    }
  }, [loading, openAlertDialog]);

  const handleAlertCancel = useCallback(() => {
    const emptyValue = {} as TemplateVariable;
    updateUIStates(emptyValue, false, false, false, LabelText.ADD_LABEL);
  }, [openAlertDialog]);

  const handleClose = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleCloseOk = useCallback(() => {
    setCloseAlertDialog(false);
    updateUIStates({} as TemplateVariable, false, false, false, '');
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]);

  const handleCloseCancel = (): void => {
    setCloseAlertDialog(false);
  };

  const handleOk = useCallback(() => {
    // nop
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(uiTemplateVariableActions.setOpenDialog(false));
    dispatch(uiTemplateVariableActions.setOpenAlertDialog(true));
  }, [openDialog, openAlertDialog]);

  const handleCancel = (): void => {
    setCloseAlertDialog(true);
  };

  const handleClickRow = useCallback(
    (key: number, scrollType: DialogProps['scroll']) => {
      const current = rows.find((r: TemplateVariable) => r.template_variable_id === key);
      if (current !== undefined) {
        updateUIStates(current, true, false, true, LabelText.UPDATE_LABEL);
        dispatch(uiTemplateVariableActions.setAction('UPDATE'));
        dispatch(uiTemplateVariableActions.setIsDisplayCopyBtn(true));
      }
    },
    [rows, currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]
  );

  const handleAdd = useCallback(() => {
    const emptyValue = {} as TemplateVariable;
    dispatch(uiTemplateVariableActions.setAction('ADD'));
    updateUIStates(emptyValue, true, false, false, LabelText.ADD_LABEL);
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText, action]);

  const handleSearch = useCallback(
    (data: Record<string, string>) => {
      console.log('call handleSearch', data);
      fetchOption.page = 0;
      dispatch(
        templateVariableActions.setSearchParams({
          fetchOption: fetchOption,
          searchParams: data,
        })
      );
    },
    [loading, fetchOption, searchParams]
  );

  const handleCopy = (): void => {
    dispatch(uiTemplateVariableActions.setOpenDialog(false));
    dispatch(uiTemplateVariableActions.setOpenCopyAlertDialog(true));
  };

  const handleCopyAlertCancel = useCallback((): void => {
    dispatch(uiTemplateVariableActions.setOpenCopyAlertDialog(false));
  }, [openCopyAlertDialog]);

  const handleCopyAlertOk = useCallback((): void => {
    dispatch(uiTemplateVariableActions.setIsDisplayCopyBtn(false));
    dispatch(uiTemplateVariableActions.setOpenCopyAlertDialog(false));

    const copyData = { ...currentValue };
    copyData.template_variable_id = undefined;
    copyData.template_id = copyData.template_id ? copyData.template_id + '_copy' : 'copy';
    dispatch(templateVariableActions.setCurrentValue(copyData));
    dispatch(uiTemplateVariableActions.setAction('ADD'));
    dispatch(uiTemplateVariableActions.setDisplayDelete(false));
    dispatch(uiTemplateVariableActions.setOkBtnText(LabelText.ADD_LABEL));
    dispatch(uiTemplateVariableActions.setOpenDialog(true));
  }, [openCopyAlertDialog]);

  const onSubmit = useCallback(
    async (data: TemplateVariable) => {
      //console.log(data);
      dispatch(templateVariableActions.setLoading(true));

      switch (action) {
        case 'ADD':
          if (data.template_id === undefined || data.name === undefined) {
            return;
          }
          try {
            const params: RequestPostTemplateVariable = {
              template_id: data.template_id,
              name: data.name,
              v1: data.v1,
              v2: data.v2,
              v3: data.v3,
              v4: data.v4,
              v5: data.v5,
              v6: data.v6,
            };
            await asyncDispatch(addTemplateVariableAction(params));
            dispatch(uiTemplateVariableActions.setOpenDialog(false));
            enqueueSnackbar(NoticeMessages.COMPLETE_ADD, { variant: 'success' });
          } catch (e) {
            setError(e, methods);
            const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_ADD);
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(templateVariableActions.setLoading(false));
          }
          break;
        case 'UPDATE':
          if (currentValue.template_variable_id !== undefined) {
            const params: RequestPatchTemplateVariable = {
              template_variable_id: currentValue.template_variable_id,
              template_id: data.template_id,
              name: data.name,
              v1: data.v1,
              v2: data.v2,
              v3: data.v3,
              v4: data.v4,
              v5: data.v5,
              v6: data.v6,
            };
            try {
              await asyncDispatch(updateTemplateVariableAction(params));
              dispatch(uiTemplateVariableActions.setOpenDialog(false));
              enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, { variant: 'success' });
            } catch (e) {
              setError(e, methods);
              const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
              enqueueSnackbar(message, { variant: 'error' });
            } finally {
              dispatch(templateVariableActions.setLoading(false));
            }
          }
          break;

        default:
          break;
      }
    },
    [action, loading, openDialog]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      console.log(newPage);
      fetchOption.page = newPage;
      dispatch(templateVariableActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      fetchOption.limit = +event.target.value;
      fetchOption.page = 0;
      dispatch(templateVariableActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const renderCopyAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCopyAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCopyAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_COPY]}
        title={LabelText.CONFIRM}
        open={openCopyAlertDialog}
      />
    ),
    [openCopyAlertDialog]
  );

  const renderAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_DELETE]}
        title={LabelText.CONFIRM}
        open={openAlertDialog}
      />
    ),
    [openAlertDialog]
  );

  const renderCloseAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCloseOk}
        okBtnText={LabelText.CLOSE_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCloseCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_CLOSE]}
        title={LabelText.CONFIRM}
        open={closeAlertDialog}
      >
        <DialogTitle id="conf-dialog-title">LabelText.CONFIRM_CLOSE</DialogTitle>
      </UnbSimpleAlertDialog>
    ),
    [closeAlertDialog]
  );

  const renderDilog = useMemo(
    () => (
      <Dialog
        id={'input-dialog'}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
      >
        {isDisplayCopyBtn ? (
          <UnbEditDialogTitle
            id="form-dialog-title"
            onCopy={(): void => handleCopy()}
            copyBtnText="複製"
            copyBtnColor={'primary'}
          >
            テンプレート変数定義
          </UnbEditDialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">テンプレート変数定義</DialogTitle>
        )}
        <UnbScrollableFormContent
          onSubmit={methods.handleSubmit(onSubmit)}
          dialogContent={
            <UnbTemplateVariableDialogContent dividers templateVariable={currentValue} />
          }
          editDilaogActions={
            <UnbEditDialogActions
              okBtnColor="primary"
              cancelBtnColor="primary"
              okBtnText={okBtnText}
              cancelBtnText="キャンセル"
              okHandler={handleOk}
              cancelHandler={handleCancel}
              deleteBtnColor="secondary"
              deleteBtnText="削除"
              deleteHandler={handleDelete}
              isDisplayDelete={displayDelete}
            />
          }
        />
      </Dialog>
    ),
    [openDialog, currentValue, okBtnText, displayDelete]
  );

  const renderTable = useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <UnbStyledTableCell
                    backgroundColor={'white'}
                    color={'black'}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </UnbStyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: TemplateVariable, i: number) => {
              return (
                <UnbStyledTableRow
                  backgroundColor={themes.palette.grey['50']}
                  color={'black'}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={(): void => {
                    if (row.template_variable_id) {
                      handleClickRow(row.template_variable_id, 'paper');
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        <div>{value}</div>
                      </TableCell>
                    );
                  })}
                </UnbStyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [rows]
  );

  const renderPagination = useMemo(
    () => (
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={'ページ毎の件数'}
        component="div"
        count={count}
        rowsPerPage={fetchOption.limit}
        page={fetchOption.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ),
    [fetchOption, count]
  );

  const renderLoading = useMemo(() => <UnbCircularProgress open={loading} />, [loading]);

  const renderContent = (
    <Paper className={classes.root}>
      <UnbTableToolbar title="テンプレート変数" onClickAdd={handleAdd} />
      {renderDilog}
      {renderAlertDialog}
      {renderCloseAlertDialog}
      {renderCopyAlertDialog}
      {renderTable}
      {renderPagination}
    </Paper>
  );

  return (
    <div>
      <FormContext {...methods}>
        <FormContext {...searchMethods}>
          <UnbTemplateVariableHeadBar handleSearch={handleSearch} />
        </FormContext>
        {renderContent}
        {loading && renderLoading}
      </FormContext>
    </div>
  );
};

export default TemplateVariableComponent;
