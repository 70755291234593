import actionCreatorFactory from 'typescript-fsa';
import { User } from 'oidc-client';
import { Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { AppState } from 'store/index';
import { UserInfo } from 'api/models/UserInfo';
import { execRequest, fetchUserInfo } from 'api/Requests';

const actionCraator = actionCreatorFactory();

export const authActions = {
  setUser: actionCraator<User>('SET_AUTH_USER'),
  setUserInfo: actionCraator<UserInfo>('SET_AUTH_USERINFO'),
  setLoading: actionCraator<boolean>('SET_AUTH_LOADING'),
  setAuthenticated: actionCraator<boolean>('SET_AUTH_IS_AUTHENTICATED'),
  setIsPrivate: actionCraator<boolean>('SET_AUTH_IS_PRIVATE'),
  getUserInfo: actionCraator.async<{}, UserInfo>('GET_AUTH_USER_INFO'),
};

export const getUserInfoAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<object>> => {
    const signal = axios.CancelToken.source();
    const accessToken = getState().auth.user.access_token;
    dispatch(authActions.getUserInfo.started({}));

    return execRequest(fetchUserInfo, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(authActions.getUserInfo.done({ params: {}, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(authActions.getUserInfo.failed({ params: {}, error: e }));
        throw e;
      });
  };
};
