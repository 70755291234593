import React, { useMemo, useCallback, ChangeEvent } from 'react';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  lighten,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LoopIcon from '@material-ui/icons/Loop';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import UnbHiddenInputFile from 'components/atoms/UnbHiddenInputFile';

type UnbTableToolbarProps = {
  title: string;
  onClickAdd?: () => void;
  onClickReload?: () => void;
  onClickImport?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickExport?: () => void;
};

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      textAlign: 'left',
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    menuIcon: {
      marginRight: '8px',
      marginTop: '4px',
    },
  })
);

const UnbTableToolbar: React.FC<UnbTableToolbarProps> = (props: UnbTableToolbarProps) => {
  const classes = useToolbarStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  const handleReload = useCallback(() => {
    setAnchorEl(null);

    if (props.onClickReload) {
      props.onClickReload();
    }
  }, [anchorEl]);

  const handleExport = useCallback(() => {
    setAnchorEl(null);
    if (props.onClickExport) {
      props.onClickExport();
    }
  }, [anchorEl]);

  const handleClickImport = useCallback(() => {
    const elm = document.getElementById('file-import');
    //console.log(elm);
    setAnchorEl(null);
    if (elm !== null && elm !== undefined) {
      if (props.onClickImport) {
        elm.click();
      }
    }
  }, [anchorEl]);

  const renderContent = (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {props.title}
      </Typography>
      {props.onClickAdd && (
        <Tooltip title="追加">
          <IconButton aria-label="追加" onClick={props.onClickAdd}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {(props.onClickReload !== undefined ||
        props.onClickImport !== undefined ||
        props.onClickExport !== undefined) && (
        <div>
          <IconButton aria-label="メニュー" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted={true}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {props.onClickReload && (
              <MenuItem onClick={handleReload}>
                <div className={classes.menuIcon}>
                  <LoopIcon fontSize={'small'} />
                </div>
                データ反映
              </MenuItem>
            )}
            {props.onClickImport && (
              <MenuItem onClick={handleClickImport}>
                <div className={classes.menuIcon}>
                  <CloudUploadIcon fontSize={'small'} />
                  <UnbHiddenInputFile
                    id={'file-import'}
                    onChange={props.onClickImport}
                    accept=".json"
                  />
                </div>
                インポート
              </MenuItem>
            )}
            {props.onClickExport && (
              <MenuItem onClick={handleExport}>
                <div className={classes.menuIcon}>
                  <CloudDownloadIcon fontSize={'small'} />
                </div>
                エクスポート
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </Toolbar>
  );

  return renderContent;
};

export default UnbTableToolbar;
