/** @fileOverview 動詞定義アクション */
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestSearchCommon from 'api/models/requests/RequestSearchCommon';
import RequestVerbDefinition from 'api/models/requests/RequestVerbDefinition';
import RequestGetCommon from 'api/models/requests/RequestGetCommon';
import { VerbDefinition } from 'api/models/VerbDefinition';
import {
  deleteVerbDefinition,
  execRequest,
  fetchVerbDefinition,
  patchVerbDefinition,
  postVerbDefinition,
  searchVerbDefinition,
} from 'api/Requests';
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const verbDefinitionActions = {
  setLoading: actionCreator<boolean>('SET_VERB_DEFINITION_LOADING'),
  setCurrentValue: actionCreator<VerbDefinition>('SET_VERB_DEFINITION_CURRENT_VALUE'),
  setFetchOption: actionCreator<{ limit: number; page: number }>(
    'SET_VERB_DEFINITION_FETCH_OPTION'
  ),
  setSearchParams: actionCreator<{
    fetchOption: {
      limit: number;
      page: number;
    };
    searchParams: Record<string, string | number>;
  }>('SET_VERB_DEFINITION_SEARCH_PARAMS'),
  getVerbDefinition: actionCreator.async<
    RequestGetCommon,
    CommonAPIResponse<CommonFetchResponse<Array<VerbDefinition>>>
  >('GET_VERB_DEFINITION'),
  searchVerbDefinition: actionCreator.async<
    RequestSearchCommon,
    CommonAPIResponse<CommonFetchResponse<Array<VerbDefinition>>>
  >('SEARCH_VERB_DEFINITION'),
  addVerbDefinition: actionCreator.async<RequestVerbDefinition, CommonAPIResponse<VerbDefinition>>(
    'ADD_VERB_DEFINITION'
  ),
  updateVerbDefinition: actionCreator.async<
    RequestVerbDefinition,
    CommonAPIResponse<VerbDefinition>
  >('UPDATE_VERB_DEFINITION'),
  deleteVerbDefinition: actionCreator.async<RequestDeleteCommon, AxiosResponse>(
    'DELETE_VERB_DEFINITION'
  ),
};

export const getVerbDefinitionAction = () => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<object> => {
    const fetchOption = getState().verbDefinition.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestGetCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
    };

    dispatch(verbDefinitionActions.getVerbDefinition.started(params));
    return execRequest(fetchVerbDefinition, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            verbDefinitionActions.getVerbDefinition.done({ params: params, result: res.data })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(verbDefinitionActions.getVerbDefinition.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const searchVerbDefinitionAction = (query: string, queryOr: null | string = null) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<object> => {
    const fetchOption = getState().verbDefinition.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestSearchCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
      q: query,
    };
    if (queryOr !== null) {
      params.q_or = queryOr;
    }

    dispatch(verbDefinitionActions.searchVerbDefinition.started(params));
    return execRequest(searchVerbDefinition, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            verbDefinitionActions.searchVerbDefinition.done({ params: params, result: res.data })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(verbDefinitionActions.searchVerbDefinition.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const addVerbDefinitionAction = (params: RequestVerbDefinition) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<object> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(verbDefinitionActions.addVerbDefinition.started(params));
    return execRequest(postVerbDefinition, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            verbDefinitionActions.addVerbDefinition.done({ params: params, result: res.data })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(verbDefinitionActions.addVerbDefinition.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const updateVerbDefinitionAction = (params: RequestVerbDefinition) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<object> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(verbDefinitionActions.updateVerbDefinition.started(params));
    return execRequest(patchVerbDefinition, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            verbDefinitionActions.updateVerbDefinition.done({ params: params, result: res.data })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(verbDefinitionActions.updateVerbDefinition.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const deleteVerbDefinitionAction = (id: number) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<object | undefined> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestDeleteCommon = {
      id: id,
    };
    dispatch(verbDefinitionActions.deleteVerbDefinition.started(params));
    return execRequest(deleteVerbDefinition, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            verbDefinitionActions.deleteVerbDefinition.done({ params: params, result: res })
          );
          return res;
        }
      })
      .catch((e) => {
        dispatch(verbDefinitionActions.deleteVerbDefinition.failed({ params: params, error: e }));
        throw e;
      });
  };
};
