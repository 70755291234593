/** @fileOverview 条件グループコンポーネント */
import {
  createStyles,
  Dialog,
  DialogProps,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import RequestRuleConditionGroup from 'api/models/requests/RequestRuleConditionGroup';
import { RuleConditionGroup } from 'api/models/RuleConditionGroup';
import { AxiosError } from 'axios';
import UnbStyledTableCell from 'components/atoms/UnbStyledTableCell';
import UnbStyledTableRow from 'components/atoms/UnbStyledTableRow';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import UnbEditDialogActions from 'components/organisms/UnbEditDialogActions';
import UnbRuleConditionGroupHeadBar from 'components/organisms/UnbRuleConditionGroupHeadBar';
import UnbTableToolbar from 'components/organisms/UnbTableToolbar';
import UnbSimpleAlertDialog from 'components/templates/UnbSimpleAlertDialog';
import UnbEditDialogTitle from 'components/molecules/UnbEditDialogTitle';
import { getNoticeErrorMessage, LabelText, NoticeMessages } from 'constants/Messages';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  addRuleConditionGroupAction,
  deleteRuleConditionGroupAction,
  getRuleConditionGroupAction,
  ruleConditionGroupActions,
  searchRuleConditionGroupAction,
  updateRuleConditionGroupAction,
} from 'store/domains/rule-condition-group/action';
import { uiRuleConditionGroupActions } from 'store/uis/rule-condition-group/action';
import UnbRuleConditionGroupDialogContent from 'components/organisms/UnbRuleConditionGroupDialogContent';
import { setError, generateRuleConditionGroupQuery as generateQuery } from 'utils/ComponentUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '80vh',
      overflowX: 'auto',
    },
    container: {
      maxHeight: '60vh',
    },
  })
);

export interface Column {
  id: 'rule_condition_group_id' | 'condition_name' | 'condition' | 'created_at' | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatNumber?: (value: number) => string;
  formatBoolean?: (value: boolean) => string;
  formatArray?: (value: Array<string>) => string;
}

export const columns: readonly Column[] = [
  { id: 'rule_condition_group_id', label: 'ID', minWidth: 100 },
  { id: 'condition_name', label: 'Conition Name', minWidth: 150 },
  { id: 'condition', label: 'Condition', minWidth: 150 },
  { id: 'updated_at', label: 'Updated At', minWidth: 170, align: 'right' },
  { id: 'created_at', label: 'Created At', minWidth: 170, align: 'right' },
];

const createRequest = (
  currentID: number | undefined = undefined,
  data: RuleConditionGroup
): RequestRuleConditionGroup => {
  const model: RequestRuleConditionGroup = {
    condition_name: data.condition_name,
    condition: data.condition,
  };
  if (currentID !== null && currentID !== undefined) {
    model.rule_condition_group_id = currentID;
  }
  return model;
};

const RuleConditionGroupComponent: React.FC = () => {
  const classes = useStyles();
  const themes = useTheme();
  const methods = useForm<RuleConditionGroup>({ mode: 'onChange' });
  const searchMethods = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const currentValue = useSelector((state: AppState) => state.ruleConditionGroup.currentValue);
  const rows = useSelector((state: AppState) => state.ruleConditionGroup.rows);
  const count = useSelector((state: AppState) => state.ruleConditionGroup.count);
  const fetchOption = useSelector((state: AppState) => state.ruleConditionGroup.fetchOption);
  const searchParams = useSelector((state: AppState) => state.ruleConditionGroup.searchParams);
  const loading = useSelector((state: AppState) => state.ruleConditionGroup.loading);
  const action = useSelector((state: AppState) => state.uiRuleConditionGroup.action);
  const openAlertDialog = useSelector(
    (state: AppState) => state.uiRuleConditionGroup.openAlertDialog
  );
  const openDialog = useSelector((state: AppState) => state.uiRuleConditionGroup.openDialog);
  const okBtnText = useSelector((state: AppState) => state.uiRuleConditionGroup.okBtnText);
  const displayDelete = useSelector((state: AppState) => state.uiRuleConditionGroup.displayDelete);
  const openCopyAlertDialog = useSelector(
    (state: AppState) => state.uiRuleConditionGroup.openCopyAlertDialog
  );
  const isDisplayCopyBtn = useSelector(
    (state: AppState) => state.uiRuleConditionGroup.isDisplayCopyBtn
  );
  const { enqueueSnackbar } = useSnackbar();
  const emptyValue = {} as RuleConditionGroup;
  const [closeAlertDialog, setCloseAlertDialog] = React.useState(false);

  useEffect(() => {
    const fn = async (): Promise<void> => {
      //console.log('call useeffect');
      fetchData(searchParams);
      const t = document.getElementsByClassName('MuiTableContainer-root')[0];
      if (t) {
        t.scrollTop = 0;
      }
    };
    fn();
  }, [fetchOption, searchParams]);

  const fetchData = async (data: Record<string, string>): Promise<void> => {
    dispatch(ruleConditionGroupActions.setLoading(true));
    if (data.value === '') {
      await asyncDispatch(getRuleConditionGroupAction());
      dispatch(ruleConditionGroupActions.setLoading(false));
    } else {
      try {
        await asyncDispatch(searchRuleConditionGroupAction(null, generateQuery(data, columns)));
        dispatch(ruleConditionGroupActions.setLoading(false));
      } catch (e) {
        dispatch(ruleConditionGroupActions.setLoading(false));
        const axiosErr = e as AxiosError;
        console.log(axiosErr.response);
        const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_SEARCH);
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  const updateUIStates = (
    currentVal: RuleConditionGroup,
    isOpenDialog: boolean,
    isOpenAlertDialog: boolean,
    isDisplayDelete: boolean,
    okBtnText: string
  ): void => {
    dispatch(ruleConditionGroupActions.setCurrentValue(currentVal));
    dispatch(uiRuleConditionGroupActions.setOpenDialog(isOpenDialog));
    dispatch(uiRuleConditionGroupActions.setOpenAlertDialog(isOpenAlertDialog));
    dispatch(uiRuleConditionGroupActions.setDisplayDelete(isDisplayDelete));
    dispatch(uiRuleConditionGroupActions.setOkBtnText(okBtnText));
  };

  const handleAlertOk = useCallback(async () => {
    dispatch(ruleConditionGroupActions.setLoading(true));
    dispatch(uiRuleConditionGroupActions.setOpenAlertDialog(false));
    try {
      if (currentValue.rule_condition_group_id !== undefined) {
        await asyncDispatch(deleteRuleConditionGroupAction(currentValue.rule_condition_group_id));
        enqueueSnackbar(NoticeMessages.COMPLETE_DELETE, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(NoticeMessages.ERROR_DELETE, { variant: 'error' });
    } finally {
      dispatch(ruleConditionGroupActions.setLoading(false));
    }
  }, [loading, openAlertDialog]);

  const handleAlertCancel = useCallback(() => {
    updateUIStates(emptyValue, false, false, false, LabelText.ADD_LABEL);
  }, [openAlertDialog]);

  const handleClose = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleCloseOk = useCallback(() => {
    setCloseAlertDialog(false);
    updateUIStates(emptyValue, false, false, false, '');
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]);

  const handleCloseCancel = (): void => {
    setCloseAlertDialog(false);
  };

  const handleOk = useCallback(() => {
    // nop
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(uiRuleConditionGroupActions.setOpenDialog(false));
    dispatch(uiRuleConditionGroupActions.setOpenAlertDialog(true));
  }, [openDialog, openAlertDialog]);

  const handleCancel = (): void => {
    setCloseAlertDialog(true);
  };

  const handleClickRow = useCallback(
    (key: number, scrollType: DialogProps['scroll']) => {
      const current = rows.find((r: RuleConditionGroup) => r.rule_condition_group_id === key);
      if (current !== undefined) {
        updateUIStates(current, true, false, true, LabelText.UPDATE_LABEL);
        dispatch(uiRuleConditionGroupActions.setAction('UPDATE'));
        dispatch(uiRuleConditionGroupActions.setIsDisplayCopyBtn(true));
      }
    },
    [rows, currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]
  );

  const handleAdd = useCallback(() => {
    dispatch(uiRuleConditionGroupActions.setAction('ADD'));
    updateUIStates(emptyValue, true, false, false, LabelText.ADD_LABEL);
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText, action]);

  const handleSearch = useCallback(
    (data: Record<string, string>) => {
      console.log('call handleSearch', data);
      fetchOption.page = 0;
      dispatch(
        ruleConditionGroupActions.setSearchParams({
          fetchOption: fetchOption,
          searchParams: data,
        })
      );
    },
    [loading, fetchOption, searchParams]
  );

  const handleCopy = (): void => {
    dispatch(uiRuleConditionGroupActions.setOpenDialog(false));
    dispatch(uiRuleConditionGroupActions.setOpenCopyAlertDialog(true));
  };

  const handleCopyAlertCancel = useCallback((): void => {
    dispatch(uiRuleConditionGroupActions.setOpenCopyAlertDialog(false));
  }, [openCopyAlertDialog]);

  const handleCopyAlertOk = useCallback((): void => {
    dispatch(uiRuleConditionGroupActions.setIsDisplayCopyBtn(false));
    dispatch(uiRuleConditionGroupActions.setOpenCopyAlertDialog(false));

    const copyData = { ...currentValue };
    copyData.rule_condition_group_id = undefined;
    copyData.condition_name = copyData.condition_name ? copyData.condition_name + '_copy' : 'copy';
    dispatch(ruleConditionGroupActions.setCurrentValue(copyData));
    dispatch(uiRuleConditionGroupActions.setAction('ADD'));
    dispatch(uiRuleConditionGroupActions.setDisplayDelete(false));
    dispatch(uiRuleConditionGroupActions.setOkBtnText(LabelText.ADD_LABEL));
    dispatch(uiRuleConditionGroupActions.setOpenDialog(true));
  }, [openCopyAlertDialog]);

  const onSubmit = useCallback(
    async (data: RuleConditionGroup) => {
      //console.log(data);
      dispatch(ruleConditionGroupActions.setLoading(true));
      switch (action) {
        case 'ADD': {
          const params = createRequest(undefined, data);
          try {
            await asyncDispatch(addRuleConditionGroupAction(params));
            dispatch(uiRuleConditionGroupActions.setOpenDialog(false));
            enqueueSnackbar(NoticeMessages.COMPLETE_ADD, { variant: 'success' });
          } catch (e) {
            setError(e, methods);
            const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_ADD);
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(ruleConditionGroupActions.setLoading(false));
          }
          break;
        }
        case 'UPDATE': {
          if (currentValue.rule_condition_group_id !== undefined) {
            const params = createRequest(currentValue.rule_condition_group_id, data);
            //console.log(params);
            try {
              await asyncDispatch(updateRuleConditionGroupAction(params));
              dispatch(uiRuleConditionGroupActions.setOpenDialog(false));
              enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, { variant: 'success' });
            } catch (e) {
              setError(e, methods);
              const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
              enqueueSnackbar(message, { variant: 'error' });
            } finally {
              dispatch(ruleConditionGroupActions.setLoading(false));
            }
          }
          break;
        }

        default:
          break;
      }
    },
    [action, loading, openDialog]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      console.log(newPage);
      fetchOption.page = newPage;
      dispatch(ruleConditionGroupActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      fetchOption.limit = +event.target.value;
      fetchOption.page = 0;
      dispatch(ruleConditionGroupActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const renderCopyAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCopyAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCopyAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_COPY]}
        title={LabelText.CONFIRM}
        open={openCopyAlertDialog}
      />
    ),
    [openCopyAlertDialog]
  );

  const renderAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_DELETE]}
        title={LabelText.CONFIRM}
        open={openAlertDialog}
      />
    ),
    [openAlertDialog]
  );

  const renderCloseAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCloseOk}
        okBtnText={LabelText.CLOSE_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCloseCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_CLOSE]}
        title={LabelText.CONFIRM}
        open={closeAlertDialog}
      >
        <DialogTitle id="conf-dialog-title">LabelText.CONFIRM_CLOSE</DialogTitle>
      </UnbSimpleAlertDialog>
    ),
    [closeAlertDialog]
  );

  const renderDilog = useMemo(
    () => (
      <Dialog
        id={'input-dialog'}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
      >
        {isDisplayCopyBtn ? (
          <UnbEditDialogTitle
            id="form-dialog-title"
            onCopy={(): void => handleCopy()}
            copyBtnText="複製"
            copyBtnColor={'primary'}
          >
            条件グループ定義
          </UnbEditDialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">条件グループ定義</DialogTitle>
        )}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <UnbRuleConditionGroupDialogContent dividers entity={currentValue} />
          <UnbEditDialogActions
            okBtnColor="primary"
            cancelBtnColor="primary"
            okBtnText={okBtnText}
            cancelBtnText="キャンセル"
            okHandler={handleOk}
            cancelHandler={handleCancel}
            deleteBtnColor="secondary"
            deleteBtnText="削除"
            deleteHandler={handleDelete}
            isDisplayDelete={displayDelete}
          />
        </form>
      </Dialog>
    ),
    [openDialog, currentValue, okBtnText, displayDelete]
  );

  const renderTable = useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <UnbStyledTableCell
                    backgroundColor={'white'}
                    color={'black'}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </UnbStyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: RuleConditionGroup, i: number) => {
              return (
                <UnbStyledTableRow
                  backgroundColor={themes.palette.grey['50']}
                  color={'black'}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={(): void => {
                    if (row.rule_condition_group_id) {
                      handleClickRow(row.rule_condition_group_id, 'paper');
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        <div>{value}</div>
                      </TableCell>
                    );
                  })}
                </UnbStyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [rows]
  );

  const renderPagination = useMemo(
    () => (
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={'ページ毎の件数'}
        component="div"
        count={count}
        rowsPerPage={fetchOption.limit}
        page={fetchOption.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ),
    [fetchOption, count]
  );

  const renderLoading = useMemo(() => <UnbCircularProgress open={loading} />, [loading]);

  const renderContent = (
    <Paper className={classes.root}>
      <UnbTableToolbar title="条件グループ" onClickAdd={handleAdd} />
      {renderDilog}
      {renderAlertDialog}
      {renderCloseAlertDialog}
      {renderCopyAlertDialog}
      {renderTable}
      {renderPagination}
    </Paper>
  );

  return (
    <div>
      <FormContext {...methods}>
        <FormContext {...searchMethods}>
          <UnbRuleConditionGroupHeadBar handleSearch={handleSearch} />
        </FormContext>
        {renderContent}
        {loading && renderLoading}
      </FormContext>
    </div>
  );
};

export default RuleConditionGroupComponent;
