import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ReplyTypeEnum, VerbDefinition } from 'api/models/VerbDefinition';
import { TimeDirectionTypeEnum } from 'api/types/TypeDirectionType';
import UnbRequiredSpan from 'components/atoms/UnbRequiredSpan';
import { weightRegistr } from 'components/form/validator/ChatRuleRegister';
import {
  confirmationReplyTaskIdRegister,
  decisionCommandRegister,
  decisionConditionRegister,
  excludeNounsRegister,
  excludeVerbsRegister,
  isAbbreviateRegister,
  isRequiredRegister,
  kakujyoshiRegister,
  replyTypeRegister,
  ruleIDRegister,
  timeDirectionRegister,
  valueTypesRegister,
  verbGroupNameRegister,
  verbNameRegister,
  verbsRegexRegister,
  verbsRegister,
} from 'components/form/validator/VerbDefinitionRegister';
import UnbListTextField from 'components/organisms/UnbListTextField';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useFieldArray, FieldError } from 'react-hook-form';
import { convertBool2Num } from 'utils/Converter';
import UnbTheme from 'themes/UnbStyle';

type UnbVerbDefinitionDialogContentProps = {
  dividers?: boolean;
  verbDefinition: VerbDefinition;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    errorHelperText: {
      color: theme.palette.error.main,
    },
  })
);

const UnbVerbDefinitionDialogContent: React.FC<UnbVerbDefinitionDialogContentProps> = (props) => {
  const classes = useStyles(UnbTheme);
  const { register, errors, setValue, control } = useFormContext();
  const [currentValue, setCurrentValue] = React.useState(props.verbDefinition);

  // Verbs
  const {
    fields: verbsFields,
    append: verbsAppend,
    remove: verbsRemove,
    insert: verbsInsert,
  } = useFieldArray({
    control,
    name: 'verbs',
  });

  // Exclude Verbs
  const {
    fields: excludeVerbsFields,
    append: excludeVerbsAppend,
    remove: excludeVerbsRemove,
    insert: excludeVerbsInsert,
  } = useFieldArray({
    control,
    name: 'exclude_verbs',
  });

  // Kakujyoshi
  const {
    fields: kakujyoshiFields,
    append: kakujyoshiAppend,
    remove: kakujyoshiRemove,
    insert: kakujyoshiInsert,
  } = useFieldArray({
    control,
    name: 'kaku_jyoshi',
  });

  // Exclude Nouns
  const {
    fields: excludeNounsFields,
    append: excludeNounsAppend,
    remove: excludeNounsRemove,
    insert: excludeNounsInsert,
  } = useFieldArray({
    control,
    name: 'exclude_nouns',
  });

  const inputIsRequiredLabel = React.useRef<HTMLLabelElement>(null);
  const [isRequiredLabelWidth, setIsRequiredLabelWidth] = useState(0);
  const handleIsRequiredChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setValue('is_required', (event.target.value as boolean) ? 1 : 0);
    setCurrentValue({
      ...currentValue,
      is_required: event.target.value as boolean,
    });
  };

  const inputIsAbbreviateLabel = React.useRef<HTMLLabelElement>(null);
  const [isAbbreviateLabelWidth, setIsAbbreviateLabelWidth] = useState(0);
  const handleIsAbbreviateChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setValue('is_abbreviate', (event.target.value as boolean) ? 1 : 0);
    setCurrentValue({
      ...currentValue,
      is_abbreviate: event.target.value as boolean,
    });
  };

  const inputTimeDirectionLabel = React.useRef<HTMLLabelElement>(null);
  const [timeDirectionLabelWidth, setTimeDirectionLabelWidth] = React.useState(0);
  const handleTimeDirectionChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      time_direction: event.target.value as TimeDirectionTypeEnum,
    });

    setSelectValues({
      ...selectValues,
      time_direction: event.target.value as string,
    });

    setValue('time_direction', event.target.value as TimeDirectionTypeEnum);
  };

  const inputReplyTypeLabel = React.useRef<HTMLLabelElement>(null);
  const [replyTypeLabelWidth, setReplyTypeLabelWidth] = React.useState(0);
  const handleReplyTypeChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      reply_type: event.target.value as ReplyTypeEnum,
    });

    setSelectValues({
      ...selectValues,
      reply_type: event.target.value as string,
    });

    setValue('reply_type', event.target.value as ReplyTypeEnum);
  };

  const inputWeightLabel = React.useRef<HTMLLabelElement>(null);
  const [weightLabelWidth, setWeightLabelWidth] = React.useState(0);
  const handleWeightChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      weight: event.target.value as number,
    });
  };

  /**
   * OptionalなStateをそのままFormのvalueに適用すると
   * undefindだった場合、controled componentからuncontrolledになってしまう
   * が発生するため、ローカルでstateに初期値を与える
   * DBから取得したModelはEnumなどで型定義されているため、空文字を入れるなどの
   * 対応が取り辛いため
   * 良い対応方法があり次第リファクタ
   */
  const [selectValues, setSelectValues] = React.useState({
    time_direction: '',
    reply_type: '',
  });

  // 初期化
  useEffect(() => {
    // カスタムregister
    register(isRequiredRegister.custom, isRequiredRegister.rule);
    register(isAbbreviateRegister.custom, isAbbreviateRegister.rule);
    register(timeDirectionRegister.custom, timeDirectionRegister.rule);
    register(replyTypeRegister.custom, replyTypeRegister.rule);

    // Formに初期値設定
    setValue('is_required', currentValue.is_required ? 1 : 0);
    setValue('is_abbreviate', currentValue.is_abbreviate ? 1 : 0);
    setValue('time_direction', currentValue.time_direction);
    setValue(
      'reply_type',
      currentValue.reply_type === null || currentValue.reply_type === undefined
        ? 'reply'
        : currentValue.reply_type
    );

    // 初期値設定
    const verbsArray =
      currentValue.verbs === null || currentValue.verbs === undefined ? [''] : currentValue.verbs;
    const excludVverbsArray =
      currentValue.exclude_verbs === null || currentValue.exclude_verbs === undefined
        ? ['']
        : currentValue.exclude_verbs;
    const kakujyoshiArray =
      currentValue.kaku_jyoshi === null || currentValue.kaku_jyoshi === undefined
        ? ['']
        : currentValue.kaku_jyoshi;
    const excludeNounsArray =
      currentValue.exclude_nouns === null || currentValue.exclude_nouns === undefined
        ? ['']
        : currentValue.exclude_nouns;
    currentValue.verbs = verbsArray;
    currentValue.exclude_verbs = excludVverbsArray;
    currentValue.kaku_jyoshi = kakujyoshiArray;
    currentValue.exclude_nouns = excludeNounsArray;

    // FieldArraysの初期化
    // Verbs
    if (verbsFields.length === 0) {
      const newFields = currentValue.verbs?.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields && newFields.length === 0) newFields.push({ name: '' });
      verbsAppend(newFields);
    }
    // Exclude Verbs
    if (excludeVerbsFields.length === 0) {
      const newFields = currentValue.exclude_verbs.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      excludeVerbsAppend(newFields);
    }
    // Kakujyoshi
    if (kakujyoshiFields.length === 0) {
      const newFields = currentValue.kaku_jyoshi.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      kakujyoshiAppend(newFields);
    }
    // Exclude Nouns
    if (excludeNounsFields.length === 0) {
      const newFields = currentValue.exclude_nouns.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      excludeNounsAppend(newFields);
    }

    // ラベルサイズ設定
    if (inputIsRequiredLabel.current) {
      setIsRequiredLabelWidth(inputIsRequiredLabel.current.offsetWidth);
    }
    if (inputIsAbbreviateLabel.current) {
      setIsAbbreviateLabelWidth(inputIsAbbreviateLabel.current.offsetWidth);
    }
    if (inputTimeDirectionLabel.current) {
      setTimeDirectionLabelWidth(inputTimeDirectionLabel.current.offsetWidth);
    }
    if (inputReplyTypeLabel.current) {
      setReplyTypeLabelWidth(inputReplyTypeLabel.current.offsetWidth);
    }
    if (inputWeightLabel.current) {
      setWeightLabelWidth(inputWeightLabel.current.offsetWidth);
    }

    setSelectValues({
      ...selectValues,
      time_direction:
        currentValue.time_direction === null || currentValue.time_direction === undefined
          ? ''
          : currentValue.time_direction.toString(),
      reply_type:
        currentValue.reply_type === null || currentValue.reply_type === undefined
          ? 'reply'
          : currentValue.reply_type.toString(),
    });
  }, []);

  const onClickAddVerbs = useCallback(
    (_id: string, index: number) => {
      if (currentValue.verbs) {
        const newArray = currentValue.verbs.slice();
        newArray.splice(index + 1, 0, '');
        setCurrentValue({
          ...currentValue,
          verbs: newArray,
        });
        verbsInsert(index + 1, {});
      }
    },
    [currentValue.verbs]
  );

  const onClickRemoveVerbs = useCallback(
    (_id: string, index: number) => {
      if (currentValue.verbs) {
        const newArray = currentValue.verbs.filter((_s: string, i: number) => i !== index);
        setCurrentValue({
          ...currentValue,
          verbs: newArray,
        });
        // 残りが1つなら削除しない
        if (verbsFields.length !== 1) {
          verbsRemove(index);
        }
      }
    },
    [currentValue.verbs]
  );

  const onClickAddExcludeVerbs = useCallback(
    (_id: string, index: number) => {
      if (currentValue.exclude_verbs) {
        const newArray = currentValue.exclude_verbs.slice();
        newArray.splice(index + 1, 0, '');
        setCurrentValue({
          ...currentValue,
          exclude_verbs: newArray,
        });
        excludeVerbsInsert(index + 1, {});
      }
    },
    [currentValue.exclude_verbs]
  );

  const onClickRemoveExcludeVerbs = useCallback(
    (_id: string, index: number) => {
      if (currentValue.exclude_verbs) {
        const newArray = currentValue.exclude_verbs.filter((_s: string, i: number) => i !== index);
        setCurrentValue({
          ...currentValue,
          exclude_verbs: newArray,
        });
        if (excludeVerbsFields.length !== 1) {
          excludeVerbsRemove(index);
        }
      }
    },
    [currentValue.exclude_verbs]
  );

  const onClickAddKakujyoshi = useCallback(
    (_id: string, index: number) => {
      if (currentValue.kaku_jyoshi) {
        const newArray = currentValue.kaku_jyoshi.slice();
        newArray.splice(index + 1, 0, '');
        setCurrentValue({
          ...currentValue,
          kaku_jyoshi: newArray,
        });
        kakujyoshiInsert(index + 1, {});
      }
    },
    [currentValue.kaku_jyoshi]
  );

  const onClickRemoveKakujyoshi = useCallback(
    (_id: string, index: number) => {
      if (currentValue.kaku_jyoshi) {
        const newArray = currentValue.kaku_jyoshi.filter((_s: string, i: number) => i !== index);
        setCurrentValue({
          ...currentValue,
          kaku_jyoshi: newArray,
        });
        if (kakujyoshiFields.length !== 1) {
          kakujyoshiRemove(index);
        }
      }
    },
    [currentValue.kaku_jyoshi]
  );

  const onClickAddExcludeNouns = useCallback(
    (_id: string, index: number) => {
      if (currentValue.exclude_nouns) {
        const newArray = currentValue.exclude_nouns.slice();
        newArray.splice(index + 1, 0, '');
        setCurrentValue({
          ...currentValue,
          exclude_nouns: newArray,
        });
        excludeNounsInsert(index + 1, {});
      }
    },
    [currentValue.exclude_nouns]
  );

  const onClickRemoveExcludeNouns = useCallback(
    (_id: string, index: number) => {
      if (currentValue.exclude_nouns) {
        const newArray = currentValue.exclude_nouns.filter((_s: string, i: number) => i !== index);
        setCurrentValue({
          ...currentValue,
          exclude_nouns: newArray,
        });
        if (excludeNounsFields.length !== 1) {
          excludeNounsRemove(index);
        }
      }
    },
    [currentValue.exclude_nouns]
  );

  return (
    <DialogContent dividers={props.dividers}>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Verb Group Name
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(verbGroupNameRegister)}
          error={!!errors.verb_group_name}
          label="Verb Group Name"
          name="verb_group_name"
          id="verb-group-name"
          className={classes.formControl}
          helperText={
            errors.verb_group_name
              ? (errors.verb_group_name as FieldError)['message']
              : '動詞グループ名'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.verb_group_name}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Verb Name
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(verbNameRegister)}
          error={!!errors.verb_name}
          label="Verb Name"
          name="verb_name"
          id="verb-name"
          className={classes.formControl}
          helperText={errors.verb_name ? (errors.verb_name as FieldError)['message'] : '動詞名'}
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.verb_name}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Verbs</Typography>
        <div className={classes.formControl}>
          {verbsFields.map((item, index: number) => {
            return (
              <UnbListTextField
                key={item.id + '-' + index}
                inputRef={register(verbsRegister(verbsFields, index))}
                error={!!(errors.verbs && (errors.verbs as FieldError[])[index])}
                helperText={
                  errors.verbs &&
                  (errors.verbs as { name: FieldError }[])[index] &&
                  (errors.verbs as { name: FieldError }[])[index].name?.message
                    ? (errors.verbs as { name: FieldError }[])[index].name?.message
                    : '動詞'
                }
                multiline
                name={`verbs[${index}].name`}
                id={'verbs-' + index}
                labelText="Verbs"
                defaultText={item.name}
                index={index}
                onClickAdd={onClickAddVerbs}
                onClickRemove={onClickRemoveVerbs}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Exclude Verbs</Typography>
        <div className={classes.formControl}>
          {excludeVerbsFields.map((item, index: number) => {
            return (
              <UnbListTextField
                key={item.id + '-' + index}
                inputRef={register(excludeVerbsRegister(excludeVerbsFields, index))}
                error={!!(errors.exclude_verbs && (errors.exclude_verbs as FieldError[])[index])}
                helperText={
                  errors.exclude_verbs &&
                  (errors.exclude_verbs as { name: FieldError }[])[index] &&
                  (errors.exclude_verbs as { name: FieldError }[])[index].name?.message
                    ? (errors.exclude_verbs as { name: FieldError }[])[index].name?.message
                    : '対象外動詞'
                }
                multiline
                name={`exclude_verbs[${index}].name`}
                id={'exclude-verbs-' + index}
                labelText="Exclude Verbs"
                defaultText={item.name}
                index={index}
                onClickAdd={onClickAddExcludeVerbs}
                onClickRemove={onClickRemoveExcludeVerbs}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Verbs Regex</Typography>
        <TextField
          inputRef={register(verbsRegexRegister)}
          error={!!errors.verbs_regex}
          label="Verbs Regex"
          id="verbs-regex"
          name="verbs_regex"
          className={classes.formControl}
          helperText={
            errors.verbs_regex
              ? (errors.verbs_regex as FieldError)['message']
              : '対象動詞（正規表現）'
          }
          margin="dense"
          multiline
          variant="outlined"
          defaultValue={currentValue.verbs_regex}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Exclude Verbs Regex</Typography>
        <TextField
          inputRef={register(verbsRegexRegister)}
          error={!!errors.exclude_verb_regex}
          label="Exclude Verbs Regex"
          id="exclude-verb-regex"
          name="exclude_verb_regex"
          className={classes.formControl}
          helperText={
            errors.exclude_verb_regex
              ? (errors.exclude_verb_regex as FieldError)['message']
              : '対象外動詞（正規表現）'
          }
          margin="dense"
          multiline
          variant="outlined"
          defaultValue={currentValue.exclude_verb_regex}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Time Direction
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="time-direction-label" ref={inputTimeDirectionLabel}>
            Time Direction
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.time_direction}
            error={!!errors.time_direction}
            labelId="time-direction-label"
            id="time-direction"
            name="time_direction"
            onChange={handleTimeDirectionChange}
            labelWidth={timeDirectionLabelWidth}
          >
            {Object.values(TimeDirectionTypeEnum).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
          {errors.time_direction ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.time_direction as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>時間方向</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Required
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="required-label" ref={inputIsRequiredLabel}>
            Required
          </InputLabel>
          <Select
            name="is_required"
            inputRef={register}
            value={convertBool2Num(currentValue.is_required)}
            labelId="required-label"
            id="is-required"
            onChange={handleIsRequiredChange}
            labelWidth={isRequiredLabelWidth}
          >
            <MenuItem value={1}>TRUE</MenuItem>
            <MenuItem value={0}>FALSE</MenuItem>
          </Select>
          {errors.is_required ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.is_required as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>必須</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Reply Type
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="reply-type-label" ref={inputReplyTypeLabel}>
            Reply Type
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.reply_type}
            error={!!errors.reply_type}
            labelId="reply-type-label"
            id="reply-type"
            name="reply_type"
            onChange={handleReplyTypeChange}
            labelWidth={replyTypeLabelWidth}
          >
            {Object.values(ReplyTypeEnum).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
          {errors.reply_type ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.reply_type as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>種別</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Kakujyoshi</Typography>
        <div className={classes.formControl}>
          {kakujyoshiFields.map((item, index: number) => {
            return (
              <UnbListTextField
                key={item.id + '-' + index}
                inputRef={register(kakujyoshiRegister(kakujyoshiFields, index))}
                error={!!(errors.kaku_jyoshi && (errors.kaku_jyoshi as FieldError[])[index])}
                helperText={
                  errors.kaku_jyoshi &&
                  (errors.kaku_jyoshi as { name: FieldError }[])[index] &&
                  (errors.kaku_jyoshi as { name: FieldError }[])[index].name?.message
                    ? (errors.kaku_jyoshi as { name: FieldError }[])[index].name?.message
                    : '格助詞'
                }
                multiline
                name={`kaku_jyoshi[${index}].name`}
                id={'kaku-jyoshi-' + index}
                labelText="Kakujyoshi"
                defaultText={item.name}
                index={index}
                onClickAdd={onClickAddKakujyoshi}
                onClickRemove={onClickRemoveKakujyoshi}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Is Abbreviate
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="is-abbreviate-label" ref={inputIsAbbreviateLabel}>
            Is Abbreviate
          </InputLabel>
          <Select
            name="is_abbreviate"
            inputRef={register}
            value={convertBool2Num(currentValue.is_abbreviate)}
            labelId="is-abbreviate-label"
            id="is-abbreviate"
            onChange={handleIsAbbreviateChange}
            labelWidth={isAbbreviateLabelWidth}
          >
            <MenuItem value={1}>TRUE</MenuItem>
            <MenuItem value={0}>FALSE</MenuItem>
          </Select>
          {errors.is_abbreviate ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.is_abbreviate as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>省略</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value Types</Typography>
        <TextField
          inputRef={register(valueTypesRegister)}
          error={!!errors.value_types}
          label="Value Types"
          name="value_types"
          id="value-types"
          className={classes.formControl}
          helperText={
            errors.value_types ? (errors.value_types as FieldError)['message'] : '格助詞の値の種別'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.value_types}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Exclude Nouns</Typography>
        <div className={classes.formControl}>
          {excludeNounsFields.map((item, index: number) => {
            return (
              <UnbListTextField
                key={item.id + '-' + index}
                inputRef={register(excludeNounsRegister(excludeNounsFields, index))}
                error={!!(errors.exclude_nouns && (errors.exclude_nouns as FieldError[])[index])}
                helperText={
                  errors.exclude_nouns &&
                  (errors.exclude_nouns as { name: FieldError }[])[index] &&
                  (errors.exclude_nouns as { name: FieldError }[])[index].name?.message
                    ? (errors.exclude_nouns as { name: FieldError }[])[index].name?.message
                    : '対象外名詞'
                }
                multiline
                name={`exclude_nouns[${index}].name`}
                id={'exclude-nouns-' + index}
                labelText="Exclude Nouns"
                defaultText={item.name}
                index={index}
                onClickAdd={onClickAddExcludeNouns}
                onClickRemove={onClickRemoveExcludeNouns}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Decision Condition</Typography>
        <TextField
          inputRef={register(decisionConditionRegister)}
          error={!!errors.decision_condition}
          label="Decision Condition"
          name="decision_condition"
          id="decision-condition"
          className={classes.formControl}
          helperText={
            errors.decision_condition
              ? (errors.decision_condition as FieldError)['message']
              : '適用条件'
          }
          margin="dense"
          variant="outlined"
          multiline
          defaultValue={currentValue.decision_condition}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Decision Command</Typography>
        <TextField
          inputRef={register(decisionCommandRegister)}
          error={!!errors.decision_command}
          label="Decision Command"
          name="decision_command"
          id="decision-command"
          className={classes.formControl}
          helperText={
            errors.decision_command
              ? (errors.decision_command as FieldError)['message']
              : '適用条件コマンド'
          }
          margin="dense"
          variant="outlined"
          multiline
          defaultValue={currentValue.decision_command}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Weight
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel htmlFor="weight" ref={inputWeightLabel} error={!!errors.weight}>
            Weight
          </InputLabel>
          <OutlinedInput
            inputRef={register(weightRegistr)}
            error={!!errors.weight}
            id="weight"
            type="number"
            name="weight"
            defaultValue={currentValue.weight}
            onChange={handleWeightChange}
            labelWidth={weightLabelWidth}
          />
          {errors.weight ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.weight as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>重み</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Confirmation Reply Task ID</Typography>
        <TextField
          inputRef={register(confirmationReplyTaskIdRegister)}
          error={!!errors.confirmation_reply_task_id}
          label="Confirmation Reply Task ID"
          name="confirmation_reply_task_id"
          id="confirmation-reply-task-id"
          className={classes.formControl}
          helperText={
            errors.confirmation_reply_task_id
              ? (errors.confirmation_reply_task_id as FieldError)['message']
              : '確認用タスクID'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.confirmation_reply_task_id}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Rule ID</Typography>
        <TextField
          inputRef={register(ruleIDRegister)}
          error={!!errors.rule_id}
          label="Rule ID"
          name="rule_id"
          id="rule-id"
          className={classes.formControl}
          helperText={errors.rule_id ? (errors.rule_id as FieldError)['message'] : 'ルールID'}
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.rule_id}
        />
      </div>
    </DialogContent>
  );
};

export default UnbVerbDefinitionDialogContent;
