import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useFormContext, FieldError } from 'react-hook-form';
import { FormHelperMessages, ValidationErrorMessages } from 'constants/Messages';

type UnbWordMasterDialogContentProps = {
  dividers?: boolean;
  noiseValue?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    requiredTypo: {
      color: theme.palette.error.main,
    },
  })
);

const requiredSpan = (classes: Record<any, string>): JSX.Element => {
  return <span className={classes.requiredTypo}>*</span>;
};

const UnbWordMasterDialogContent: React.FC<UnbWordMasterDialogContentProps> = (props) => {
  const classes = useStyles();
  const { register, errors } = useFormContext();
  const noiseRegister = {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  };

  return (
    <DialogContent dividers={props.dividers}>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>ノイズ値{requiredSpan(classes)}</Typography>
        <TextField
          inputRef={register(noiseRegister)}
          error={!!errors.noise}
          name="noise"
          label="ノイズ値"
          id="noise-value"
          className={classes.formControl}
          helperText={
            errors.noise
              ? (errors.noise as FieldError)['message']
              : FormHelperMessages.WORD_MASTER_NOISE
          }
          margin="dense"
          variant="outlined"
          defaultValue={props.noiseValue}
        />
      </div>
    </DialogContent>
  );
};

export default UnbWordMasterDialogContent;
