import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { TemplateVariable } from 'api/models/TemplateVariable';
import UnbRequiredSpan from 'components/atoms/UnbRequiredSpan';
import {
  nameRegister,
  templateIDRegister,
  valueRegister,
} from 'components/form/validator/TemplateVariableRegister';
import React from 'react';
import { useFormContext, FieldError } from 'react-hook-form';

type UnbTemplateVariableDialogContentProps = {
  dividers?: boolean;
  templateVariable: TemplateVariable;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const UnbTemplateVariableDialogContent: React.FC<UnbTemplateVariableDialogContentProps> = (
  props
) => {
  const classes = useStyles();
  const { register, errors } = useFormContext();

  return (
    <DialogContent dividers={props.dividers}>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Template ID
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(templateIDRegister)}
          error={!!errors.template_id}
          label="Template ID"
          name="template_id"
          id="template-id"
          className={classes.formControl}
          helperText={
            errors.template_id
              ? (errors.template_id as FieldError)['message']
              : 'テンプレート変数を識別するためのID'
          }
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.template_id}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Name
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(nameRegister)}
          error={!!errors.name}
          label="Name"
          name="name"
          id="name"
          className={classes.formControl}
          helperText={errors.name ? (errors.name as FieldError)['message'] : 'テンプレート変数名'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.name}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value 1</Typography>
        <TextField
          inputRef={register(valueRegister)}
          error={!!errors.v1}
          label="Value 1"
          name="v1"
          id="v1"
          className={classes.formControl}
          helperText={errors.v1 ? (errors.v1 as FieldError)['message'] : '値1'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.v1}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value 2</Typography>
        <TextField
          inputRef={register(valueRegister)}
          error={!!errors.v2}
          label="Value 2"
          name="v2"
          id="v2"
          className={classes.formControl}
          helperText={errors.v2 ? (errors.v2 as FieldError)['message'] : '値2'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.v2}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value 3</Typography>
        <TextField
          inputRef={register(valueRegister)}
          error={!!errors.v3}
          label="Value 3"
          name="v3"
          id="v3"
          className={classes.formControl}
          helperText={errors.v3 ? (errors.v3 as FieldError)['message'] : '値3'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.v3}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value 4</Typography>
        <TextField
          inputRef={register(valueRegister)}
          error={!!errors.v4}
          label="Value 4"
          name="v4"
          id="v4"
          className={classes.formControl}
          helperText={errors.v4 ? (errors.v4 as FieldError)['message'] : '値4'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.v4}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value 5</Typography>
        <TextField
          inputRef={register(valueRegister)}
          error={!!errors.v5}
          label="Value 5"
          name="v5"
          id="v5"
          className={classes.formControl}
          helperText={errors.v5 ? (errors.v5 as FieldError)['message'] : '値5'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.v5}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Value 6</Typography>
        <TextField
          inputRef={register(valueRegister)}
          error={!!errors.v6}
          label="Value 6"
          name="v6"
          id="v6"
          className={classes.formControl}
          helperText={errors.v6 ? (errors.v6 as FieldError)['message'] : '値6'}
          margin="dense"
          variant="outlined"
          defaultValue={props.templateVariable.v6}
        />
      </div>
    </DialogContent>
  );
};

export default UnbTemplateVariableDialogContent;
