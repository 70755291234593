import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LabelText } from 'constants/Messages';
import { uiTemplateVariableActions } from 'store/uis/template-variable/action';

export type UITemplateVariableState = {
  error?: any;
  openAlertDialog: boolean;
  openDialog: boolean;
  okBtnText: string;
  displayDelete: boolean;
  action?: 'ADD' | 'UPDATE';
  isDisplayCopyBtn: boolean;
  openCopyAlertDialog: boolean;
};

export const initialState: UITemplateVariableState = {
  openAlertDialog: false,
  openDialog: false,
  okBtnText: LabelText.UPDATE_LABEL,
  displayDelete: false,
  isDisplayCopyBtn: false,
  openCopyAlertDialog: false,
};

export const uiTemplateVacriableReducer = reducerWithInitialState(initialState)
  .case(uiTemplateVariableActions.setOpenCopyAlertDialog, (state, payload) => {
    return {
      ...state,
      openCopyAlertDialog: payload,
    };
  })
  .case(uiTemplateVariableActions.setIsDisplayCopyBtn, (state, payload) => {
    return {
      ...state,
      isDisplayCopyBtn: payload,
    };
  })
  .case(uiTemplateVariableActions.setOkBtnText, (state, payload) => {
    return {
      ...state,
      okBtnText: payload,
    };
  })
  .case(uiTemplateVariableActions.setOpenDialog, (state, payload) => {
    return {
      ...state,
      openDialog: payload,
    };
  })
  .case(uiTemplateVariableActions.setOpenAlertDialog, (state, payload) => {
    return {
      ...state,
      openAlertDialog: payload,
    };
  })
  .case(uiTemplateVariableActions.setDisplayDelete, (state, payload) => {
    return {
      ...state,
      displayDelete: payload,
    };
  })
  .case(uiTemplateVariableActions.setAction, (state, payload) => {
    return {
      ...state,
      action: payload,
    };
  });
