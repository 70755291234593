/** @fileOverview 会話データレデューサ */
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ChatRule } from 'api/models/ChatRule';
import { chatRuleActions } from 'store/domains/chat-rule/action';
import { cleanStringArray } from 'utils/CollectionUtil';
import { ScenarioRelationships } from 'api/models/ScenarioRelationships';

/** 会話データ状態 */
export type ChatRuleState = {
  rows: Array<ChatRule>;
  count: number;
  fetchOption: {
    limit: number;
    page: number;
  };
  currentChatRule: ChatRule;
  loading: boolean;
  error?: Error;
  searchParams: Record<string, string | number>;
  exportChatRuleFile?: Blob;
  relationships: ScenarioRelationships;
};

/** 初期状態 */
export const initialState: ChatRuleState = {
  rows: [],
  count: 0,
  fetchOption: {
    limit: 20,
    page: 0,
  },
  currentChatRule: {} as ChatRule,
  loading: false,
  searchParams: {
    starting_flag: 2,
    rule_id: '',
    reply: '',
  } as Record<string, string | number>,
  relationships: {} as ScenarioRelationships,
};

/** 会話データレデューサ */
export const chatRuleReducer = reducerWithInitialState(initialState)
  // 取得
  .case(chatRuleActions.getChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.getChatRule.done, (state, payload) => {
    const datas = payload.result.result.row.map((r: ChatRule) => r);
    const count = payload.result.result.count;
    return {
      ...state,
      rows: datas,
      count: count,
    };
  })
  .case(chatRuleActions.getChatRule.failed, (state, payload) => {
    return state;
  })
  // 検索
  .case(chatRuleActions.searchChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.searchChatRule.done, (state, payload) => {
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(chatRuleActions.searchChatRule.failed, (state, payload) => {
    return state;
  })
  // 追加
  .case(chatRuleActions.addChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.addChatRule.done, (state, payload) => {
    const result = payload.result.result;
    return {
      ...state,
      rows: state.rows.concat(result),
    };
  })
  .case(chatRuleActions.addChatRule.failed, (state, payload) => {
    return state;
  })
  // 更新
  .case(chatRuleActions.updateChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.updateChatRule.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows.slice();
    const index = rows.findIndex((r: ChatRule) => r.chat_rule_id === result.chat_rule_id);

    if (result.before_rule_id !== null && result.before_rule_id !== undefined) {
      result.before_rule_id = cleanStringArray(result.before_rule_id);
    }
    if (result.next_rule_id !== null && result.next_rule_id !== undefined) {
      result.next_rule_id = cleanStringArray(result.next_rule_id);
    }
    if (result.template_ids !== null && result.template_ids !== undefined) {
      result.template_ids = cleanStringArray(result.template_ids);
    }
    if (result.qa_query !== null && result.qa_query !== undefined) {
      result.qa_query = cleanStringArray(result.qa_query);
    }

    rows[index] = result;
    return {
      ...state,
      rows: rows,
    };
  })
  .case(chatRuleActions.updateChatRule.failed, (state, payload) => {
    return state;
  })
  // 削除
  .case(chatRuleActions.deleteChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.deleteChatRule.done, (state, payload) => {
    const id = payload.params.id;
    const deletedRows = state.rows.filter((r: ChatRule) => r.chat_rule_id !== id);
    return {
      ...state,
      rows: deletedRows,
    };
  })
  .case(chatRuleActions.deleteChatRule.failed, (state, payload) => {
    return state;
  })
  // 反映
  .case(chatRuleActions.reloadPrivateChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.reloadPrivateChatRule.done, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.reloadPrivateChatRule.failed, (state, payload) => {
    return state;
  })
  // エクスポート
  .case(chatRuleActions.exportChatRule.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.exportChatRule.done, (state, payload) => {
    const data = payload.result.data;
    const blob = new Blob([data], {
      type: 'application/json',
    });

    return {
      ...state,
      exportChatRuleFile: blob,
    };
  })
  .case(chatRuleActions.exportChatRule.failed, (state, payload) => {
    return state;
  })
  // 読込中
  .case(chatRuleActions.setLoading, (state, payload) => {
    return {
      ...state,
      loading: payload,
    };
  })
  // ページ毎の件数、ページ設定
  .case(chatRuleActions.setFetchOption, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.limit,
        page: payload.page,
      },
    };
  })
  // 行選択
  .case(chatRuleActions.setCurrentChatRule, (state, payload) => {
    return {
      ...state,
      currentChatRule: payload,
    };
  })
  // 検索設定
  .case(chatRuleActions.setSearchParams, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.fetchOption.limit,
        page: payload.fetchOption.page,
      },
      searchParams: payload.searchParams,
    };
  })
  // シナリオ同士の依存関係・関連性の取得
  .case(chatRuleActions.getScenarioRelationships.started, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.getScenarioRelationships.done, (state, payload) => {
    return {
      ...state,
      relationships: payload.result.result,
    };
  })
  .case(chatRuleActions.getScenarioRelationships.failed, (state, payload) => {
    return state;
  })
  .case(chatRuleActions.clearScenarioRelationships, (state, payload) => {
    return {
      ...state,
      relationships: payload as ScenarioRelationships,
    };
  });
