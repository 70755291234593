import { FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { TaskTypeEnum } from 'api/types/TaskType';
import { TimeDirectionTypeEnum } from 'api/types/TypeDirectionType';
import {
  isNeedNextActionRegister,
  isPermitOtherValueRegister,
  isRequiredRegister,
  taskTypeRegister,
  timeDirectionRegister,
} from 'components/form/validator/TaskRegister';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      alignItems: 'center',
    },
    formControlSM: {
      margin: theme.spacing(1),
      minWidth: 100,
    },
    formControlMD: {
      margin: theme.spacing(1),
      minWidth: 130,
    },
    addButton: {
      margin: '0 0 0 auto',
    },
  })
);

type UnbTaskHeadBarProps = {
  handleSearch: (data: Record<string, string | number>) => void;
};

const UnbTaskHeadBar: React.FC<UnbTaskHeadBarProps> = (props) => {
  const classes = useStyles();
  const { handleSubmit, register, setValue } = useFormContext();
  const [isRequired, setIsRequired] = useState(2);
  const inputIsRequiredLabel = React.useRef<HTMLLabelElement>(null);
  const [isRequiredLabelWidth, setIsRequiredLabelWidth] = React.useState(0);

  const [isPermitOtherValue, setIsPermitOtherValue] = useState(2);
  const inputIsPermitOtherValueLabel = React.useRef<HTMLLabelElement>(null);
  const [isPermitOtherValueLabelWidth, setIsPermitOtherValueLabelWidth] = React.useState(0);

  const [isNeedNextAction, setIsNeedNextAction] = useState(2);
  const inputIsNeedNextActionLabel = React.useRef<HTMLLabelElement>(null);
  const [isNeedNextActionLabelWidth, setIsNeedNextActionLabelWidth] = React.useState(0);

  const [timeDirection, setTimeDirection] = useState<TimeDirectionTypeEnum | 'all'>('all');
  const inputTimeDirectionLabel = React.useRef<HTMLLabelElement>(null);
  const [timeDirectionLabelWidth, setTimeDirectionLabelWidth] = React.useState(0);

  const [taskType, setTaskType] = useState<TaskTypeEnum | 'all'>('all');
  const inputTaskTypeLabel = React.useRef<HTMLLabelElement>(null);
  const [taskTypeLabelWidth, setTaskTypeLabelWidth] = React.useState(0);

  const handleChangeRrequired = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const val = event.target.value as number;
    setIsRequired(val);
    setValue('required', val);
  };

  const handleChangePermitOtherValue = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const val = event.target.value as number;
    setIsPermitOtherValue(val);
    setValue('is_permit_other_value', val);
  };

  const handleChangeNeedNextAction = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const val = event.target.value as number;
    setIsNeedNextAction(val);
    setValue('is_need_next_action', val);
  };

  const handleChangeTimeDirection = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const valStr = event.target.value as string;
    if (valStr === 'all') {
      setValue('time_direction_type', 'all');
      setTimeDirection('all');
    } else {
      const val = event.target.value as TimeDirectionTypeEnum;
      setValue('time_direction_type', val);
      setTimeDirection(val);
    }
  };

  const handleChangeTaskType = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const valStr = event.target.value as string;
    if (valStr === 'all') {
      setValue('task_type', 'all');
      setTaskType('all');
    } else {
      const val = event.target.value as TaskTypeEnum;
      setValue('task_type', val);
      setTaskType(val);
    }
  };

  useEffect(() => {
    register(isRequiredRegister.custom, isRequiredRegister.rule);
    register(isPermitOtherValueRegister.custom, isPermitOtherValueRegister.rule);
    register(isNeedNextActionRegister.custom, isNeedNextActionRegister.rule);
    register(timeDirectionRegister.custom, timeDirectionRegister.rule);
    register(taskTypeRegister.custom, taskTypeRegister.rule);
    setValue('required', 2);
    setValue('is_permit_other_value', 2);
    setValue('is_need_next_action', 2);
    setValue('time_direction_type', 'all');
    setValue('task_type', 'all');
    if (inputIsRequiredLabel.current) {
      setIsRequiredLabelWidth(inputIsRequiredLabel.current.offsetWidth);
    }
    if (inputIsPermitOtherValueLabel.current) {
      setIsPermitOtherValueLabelWidth(inputIsPermitOtherValueLabel.current.offsetWidth);
    }
    if (inputIsNeedNextActionLabel.current) {
      setIsNeedNextActionLabelWidth(inputIsNeedNextActionLabel.current.offsetWidth);
    }
    if (inputTimeDirectionLabel.current) {
      setTimeDirectionLabelWidth(inputTimeDirectionLabel.current.offsetWidth);
    }
    if (inputTaskTypeLabel.current) {
      setTaskTypeLabelWidth(inputTaskTypeLabel.current.offsetWidth);
    }
  }, []);

  return (
    <form className={classes.root} onSubmit={handleSubmit(props.handleSearch)}>
      <TextField
        id="search-value"
        label="キーワード"
        type="search"
        margin="dense"
        variant="outlined"
        name="value"
        inputRef={register}
      />
      <FormControl variant="outlined" margin="dense" className={classes.formControlSM}>
        <InputLabel id="time-direction-label" ref={inputTimeDirectionLabel}>
          Time Direction
        </InputLabel>
        <Select
          labelId="time-direction-label"
          id="time-direction"
          name="time_direction_type"
          inputRef={register}
          value={timeDirection}
          onChange={handleChangeTimeDirection}
          labelWidth={timeDirectionLabelWidth}
        >
          <MenuItem value={'all'}>全て</MenuItem>
          {Object.values(TimeDirectionTypeEnum).map((val) => {
            return (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControlSM}>
        <InputLabel id="task-type-label" ref={inputTaskTypeLabel}>
          Task Type
        </InputLabel>
        <Select
          labelId="task-type-label"
          id="task-type"
          name="task_type"
          inputRef={register}
          value={taskType}
          onChange={handleChangeTaskType}
          labelWidth={taskTypeLabelWidth}
        >
          <MenuItem value={'all'}>全て</MenuItem>
          {Object.values(TaskTypeEnum).map((val) => {
            return (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControlSM}>
        <InputLabel id="is-required-label" ref={inputIsRequiredLabel}>
          Required
        </InputLabel>
        <Select
          labelId="is-required-label"
          id="is-required"
          name="required"
          inputRef={register}
          value={isRequired}
          onChange={handleChangeRrequired}
          labelWidth={isRequiredLabelWidth}
        >
          <MenuItem value={2}>全て</MenuItem>
          <MenuItem value={1}>TRUE</MenuItem>
          <MenuItem value={0}>FALSE</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControlMD}>
        <InputLabel id="is-permit-other-value-label" ref={inputIsPermitOtherValueLabel}>
          Permit Other Value
        </InputLabel>
        <Select
          labelId="is-permit-other-value-label"
          id="is-permit-other-value"
          name="is_permit_other_value"
          inputRef={register}
          value={isPermitOtherValue}
          onChange={handleChangePermitOtherValue}
          labelWidth={isPermitOtherValueLabelWidth}
        >
          <MenuItem value={2}>全て</MenuItem>
          <MenuItem value={1}>TRUE</MenuItem>
          <MenuItem value={0}>FALSE</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControlMD}>
        <InputLabel id="is-need-next-action-label" ref={inputIsNeedNextActionLabel}>
          Need Next Action
        </InputLabel>
        <Select
          labelId="is-need-next-action-label"
          id="is-need-next-action"
          name="is_need_next_action"
          inputRef={register}
          value={isNeedNextAction}
          onChange={handleChangeNeedNextAction}
          labelWidth={isNeedNextActionLabelWidth}
        >
          <MenuItem value={2}>全て</MenuItem>
          <MenuItem value={1}>TRUE</MenuItem>
          <MenuItem value={0}>FALSE</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
        startIcon={<SearchIcon />}
      >
        検索
      </Button>
    </form>
  );
};

export default UnbTaskHeadBar;
