import { IDENTITY_CONFIG, METADATA_OIDC } from 'constants/OIDCConfig';
import { UserManager, WebStorageStateStore, User, SigninRequest } from 'oidc-client';

class AuthService {
  userMng: UserManager;

  constructor() {
    const settings = {
      authority: IDENTITY_CONFIG.authority,
      client_id: IDENTITY_CONFIG.client_id,
      client_secret: `${IDENTITY_CONFIG.client_secret}`,
      redirect_uri: `${IDENTITY_CONFIG.redirect_uri}`,
      silent_redirect_uri: `${IDENTITY_CONFIG.silent_redirect_uri}`,
      // `post_logout_redirect_uri` を指定すると `id_token_hint` のパラメータが必要だが、
      // 現状、OIDC及びIDP側の事情により `id_token` を返却できていないため、未指定にし
      // OP-initiated flow でログアウトを実現する
      // post_logout_redirect_uri: `${IDENTITY_CONFIG.post_logout_redirect_uri}`,
      response_type: `${IDENTITY_CONFIG.responseType}`,
      scope: IDENTITY_CONFIG.scope,
      response_mode: 'query',
    };
    this.userMng = new UserManager({
      ...settings,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    // Logger
    this.userMng.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userMng.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.signinRedirect();
    });
  }

  signinRedirectCallback = (): Promise<User> => {
    return this.userMng.signinRedirectCallback();
  };

  getUser = async (): Promise<User | null> => {
    const user = await this.userMng.getUser();
    return user;
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (): Promise<void> => {
    localStorage.setItem('redirectUri', window.location.pathname);
    return this.userMng.signinRedirect();
  };

  navigateToLogin = (): void => {
    window.location.replace('/login');
  };

  isAuthenticated = (): boolean => {
    const session = sessionStorage.getItem(
      `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
    );
    if (session === null || session === undefined) {
      console.log('unauthenticated');
      return false;
    }

    const oidcStorage = JSON.parse(session);
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = (): void => {
    this.userMng
      .signinSilent()
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = (): void => {
    this.userMng.signinSilentCallback();
  };

  createSigninRequest = (): Promise<SigninRequest> => {
    return this.userMng.createSigninRequest();
  };

  logout = (): void => {
    this.userMng.signoutRedirect();
    this.userMng.clearStaleState();
  };

  // TODO: OIDC側の方針が決まり次第実装する
  isPrivate = (): boolean => {
    return true;
  };

  signoutRedirectCallback = (): void => {
    this.userMng.signoutRedirectCallback();
    this.userMng.clearStaleState();
  };
}

export default AuthService;
