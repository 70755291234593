import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { uiChatToolsActions } from 'store/uis/tools/chat/action';
import { ChatReply } from 'api/models/ChatReply';

export type UIChatToolsState = {
  loading: boolean;
  privateOnly: boolean;
  conversationValue: string;
  selectedReply: ChatReply;
};

export const initialState: UIChatToolsState = {
  loading: false,
  privateOnly: false,
  conversationValue: '',
  selectedReply: {} as ChatReply,
};

export const uiChatToolsReducer = reducerWithInitialState(initialState)
  .case(uiChatToolsActions.setLoading, (state, payload) => {
    return {
      ...state,
      loading: payload,
    };
  })
  .case(uiChatToolsActions.setPrivateOnly, (state, payload) => {
    return {
      ...state,
      privateOnly: payload,
    };
  })
  .case(uiChatToolsActions.setConversationValue, (state, payload) => {
    return {
      ...state,
      conversationValue: payload,
    };
  })
  .case(uiChatToolsActions.setSelectedReply, (state, payload) => {
    return {
      ...state,
      selectedReply: payload,
    };
  });
