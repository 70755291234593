import { User } from 'oidc-client';
import { UserInfo } from 'api/models/UserInfo';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { authActions } from 'store/domains/auth/action';

export type AuthState = {
  user: User;
  userInfo: UserInfo;
  isAuthenticated: boolean;
  isPrivate: boolean;
  loading?: boolean;
  error?: any;
};

export const initialState: AuthState = {
  user: {} as User,
  userInfo: {} as UserInfo,
  isAuthenticated: false,
  isPrivate: false,
  loading: true,
};

export const authReducer = reducerWithInitialState(initialState)
  .case(authActions.setLoading, (state, payload) => {
    state.loading = payload;
    return Object.assign({}, state);
  })
  .case(authActions.setUser, (state, payload) => {
    state.user = payload;
    return Object.assign({}, state);
  })
  .case(authActions.setUserInfo, (state, paylod) => {
    return {
      ...state,
      userInfo: paylod,
    };
  })
  .case(authActions.getUserInfo.started, (state, payload) => {
    return state;
  })
  .case(authActions.getUserInfo.failed, (state, payload) => {
    return state;
  })
  .case(authActions.getUserInfo.done, (state, payload) => {
    return {
      ...state,
      userInfo: payload.result,
    };
  })
  .case(authActions.setAuthenticated, (state, payload) => {
    return {
      ...state,
      isAuthenticated: payload,
    };
  })
  .case(authActions.setIsPrivate, (state, payload) => {
    return {
      ...state,
      isPrivate: payload,
    };
  });
