import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, InputLabel } from '@material-ui/core';
import { ReplyTypeEnum } from 'api/models/VerbDefinition';
import { TimeDirectionTypeEnum } from 'api/types/TypeDirectionType';
import {
  isRequiredRegister,
  isAbbreviateRegister,
  timeDirectionRegister,
  replyTypeRegister,
} from 'components/form/validator/VerbDefinitionRegister';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    addButton: {
      margin: '0 0 0 auto',
    },
  })
);

type UnbVerbDefinitionHeadBarProps = {
  handleSearch: (data: Record<string, any>) => void;
};

const UnbVerbDefinitionHeadBar: React.FC<UnbVerbDefinitionHeadBarProps> = (props) => {
  const classes = useStyles();
  const { handleSubmit, register, setValue } = useFormContext();
  const [isRequired, setIsRequired] = useState(2);
  const inputIsRequiredLabel = React.useRef<HTMLLabelElement>(null);
  const [isRequiredLabelWidth, setIsRequiredLabelWidth] = React.useState(0);
  const [isAbbreviate, setIsAbbreviate] = useState(2);
  const inputIsAbbreviateLabel = React.useRef<HTMLLabelElement>(null);
  const [isAbbreviateLabelWidth, setIsAbbreviateLabelWidth] = React.useState(0);
  const [timeDirection, setTimeDirection] = useState<TimeDirectionTypeEnum | 'all'>('all');
  const inputTimeDirectionLabel = React.useRef<HTMLLabelElement>(null);
  const [timeDirectionLabelWidth, setTimeDirectionLabelWidth] = React.useState(0);
  const [replyType, setReplyType] = useState<ReplyTypeEnum | 'all'>('all');
  const inputReplyTypeLabel = React.useRef<HTMLLabelElement>(null);
  const [replyTypeLabelWidth, setReplyTypeLabelWidth] = React.useState(0);

  const handleChangeRrequired = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const val = event.target.value as number;
    setIsRequired(val);
    setValue('is_required', val);
  };

  const handleChangeAbbreviate = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const val = event.target.value as number;
    setIsAbbreviate(val);
    setValue('is_abbreviate', val);
  };

  const handleChangeTimeDirection = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const valStr = event.target.value as string;
    if (valStr === 'all') {
      setValue('time_direction', 'all');
      setTimeDirection('all');
    } else {
      const val = event.target.value as TimeDirectionTypeEnum;
      setValue('time_direction', val);
      setTimeDirection(val);
    }
  };

  const handleChangeReplyType = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const valStr = event.target.value as string;
    if (valStr === 'all') {
      setValue('reply_type', 'all');
      setReplyType('all');
    } else {
      const val = event.target.value as ReplyTypeEnum;
      setValue('reply_type', val);
      setReplyType(val);
    }
  };

  useEffect(() => {
    register(isRequiredRegister.custom, isRequiredRegister.rule);
    register(isAbbreviateRegister.custom, isAbbreviateRegister.rule);
    register(timeDirectionRegister.custom, timeDirectionRegister.rule);
    register(replyTypeRegister.custom, replyTypeRegister.rule);
    setValue('is_required', 2);
    setValue('is_abbreviate', 2);
    setValue('time_direction', 'all');
    setValue('reply_type', 'all');
    if (inputIsRequiredLabel.current) {
      setIsRequiredLabelWidth(inputIsRequiredLabel.current.offsetWidth);
    }
    if (inputIsAbbreviateLabel.current) {
      setIsAbbreviateLabelWidth(inputIsAbbreviateLabel.current.offsetWidth);
    }
    if (inputTimeDirectionLabel.current) {
      setTimeDirectionLabelWidth(inputTimeDirectionLabel.current.offsetWidth);
    }
    if (inputReplyTypeLabel.current) {
      setReplyTypeLabelWidth(inputReplyTypeLabel.current.offsetWidth);
    }
  }, []);

  return (
    <form className={classes.root} onSubmit={handleSubmit(props.handleSearch)}>
      <TextField
        id="search-value"
        label="キーワード"
        type="search"
        margin="dense"
        variant="outlined"
        name="value"
        inputRef={register}
      />
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel id="time-direction-label" ref={inputTimeDirectionLabel}>
          Time Direction
        </InputLabel>
        <Select
          labelId="time-direction-label"
          id="time-direction"
          name="time_direction"
          inputRef={register}
          value={timeDirection}
          onChange={handleChangeTimeDirection}
          labelWidth={timeDirectionLabelWidth}
        >
          <MenuItem value={'all'}>全て</MenuItem>
          {Object.values(TimeDirectionTypeEnum).map((val) => {
            return (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel id="reply-type-label" ref={inputReplyTypeLabel}>
          Reply Type
        </InputLabel>
        <Select
          labelId="reply-type-label"
          id="reply-type"
          name="reply_type"
          inputRef={register}
          value={replyType}
          onChange={handleChangeReplyType}
          labelWidth={replyTypeLabelWidth}
        >
          <MenuItem value={'all'}>全て</MenuItem>
          {Object.values(ReplyTypeEnum).map((val) => {
            return (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel id="is-required-label" ref={inputIsRequiredLabel}>
          Required
        </InputLabel>
        <Select
          labelId="is-required-label"
          id="is-required"
          name="is_required"
          inputRef={register}
          value={isRequired}
          onChange={handleChangeRrequired}
          labelWidth={isRequiredLabelWidth}
        >
          <MenuItem value={2}>全て</MenuItem>
          <MenuItem value={1}>TRUE</MenuItem>
          <MenuItem value={0}>FALSE</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel id="is-abbreviate-label" ref={inputIsAbbreviateLabel}>
          Abbreviate
        </InputLabel>
        <Select
          labelId="is-abbreviate-label"
          id="is-abbreviate"
          name="is_rabreviate"
          inputRef={register}
          value={isAbbreviate}
          onChange={handleChangeAbbreviate}
          labelWidth={isAbbreviateLabelWidth}
        >
          <MenuItem value={2}>全て</MenuItem>
          <MenuItem value={1}>TRUE</MenuItem>
          <MenuItem value={0}>FALSE</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
        startIcon={<SearchIcon />}
      >
        検索
      </Button>
    </form>
  );
};

export default UnbVerbDefinitionHeadBar;
