import React, { ChangeEvent, useMemo } from 'react';
import UnbMessageInput from 'components/molecules/UnbMessageInput';
import UnbBubbleText from 'components/atoms/UnbBubbleText';
import { createStyles, makeStyles } from '@material-ui/core';
import { ChatMessage } from 'api/models/ChatMessage';
import { ChatReply } from 'api/models/ChatReply';

type UnbChatProps = {
  messages: Array<ChatMessage>;
  onClickSend: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string;
  onClickInfo: (reply: ChatReply) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    contaier: {
      position: 'absolute',
      width: '100%',
      bottom: '0px',
      maxHeight: '100%',
    },
    messageWrapper: {
      maxHeight: '70vh',
      overflowY: 'scroll',
    },
  })
);

const UnbChat: React.FC<UnbChatProps> = (props) => {
  const classes = useStyles();
  const render = useMemo(() => {
    return (
      <div className={classes.contaier}>
        <div
          className={classes.messageWrapper}
          id={'unb-message-container'}
          data-testid={'unb-message-container'}
        >
          {props.messages.map((message: ChatMessage, index: number) => (
            <UnbBubbleText msg={message} key={index} onClickInfo={props.onClickInfo} />
          ))}
        </div>
        <UnbMessageInput
          value={props.value}
          onClickSend={props.onClickSend}
          onChange={props.onChange}
        />
      </div>
    );
  }, [props.messages, props.value]);

  return render;
};

export default UnbChat;
