import React from 'react';
import { IconButton, SvgIconProps, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export type inlineIconButtonProps = {
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  icon: (props: SvgIconProps) => JSX.Element;
};

export type UnbInlineIconButtonProps = {
  btns: inlineIconButtonProps[];
  key: string;
  ele_id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn_contener: {
      marginTop: 5,
    },
  })
);

const UnbInlineIconButton: React.FC<UnbInlineIconButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <div key={props.ele_id} className={clsx(classes.btn_contener)}>
      {props.btns.map((item, index: number) => {
        return (
          <IconButton key={props.ele_id + '-' + index} onClick={item.onClick} edge="end">
            <item.icon />
          </IconButton>
        );
      })}
    </div>
  );
};

export default UnbInlineIconButton;
