import {
  createStyles,
  Dialog,
  DialogProps,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import RequestTask from 'api/models/requests/RequestTask';
import { Task } from 'api/models/Task';
import { VerbDefinition } from 'api/models/VerbDefinition';
import { AxiosError } from 'axios';
import UnbStyledTableCell from 'components/atoms/UnbStyledTableCell';
import UnbStyledTableRow from 'components/atoms/UnbStyledTableRow';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import UnbEditDialogActions from 'components/organisms/UnbEditDialogActions';
import UnbTableToolbar from 'components/organisms/UnbTableToolbar';
import UnbTaskDialogContent from 'components/organisms/UnbTaskDialogContent';
import UnbTaskHeadBar from 'components/organisms/UnbTaskHeadBar';
import UnbScrollableFormContent from 'components/organisms/UnbScrollableFormContent';
import UnbSimpleAlertDialog from 'components/templates/UnbSimpleAlertDialog';
import UnbEditDialogTitle from 'components/molecules/UnbEditDialogTitle';
import { getNoticeErrorMessage, LabelText, NoticeMessages } from 'constants/Messages';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  addTaskAction,
  deleteTaskAction,
  getTaskAction,
  searchTaskAction,
  taskActions,
  updateTaskAction,
} from 'store/domains/task/action';
import { uiTaskActions } from 'store/uis/task/action';
import { setError, generateTaskAndQuery, generateTaskOrQuery } from 'utils/ComponentUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '80vh',
      overflowX: 'auto',
    },
    container: {
      maxHeight: '60vh',
    },
  })
);

export interface Column {
  id:
    | 'task_id'
    | 'task_name'
    | 'task_sub_id'
    | 'task_type'
    | 'required'
    | 'order'
    | 'conversation'
    | 'decision_condition'
    | 'decision_condition_command'
    | 'variable'
    | 'acquisition_method'
    | 'is_permit_other_value'
    | 'last_acquisition_method'
    | 'repeat_limit'
    | 'null_confirmation_reply'
    | 'confirmation_reply'
    | 'negative_words'
    | 'negative_words_reply'
    | 'ok_confirmation_reply'
    | 'reply_after_confirmation'
    | 'reply'
    | 'nod_reply'
    | 'stop_condition'
    | 'stop_reply_rule_id'
    | 'is_need_next_action'
    | 'time_direction_type'
    | 'variable_from'
    | 'variable_to'
    | 'time_confirmation'
    | 'created_at'
    | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatNumber?: (value: number) => string;
  formatBoolean?: (value: boolean) => string;
  formatArray?: (value: Array<string>) => string;
}

export const columns: readonly Column[] = [
  { id: 'task_id', label: 'ID', minWidth: 100 },
  { id: 'task_name', label: 'Name', minWidth: 150 },
  { id: 'task_sub_id', label: 'Sub ID', minWidth: 150 },
  { id: 'task_type', label: 'Type', minWidth: 150 },
  {
    id: 'required',
    label: 'Required',
    minWidth: 120,
    formatBoolean: (v: boolean): string => v.toString(),
  },
  { id: 'order', label: 'Order', minWidth: 100, align: 'right' },
  { id: 'conversation', label: 'Conversation', minWidth: 170 },
  { id: 'decision_condition', label: 'Decision Condition', minWidth: 200 },
  {
    id: 'decision_condition_command',
    label: 'Decision Command',
    minWidth: 200,
  },
  { id: 'variable', label: 'Variable', minWidth: 150 },
  { id: 'acquisition_method', label: 'Acquisition Method', minWidth: 150 },
  {
    id: 'is_permit_other_value',
    label: 'Permit Other Value',
    minWidth: 170,
    align: 'right',
    formatBoolean: (v: boolean): string => v.toString(),
  },
  {
    id: 'last_acquisition_method',
    label: 'Last Acquisition Method',
    minWidth: 200,
    align: 'right',
  },
  { id: 'repeat_limit', label: 'Repeat Limit', minWidth: 150, align: 'right' },
  {
    id: 'null_confirmation_reply',
    label: 'Null Confirmation Reply',
    minWidth: 200,
    align: 'right',
  },
  { id: 'confirmation_reply', label: 'Confirmation Reply', minWidth: 170, align: 'right' },
  { id: 'negative_words', label: 'Negative Words', minWidth: 150 },
  { id: 'negative_words_reply', label: 'Negative Words Reply', minWidth: 200 },
  { id: 'ok_confirmation_reply', label: 'Ok Confirmation Reply', minWidth: 200 },
  { id: 'reply_after_confirmation', label: 'After Confirmation', minWidth: 170 },
  { id: 'reply', label: 'Reply', minWidth: 170 },
  { id: 'nod_reply', label: 'Nod Reply', minWidth: 170 },
  { id: 'stop_condition', label: 'Stop Condition', minWidth: 150 },
  { id: 'stop_reply_rule_id', label: 'Stop Reply Rule ID', minWidth: 190 },
  {
    id: 'is_need_next_action',
    label: 'Need Next Action',
    minWidth: 150,
    formatBoolean: (v: boolean): string => v.toString(),
  },
  { id: 'time_direction_type', label: 'Time Direction', minWidth: 150 },
  { id: 'variable_from', label: 'From Variable', minWidth: 150 },
  { id: 'variable_to', label: 'To Variable', minWidth: 150 },
  { id: 'time_confirmation', label: 'Time Confirmation', minWidth: 150 },
  { id: 'updated_at', label: 'Updated At', minWidth: 170, align: 'right' },
  { id: 'created_at', label: 'Created At', minWidth: 170, align: 'right' },
];

const createRequest = (currentID: number | undefined = undefined, data: Task): RequestTask => {
  const model: RequestTask = {
    task_name: data.task_name,
    task_sub_id: Number(data.task_sub_id),
    task_type: data.task_type,
    required: data.required === null || data.required === undefined ? false : !!+data.required,
    order: Number(data.order),
    conversation: data.conversation,
    decision_condition: data.decision_condition,
    decision_condition_command: data.decision_condition_command,
    variable: data.variable,
    acquisition_method: data.acquisition_method,
    is_permit_other_value:
      data.is_permit_other_value === null || data.is_permit_other_value === undefined
        ? false
        : !!+data.is_permit_other_value,
    last_acquisition_method: data.last_acquisition_method,
    repeat_limit: Number(data.repeat_limit),
    null_confirmation_reply: data.null_confirmation_reply,
    confirmation_reply: data.confirmation_reply,
    negative_words: data.negative_words,
    negative_words_reply: data.negative_words_reply,
    ok_confirmation_reply: data.ok_confirmation_reply,
    reply_after_confirmation: data.reply_after_confirmation,
    reply: data.reply,
    nod_reply: data.nod_reply,
    stop_condition: data.stop_condition,
    is_need_next_action:
      data.is_need_next_action === null || data.is_need_next_action === undefined
        ? false
        : !!+data.is_need_next_action,
    time_direction_type: data.time_direction_type,
    stop_reply_rule_id: data.stop_reply_rule_id,
    variable_from: data.variable_from,
    variable_to: data.variable_to,
    time_confirmation: data.time_confirmation,
  };

  if (currentID !== null && currentID !== undefined) {
    model.task_id = currentID;
  }
  return model;
};

/** 会話データ */
const TaskComponent: React.FC = () => {
  const classes = useStyles();
  const themes = useTheme();
  const methods = useForm<Task>({ mode: 'onChange' });
  const searchMethods = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const currentValue = useSelector((state: AppState) => state.task.currentValue);
  const rows = useSelector((state: AppState) => state.task.rows);
  const count = useSelector((state: AppState) => state.task.count);
  const fetchOption = useSelector((state: AppState) => state.task.fetchOption);
  const searchParams = useSelector((state: AppState) => state.task.searchParams);
  const loading = useSelector((state: AppState) => state.task.loading);
  const action = useSelector((state: AppState) => state.uiTask.action);
  const openAlertDialog = useSelector((state: AppState) => state.uiTask.openAlertDialog);
  const openDialog = useSelector((state: AppState) => state.uiTask.openDialog);
  const okBtnText = useSelector((state: AppState) => state.uiTask.okBtnText);
  const displayDelete = useSelector((state: AppState) => state.uiTask.displayDelete);
  const openCopyAlertDialog = useSelector((state: AppState) => state.uiTask.openCopyAlertDialog);
  const isDisplayCopyBtn = useSelector((state: AppState) => state.uiTask.isDisplayCopyBtn);
  const { enqueueSnackbar } = useSnackbar();
  const [closeAlertDialog, setCloseAlertDialog] = React.useState(false);

  useEffect(() => {
    const fn = (): void => {
      //console.log('call useEffect');
      fetchData(searchParams);
      const t = document.getElementsByClassName('MuiTableContainer-root')[0];
      if (t) {
        t.scrollTop = 0;
      }
    };
    fn();
  }, [fetchOption, searchParams]);

  const fetchData = async (data: Record<string, string | number>): Promise<void> => {
    dispatch(taskActions.setLoading(true));
    if (
      data.value === '' &&
      data.time_direction_type === 'all' &&
      data.task_type === 'all' &&
      data.required === 2 &&
      data.is_permit_other_value === 2 &&
      data.is_need_next_action === 2
    ) {
      await asyncDispatch(getTaskAction());
      dispatch(taskActions.setLoading(false));
    } else {
      try {
        await asyncDispatch(
          searchTaskAction(generateTaskAndQuery(data), generateTaskOrQuery(data, columns))
        );
      } catch (e) {
        const axiosErr = e as AxiosError;
        console.log(axiosErr.response);
        const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_SEARCH);
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        dispatch(taskActions.setLoading(false));
      }
    }
  };

  const updateUIStates = (
    currentVal: VerbDefinition,
    isOpenDialog: boolean,
    isOpenAlertDialog: boolean,
    isDisplayDelete: boolean,
    okBtnText: string
  ): void => {
    dispatch(taskActions.setCurrentValue(currentVal));
    dispatch(uiTaskActions.setOpenDialog(isOpenDialog));
    dispatch(uiTaskActions.setOpenAlertDialog(isOpenAlertDialog));
    dispatch(uiTaskActions.setDisplayDelete(isDisplayDelete));
    dispatch(uiTaskActions.setOkBtnText(okBtnText));
  };

  const handleAlertOk = useCallback(async () => {
    dispatch(taskActions.setLoading(true));
    dispatch(uiTaskActions.setOpenAlertDialog(false));
    try {
      if (currentValue.task_id !== undefined) {
        await asyncDispatch(deleteTaskAction(currentValue.task_id));
        enqueueSnackbar(NoticeMessages.COMPLETE_DELETE, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(NoticeMessages.ERROR_DELETE, { variant: 'error' });
    } finally {
      dispatch(taskActions.setLoading(false));
    }
  }, [loading, openAlertDialog]);

  const handleAlertCancel = useCallback(() => {
    const emptyValue = {} as Task;
    updateUIStates(emptyValue, false, false, false, LabelText.ADD_LABEL);
    methods.clearError();
  }, [openAlertDialog]);

  const handleClose = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleCloseDelete = useCallback(() => {
    setCloseAlertDialog(false);
    updateUIStates({} as Task, false, false, false, '');
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]);

  const handleCloseCancel = (): void => {
    setCloseAlertDialog(false);
  };

  const handleOk = useCallback(() => {
    // nop
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(uiTaskActions.setOpenDialog(false));
    dispatch(uiTaskActions.setOpenAlertDialog(true));
  }, [openDialog, openAlertDialog]);

  const handleCancel = (): void => {
    setCloseAlertDialog(true);
  };

  const handleClickRow = useCallback(
    (key: number, scrollType: DialogProps['scroll']) => {
      const current = rows.find((r: Task) => r.task_id === key);
      if (current !== undefined) {
        updateUIStates(current, true, false, true, LabelText.UPDATE_LABEL);
        dispatch(uiTaskActions.setAction('UPDATE'));
        dispatch(uiTaskActions.setIsDisplayCopyBtn(true));
      }
    },
    [rows, currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]
  );

  const handleAdd = useCallback(() => {
    const emptyValue = {} as Task;
    dispatch(uiTaskActions.setAction('ADD'));
    updateUIStates(emptyValue, true, false, false, LabelText.ADD_LABEL);
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText, action]);

  const handleSearch = useCallback(
    (data: Record<string, string | number>): void => {
      console.log('call handleSearch', data);
      fetchOption.page = 0;
      dispatch(
        taskActions.setSearchParams({
          fetchOption: fetchOption,
          searchParams: data,
        })
      );
    },
    [loading, fetchOption, searchParams]
  );

  const handleCopy = (): void => {
    dispatch(uiTaskActions.setOpenDialog(false));
    dispatch(uiTaskActions.setOpenCopyAlertDialog(true));
  };

  const handleCopyAlertCancel = useCallback((): void => {
    dispatch(uiTaskActions.setOpenCopyAlertDialog(false));
  }, [openCopyAlertDialog]);

  const handleCopyAlertOk = useCallback((): void => {
    dispatch(uiTaskActions.setIsDisplayCopyBtn(false));
    dispatch(uiTaskActions.setOpenCopyAlertDialog(false));

    const copyData = { ...currentValue };
    copyData.task_id = undefined;
    copyData.task_name = copyData.task_name ? copyData.task_name + '_copy' : 'copy';
    dispatch(taskActions.setCurrentValue(copyData));
    dispatch(uiTaskActions.setAction('ADD'));
    dispatch(uiTaskActions.setDisplayDelete(false));
    dispatch(uiTaskActions.setOkBtnText(LabelText.ADD_LABEL));
    dispatch(uiTaskActions.setOpenDialog(true));
  }, [openCopyAlertDialog]);

  const onSubmit = useCallback(
    async (data: VerbDefinition) => {
      dispatch(taskActions.setLoading(true));

      switch (action) {
        case 'ADD': {
          const params = createRequest(undefined, data);
          try {
            await asyncDispatch(addTaskAction(params));
            dispatch(uiTaskActions.setOpenDialog(false));
            enqueueSnackbar(NoticeMessages.COMPLETE_ADD, { variant: 'success' });
          } catch (e) {
            setError(e, methods);
            const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_ADD);
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(taskActions.setLoading(false));
          }
          break;
        }
        case 'UPDATE': {
          if (currentValue.task_id !== undefined) {
            const params = createRequest(currentValue.task_id, data);
            try {
              await asyncDispatch(updateTaskAction(params));
              dispatch(uiTaskActions.setOpenDialog(false));
              enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, { variant: 'success' });
            } catch (e) {
              setError(e, methods);
              // const axiosErr = e as AxiosError;
              // if (axiosErr.response !== null && axiosErr.response !== undefined) {
              //   console.log(axiosErr.response);
              //   Object.entries(axiosErr.response.data.errors).map(([key, val]) => {
              //     methods.setError(key, '', val as string);
              //   });
              // }
              const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
              enqueueSnackbar(message, { variant: 'error' });
            } finally {
              dispatch(taskActions.setLoading(false));
            }
          }
          break;
        }

        default:
          break;
      }
    },
    [action, loading, openDialog]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      console.log(newPage);
      fetchOption.page = newPage;
      dispatch(taskActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      fetchOption.limit = +event.target.value;
      fetchOption.page = 0;
      dispatch(taskActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const renderAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_DELETE]}
        title={LabelText.CONFIRM}
        open={openAlertDialog}
      />
    ),
    [openAlertDialog]
  );

  const renderCloseAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCloseDelete}
        okBtnText={LabelText.CLOSE_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCloseCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_CLOSE]}
        title={LabelText.CONFIRM}
        open={closeAlertDialog}
      >
        <DialogTitle id="conf-dialog-title">LabelText.CONFIRM_CLOSE</DialogTitle>
      </UnbSimpleAlertDialog>
    ),
    [closeAlertDialog]
  );

  const renderCopyAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCopyAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCopyAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_COPY]}
        title={LabelText.CONFIRM}
        open={openCopyAlertDialog}
      />
    ),
    [openCopyAlertDialog]
  );

  const renderDilog = useMemo(
    () => (
      <Dialog
        id={'input-dialog'}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
      >
        {isDisplayCopyBtn ? (
          <UnbEditDialogTitle
            id="form-dialog-title"
            onCopy={(): void => handleCopy()}
            copyBtnText="複製"
            copyBtnColor={'primary'}
          >
            タスク定義
          </UnbEditDialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">タスク定義</DialogTitle>
        )}
        <UnbScrollableFormContent
          onSubmit={methods.handleSubmit(onSubmit)}
          dialogContent={<UnbTaskDialogContent dividers entity={currentValue} />}
          editDilaogActions={
            <UnbEditDialogActions
              okBtnColor="primary"
              cancelBtnColor="primary"
              okBtnText={okBtnText}
              cancelBtnText="キャンセル"
              okHandler={handleOk}
              cancelHandler={handleCancel}
              deleteBtnColor="secondary"
              deleteBtnText="削除"
              deleteHandler={handleDelete}
              isDisplayDelete={displayDelete}
            />
          }
        />
      </Dialog>
    ),
    [openDialog, currentValue, okBtnText, displayDelete]
  );

  const renderTable = useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <UnbStyledTableCell
                    backgroundColor={'white'}
                    color={'black'}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </UnbStyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: Task, i: number) => {
              return (
                <UnbStyledTableRow
                  backgroundColor={themes.palette.grey['50']}
                  color={'black'}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={(): void => {
                    if (row.task_id) {
                      handleClickRow(row.task_id, 'paper');
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        <div>
                          {column.formatBoolean && typeof value === 'boolean'
                            ? column.formatBoolean(value)
                            : value}
                        </div>
                      </TableCell>
                    );
                  })}
                </UnbStyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [rows]
  );

  const renderPagination = useMemo(
    () => (
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={'ページ毎の件数'}
        component="div"
        count={count}
        rowsPerPage={fetchOption.limit}
        page={fetchOption.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ),
    [fetchOption, count]
  );

  const renderLoading = useMemo(() => <UnbCircularProgress open={loading} />, [loading]);

  const renderContent = (
    <Paper className={classes.root}>
      <UnbTableToolbar title="タスク" onClickAdd={handleAdd} />
      {renderDilog}
      {renderAlertDialog}
      {renderCloseAlertDialog}
      {renderCopyAlertDialog}
      {renderTable}
      {renderPagination}
    </Paper>
  );

  return (
    <div>
      <FormContext {...methods}>
        <FormContext {...searchMethods}>
          <UnbTaskHeadBar handleSearch={handleSearch} />
        </FormContext>
        {renderContent}
        {loading && renderLoading}
      </FormContext>
    </div>
  );
};

export default TaskComponent;
