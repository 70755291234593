import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ChatReply } from 'api/models/ChatReply';
import { chatToolsActions } from 'store/domains/tools/chat/action';
import { MultiConversation } from 'api/models/MultiConversation';
import { ChatMessage } from 'api/models/ChatMessage';

export type ChatToolsState = {
  responseHistory: Array<ChatReply>;
  requestHistory: Array<MultiConversation>;
  messages: Array<ChatMessage>;
};

export const initialState: ChatToolsState = {
  responseHistory: [],
  requestHistory: [],
  messages: [],
};

export const chatToolsReducer = reducerWithInitialState(initialState)
  .case(chatToolsActions.postChatV2.started, (state, payload) => {
    return state;
  })
  .case(chatToolsActions.postChatV2.failed, (state, payload) => {
    return state;
  })
  .case(chatToolsActions.postChatV2.done, (state, payload) => {
    const history = state.responseHistory.concat(payload.result);
    const msgs = state.messages.concat({
      type: 'system',
      message: payload.result,
    });
    return {
      ...state,
      responseHistory: history,
      messages: msgs,
    };
  })
  .case(chatToolsActions.addRequestHistory, (state, payload) => {
    const history = state.requestHistory.concat(payload);
    const msgs = state.messages.concat({
      type: 'user',
      message: payload,
    });
    return {
      ...state,
      requestHistory: history,
      messages: msgs,
    };
  });
