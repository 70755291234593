import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type UnbRenderLinkProps = {
  to: string;
};

const UnbRenderLink = (props: UnbRenderLinkProps) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>(
        (itemProps, ref) => (
          // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
          // See https://github.com/ReactTraining/react-router/issues/6056
          <RouterLink to={props.to} {...itemProps} innerRef={ref} />
        )
      ),
    [props.to]
  );

  return renderLink;
};

export default UnbRenderLink;
