import React from 'react';
import { Switch, Route } from 'react-router';
import ScenarioChatRuleComponent from 'components/pages/editor/scenario/ScenarioChatRuleComponent';
import MasterWordsComponent from 'components/pages/editor/master/MasterWordsComponent';
import TemplateVariableComponent from 'components/pages/editor/scenario/TemplateVariableComponent';
import VerbDefinitionComponent from 'components/pages/editor/scenario/VerbDefinitionComponent';
import TaskComponent from 'components/pages/editor/scenario/TaskComponent';
import RuleConditionGroupComponent from 'components/pages/editor/scenario/RuleConditionGroupComponent';
import UnbChatPageComponent from 'components/pages/editor/tools/UnbChatPageComponent';

const UnbProtectedRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/scenario/chat-rule" exact component={ScenarioChatRuleComponent} />
      <Route path="/scenario/template-variable" exact component={TemplateVariableComponent} />
      <Route path="/scenario/verb-definition" exact component={VerbDefinitionComponent} />
      <Route path="/scenario/task" exact component={TaskComponent} />
      <Route path="/scenario/rule-condition-group" exact component={RuleConditionGroupComponent} />
      <Route path="/master/noise" exact component={MasterWordsComponent} />
      <Route path="/tools/chat" exact component={UnbChatPageComponent} />
    </Switch>
  );
};

export default UnbProtectedRouter;
