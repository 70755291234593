import React, { useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChatMessage } from 'api/models/ChatMessage';
import { MultiConversation } from 'api/models/MultiConversation';
import { ChatReply } from 'api/models/ChatReply';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton } from '@material-ui/core';

type UnbBubbleTextProps = {
  msg: ChatMessage;
  onClickInfo: (reply: ChatReply) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatbubbleWrapper: {
      overflow: 'auto',
      boxSizing: 'content-box',
      textAlign: 'justify',
    },
    chatbubble: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderRadius: 20,
      marginTop: 5,
      marginRight: 8,
      marginBottom: 5,
      marginLeft: 'auto',
      maxWidth: '75%',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 14,
      paddingRight: 14,
      width: '-webkit-fit-content',
      float: 'right',
      overflowWrap: 'break-word',
    },
    recipientChatbubble: {
      backgroundColor: '#e8e8e8',
      borderRadius: 20,
      marginTop: 5,
      marginRight: 'auto',
      marginBottom: 5,
      marginLeft: 'auto',
      maxWidth: '75%',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 14,
      paddingRight: 14,
      width: '-webkit-fit-content',
      float: 'left',
      overflowWrap: 'break-word',
    },
    p: {
      color: '#FFFFFF',
      fontSize: 16,
      fontWeight: 300,
      margin: 0,
    },
    infoIcon: {
      position: 'absolute',
      top: '50%',
      marginLeft: '20px',
      transform: 'translate(-50%, -50%)',
    },
    systemBubbleWrapper: {
      display: 'flow-root',
      position: 'relative',
    },
  })
);

const UnbBubbleText: React.FC<UnbBubbleTextProps> = (props) => {
  const classes = useStyles();

  const getLatestUserSentence = (multiConversation: MultiConversation): string => {
    const conversations = multiConversation.conversations;
    if (conversations === undefined || conversations.length <= 0) {
      return '';
    }
    const sentence = conversations[conversations.length - 1].userSentence;
    if (sentence === undefined) {
      return '';
    }
    return sentence;
  };

  const getLatestSystemSentence = (reply: ChatReply): string => {
    const sentence = reply.displaySentence;
    if (sentence === undefined) {
      return '';
    }
    return sentence;
  };

  const render = useMemo(() => {
    return (
      <div className={classes.chatbubbleWrapper}>
        {props.msg.type === 'user' ? (
          <p className={classes.chatbubble}>
            {getLatestUserSentence(props.msg.message as MultiConversation)}
          </p>
        ) : (
          <div className={classes.systemBubbleWrapper}>
            <div className={classes.recipientChatbubble}>
              {getLatestSystemSentence(props.msg.message as ChatReply)}
            </div>
            <IconButton
              className={classes.infoIcon}
              color="default"
              aria-label="info"
              size="small"
              onClick={(): void => props.onClickInfo(props.msg.message as ChatReply)}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
    );
  }, [props.msg]);

  return render;
};

export default UnbBubbleText;
