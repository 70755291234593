import { PropTypes } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import UnbConfirmDialogActions from 'components/organisms/UnbConfirmDialogActions';
import UnbSimpleDialogContent from 'components/organisms/UnbSimpleDialogContent';
import React from 'react';

type UnbSimpleAlertDialogProps = {
  okHandler: () => void;
  okBtnColor?: PropTypes.Color;
  okBtnText: string;
  cancelHandler: () => void;
  cancelBtnColor?: PropTypes.Color;
  cancelBtnText: string;
  textValue: Array<string>;
  title: string;
  open: boolean;
  closeHandler?: () => void;
};

export const UnbSimpleAlertDialog: React.FC<UnbSimpleAlertDialogProps> = (
  props: UnbSimpleAlertDialogProps
) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <UnbSimpleDialogContent dividers textValue={props.textValue} />
      <UnbConfirmDialogActions
        okHandler={props.okHandler}
        okBtnText={props.okBtnText}
        okBtnColor={props.okBtnColor}
        cancelHandler={props.cancelHandler}
        cancelBtnText={props.cancelBtnText}
        cancelBtnColor={props.cancelBtnColor}
      />
    </Dialog>
  );
};

export default UnbSimpleAlertDialog;
