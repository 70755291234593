import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import React from 'react';

type UnbSimpleDialogContentProps = {
  dividers?: boolean;
  textValue: Array<string>;
};

const UnbSimpleDialogContent: React.FC<UnbSimpleDialogContentProps> = (props) => {
  return (
    <DialogContent dividers={props.dividers}>
      <DialogContentText>
        {props.textValue.map((v) => (
          <Typography key={v} component={'span'}>
            {v}
            <br />
          </Typography>
        ))}
      </DialogContentText>
    </DialogContent>
  );
};

export default UnbSimpleDialogContent;
