import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { Redirect } from 'react-router-dom';
import { AppState } from 'store';
import { authActions, getUserInfoAction } from 'store/domains/auth/action';
import { User } from 'oidc-client';
import { UserInfo } from 'api/models/UserInfo';
import { commonActions } from 'store/app/actions';

type UnbAuthProps = {};

const UnbAuth: React.FC<UnbAuthProps> = (props) => {
  const [isRedirectLogin, setRedirectLogin] = useState(false);
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const loading = useSelector((state: AppState) => state.auth.loading);
  const authService = useSelector((state: AppState) => state.app.authService);
  const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);
  const user = useSelector((state: AppState) => state.auth.user);
  const userInfo = useSelector((state: AppState) => state.auth.userInfo);

  useEffect(() => {
    const getAuthenticated = (): void => {
      const isAuthenticated = authService.isAuthenticated();
      dispatch(authActions.setAuthenticated(isAuthenticated));
    };

    setRedirectLogin(false);
    dispatch(authActions.setLoading(true));
    getAuthenticated();
    if (!isAuthenticated) {
      dispatch(authActions.setLoading(false));
      setRedirectLogin(true);
    } else {
      const isPrivate = authService.isPrivate();
      dispatch(authActions.setIsPrivate(isPrivate));

      authService.getUser().then((user: User | null) => {
        if (user) {
          dispatch(authActions.setUser(user));
          getUserInfo().then(() => {
            dispatch(authActions.setLoading(false));
          });
        } else {
          dispatch(authActions.setLoading(false));
        }
      });
    }
  }, []);

  const getUserInfo = async (): Promise<void> => {
    const userInfoResponse = await asyncDispatch(getUserInfoAction());
    if (userInfoResponse) {
      const projectIds = (userInfoResponse as UserInfo).projectId;
      if (projectIds) {
        dispatch(commonActions.setSelectedProjectId(projectIds[0]));
      }
    }
  };

  const isEmpty = (obj: object): boolean => {
    return !Object.keys(obj).length;
  };

  const renderFn = useMemo(() => {
    if (loading) {
      return <UnbCircularProgress open={loading} />;
    }

    if (!loading && isAuthenticated && !isEmpty(user)) {
      return props.children;
    } else {
      return <Redirect to={'/login'} />;
    }
  }, [loading, isAuthenticated, user]);

  return <div>{renderFn}</div>;
};

export default UnbAuth;
