import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import React, { ReactNode } from 'react';
import { PropTypes } from '@material-ui/core';

type UnbStyledTableRowProps = {
  backgroundColor: string;
  color: string;
  hover?: boolean;
  align?: PropTypes.Alignment;
  children: ReactNode;
  role?: string;
  tabIndex?: number;
  style?: {};
  onClick?: React.MouseEventHandler;
};

const UnbStyledTableRow: React.FC<UnbStyledTableRowProps> = (props: UnbStyledTableRowProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: props.backgroundColor,
        },
      },
    })
  )(TableRow);

  return (
    <StyledTableRow
      hover={props.hover}
      role={props.role}
      tabIndex={props.tabIndex}
      onClick={props.onClick}
    >
      {props.children}
    </StyledTableRow>
  );
};

export default UnbStyledTableRow;
