import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Task } from 'api/models/Task';
import { TaskTypeEnum } from 'api/types/TaskType';
import { TimeDirectionTypeEnum } from 'api/types/TypeDirectionType';
import UnbRequiredSpan from 'components/atoms/UnbRequiredSpan';
import { replyRegister } from 'components/form/validator/ChatRuleRegister';
import {
  taskNameRegister,
  acquisitionMethodRegister,
  confirmationReplyRegister,
  conversationRegister,
  decisionConditionCommandRegister,
  decisionConditionRegister,
  isNeedNextActionRegister,
  isPermitOtherValueRegister,
  isRequiredRegister,
  lastAcquisitionMethodRegister,
  negativeWordsRegister,
  negativeWordsReplyRegister,
  nodReplyRegister,
  nullConfirmationReplyRegister,
  okConfirmationReplyRegister,
  orderRegister,
  repeatLimitRegister,
  replyAfterConfirmationRegister,
  stopConditionRegister,
  stopReplyRuleIDRegister,
  taskSubIDRegister,
  taskTypeRegister,
  timeConfirmationRegister,
  timeDirectionRegister,
  variableFromRegister,
  variableRegister,
  variableToRegister,
} from 'components/form/validator/TaskRegister';
import React, { useEffect, useState } from 'react';
import { useFormContext, FieldError } from 'react-hook-form';
import { convertBool2Num } from 'utils/Converter';
import { AcquisitionMethodTypeEnum } from 'api/types/AcquisitionMethidType';
import UnbTheme from 'themes/UnbStyle';

type UnbTaskDialogContentProps = {
  dividers?: boolean;
  entity: Task;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    errorHelperText: {
      color: theme.palette.error.main,
    },
  })
);

const UnbTaskDialogContent: React.FC<UnbTaskDialogContentProps> = (props) => {
  const classes = useStyles(UnbTheme);
  const { register, errors, setValue } = useFormContext();
  const [currentValue, setCurrentValue] = React.useState(props.entity);

  const inputIsRequiredLabel = React.useRef<HTMLLabelElement>(null);
  const [isRequiredLabelWidth, setIsRequiredLabelWidth] = useState(0);
  const handleIsRequiredChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setValue('required', (event.target.value as boolean) ? 1 : 0);
    setCurrentValue({
      ...currentValue,
      required: event.target.value as boolean,
    });
  };

  const inputIsPermitOtherValueLabel = React.useRef<HTMLLabelElement>(null);
  const [isPermitOtherValueLabelWidth, setIsPermitOtherValueLabelWidth] = useState(0);
  const handleIsPermitOtherValueChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setValue('is_permit_other_value', (event.target.value as boolean) ? 1 : 0);
    setCurrentValue({
      ...currentValue,
      is_permit_other_value: event.target.value as boolean,
    });
  };

  const inputIsNeedNextActionLabel = React.useRef<HTMLLabelElement>(null);
  const [isNeedNextActionLabelWidth, setIsNeedNextActionLabelWidth] = useState(0);
  const handleIsNeedNextActionChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setValue('is_need_next_action', (event.target.value as boolean) ? 1 : 0);
    setCurrentValue({
      ...currentValue,
      is_need_next_action: event.target.value as boolean,
    });
  };

  const inputTimeDirectionLabel = React.useRef<HTMLLabelElement>(null);
  const [timeDirectionLabelWidth, setTimeDirectionLabelWidth] = React.useState(0);
  const handleTimeDirectionChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      time_direction_type: event.target.value as TimeDirectionTypeEnum,
    });

    setSelectValues({
      ...selectValues,
      time_direction_type: event.target.value as string,
    });

    setValue('time_direction_type', event.target.value as TimeDirectionTypeEnum);
  };

  const inputTaskTypeLabel = React.useRef<HTMLLabelElement>(null);
  const [taskTypeLabelWidth, setTaskTypeLabelWidth] = React.useState(0);
  const handleTaskTypeChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      task_type: event.target.value as TaskTypeEnum,
    });

    setSelectValues({
      ...selectValues,
      task_type: event.target.value as string,
    });

    setValue('task_type', event.target.value as TaskTypeEnum);
  };

  const inputAcquisitionMethodLabel = React.useRef<HTMLLabelElement>(null);
  const [acquisitionMethodLabelWidth, setAcquisitionMethodLabelWidth] = React.useState(0);
  const handleAcquisitionMethodChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      acquisition_method: event.target.value as AcquisitionMethodTypeEnum,
    });

    setSelectValues({
      ...selectValues,
      acquisition_method: event.target.value as string,
    });

    setValue('acquisition_method', event.target.value as AcquisitionMethodTypeEnum);
  };

  const inputLastAcquisitionMethodLabel = React.useRef<HTMLLabelElement>(null);
  const [lastAcquisitionMethodLabelWidth, setLastAcquisitionMethodLabelWidth] = React.useState(0);
  const handleLastAcquisitionMethodChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setCurrentValue({
      ...currentValue,
      last_acquisition_method: event.target.value as AcquisitionMethodTypeEnum,
    });

    setSelectValues({
      ...selectValues,
      last_acquisition_method: event.target.value as string,
    });

    setValue('last_acquisition_method', event.target.value as AcquisitionMethodTypeEnum);
  };

  const inputOrderLabel = React.useRef<HTMLLabelElement>(null);
  const [orderLabelWidth, setOrderLabelWidth] = React.useState(0);
  const handleOrderChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      order: event.target.value as number,
    });
  };

  const inputTaskSubIDLabel = React.useRef<HTMLLabelElement>(null);
  const [taskSubIDLabelWidth, setTaskSubIDLabelWidth] = React.useState(0);
  const handleTaskSubIDChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      task_sub_id: event.target.value as number,
    });
  };

  const inputRepeatLimitLabel = React.useRef<HTMLLabelElement>(null);
  const [repeatLimitLabelWidth, setRepeatLimitLabelWidth] = React.useState(0);
  const handleRepeatLimitChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCurrentValue({
      ...currentValue,
      repeat_limit: event.target.value as number,
    });
  };

  /**
   * OptionalなStateをそのままFormのvalueに適用すると
   * undefindだった場合、controled componentからuncontrolledになってしまう
   * が発生するため、ローカルでstateに初期値を与える
   * DBから取得したModelはEnumなどで型定義されているため、空文字を入れるなどの
   * 対応が取り辛いため
   * 良い対応方法があり次第リファクタ
   */
  const [selectValues, setSelectValues] = React.useState({
    time_direction_type: '',
    task_type: '',
    acquisition_method: '',
    last_acquisition_method: '',
  });

  useEffect(() => {
    register(isRequiredRegister.custom, isRequiredRegister.rule);
    register(timeDirectionRegister.custom, timeDirectionRegister.rule);
    register(isNeedNextActionRegister.custom, isNeedNextActionRegister.rule);
    register(isPermitOtherValueRegister.custom, isPermitOtherValueRegister.rule);
    register(taskTypeRegister.custom, taskTypeRegister.rule);
    register(acquisitionMethodRegister.custom, acquisitionMethodRegister.rule);
    register(lastAcquisitionMethodRegister.custom, lastAcquisitionMethodRegister.rule);

    setValue('required', currentValue.required ? 1 : 0);
    setValue('is_permit_other_value', currentValue.is_permit_other_value ? 1 : 0);
    setValue('is_need_next_action', currentValue.is_need_next_action ? 1 : 0);
    setValue(
      'time_direction_type',
      currentValue.time_direction_type === null || currentValue.time_direction_type === undefined
        ? TimeDirectionTypeEnum.Current
        : currentValue.time_direction_type
    );
    setValue(
      'task_type',
      currentValue.task_type === null || currentValue.task_type === undefined
        ? TaskTypeEnum.Register
        : currentValue.task_type
    );
    setValue(
      'acquisition_method',
      currentValue.acquisition_method === null || currentValue.acquisition_method === undefined
        ? ''
        : currentValue.acquisition_method
    );
    setValue(
      'last_acquisition_method',
      currentValue.last_acquisition_method === null ||
        currentValue.last_acquisition_method === undefined
        ? ''
        : currentValue.last_acquisition_method
    );
    setValue(
      'order',
      currentValue.order === null || currentValue.order === undefined ? 1 : currentValue.order
    );
    setValue(
      'task_sub_id',
      currentValue.task_sub_id === null || currentValue.task_sub_id === undefined
        ? 1
        : currentValue.task_sub_id
    );
    setValue(
      'repeat_limit',
      currentValue.repeat_limit === null || currentValue.repeat_limit === undefined
        ? 1
        : currentValue.repeat_limit
    );

    if (inputIsRequiredLabel.current) {
      setIsRequiredLabelWidth(inputIsRequiredLabel.current.offsetWidth);
    }
    if (inputIsPermitOtherValueLabel.current) {
      setIsPermitOtherValueLabelWidth(inputIsPermitOtherValueLabel.current.offsetWidth);
    }
    if (inputIsNeedNextActionLabel.current) {
      setIsNeedNextActionLabelWidth(inputIsNeedNextActionLabel.current.offsetWidth);
    }
    if (inputTimeDirectionLabel.current) {
      setTimeDirectionLabelWidth(inputTimeDirectionLabel.current.offsetWidth);
    }
    if (inputTaskTypeLabel.current) {
      setTaskTypeLabelWidth(inputTaskTypeLabel.current.offsetWidth);
    }
    if (inputAcquisitionMethodLabel.current) {
      setAcquisitionMethodLabelWidth(inputAcquisitionMethodLabel.current.offsetWidth);
    }
    if (inputLastAcquisitionMethodLabel.current) {
      setLastAcquisitionMethodLabelWidth(inputLastAcquisitionMethodLabel.current.offsetWidth);
    }
    if (inputOrderLabel.current) {
      setOrderLabelWidth(inputOrderLabel.current.offsetWidth);
    }
    if (inputTaskSubIDLabel.current) {
      setTaskSubIDLabelWidth(inputTaskSubIDLabel.current.offsetWidth);
    }
    if (inputRepeatLimitLabel.current) {
      setRepeatLimitLabelWidth(inputRepeatLimitLabel.current.offsetWidth);
    }

    setSelectValues({
      ...selectValues,
      time_direction_type:
        currentValue.time_direction_type === null || currentValue.time_direction_type === undefined
          ? TimeDirectionTypeEnum.Current
          : currentValue.time_direction_type.toString(),
      task_type:
        currentValue.task_type === null || currentValue.task_type === undefined
          ? TaskTypeEnum.Register
          : currentValue.task_type.toString(),
      acquisition_method:
        currentValue.acquisition_method === null || currentValue.acquisition_method === undefined
          ? ''
          : currentValue.acquisition_method.toString(),
      last_acquisition_method:
        currentValue.last_acquisition_method === null ||
        currentValue.last_acquisition_method === undefined
          ? ''
          : currentValue.last_acquisition_method.toString(),
    });
  }, []);

  return (
    <DialogContent dividers={props.dividers}>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Task Name
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(taskNameRegister)}
          error={!!errors.task_name}
          label="Task Name"
          name="task_name"
          id="task-name"
          className={classes.formControl}
          helperText={errors.task_name ? (errors.task_name as FieldError)['message'] : 'タスク名'}
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.task_name}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Sub ID
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel htmlFor="task-sub-id" ref={inputTaskSubIDLabel} error={!!errors.task_sub_id}>
            Sub ID
          </InputLabel>
          <OutlinedInput
            inputRef={register(taskSubIDRegister)}
            error={!!errors.task_sub_id}
            id="task-sub-id"
            type="number"
            name="task_sub_id"
            defaultValue={currentValue.task_sub_id}
            onChange={handleTaskSubIDChange}
            labelWidth={taskSubIDLabelWidth}
          />
          {errors.task_sub_id ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.task_sub_id as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>サブID</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Task Type
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="task-type-label" ref={inputTaskTypeLabel}>
            Task Type
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.task_type}
            error={!!errors.task_type}
            labelId="task-type-label"
            id="task-type"
            name="task_type"
            onChange={handleTaskTypeChange}
            labelWidth={taskTypeLabelWidth}
          >
            {Object.values(TaskTypeEnum).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
          {errors.task_type ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.task_type as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>種別</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Required
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="required-label" ref={inputIsRequiredLabel}>
            Required
          </InputLabel>
          <Select
            name="required"
            inputRef={register}
            value={convertBool2Num(currentValue.required)}
            labelId="required-label"
            id="required"
            onChange={handleIsRequiredChange}
            labelWidth={isRequiredLabelWidth}
          >
            <MenuItem value={1}>TRUE</MenuItem>
            <MenuItem value={0}>FALSE</MenuItem>
          </Select>
          {errors.required ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.required as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>必須</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Order
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel htmlFor="order" ref={inputOrderLabel} error={!!errors.order}>
            Order
          </InputLabel>
          <OutlinedInput
            inputRef={register(orderRegister)}
            error={!!errors.order}
            id="order"
            type="number"
            name="order"
            defaultValue={currentValue.order}
            onChange={handleOrderChange}
            labelWidth={orderLabelWidth}
          />
          {errors.order ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.order as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>順序</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Conversation</Typography>
        <TextField
          inputRef={register(conversationRegister)}
          error={!!errors.conversation}
          label="Conversation"
          name="conversation"
          id="conversation"
          className={classes.formControl}
          helperText={
            errors.conversation ? (errors.conversation as FieldError)['message'] : '会話文'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.conversation}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Decision Condition</Typography>
        <TextField
          inputRef={register(decisionConditionRegister)}
          error={!!errors.decision_condition}
          label="Decision Condition"
          name="decision_condition"
          id="decision-condition"
          className={classes.formControl}
          helperText={
            errors.decision_condition
              ? (errors.decision_condition as FieldError)['message']
              : '適用条件'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.decision_condition}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Decision Command</Typography>
        <TextField
          inputRef={register(decisionConditionCommandRegister)}
          error={!!errors.decision_condition_command}
          label="Decision Command"
          name="decision_condition_command"
          id="decision-condition-command"
          className={classes.formControl}
          helperText={
            errors.decision_condition_command
              ? (errors.decision_condition_command as FieldError)['message']
              : '適用条件コマンド'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.decision_condition_command}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Variable</Typography>
        <TextField
          inputRef={register(variableRegister)}
          error={!!errors.variable}
          label="Variable"
          name="variable"
          id="variable"
          className={classes.formControl}
          helperText={errors.variable ? (errors.variable as FieldError)['message'] : '変数'}
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.variable}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Acquisition Method</Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="acquisition-method-label" ref={inputAcquisitionMethodLabel}>
            Acquisition Method
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.acquisition_method}
            error={!!errors.acquisition_method}
            labelId="acquisition-method-label"
            id="acquisition-method"
            name="acquisition_method"
            onChange={handleAcquisitionMethodChange}
            labelWidth={acquisitionMethodLabelWidth}
          >
            <MenuItem value={''}>選択なし</MenuItem>
            {Object.entries(AcquisitionMethodTypeEnum).map(([k, v]) => {
              return (
                <MenuItem key={k} value={v}>
                  {k}
                </MenuItem>
              );
            })}
          </Select>
          {errors.acquisition_method ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.acquisition_method as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>取得方法</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Permit Other Value
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="permit-other-value-label" ref={inputIsPermitOtherValueLabel}>
            Permit Other Value
          </InputLabel>
          <Select
            name="is_permit_other_value"
            inputRef={register}
            value={convertBool2Num(currentValue.is_permit_other_value)}
            labelId="permit-other-value-label"
            id="is-permit-other-value"
            onChange={handleIsPermitOtherValueChange}
            labelWidth={isPermitOtherValueLabelWidth}
          >
            <MenuItem value={1}>TRUE</MenuItem>
            <MenuItem value={0}>FALSE</MenuItem>
          </Select>
          {errors.is_permit_other_value ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.is_permit_other_value as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>マッチしない場合も取得対象とするか</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Last Acquisition Method</Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="last-acquisition-method-label" ref={inputLastAcquisitionMethodLabel}>
            Last Acquisition Method
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.last_acquisition_method}
            error={!!errors.last_acquisition_method}
            labelId="last-acquisition-method-label"
            id="last-acquisition-method"
            name="last_acquisition_method"
            onChange={handleLastAcquisitionMethodChange}
            labelWidth={lastAcquisitionMethodLabelWidth}
          >
            <MenuItem value={''}>選択なし</MenuItem>
            {Object.entries(AcquisitionMethodTypeEnum).map(([k, v]) => {
              return (
                <MenuItem key={k} value={v}>
                  {k}
                </MenuItem>
              );
            })}
          </Select>
          {errors.last_acquisition_method ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.last_acquisition_method as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>最終確認時の取得方法</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Repeat Limit
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel
            htmlFor="repeat-limit"
            ref={inputRepeatLimitLabel}
            error={!!errors.repeat_limit}
          >
            Repeat Limit
          </InputLabel>
          <OutlinedInput
            inputRef={register(repeatLimitRegister)}
            error={!!errors.repeat_limit}
            id="repeat-limit"
            type="number"
            name="repeat_limit"
            defaultValue={currentValue.repeat_limit}
            onChange={handleRepeatLimitChange}
            labelWidth={repeatLimitLabelWidth}
          />
          {errors.repeat_limit ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.repeat_limit as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>繰り返し回数</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Null Confirmation Reply</Typography>
        <TextField
          inputRef={register(nullConfirmationReplyRegister)}
          error={!!errors.null_confirmation_reply}
          label="Null Confirmation Reply"
          name="null_confirmation_reply"
          id="null-confirmation-reply"
          className={classes.formControl}
          helperText={
            errors.null_confirmation_reply
              ? (errors.null_confirmation_reply as FieldError)['message']
              : 'nullの場合の確認用返答'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.null_confirmation_reply}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Confirmation Reply</Typography>
        <TextField
          inputRef={register(confirmationReplyRegister)}
          error={!!errors.confirmation_reply}
          label="Confirmation Reply"
          name="confirmation_reply"
          id="confirmation-reply"
          className={classes.formControl}
          helperText={
            errors.confirmation_reply
              ? (errors.confirmation_reply as FieldError)['message']
              : '曖昧語取得時の確認用返答'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.confirmation_reply}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Negative Words</Typography>
        <TextField
          inputRef={register(negativeWordsRegister)}
          error={!!errors.negative_words}
          label="Negative Words"
          name="negative_words"
          id="negative-words"
          className={classes.formControl}
          helperText={
            errors.negative_words ? (errors.negative_words as FieldError)['message'] : '否定語'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.negative_words}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Negative Words Reply</Typography>
        <TextField
          inputRef={register(negativeWordsReplyRegister)}
          error={!!errors.negative_words_reply}
          label="Negative Words Reply"
          name="negative_words_reply"
          id="negative-words-reply"
          className={classes.formControl}
          helperText={
            errors.negative_words_reply
              ? (errors.negative_words_reply as FieldError)['message']
              : '否定語用の返答'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.negative_words_reply}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>OK Confirmation Reply</Typography>
        <TextField
          inputRef={register(okConfirmationReplyRegister)}
          error={!!errors.ok_confirmation_reply}
          label="OK Confirmation Reply"
          name="ok_confirmation_reply"
          id="ok-confirmation-reply"
          className={classes.formControl}
          helperText={
            errors.ok_confirmation_reply
              ? (errors.ok_confirmation_reply as FieldError)['message']
              : '取得時の確認用返答（未設定の場合、確認しない）'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.ok_confirmation_reply}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Reply After Confirmation</Typography>
        <TextField
          inputRef={register(replyAfterConfirmationRegister)}
          error={!!errors.reply_after_confirmation}
          label="Reply After Confirmation"
          name="reply_after_confirmation"
          id="reply-after-confirmation"
          className={classes.formControl}
          helperText={
            errors.reply_after_confirmation
              ? (errors.reply_after_confirmation as FieldError)['message']
              : '確認返答後の返答'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.reply_after_confirmation}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Reply</Typography>
        <TextField
          inputRef={register(replyRegister)}
          error={!!errors.reply}
          label="Reply"
          name="reply"
          id="reply"
          className={classes.formControl}
          helperText={errors.reply ? (errors.reply as FieldError)['message'] : '取得時の返答'}
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.reply}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Nod Reply</Typography>
        <TextField
          inputRef={register(nodReplyRegister)}
          error={!!errors.nod_reply}
          label="Nod Reply"
          name="nod_reply"
          id="nod-reply"
          className={classes.formControl}
          helperText={errors.nod_reply ? (errors.nod_reply as FieldError)['message'] : '相槌返答'}
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.nod_reply}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Stop Reply Rule ID</Typography>
        <TextField
          inputRef={register(stopReplyRuleIDRegister)}
          error={!!errors.stop_reply_rule_id}
          label="Stop Reply Rule ID"
          name="stop_reply_rule_id"
          id="stop-reply-rule-id"
          className={classes.formControl}
          helperText={
            errors.stop_reply_rule_id
              ? (errors.stop_reply_rule_id as FieldError)['message']
              : '停止時の返答用Rule ID'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.stop_reply_rule_id}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Stop Condition</Typography>
        <TextField
          inputRef={register(stopConditionRegister)}
          error={!!errors.stop_condition}
          label="Stop Condition"
          name="stop_condition"
          id="stop-condition"
          className={classes.formControl}
          helperText={
            errors.stop_condition ? (errors.stop_condition as FieldError)['message'] : '停止条件'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.stop_condition}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Need Next Action
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="need-next-action-label" ref={inputIsNeedNextActionLabel}>
            Need Next Action
          </InputLabel>
          <Select
            name="is_need_next_action"
            inputRef={register}
            value={convertBool2Num(currentValue.is_need_next_action)}
            labelId="need-next-action-label"
            id="is-need-next-action"
            onChange={handleIsNeedNextActionChange}
            labelWidth={isNeedNextActionLabelWidth}
          >
            <MenuItem value={1}>TRUE</MenuItem>
            <MenuItem value={0}>FALSE</MenuItem>
          </Select>
          {errors.is_need_next_action ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.is_need_next_action as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>後続処理要否</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Time Direction
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="time-direction-type-label" ref={inputTimeDirectionLabel}>
            Time Direction
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.time_direction_type}
            error={!!errors.time_direction_type}
            labelId="time-direction-type-label"
            id="time-direction-type"
            name="time_direction_type"
            onChange={handleTimeDirectionChange}
            labelWidth={timeDirectionLabelWidth}
          >
            {Object.values(TimeDirectionTypeEnum).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
          {errors.time_direction_type ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.time_direction_type as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>時間方向</FormHelperText>
          )}
        </FormControl>
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>From Variable</Typography>
        <TextField
          inputRef={register(variableFromRegister)}
          error={!!errors.variable_from}
          label="From Variable"
          name="variable_from"
          id="variable-from"
          className={classes.formControl}
          helperText={
            errors.variable_from ? (errors.variable_from as FieldError)['message'] : '変数（From）'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.variable_from}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>To Variable</Typography>
        <TextField
          inputRef={register(variableToRegister)}
          error={!!errors.variable_to}
          label="To Variable"
          name="variable_to"
          id="variable-to"
          className={classes.formControl}
          helperText={
            errors.variable_to ? (errors.variable_to as FieldError)['message'] : '変数（To）'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.variable_to}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Time Confirmation</Typography>
        <TextField
          inputRef={register(timeConfirmationRegister)}
          error={!!errors.time_confirmation}
          label="Time Confirmation"
          name="time_confirmation"
          id="time-confirmation"
          className={classes.formControl}
          helperText={
            errors.time_confirmation
              ? (errors.time_confirmation as FieldError)['message']
              : '時間確認'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentValue.time_confirmation}
        />
      </div>
    </DialogContent>
  );
};

export default UnbTaskDialogContent;
