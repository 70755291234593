import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { RuleConditionGroup } from 'api/models/RuleConditionGroup';
import UnbRequiredSpan from 'components/atoms/UnbRequiredSpan';
import React from 'react';
import { useFormContext, FieldError } from 'react-hook-form';
import {
  conditionNameRegister,
  conditionRegister,
} from 'components/form/validator/RuleConditionGroupRegister';

type UnbRuleConditoinGroupDialogContentProps = {
  dividers?: boolean;
  entity: RuleConditionGroup;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const UnbRuleConditionGroupDialogContent: React.FC<UnbRuleConditoinGroupDialogContentProps> = (
  props
) => {
  const classes = useStyles();
  const { register, errors } = useFormContext();

  return (
    <DialogContent dividers={props.dividers}>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Condition Name
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(conditionNameRegister)}
          error={!!errors.condition_name}
          label="Condition Name"
          name="condition_name"
          id="condition-name"
          className={classes.formControl}
          helperText={
            errors.condition_name
              ? (errors.condition_name as FieldError)['message']
              : '条件グループ名'
          }
          margin="dense"
          variant="outlined"
          defaultValue={props.entity.condition_name}
        />
      </div>
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Condition
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(conditionRegister)}
          error={!!errors.condition}
          label="Condition"
          name="condition"
          id="condition"
          className={classes.formControl}
          helperText={errors.condition ? (errors.condition as FieldError)['message'] : '条件'}
          margin="dense"
          variant="outlined"
          defaultValue={props.entity.condition}
        />
      </div>
    </DialogContent>
  );
};

export default UnbRuleConditionGroupDialogContent;
