import React from 'react';
import UnbIconButton, { UnbIconButtonProps } from 'components/molecules/UnbIconButton';
import UnbMenu from 'components/organisms/UnbMenu';
import { UnbMenuItemProps } from 'components/atoms/UnbMenuItem';

type UnbIconMenuProps = {
  iconButtonProps: UnbIconButtonProps;
  items: UnbMenuItemProps[];
};

const UnbIconMenu: React.FC<UnbIconMenuProps> = (props) => {
  const accountIconColor = 'inherit';

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    //console.log('start (handleMenu):' + props.iconButtonProps.ariaLabel);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuItem = (action: string): void => {
    //console.log(action);
    setAnchorEl(null);
  };

  const items = props.items.map((item) => {
    item.onClickHandlerForParent = handleMenuItem;
    return item;
  });

  const menuId = 'icon-menu-' + props.iconButtonProps.ariaLabel;

  return (
    <div>
      <UnbIconButton
        {...props.iconButtonProps}
        onClickHandler={handleMenu}
        color={accountIconColor}
        ariaControls={menuId}
        ariaHaspopup={true}
        edge="end"
      />
      <UnbMenu id={menuId} onCloseHandler={handleClose} items={items} anchorEl={anchorEl} />
    </div>
  );
};

export default UnbIconMenu;
