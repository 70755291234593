import {
  createStyles,
  Dialog,
  DialogProps,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import RequestVerbDefinition from 'api/models/requests/RequestVerbDefinition';
import { ReplyTypeEnum, VerbDefinition } from 'api/models/VerbDefinition';
import { TimeDirectionTypeEnum } from 'api/types/TypeDirectionType';
import { AxiosError } from 'axios';
import UnbStyledTableCell from 'components/atoms/UnbStyledTableCell';
import UnbStyledTableRow from 'components/atoms/UnbStyledTableRow';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import UnbEditDialogActions from 'components/organisms/UnbEditDialogActions';
import UnbTableToolbar from 'components/organisms/UnbTableToolbar';
import UnbVerbDefinitionDialogContent from 'components/organisms/UnbVerbDefinitionDialogContent';
import UnbScrollableFormContent from 'components/organisms/UnbScrollableFormContent';
import UnbSimpleAlertDialog from 'components/templates/UnbSimpleAlertDialog';
import UnbEditDialogTitle from 'components/molecules/UnbEditDialogTitle';
import { getNoticeErrorMessage, LabelText, NoticeMessages } from 'constants/Messages';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  deleteVerbDefinitionAction,
  getVerbDefinitionAction,
  updateVerbDefinitionAction,
  verbDefinitionActions,
  addVerbDefinitionAction,
  searchVerbDefinitionAction,
} from 'store/domains/verb-definition/action';
import { uiVerbDefinitionActions } from 'store/uis/verb-definition/action';
import { cleanStringArray, convertFieldArrayFormData } from 'utils/CollectionUtil';
import UnbVerbDefinitionHeadBar from 'components/organisms/UnbVerbDefinitionHeadBar';
import { VerbDefinitionFormData } from 'components/form/UnbVerbDefinitionForms';
import {
  setError,
  generateVerbDefinitinAndQuery as generateAndQuery,
  generateVerbDefinitinOrQuery as generateOrQuery,
} from 'utils/ComponentUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '80vh',
      overflowX: 'auto',
    },
    container: {
      maxHeight: '60vh',
    },
  })
);

export interface Column {
  id:
    | 'verb_definition_id'
    | 'verb_group_name'
    | 'verb_name'
    | 'verbs'
    | 'exclude_verbs'
    | 'verbs_regex'
    | 'exclude_verb_regex'
    | 'time_direction'
    | 'is_required'
    | 'reply_type'
    | 'kaku_jyoshi'
    | 'is_abbreviate'
    | 'value_types'
    | 'exclude_nouns'
    | 'decision_condition'
    | 'decision_command'
    | 'weight'
    | 'confirmation_reply_task_id'
    | 'rule_id'
    | 'created_at'
    | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatNumber?: (value: number) => string;
  formatBoolean?: (value: boolean) => string;
  formatArray?: (value: Array<string>) => string;
}

export const columns: readonly Column[] = [
  { id: 'verb_definition_id', label: 'ID', minWidth: 100 },
  { id: 'verb_group_name', label: 'Group Name', minWidth: 150 },
  { id: 'verb_name', label: 'Name', minWidth: 150 },
  { id: 'verbs', label: 'Verbs', minWidth: 150 },
  { id: 'exclude_verbs', label: 'Exclude Verbs', minWidth: 150 },
  { id: 'verbs_regex', label: 'Verbs Regex', minWidth: 150 },
  { id: 'exclude_verb_regex', label: 'Exclude Verbs Regex', minWidth: 170 },
  { id: 'time_direction', label: 'Time Direction', minWidth: 150 },
  {
    id: 'is_required',
    label: 'Required',
    minWidth: 100,
    formatBoolean: (v: boolean): string => v.toString(),
  },
  { id: 'reply_type', label: 'Reply Type', minWidth: 150, align: 'right' },
  { id: 'kaku_jyoshi', label: 'Kakujyoshi', minWidth: 150, align: 'right' },
  {
    id: 'is_abbreviate',
    label: 'Abbreviate',
    minWidth: 100,
    align: 'right',
    formatBoolean: (v: boolean): string => v.toString(),
  },
  { id: 'value_types', label: 'Value Type', minWidth: 150, align: 'right' },
  { id: 'exclude_nouns', label: 'Exclude Nouns', minWidth: 150, align: 'right' },
  { id: 'decision_condition', label: 'Decision Condition', minWidth: 200, align: 'right' },
  { id: 'decision_command', label: 'Decision Command', minWidth: 200, align: 'right' },
  { id: 'weight', label: 'Weight', minWidth: 100 },
  { id: 'confirmation_reply_task_id', label: 'Confirmation Reply Task ID', minWidth: 200 },
  { id: 'rule_id', label: 'Rule ID', minWidth: 150 },
  { id: 'updated_at', label: 'Updated At', minWidth: 170, align: 'right' },
  { id: 'created_at', label: 'Created At', minWidth: 170, align: 'right' },
];

const createRequest = (
  currentID: number | undefined = undefined,
  data: VerbDefinitionFormData
): RequestVerbDefinition => {
  const model: RequestVerbDefinition = {
    verb_group_name: data.verb_group_name,
    verb_name: data.verb_name,
    verbs:
      data.verbs === null || data.verbs === undefined
        ? []
        : cleanStringArray(convertFieldArrayFormData(data.verbs)),
    exclude_verbs:
      data.exclude_verbs === null || data.exclude_verbs === undefined
        ? []
        : cleanStringArray(convertFieldArrayFormData(data.exclude_verbs)),
    verbs_regex: data.verbs_regex,
    exclude_verb_regex: data.exclude_verb_regex,
    time_direction: data.time_direction as TimeDirectionTypeEnum,
    is_required: !!+(data.is_required === undefined ? false : data.is_required),
    reply_type: data.reply_type as ReplyTypeEnum,
    kaku_jyoshi:
      data.kaku_jyoshi === null || data.kaku_jyoshi === undefined
        ? []
        : cleanStringArray(convertFieldArrayFormData(data.kaku_jyoshi)),
    is_abbreviate: !!+(data.is_abbreviate === undefined ? false : data.is_abbreviate),
    value_types: data.value_types,
    exclude_nouns:
      data.exclude_nouns === null || data.exclude_nouns === undefined
        ? []
        : cleanStringArray(convertFieldArrayFormData(data.exclude_nouns)),
    decision_command: data.decision_command,
    decision_condition: data.decision_condition,
    weight: Number(data.weight),
    confirmation_reply_task_id: data.confirmation_reply_task_id,
    rule_id: data.rule_id,
  };
  if (currentID !== null && currentID !== undefined) {
    model.verb_definition_id = currentID;
  }
  return model;
};

const VerbDefinitionComponent: React.FC = () => {
  const classes = useStyles();
  const themes = useTheme();
  const methods = useForm<VerbDefinitionFormData>({ mode: 'onChange' });
  const searchMethods = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const currentValue = useSelector((state: AppState) => state.verbDefinition.currentValue);
  const rows = useSelector((state: AppState) => state.verbDefinition.rows);
  const count = useSelector((state: AppState) => state.verbDefinition.count);
  const fetchOption = useSelector((state: AppState) => state.verbDefinition.fetchOption);
  const searchParams = useSelector((state: AppState) => state.verbDefinition.searchParams);
  const loading = useSelector((state: AppState) => state.verbDefinition.loading);
  const action = useSelector((state: AppState) => state.uiVerbDefinition.action);
  const openAlertDialog = useSelector((state: AppState) => state.uiVerbDefinition.openAlertDialog);
  const openDialog = useSelector((state: AppState) => state.uiVerbDefinition.openDialog);
  const okBtnText = useSelector((state: AppState) => state.uiVerbDefinition.okBtnText);
  const displayDelete = useSelector((state: AppState) => state.uiVerbDefinition.displayDelete);
  const openCopyAlertDialog = useSelector(
    (state: AppState) => state.uiVerbDefinition.openCopyAlertDialog
  );
  const isDisplayCopyBtn = useSelector(
    (state: AppState) => state.uiVerbDefinition.isDisplayCopyBtn
  );
  const { enqueueSnackbar } = useSnackbar();
  const [closeAlertDialog, setCloseAlertDialog] = React.useState(false);

  useEffect(() => {
    const fn = async (): Promise<void> => {
      //console.log('call useEffect');
      await fetchData(searchParams);
      const t = document.getElementsByClassName('MuiTableContainer-root')[0];
      if (t) {
        t.scrollTop = 0;
      }
    };
    fn();
  }, [fetchOption, searchParams]);

  const fetchData = async (data: Record<string, string | number>): Promise<void> => {
    dispatch(verbDefinitionActions.setLoading(true));

    if (
      data.value === '' &&
      data.time_direction === 'all' &&
      data.reply_type === 'all' &&
      data.is_required === 2 &&
      data.is_abbreviate === 2
    ) {
      await asyncDispatch(getVerbDefinitionAction());
      dispatch(verbDefinitionActions.setLoading(false));
    } else {
      try {
        await asyncDispatch(
          searchVerbDefinitionAction(generateAndQuery(data), generateOrQuery(data, columns))
        );
        dispatch(verbDefinitionActions.setLoading(false));
      } catch (e) {
        dispatch(verbDefinitionActions.setLoading(false));
        const axiosErr = e as AxiosError;
        console.log(axiosErr.response);
        const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_SEARCH);
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  const updateUIStates = (
    currentVal: VerbDefinition,
    isOpenDialog: boolean,
    isOpenAlertDialog: boolean,
    isDisplayDelete: boolean,
    okBtnText: string
  ): void => {
    dispatch(verbDefinitionActions.setCurrentValue(currentVal));
    dispatch(uiVerbDefinitionActions.setOpenDialog(isOpenDialog));
    dispatch(uiVerbDefinitionActions.setOpenAlertDialog(isOpenAlertDialog));
    dispatch(uiVerbDefinitionActions.setDisplayDelete(isDisplayDelete));
    dispatch(uiVerbDefinitionActions.setOkBtnText(okBtnText));
  };

  const handleAlertOk = useCallback(async () => {
    dispatch(verbDefinitionActions.setLoading(true));
    dispatch(uiVerbDefinitionActions.setOpenAlertDialog(false));
    try {
      if (currentValue.verb_definition_id !== undefined) {
        await asyncDispatch(deleteVerbDefinitionAction(currentValue.verb_definition_id));
        enqueueSnackbar(NoticeMessages.COMPLETE_DELETE, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(NoticeMessages.ERROR_DELETE, { variant: 'error' });
    } finally {
      dispatch(verbDefinitionActions.setLoading(false));
    }
  }, [loading, openAlertDialog]);

  const handleAlertCancel = useCallback(() => {
    const emptyValue = {} as VerbDefinition;
    updateUIStates(emptyValue, false, false, false, LabelText.ADD_LABEL);
    methods.clearError();
  }, [openAlertDialog]);

  const handleClose = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleCloseOk = useCallback(() => {
    setCloseAlertDialog(false);
    updateUIStates({} as VerbDefinition, false, false, false, '');
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]);

  const handleCloseCancel = (): void => {
    setCloseAlertDialog(false);
  };

  const handleOk = useCallback(() => {
    //nop
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(uiVerbDefinitionActions.setOpenDialog(false));
    dispatch(uiVerbDefinitionActions.setOpenAlertDialog(true));
  }, [openDialog, openAlertDialog]);

  const handleCancel = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleClickRow = useCallback(
    (key: number, scrollType: DialogProps['scroll']) => {
      const current = rows.find((r: VerbDefinition) => r.verb_definition_id === key);
      if (current !== undefined) {
        updateUIStates(current, true, false, true, LabelText.UPDATE_LABEL);
        dispatch(uiVerbDefinitionActions.setAction('UPDATE'));
        dispatch(uiVerbDefinitionActions.setIsDisplayCopyBtn(true));
      }
    },
    [rows, currentValue, openDialog, openAlertDialog, displayDelete, okBtnText]
  );

  const handleAdd = useCallback(() => {
    const emptyValue = {} as VerbDefinition;
    dispatch(uiVerbDefinitionActions.setAction('ADD'));
    updateUIStates(emptyValue, true, false, false, LabelText.ADD_LABEL);
  }, [currentValue, openDialog, openAlertDialog, displayDelete, okBtnText, action]);

  const handleSearch = useCallback(
    (data: Record<string, string | number>): void => {
      console.log('call handleSearch', data);
      fetchOption.page = 0;
      dispatch(
        verbDefinitionActions.setSearchParams({
          fetchOption: fetchOption,
          searchParams: data,
        })
      );
    },
    [loading, fetchOption, searchParams]
  );

  const handleCopy = (): void => {
    dispatch(uiVerbDefinitionActions.setOpenDialog(false));
    dispatch(uiVerbDefinitionActions.setOpenCopyAlertDialog(true));
  };

  const handleCopyAlertCancel = useCallback((): void => {
    dispatch(uiVerbDefinitionActions.setOpenCopyAlertDialog(false));
  }, [openCopyAlertDialog]);

  const handleCopyAlertOk = useCallback((): void => {
    dispatch(uiVerbDefinitionActions.setIsDisplayCopyBtn(false));
    dispatch(uiVerbDefinitionActions.setOpenCopyAlertDialog(false));

    const copyData = { ...currentValue };
    copyData.verb_definition_id = undefined;
    copyData.verb_group_name = copyData.verb_group_name
      ? copyData.verb_group_name + '_copy'
      : 'copy';
    dispatch(verbDefinitionActions.setCurrentValue(copyData));
    dispatch(uiVerbDefinitionActions.setAction('ADD'));
    dispatch(uiVerbDefinitionActions.setDisplayDelete(false));
    dispatch(uiVerbDefinitionActions.setOkBtnText(LabelText.ADD_LABEL));
    dispatch(uiVerbDefinitionActions.setOpenDialog(true));
  }, [openCopyAlertDialog]);

  const onSubmit = useCallback(
    async (data: VerbDefinitionFormData) => {
      dispatch(verbDefinitionActions.setLoading(true));

      switch (action) {
        case 'ADD': {
          const params = createRequest(undefined, data);
          try {
            await asyncDispatch(addVerbDefinitionAction(params));
            dispatch(uiVerbDefinitionActions.setOpenDialog(false));
            enqueueSnackbar(NoticeMessages.COMPLETE_ADD, { variant: 'success' });
          } catch (e) {
            setError(e, methods);
            const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_ADD);
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(verbDefinitionActions.setLoading(false));
          }
          break;
        }
        case 'UPDATE': {
          if (currentValue.verb_definition_id !== undefined) {
            const params = createRequest(currentValue.verb_definition_id, data);
            //console.log(params);

            try {
              await asyncDispatch(updateVerbDefinitionAction(params));
              dispatch(uiVerbDefinitionActions.setOpenDialog(false));
              enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, { variant: 'success' });
            } catch (e) {
              setError(e, methods);
              const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
              enqueueSnackbar(message, { variant: 'error' });
            } finally {
              dispatch(verbDefinitionActions.setLoading(false));
            }
          }
          break;
        }

        default:
          break;
      }
    },
    [action, loading, openDialog]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      console.log(newPage);
      fetchOption.page = newPage;
      dispatch(verbDefinitionActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      fetchOption.limit = +event.target.value;
      fetchOption.page = 0;
      dispatch(verbDefinitionActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const renderCopyAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCopyAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCopyAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_COPY]}
        title={LabelText.CONFIRM}
        open={openCopyAlertDialog}
      />
    ),
    [openCopyAlertDialog]
  );

  const renderAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_DELETE]}
        title={LabelText.CONFIRM}
        open={openAlertDialog}
      />
    ),
    [openAlertDialog]
  );

  const renderCloseAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCloseOk}
        okBtnText={LabelText.CLOSE_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCloseCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_CLOSE]}
        title={LabelText.CONFIRM}
        open={closeAlertDialog}
      >
        <DialogTitle id="conf-dialog-title">LabelText.CONFIRM</DialogTitle>
      </UnbSimpleAlertDialog>
    ),
    [closeAlertDialog]
  );

  const renderArrayTableCell = (val: Array<string>): JSX.Element[] => {
    return val.map((v: string, i: number) => {
      return <div key={i}>{v}</div>;
    });
  };

  const renderDilog = useMemo(
    () => (
      <Dialog
        id={'input-dialog'}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
      >
        {isDisplayCopyBtn ? (
          <UnbEditDialogTitle
            id="form-dialog-title"
            onCopy={(): void => handleCopy()}
            copyBtnText="複製"
            copyBtnColor={'primary'}
          >
            動詞定義
          </UnbEditDialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">動詞定義</DialogTitle>
        )}
        <UnbScrollableFormContent
          onSubmit={methods.handleSubmit(onSubmit)}
          dialogContent={<UnbVerbDefinitionDialogContent dividers verbDefinition={currentValue} />}
          editDilaogActions={
            <UnbEditDialogActions
              okBtnColor="primary"
              cancelBtnColor="primary"
              okBtnText={okBtnText}
              cancelBtnText="キャンセル"
              okHandler={handleOk}
              cancelHandler={handleCancel}
              deleteBtnColor="secondary"
              deleteBtnText="削除"
              deleteHandler={handleDelete}
              isDisplayDelete={displayDelete}
            />
          }
        />
      </Dialog>
    ),
    [openDialog, currentValue, okBtnText, displayDelete]
  );

  const renderTable = useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <UnbStyledTableCell
                    backgroundColor={'white'}
                    color={'black'}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </UnbStyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: VerbDefinition, i: number) => {
              return (
                <UnbStyledTableRow
                  backgroundColor={themes.palette.grey['50']}
                  color={'black'}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={(): void => {
                    if (row.verb_definition_id) {
                      handleClickRow(row.verb_definition_id, 'paper');
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value instanceof Array ? (
                          renderArrayTableCell(value)
                        ) : (
                          <div>
                            {column.formatBoolean && typeof value === 'boolean'
                              ? column.formatBoolean(value)
                              : value}
                          </div>
                        )}
                      </TableCell>
                    );
                  })}
                </UnbStyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [rows]
  );

  const renderPagination = useMemo(
    () => (
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={'ページ毎の件数'}
        component="div"
        count={count}
        rowsPerPage={fetchOption.limit}
        page={fetchOption.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ),
    [fetchOption, count]
  );

  const renderLoading = useMemo(() => <UnbCircularProgress open={loading} />, [loading]);

  const renderContent = (
    <Paper className={classes.root}>
      <UnbTableToolbar title="動詞定義" onClickAdd={handleAdd} />
      {renderDilog}
      {renderAlertDialog}
      {renderCloseAlertDialog}
      {renderCopyAlertDialog}
      {renderTable}
      {renderPagination}
    </Paper>
  );

  return (
    <div>
      <FormContext {...methods}>
        <FormContext {...searchMethods}>
          <UnbVerbDefinitionHeadBar handleSearch={handleSearch} />
        </FormContext>
        {renderContent}
        {loading && renderLoading}
      </FormContext>
    </div>
  );
};

export default VerbDefinitionComponent;
