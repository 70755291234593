import React, { ChangeEvent } from 'react';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type UnbMessageInputProps = {
  onClickSend: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      verticalAlign: 'middle',
      width: '85%',
    },
    sendBtn: {
      paddingLeft: '18px',
      paddingRight: '2px',
    },
  })
);

const UnbMessageInput: React.FC<UnbMessageInputProps> = (props) => {
  const classes = useStyles();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) {
      props.onClickSend();
    }
  };
  return (
    <div>
      <TextField
        className={classes.input}
        id="outlined-textarea"
        variant="outlined"
        size="small"
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        value={props.value}
      />
      <IconButton color="primary" aria-label="send" onClick={props.onClickSend}>
        <SendIcon />
      </IconButton>
    </div>
  );
};

export default UnbMessageInput;
