import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { uiDrawerActions } from 'store/uis/drawer/action';

export type UIDrawerState = {
  selected: number;
};

export const initialState: UIDrawerState = {
  selected: 0,
};

export const uiDrawerReducer = reducerWithInitialState(initialState).case(
  uiDrawerActions.setSelectedItem,
  (state, payload) => {
    return {
      ...state,
      selected: payload,
    };
  }
);
