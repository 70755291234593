import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { commonActions } from 'store/app/actions';
import AuthService from 'service/AuthService';

export type CommonState = {
  authService: AuthService;
  selectedProjectId?: number;
};

export const initialState: CommonState = {
  authService: new AuthService(),
};

export const commonReducer = reducerWithInitialState(initialState)
  .case(commonActions.setAuthService, (state, payload) => {
    return {
      ...state,
      authService: payload,
    };
  })
  .case(commonActions.setSelectedProjectId, (state, payload) => {
    return {
      ...state,
      selectedProjectId: payload,
    };
  });
