import { AxiosError } from 'axios';

export const ValidationErrorMessages = {
  REQUIRED: '必須入力項目です',
  PATTERN: '{0} の形式で入力してください',
  MIN: '{0}以上の値を入力してください',
  MAX: '{0}以下の値を入力してください',
  MIN_LEN: '{0}文字以上で入力してください',
  MAX_LEN: '{0}文字以内で入力してください',
  REQUIRED_FOR_MULTIPLE: '複数項目の場合必須',
};

export const FormHelperMessages = {
  WORD_MASTER_NOISE: 'シナリオを発火させない条件の定義',
};

export const NoticeMessages = {
  COMPLETE_UPDATE: '更新しました',
  COMPLETE_DELETE: '削除しました',
  COMPLETE_ADD: '追加しました',
  COMPLETE_RELOAD: 'サーバに反映しました',
  COMPLETE_IMPORT: 'データのインポートが完了しました',
  COMPLETE_EXPORT: 'データのエスクポートが完了しました',
  ERROR_UPDATE: '更新に失敗しました',
  ERROR_DELETE: '削除に失敗しました',
  ERROR_ADD: '追加に失敗しました',
  ERROR_SEARCH: '検索に失敗しました',
  ERROR_RELOAD: 'サーバの反映に失敗しました',
  ERROR_IMPORT: 'データのインポートに失敗しました',
  ERROR_EXPORT: 'データのエクスポートに失敗しました',
  ERROR_NO_SELECT_PROJECT: 'プロジェクトが選択されていません',
  ERROR_SENDMSG: 'メッセージの送信に失敗しました',
  DUPLICATION: '重複しているため失敗しました',
  ERROR_GET_RELATION: 'マップ表示データの取得に失敗しました',
};

export const LabelText = {
  UPDATE_LABEL: '更新',
  DELETE_LABEL: '削除',
  ADD_LABEL: '追加',
  ALERT_OK_LABEL: 'はい',
  ALERT_CANCEL_LABEL: 'キャンセル',
  CLOSE_LABEL: '閉じる',
  CONFIRM_DELETE: '本当に削除してよろしいですか？',
  CONFIRM_DELETE_CHAT_RULE: '関連するシナリオも削除されます',
  CONFIRM_CLOSE: '編集中のデータがリセットされますが、よろしいですか？',
  CONFIRM: '確認',
  CONFIRM_COPY: '複製して新規作成しますか？',
  CONFIRM_RELOAD: '編集したシナリオデータを反映しますか？',
  CONFIRM_SLOW_PROCESS: '※処理に時間がかかる場合があります',
};

export const getNoticeErrorMessage = (e: AxiosError, defaultMessage: string): string => {
  let message = '';
  switch (e.response?.status) {
    case 409:
      message = NoticeMessages.DUPLICATION;
      break;
    default:
      message = defaultMessage;
      break;
  }

  return message;
};

export const getNoticeImportErrorMessage = (e: AxiosError, defaultMessage: string): string => {
  let message = '';
  if (e.response?.data !== undefined) {
    if ('file' in e.response?.data.errors) {
      message = e.response?.data.errors.file.join('\n');
    } else {
      message = NoticeMessages.ERROR_IMPORT;
    }
  } else {
    message = NoticeMessages.ERROR_IMPORT;
  }
  return message;
};
