import { ValidationErrorMessages } from 'constants/Messages';
import 'utils/StringUtil.ts';

export const conditionNameRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
  pattern: { value: /^\[.*.\]$/, message: ValidationErrorMessages.PATTERN.format('/^[.*.]$/') },
};

export const conditionRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};
