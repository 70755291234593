import { TimeDirectionTypeEnum } from 'api/types/TypeDirectionType';
/**
 * nlp-server API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VerbDefinition {
  /**
   * 識別子
   */
  verb_definition_id?: number;
  /**
   * 動詞グループ名
   */
  verb_group_name?: string;
  /**
   * 動詞名
   */
  verb_name?: string;
  /**
   * 対象動詞
   */
  verbs?: Array<string>;
  /**
   * 対象外動詞
   */
  exclude_verbs?: Array<string>;
  /**
   * 対象動詞（正規表現）
   */
  verbs_regex?: string;
  /**
   * 対象外動詞（正規表現）
   */
  exclude_verb_regex?: string;
  /**
   * 時間方向
   */
  time_direction?: TimeDirectionTypeEnum;
  /**
   * 必須項目
   */
  is_required?: boolean;
  /**
   * 種別
   */
  reply_type?: ReplyTypeEnum;
  /**
   * 格助詞
   */
  kaku_jyoshi?: Array<string>;
  /**
   * 格助詞の省略対象
   */
  is_abbreviate?: boolean;
  /**
   * 格助詞の値の種別
   */
  value_types?: string;
  /**
   * 対象外名詞
   */
  exclude_nouns?: Array<string>;
  /**
   * 適用条件
   */
  decision_condition?: string;
  /**
   * 適用条件コマンド
   */
  decision_command?: string;
  /**
   * 適用の重み
   */
  weight?: number;
  /**
   * 確認用返答文
   */
  confirmation_reply_task_id?: string;
  /**
   * rule_id
   */
  rule_id?: string;
  /**
   * 登録日付
   */
  created_at?: string;
  /**
   * 更新日付
   */
  updated_at?: string;
}
export type ReplyTypeEnum = 'reply' | 'confirmation';
export const ReplyTypeEnum = {
  Reply: 'reply' as ReplyTypeEnum,
  Confirmation: 'confirmation' as ReplyTypeEnum,
};
