export const convertBool2Num = (b?: boolean): boolean | 0 | 1 => {
  if (b === null || b === undefined) {
    return 0;
  }
  return b ? 1 : 0;
};

export const convertNum2Bool = (n: 0 | 1 | 2): boolean => {
  switch (n) {
    case 0:
      return false;
    case 1:
      return true;
    default:
      return false;
  }
};
