/** @fileOverview 会話データコンポーネント */
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import CloseIcon from '@material-ui/icons/Close';
import {
  ActionCodeEnum,
  ChatRule,
  FacialExpressionCodeEnum,
  ProcTypeEnum,
  RuleTypeEnum,
} from 'api/models/ChatRule';
import { ReplyCommand } from 'api/models/ReplyCommand';
import RequestPatchChatRule from 'api/models/requests/RequestPatchChatRule';
import { TestResult, TestResultStatusEnum } from 'api/models/TestResult';
import axios, { AxiosError } from 'axios';
import UnbStyledTableCell from 'components/atoms/UnbStyledTableCell';
import UnbStyledTableRow from 'components/atoms/UnbStyledTableRow';
import {
  ChatRuleFormData,
  SearchChatRuleFormData,
  StateActionFormData,
} from 'components/form/UnbChatRuleForms';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import UnbChatRuleDialogContent from 'components/organisms/UnbChatRuleDialogContent';
import UnbChatRuleDialogContentForNetwork from 'components/organisms/UnbChatRuleDialogContentForNetwork';
import UnbChatRuleHeadBar from 'components/organisms/UnbChatRuleHeadBar';
import UnbEditDialogActions, { Action } from 'components/organisms/UnbEditDialogActions';
import UnbTableToolbar from 'components/organisms/UnbTableToolbar';
import UnbTestResultDialogContent from 'components/organisms/UnbTestResultDialogContent';
import UnbScrollableFormContent from 'components/organisms/UnbScrollableFormContent';
import UnbSimpleAlertDialog from 'components/templates/UnbSimpleAlertDialog';
import UnbEditDialogTitle from 'components/molecules/UnbEditDialogTitle';
import {
  getNoticeErrorMessage,
  getNoticeImportErrorMessage,
  LabelText,
  NoticeMessages,
} from 'constants/Messages';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState, useCallback, ChangeEvent } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  addChatRuleAction,
  chatRuleActions,
  deleteChatRuleAction,
  getChatRuleAction,
  updateChatRuleAction,
  searchChatRuleAction,
  reloadPrivateChatRuleAction,
  exportChatRuleAction,
  importChatRuleAction,
} from 'store/domains/chat-rule/action';
import { cleanStringArray, convertFieldArrayFormData } from 'utils/CollectionUtil';
import { setError, generateScenarioChatRuleQuery as generateQuery } from 'utils/ComponentUtil';
import { StateAction } from 'api/models/StateAction';
import { uiChatRuleActions } from 'store/uis/chat-rule/action';
import UnbStateActions from 'components/organisms/UnbStateActions';
import FileSaver from 'file-saver';
import { Fragment } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '80vh',
      overflowX: 'auto',
    },
    container: {
      maxHeight: '60vh',
    },
  })
);

interface Column {
  id:
    | 'chat_rule_id'
    | 'starting_flag'
    | 'rule_type'
    | 'decision_condition'
    | 'decision_command'
    | 'command_summary'
    | 'weight'
    | 'rule_id'
    | 'before_rule_id'
    | 'next_rule_id'
    | 'reply'
    | 'reply_command'
    | 'function_code'
    | 'action_code'
    | 'facial_expression_code'
    | 'system_sentiment'
    | 'user_sentiment'
    | 'state_action'
    | 'template_ids'
    | 'template_variable_reset_condition'
    | 'next_period'
    | 'task_name'
    | 'qa_query'
    | 'proc_type'
    | 'should_keep_user'
    | 'test_result'
    | 'created_at'
    | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatNumber?: (value: number) => string;
  formatBoolean?: (value: boolean) => string;
  formatArray?: (value: Array<string>) => string;
}

const columns: readonly Column[] = [
  { id: 'chat_rule_id', label: 'ID', minWidth: 100 },
  { id: 'rule_id', label: 'Rule ID', minWidth: 150 },
  { id: 'before_rule_id', label: 'Before Rule IDs', minWidth: 150 },
  { id: 'next_rule_id', label: 'Next Rule IDs', minWidth: 150 },
  {
    id: 'starting_flag',
    label: 'Starting Flag',
    minWidth: 150,
    formatBoolean: (v: boolean): string => v.toString(),
  },
  { id: 'rule_type', label: 'Rule Type', minWidth: 100 },
  { id: 'decision_condition', label: 'Decision Condition', minWidth: 150 },
  { id: 'decision_command', label: 'Decision Command', minWidth: 150 },
  { id: 'command_summary', label: 'Command Summary', minWidth: 160 },
  { id: 'reply', label: 'Reply', minWidth: 400 },
  { id: 'reply_command', label: 'Reply Command', minWidth: 150 },
  { id: 'weight', label: 'Weight', minWidth: 100 },
  { id: 'function_code', label: 'Function Code', minWidth: 150 },
  { id: 'action_code', label: 'Action Code', minWidth: 150 },
  {
    id: 'facial_expression_code',
    label: 'Facial Expression Code',
    minWidth: 180,
  },
  { id: 'system_sentiment', label: 'System Sentiment', minWidth: 150 },
  { id: 'user_sentiment', label: 'User Sentiment', minWidth: 150 },
  { id: 'state_action', label: 'State Action', minWidth: 150 },
  { id: 'template_ids', label: 'Template IDs', minWidth: 150 },
  {
    id: 'template_variable_reset_condition',
    label: 'Template Variable Reset Condition',
    minWidth: 250,
  },
  { id: 'next_period', label: 'Next Period', minWidth: 150 },
  { id: 'task_name', label: 'Task Name', minWidth: 150 },
  { id: 'qa_query', label: 'QA Queries', minWidth: 150 },
  { id: 'proc_type', label: 'Proc Type', minWidth: 100 },
  {
    id: 'should_keep_user',
    label: 'Shoud Keep User',
    minWidth: 180,
    formatBoolean: (v: boolean): string => v.toString(),
  },
  { id: 'test_result', label: 'Test Result', minWidth: 100 },
  { id: 'updated_at', label: 'Updated At', minWidth: 170, align: 'right' },
  { id: 'created_at', label: 'Created At', minWidth: 170, align: 'right' },
];
interface TestResultColumn {
  id:
    | 'test_result_id'
    | 'scenario_creator'
    | 'reflected_date'
    | 'tester'
    | 'tested_date'
    | 'unibo_id'
    | 'note'
    | 'test_result_status'
    | 'created_at'
    | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatStatus?: (value: TestResultStatusEnum) => string;
}

const testResultColumns: TestResultColumn[] = [
  {
    id: 'test_result_status',
    label: 'Test Result Status',
    minWidth: 150,
    formatStatus: (v: TestResultStatusEnum): string => formatTestResultStatus(v),
  },
];
interface ReplyCommandColumn {
  id: 'reply_command_id' | 'function_name' | 'parameters' | 'created_at' | 'updated_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  formatArray?: (value: Array<string>) => string;
}

const replyCommandColumns: ReplyCommandColumn[] = [
  {
    id: 'function_name',
    label: 'Function Name',
    minWidth: 200,
  },
  {
    id: 'parameters',
    label: 'Scenario Params',
    minWidth: 250,
  },
];

const formatTestResultStatus = (val: TestResultStatusEnum): string => {
  switch (val) {
    case TestResultStatusEnum.SUCCESS:
      return '○';
    case TestResultStatusEnum.FAILURE:
      return '×';
    case TestResultStatusEnum.HOLD:
      return '△';
    default:
      return '×';
  }
};

const initTestResult = {
  test_result_status: TestResultStatusEnum.HOLD,
  scenario_creator: '',
  tester: '',
  tested_date: new Date(),
  reflected_date: new Date(),
  unibo_id: '',
  note: '',
};

/** 会話データコンポーネント */
const ScenarioChatRuleComponent: React.FC = () => {
  const classes = useStyles();
  const themes = useTheme();
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();

  const selectedProjectId = useSelector((state: AppState) => state.app.selectedProjectId);
  const rows = useSelector((state: AppState) => state.chatRule.rows);
  const count = useSelector((state: AppState) => state.chatRule.count);
  const fetchOption = useSelector((state: AppState) => state.chatRule.fetchOption);
  const currentChatRule = useSelector((state: AppState) => state.chatRule.currentChatRule);
  const searchParams = useSelector((state: AppState) => state.chatRule.searchParams);
  const loading = useSelector((state: AppState) => state.chatRule.loading);
  const openCopyAlertDialog = useSelector(
    (state: AppState) => state.uiChatRule.openCopyAlertDialog
  );
  const openReloadAlertDialog = useSelector(
    (state: AppState) => state.uiChatRule.openReloadAlertDialog
  );
  const isDisplayCopyBtn = useSelector((state: AppState) => state.uiChatRule.isDisplayCopyBtn);
  const currentProjectId = useSelector((state: AppState) => state.app.selectedProjectId);
  const exportChatRuleBlob = useSelector((state: AppState) => state.chatRule.exportChatRuleFile);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [openDialog, setDialog] = React.useState(false);
  const [openAlertDialog, setAlertDialog] = React.useState(false);
  const [closeAlertDialog, setCloseAlertDialog] = React.useState(false);
  const [openStateActionDialog, setStateActionDialog] = React.useState(false);
  const [displayDelete, setDisplayDelete] = useState(true);
  const [okBtnText, setOkBtnText] = useState(LabelText.UPDATE_LABEL);
  const [action, setAction] = useState(Action.UPDATE);
  const methods = useForm<ChatRuleFormData>({ mode: 'onChange' });
  const searchMethods = useForm<SearchChatRuleFormData>({ mode: 'onChange' });

  const handleChangePage = (event: unknown, newPage: number): void => {
    fetchOption.page = newPage;
    dispatch(chatRuleActions.setFetchOption(fetchOption));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    fetchOption.limit = +event.target.value;
    fetchOption.page = 0;
    dispatch(chatRuleActions.setFetchOption(fetchOption));
  };

  const handleClickRow = (key: number, scrollType: DialogProps['scroll']): void => {
    const target = rows.find((r: ChatRule) => r.chat_rule_id === key);
    if (target !== undefined && target !== null) {
      dispatch(chatRuleActions.setCurrentChatRule(JSON.parse(JSON.stringify(target))));
    }
    dispatch(uiChatRuleActions.setIsDisplayCopyBtn(true));
    setAction(Action.UPDATE);
    setDisplayDelete(true);
    setOkBtnText(LabelText.UPDATE_LABEL);
    setDialog(true);
  };

  /** サブミット */
  const onSubmit = async (data: ChatRuleFormData): Promise<void> => {
    //console.log(data);
    dispatch(chatRuleActions.setLoading(true));
    const params = createChatRuleModel(data);
    //console.log(params);
    switch (action) {
      case Action.UPDATE:
        try {
          await asyncDispatch(updateChatRuleAction(params));
          setDialog(false);
          methods.clearError();
          enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, {
            variant: 'success',
          });
        } catch (e) {
          setError(e, methods);
          const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
          enqueueSnackbar(message, { variant: 'error' });
        } finally {
          dispatch(chatRuleActions.setLoading(false));
        }
        break;
      case Action.ADD:
        try {
          await asyncDispatch(addChatRuleAction(params));
          setDialog(false);
          methods.clearError();
          enqueueSnackbar(NoticeMessages.COMPLETE_ADD, { variant: 'success' });
        } catch (e) {
          setError(e, methods);
          const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_ADD);
          enqueueSnackbar(message, { variant: 'error' });
        } finally {
          dispatch(chatRuleActions.setLoading(false));
        }
        break;

      default:
        break;
    }
  };

  const handleClose = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleCloseOk = (): void => {
    setCloseAlertDialog(false);
    setDialog(false);
  };

  const handleCloseCancel = (): void => {
    setCloseAlertDialog(false);
  };

  const handleOk = (): void => {
    // nop
  };

  const handleCancel = (): void => {
    setCloseAlertDialog(true);
  };

  const handleAlertOk = async (): Promise<void> => {
    dispatch(chatRuleActions.setLoading(true));
    setAlertDialog(false);
    try {
      if (currentChatRule.chat_rule_id !== undefined) {
        await asyncDispatch(deleteChatRuleAction(currentChatRule.chat_rule_id));
        enqueueSnackbar(NoticeMessages.COMPLETE_DELETE, { variant: 'success' });
      }
    } catch (e) {
      const axiosErr = e as AxiosError;
      console.log(axiosErr.response);
      enqueueSnackbar(NoticeMessages.ERROR_DELETE, { variant: 'error' });
    } finally {
      dispatch(chatRuleActions.setLoading(false));
    }
  };

  const handleAlertCancel = (): void => {
    setAlertDialog(false);
  };

  const handleCopyAlertCancel = useCallback((): void => {
    dispatch(uiChatRuleActions.setOpenCopyAlertDialog(false));
  }, [openCopyAlertDialog]);

  const handleCopyAlertOk = useCallback((): void => {
    dispatch(uiChatRuleActions.setIsDisplayCopyBtn(false));
    dispatch(uiChatRuleActions.setOpenCopyAlertDialog(false));

    const copyChatRule = { ...currentChatRule };
    copyChatRule.chat_rule_id = undefined;
    if (copyChatRule.test_result !== undefined) {
      copyChatRule.test_result_id = undefined;
      copyChatRule.test_result.test_result_id = undefined;
    }
    if (copyChatRule.reply_command !== undefined) {
      copyChatRule.reply_command_id = undefined;
      copyChatRule.reply_command.reply_command_id = undefined;
    }
    if (copyChatRule.state_actions !== undefined) {
      copyChatRule.state_actions.forEach((action) => {
        action.state_action_id = undefined;
      });
    }

    copyChatRule.rule_id = copyChatRule.rule_id ? copyChatRule.rule_id + '_copy' : 'copy';
    dispatch(chatRuleActions.setCurrentChatRule(copyChatRule));
    setAction(Action.ADD);
    setDisplayDelete(false);
    setOkBtnText(LabelText.ADD_LABEL);
    setDialog(true);
  }, [openCopyAlertDialog]);

  const handleReloadAlertCancel = useCallback((): void => {
    dispatch(uiChatRuleActions.setOpenReloadAlertDialog(false));
  }, [openReloadAlertDialog]);

  const handleReloadAlertOk = useCallback(async (): Promise<void> => {
    dispatch(uiChatRuleActions.setOpenReloadAlertDialog(false));
    dispatch(chatRuleActions.setLoading(true));
    try {
      await asyncDispatch(reloadPrivateChatRuleAction());
      enqueueSnackbar(NoticeMessages.COMPLETE_RELOAD, { variant: 'success' });
    } catch (e) {
      const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_RELOAD);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      dispatch(chatRuleActions.setLoading(false));
    }
  }, [openReloadAlertDialog]);

  const handleDelete = (): void => {
    setDialog(false);
    setAlertDialog(true);
  };

  const handleAdd = async (): Promise<void> => {
    setAction(Action.ADD);
    dispatch(uiChatRuleActions.setIsDisplayCopyBtn(false));
    const emptyChatRule = {} as ChatRule;

    emptyChatRule.test_result = initTestResult;
    dispatch(chatRuleActions.setCurrentChatRule(emptyChatRule));
    setOkBtnText(LabelText.ADD_LABEL);
    setDisplayDelete(false);
    setDialog(true);
  };

  const handleReload = useCallback(async (): Promise<void> => {
    dispatch(uiChatRuleActions.setOpenReloadAlertDialog(true));
  }, [openReloadAlertDialog]);

  /** インポート */
  const handleImport = useCallback(
    async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (e !== null) {
        const target = e.target as HTMLInputElement;
        if (target.files && target.files.length) {
          // プロジェクト未選択
          if (currentProjectId === undefined || currentProjectId === null) {
            const message = NoticeMessages.ERROR_NO_SELECT_PROJECT;
            enqueueSnackbar(message, { variant: 'error' });
            return;
          }

          try {
            dispatch(chatRuleActions.setLoading(true));
            const files = target.files;
            const formData = new FormData();
            formData.append('file', files[0]);
            await asyncDispatch(importChatRuleAction(formData));
            enqueueSnackbar(NoticeMessages.COMPLETE_IMPORT, {
              variant: 'success',
            });

            // データフェッチ
            dispatchInitialFetchParams();
          } catch (e) {
            const action = (key: any) => (
              <Fragment>
                <IconButton
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Fragment>
            );
            const message = getNoticeImportErrorMessage(e, NoticeMessages.ERROR_IMPORT);
            const key = enqueueSnackbar(message, {
              variant: 'error',
              persist: true,
              action,
              style: { whiteSpace: 'pre-line' },
            });
          } finally {
            dispatch(chatRuleActions.setLoading(false));
            // reset target file
            target.value = '';
          }
        }
      }
    },
    [currentProjectId]
  );

  /**エクスポート */
  const handleExport = useCallback(async () => {
    // プロジェクト未選択
    if (currentProjectId === undefined || currentProjectId === null) {
      const message = NoticeMessages.ERROR_NO_SELECT_PROJECT;
      enqueueSnackbar(message, { variant: 'error' });
      return;
    }

    try {
      dispatch(chatRuleActions.setLoading(true));
      const res = await asyncDispatch(exportChatRuleAction());
      //console.log(JSON.stringify(res));

      if (res === undefined || res === null) {
        throw new Error('res is null or undefined');
      }
      const blob = new Blob([res.data], {
        type: 'application/json',
      });
      const fname = `chat_rule_${currentProjectId}.json`;
      FileSaver.saveAs(blob, fname);
      enqueueSnackbar(NoticeMessages.COMPLETE_EXPORT, {
        variant: 'success',
      });
    } catch (e) {
      const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_EXPORT);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      dispatch(chatRuleActions.setLoading(false));
    }
  }, [currentProjectId, exportChatRuleBlob]);

  const handleSearch = useCallback(
    (data: Record<string, string | number>): void => {
      console.log('handleSearch', data);
      fetchOption.page = 0;
      dispatch(
        chatRuleActions.setSearchParams({
          fetchOption: fetchOption,
          searchParams: data,
        })
      );
    },
    [loading, fetchOption, searchParams]
  );

  const handleClickStateAction = (e: any, chat_rule_id?: number): void => {
    //console.log('start handleClickStateAction() chat_rule_id=' + chat_rule_id);

    const target = rows.find((r: ChatRule) => r.chat_rule_id === chat_rule_id);
    if (target !== undefined && target !== null) {
      dispatch(chatRuleActions.setCurrentChatRule(JSON.parse(JSON.stringify(target))));
    }

    // ダイアログを表示
    setStateActionDialog(true);
    e.stopPropagation();
  };

  const handleStateActionOk = async (): Promise<void> => {
    // nop
  };

  const handleStateActionCancel = (): void => {
    setStateActionDialog(false);
  };

  const handleStateActionClose = (): void => {
    setStateActionDialog(false);
    methods.clearError();
  };

  /** ステートアクションダイアログサブミット */
  const onStateActionSubmit = async (data: StateActionFormData): Promise<void> => {
    setAction(Action.UPDATE);
    //console.log(data);
    dispatch(chatRuleActions.setLoading(true));
    const params = createChatRuleModelFromStateAction(data);
    //console.log(params);

    try {
      await asyncDispatch(updateChatRuleAction(params));
      setStateActionDialog(false);
      methods.clearError();
      enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, { variant: 'success' });
    } catch (e) {
      setError(e, methods);
      const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      dispatch(chatRuleActions.setLoading(false));
    }
  };

  /** ステートアクションから会話データ作成 */
  const createChatRuleModelFromStateAction = (data: StateActionFormData): RequestPatchChatRule => {
    const model: RequestPatchChatRule = {
      chat_rule_id: currentChatRule.chat_rule_id,
      starting_flag: !!currentChatRule.starting_flag,
      rule_type: currentChatRule.rule_type,
      decision_condition: currentChatRule.decision_condition,
      decision_command: currentChatRule.decision_command,
      rule_id: currentChatRule.rule_id,
      weight: currentChatRule.weight,
      before_rule_id: currentChatRule.before_rule_id,
      next_rule_id: currentChatRule.next_rule_id,
      reply: currentChatRule.reply,
      function_code: currentChatRule.function_code,
      action_code: currentChatRule.action_code,
      facial_expression_code: currentChatRule.facial_expression_code,
      system_sentiment: currentChatRule.system_sentiment,
      user_sentiment: currentChatRule.user_sentiment,
      state_actions: createStateActions(),
      template_ids: currentChatRule.template_ids,
      template_variable_reset_condition: currentChatRule.template_variable_reset_condition,
      next_period: currentChatRule.next_period,
      task_name: currentChatRule.task_name,
      qa_query: currentChatRule.qa_query,
      proc_type: currentChatRule.proc_type,
      reply_command: currentChatRule.reply_command,
      test_result: currentChatRule.test_result,
    };
    return model;
  };

  const handleCopy = (): void => {
    setDialog(false);
    dispatch(uiChatRuleActions.setOpenCopyAlertDialog(true));
  };

  const fetchData = async (data: Record<string, string | number>): Promise<void> => {
    dispatch(chatRuleActions.setLoading(true));
    if (+data.starting_flag === 2 && data.rule_id === '' && data.reply === '') {
      await asyncDispatch(getChatRuleAction());
      dispatch(chatRuleActions.setLoading(false));
    } else {
      try {
        await asyncDispatch(searchChatRuleAction(generateQuery(data), null));
        dispatch(chatRuleActions.setLoading(false));
      } catch (e) {
        dispatch(chatRuleActions.setLoading(false));
        const axiosErr = e as AxiosError;
        console.log(axiosErr.response);
        const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_SEARCH);
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  /** 会話データ作成 */
  const createChatRuleModel = (data: ChatRuleFormData): RequestPatchChatRule => {
    const model: RequestPatchChatRule = {
      chat_rule_id: currentChatRule.chat_rule_id,
      starting_flag: !!+data.starting_flag,
      rule_type: data.rule_type as RuleTypeEnum,
      decision_condition: data.decision_condition,
      decision_command: data.decision_command,
      rule_id: data.rule_id,
      weight: Number(data.weight),
      before_rule_id: cleanStringArray(convertFieldArrayFormData(data.before_rule_id)),
      next_rule_id: cleanStringArray(convertFieldArrayFormData(data.next_rule_id)),
      reply: data.reply,
      function_code: data.function_code,
      action_code: data.action_code as ActionCodeEnum,
      facial_expression_code: data.facial_expression_code as FacialExpressionCodeEnum,
      system_sentiment: data.system_sentiment,
      user_sentiment: data.user_sentiment,
      state_actions: createStateActions(),
      template_ids: cleanStringArray(convertFieldArrayFormData(data.template_ids)),
      template_variable_reset_condition: data.template_variable_reset_condition,
      next_period: data.next_period,
      task_name: data.task_name,
      qa_query: cleanStringArray(convertFieldArrayFormData(data.qa_query)),
      proc_type: data.proc_type as ProcTypeEnum,
      reply_command: createReplyCommand(data.function_name, data.parameters),
      test_result: createTestResult(data),
    };

    return model;
  };

  const createStateActions = (): Array<StateAction> | undefined => {
    if (!currentChatRule.state_actions) return undefined;

    const stateActions = currentChatRule.state_actions.map((item, index: number) => {
      const sa: StateAction = {
        state_mod_action: item.state_mod_action,
        modification_command: item.modification_command,
        state_action_id: item.state_action_id,
      };
      return sa;
    });

    return stateActions;
  };

  const dispatchInitialFetchParams = (): void => {
    dispatch(
      chatRuleActions.setSearchParams({
        fetchOption: {
          limit: 20,
          page: 0,
        },
        searchParams: {
          starting_flag: 2,
          rule_id: '',
          reply: '',
        },
      })
    );
  };

  const createReplyCommand = (
    functionName: string,
    params: Array<{ name: string }>
  ): ReplyCommand | undefined => {
    if (currentChatRule.reply_command) {
      const replyCommand =
        currentChatRule.reply_command === null || currentChatRule.reply_command === undefined
          ? ({} as ReplyCommand)
          : currentChatRule.reply_command;
      replyCommand.function_name = functionName;
      replyCommand.parameters = cleanStringArray(convertFieldArrayFormData(params));
      return replyCommand;
    }
    return undefined;
  };

  const createTestResult = (data: ChatRuleFormData): TestResult | undefined => {
    if (currentChatRule.test_result) {
      const testResult =
        currentChatRule.test_result === null || currentChatRule.test_result === undefined
          ? ({} as TestResult)
          : currentChatRule.test_result;
      testResult.scenario_creator = data.scenario_creator;
      testResult.reflected_date = data.reflected_date;
      testResult.tester = data.tester;
      testResult.note = data.note;
      testResult.unibo_id = data.unibo_id;
      testResult.tested_date = data.tested_date;
      testResult.test_result_status = data.test_result_status as TestResultStatusEnum;
      return testResult;
    }
    return undefined;
  };

  const signal = axios.CancelToken.source();
  useEffect(() => {
    const fn = async (): Promise<void> => {
      try {
        fetchData(searchParams);
      } catch (e) {
        const axiosErr = e as AxiosError;
        console.log(axiosErr.response);
      } finally {
        dispatch(chatRuleActions.setLoading(false));
        const t = document.getElementsByClassName('MuiTableContainer-root')[0];
        if (t) {
          t.scrollTop = 0;
        }
      }
    };

    fn();
    return (): void => {
      signal.cancel('Api is being canceled');
    };
  }, [fetchOption, searchParams, selectedProjectId]);

  const renderArrayTableCell = (val: Array<string>): object => {
    return val.map((v: string, i: number) => {
      return <div key={i}>{v}</div>;
    });
  };

  /**
   * 変数操作情報のテーブルセル表示
   */
  const renderStateActionTableCell = (
    stateActions: Array<StateAction> | undefined,
    chatRuleId?: number
  ): object => {
    if (stateActions === null || stateActions === undefined || !stateActions[0]) return <div></div>;
    const stateModAction = stateActions[0].state_mod_action ? stateActions[0].state_mod_action : '';
    const modificationCommand = stateActions[0].modification_command
      ? stateActions[0].modification_command
      : '';
    let displayStr = stateModAction ? stateModAction : modificationCommand;

    // 30文字超過の場合、...表示
    if (displayStr.length > 30) {
      displayStr = displayStr.substring(0, 30) + '...';
    } else {
      // stateActionsが複数ある場合、...表示
      if (stateActions.length > 1) displayStr = displayStr + '...';
    }

    return (
      <Link onClick={(e: any): void => handleClickStateAction(e, chatRuleId)}>{displayStr}</Link>
    );
  };

  /** ステートアクションダイアログ表示 */
  const renderStateActionDialog = useMemo(
    () => (
      <Dialog
        id={'input-dialog-state-action'}
        open={openStateActionDialog}
        onClose={handleStateActionClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
      >
        <DialogTitle id="form-dialog-title-state-action">変数操作情報</DialogTitle>
        <form onSubmit={methods.handleSubmit(onStateActionSubmit)}>
          <DialogContent>
            <UnbStateActions chatRule={currentChatRule} />
          </DialogContent>
          <UnbEditDialogActions
            okBtnColor="primary"
            cancelBtnColor="primary"
            okBtnText="更新"
            cancelBtnText="キャンセル"
            okHandler={handleStateActionOk}
            cancelHandler={handleStateActionCancel}
            deleteBtnColor="secondary"
            deleteBtnText=""
            deleteHandler={(): void => {
              // nop
            }}
            isDisplayDelete={false}
            testIdPostFix="-state-action"
          />
        </form>
      </Dialog>
    ),
    [openStateActionDialog, currentChatRule]
  );

  const renderAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_DELETE, LabelText.CONFIRM_DELETE_CHAT_RULE]}
        title={LabelText.CONFIRM}
        open={openAlertDialog}
      />
    ),
    [openAlertDialog]
  );

  const renderCloseAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCloseOk}
        okBtnText={LabelText.CLOSE_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCloseCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_CLOSE]}
        title={LabelText.CONFIRM}
        open={closeAlertDialog}
      />
    ),
    [closeAlertDialog]
  );

  const renderCopyAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCopyAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCopyAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_COPY]}
        title={LabelText.CONFIRM}
        open={openCopyAlertDialog}
      />
    ),
    [openCopyAlertDialog]
  );

  const renderReloadAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleReloadAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleReloadAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_RELOAD, LabelText.CONFIRM_SLOW_PROCESS]}
        title={LabelText.CONFIRM}
        open={openReloadAlertDialog}
      />
    ),
    [openReloadAlertDialog]
  );

  const renderDilog = useMemo(
    () => (
      <Dialog
        id={'input-dialog'}
        open={openDialog}
        fullScreen={okBtnText === LabelText.ADD_LABEL ? false : true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
      >
        {isDisplayCopyBtn ? (
          <UnbEditDialogTitle
            id="form-dialog-title"
            onCopy={(): void => handleCopy()}
            copyBtnText="複製"
            copyBtnColor={'primary'}
          >
            シナリオ定義
          </UnbEditDialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">シナリオ定義</DialogTitle>
        )}
        <UnbScrollableFormContent
          onSubmit={methods.handleSubmit(onSubmit)}
          dialogContent={<UnbChatRuleDialogContent dividers chatRule={currentChatRule} />}
          anotherDialogContent={
            !(okBtnText === LabelText.ADD_LABEL) && (
              <UnbChatRuleDialogContentForNetwork dividers chatRule={currentChatRule} />
            )
          }
          optionalDialogContent={
            currentChatRule.test_result && (
              <div>
                <DialogTitle id="form-dialog-title">テスト結果</DialogTitle>
                <UnbTestResultDialogContent dividers testResult={currentChatRule.test_result} />
              </div>
            )
          }
          editDilaogActions={
            <UnbEditDialogActions
              okBtnColor="primary"
              cancelBtnColor="primary"
              okBtnText={okBtnText}
              cancelBtnText="キャンセル"
              okHandler={handleOk}
              cancelHandler={handleCancel}
              deleteBtnColor="secondary"
              deleteBtnText="削除"
              deleteHandler={handleDelete}
              isDisplayDelete={displayDelete}
            />
          }
        />
      </Dialog>
    ),
    [openDialog, currentChatRule, okBtnText, displayDelete]
  );

  const renderTable = useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.id === 'test_result') {
                  return testResultColumns.map((testResultColumn) => {
                    return (
                      <UnbStyledTableCell
                        backgroundColor={'white'}
                        color={'black'}
                        key={testResultColumn.id}
                        align={testResultColumn.align}
                        style={{ minWidth: testResultColumn.minWidth }}
                      >
                        {testResultColumn.label}
                      </UnbStyledTableCell>
                    );
                  });
                } else if (column.id === 'reply_command') {
                  return replyCommandColumns.map((replyCommandColumn) => {
                    return (
                      <UnbStyledTableCell
                        backgroundColor={'white'}
                        color={'black'}
                        key={replyCommandColumn.id}
                        align={replyCommandColumn.align}
                        style={{ minWidth: replyCommandColumn.minWidth }}
                      >
                        {replyCommandColumn.label}
                      </UnbStyledTableCell>
                    );
                  });
                } else {
                  return (
                    <UnbStyledTableCell
                      backgroundColor={'white'}
                      color={'black'}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </UnbStyledTableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: ChatRule, i: number) => {
              return (
                <UnbStyledTableRow
                  backgroundColor={themes.palette.grey['50']}
                  color={'black'}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={(): void | number =>
                    row.chat_rule_id && handleClickRow(row.chat_rule_id, 'paper')
                  }
                >
                  {columns.map((column) => {
                    if (column.id === 'test_result') {
                      const testResult =
                        row.test_result === null ? ({} as TestResult) : row.test_result;
                      return testResultColumns.map((testResultColumn) => {
                        const value = testResult && testResult[testResultColumn.id];
                        return (
                          <TableCell key={testResultColumn.id} align={testResultColumn.align}>
                            {testResultColumn.formatStatus
                              ? testResultColumn.formatStatus(value as TestResultStatusEnum)
                              : value}
                          </TableCell>
                        );
                      });
                    } else if (column.id === 'reply_command') {
                      const replyCommand =
                        row.reply_command === null ? ({} as ReplyCommand) : row.reply_command;
                      return replyCommandColumns.map((replyCommandColumn) => {
                        const value = replyCommand && replyCommand[replyCommandColumn.id];
                        return (
                          <TableCell key={replyCommandColumn.id} align={replyCommandColumn.align}>
                            {value instanceof Array ? (
                              renderArrayTableCell(value)
                            ) : (
                              <div>{value}</div>
                            )}
                          </TableCell>
                        );
                      });
                    } else if (column.id === 'state_action') {
                      return (
                        <TableCell key="state_action">
                          {renderStateActionTableCell(row.state_actions, row.chat_rule_id)}
                        </TableCell>
                      );
                    } else {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value instanceof Array ? (
                            renderArrayTableCell(value)
                          ) : (
                            <div>
                              {column.formatBoolean && typeof value === 'boolean'
                                ? column.formatBoolean(value)
                                : value}
                            </div>
                          )}
                        </TableCell>
                      );
                    }
                  })}
                </UnbStyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [rows]
  );

  const renderPagination = useMemo(
    () => (
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={'ページ毎の件数'}
        component="div"
        count={count}
        rowsPerPage={fetchOption.limit}
        page={fetchOption.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ),
    [fetchOption, count]
  );

  const renderContent = (): object => {
    return (
      <Paper className={classes.root}>
        <UnbTableToolbar
          title={'シナリオ一覧'}
          onClickAdd={handleAdd}
          onClickReload={handleReload}
          onClickImport={handleImport}
          onClickExport={handleExport}
        />
        {renderDilog}
        {renderAlertDialog}
        {renderCloseAlertDialog}
        {renderStateActionDialog}
        {renderCopyAlertDialog}
        {renderReloadAlertDialog}
        {renderTable}
        {renderPagination}
      </Paper>
    );
  };

  return (
    <div>
      <FormContext {...methods}>
        <FormContext {...searchMethods}>
          <UnbChatRuleHeadBar handleSearch={handleSearch} />
        </FormContext>
        {renderContent()}
        {loading && <UnbCircularProgress open={loading} />}
      </FormContext>
    </div>
  );
};

export default ScenarioChatRuleComponent;
