import { TemplateVariable } from 'api/models/TemplateVariable';
import { templateVariableActions } from 'store/domains/template-variable/action';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export type TemplateVariableState = {
  rows: Array<TemplateVariable>;
  count: number;
  fetchOption: {
    limit: number;
    page: number;
  };
  loading: boolean;
  error?: void;
  currentValue: TemplateVariable;
  searchParams: Record<string, string>;
};

export const initialState: TemplateVariableState = {
  fetchOption: {
    limit: 20,
    page: 0,
  },
  rows: [],
  count: 0,
  loading: false,
  currentValue: {} as TemplateVariable,
  searchParams: {
    template_id: '',
    name: '',
    value: '',
  } as Record<string, string>,
};

export const templateVariableReducer = reducerWithInitialState(initialState)
  .case(templateVariableActions.setLoading, (state, payload) => {
    return {
      ...state,
      loading: payload,
    };
  })
  .case(templateVariableActions.setCurrentValue, (state, payload) => {
    return {
      ...state,
      currentValue: payload,
    };
  })
  .case(templateVariableActions.setFetchOption, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.limit,
        page: payload.page,
      },
    };
  })
  .case(templateVariableActions.setSearchParams, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.fetchOption.limit,
        page: payload.fetchOption.page,
      },
      searchParams: payload.searchParams,
    };
  })
  .case(templateVariableActions.getTemplateVariable.started, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.getTemplateVariable.failed, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.getTemplateVariable.done, (state, payload) => {
    //console.log(JSON.stringify(payload.result));
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(templateVariableActions.searchTemplateVariable.started, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.searchTemplateVariable.failed, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.searchTemplateVariable.done, (state, payload) => {
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(templateVariableActions.addTemplateVariable.started, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.addTemplateVariable.failed, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.addTemplateVariable.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows;
    const newRows = rows.concat([result]);
    return {
      ...state,
      rows: newRows,
    };
  })
  .case(templateVariableActions.updateTemplateVariable.started, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.updateTemplateVariable.failed, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.updateTemplateVariable.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows.slice();
    const index = rows.findIndex(
      (r: TemplateVariable) => r.template_variable_id === result.template_variable_id
    );
    rows[index] = result;
    return {
      ...state,
      rows: rows,
    };
  })
  .case(templateVariableActions.deleteTemplateVariable.started, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.deleteTemplateVariable.failed, (state, payload) => {
    return state;
  })
  .case(templateVariableActions.deleteTemplateVariable.done, (state, payload) => {
    const id = payload.params.id;
    const deletedRows = state.rows.filter((r: TemplateVariable) => r.template_variable_id !== id);
    return {
      ...state,
      rows: deletedRows,
    };
  });
