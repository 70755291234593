import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { authActions } from 'store/domains/auth/action';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';

const UnbAuthCallback: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: AppState) => state.auth.loading);
  const authService = useSelector((state: AppState) => state.app.authService);
  useEffect(() => {
    console.log('call UnbAuthCallback useEffect');
    getUser();
  }, []);

  const getUser = async (): Promise<void> => {
    dispatch(authActions.setLoading(true));
    try {
      await authService.signinRedirectCallback();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(authActions.setLoading(false));
    }
  };
  return (
    <div>
      {loading && <UnbCircularProgress open={loading} />}
      {!loading && <Redirect to={'/'} />}
    </div>
  );
};

export default UnbAuthCallback;
