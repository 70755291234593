/**
 * nlp-server API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TestResult {
  /**
   * 識別子
   */
  test_result_id?: number;
  /**
   * シナリオ作成者
   */
  scenario_creator?: string;
  /**
   * 反映日
   */
  reflected_date?: Date;
  /**
   * テスト担当者
   */
  tester?: string;
  /**
   * テスト実施日
   */
  tested_date?: Date;
  /**
   * ユニボID
   */
  unibo_id?: string;
  /**
   * 備考
   */
  note?: string;
  /**
   * テスト結果
   */
  test_result_status?: TestResultStatusEnum;
  /**
   * 登録日付
   */
  created_at?: string;
  /**
   * 更新日付
   */
  updated_at?: string;
}

export type TestResultStatusEnum = 'SUCCESS' | 'FAILURE' | 'HOLD';
export const TestResultStatusEnum = {
  SUCCESS: 'SUCCESS' as TestResultStatusEnum,
  FAILURE: 'FAILURE' as TestResultStatusEnum,
  HOLD: 'HOLD' as TestResultStatusEnum,
};
