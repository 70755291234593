import { createStyles, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    scrollForm: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    displayContent: {
      display: 'contents',
    },
    fixedContent: {
      display: 'flex',
      position: 'fixed',
      width: '45%',
      height: '85%',
      visibility: 'visible',
    },
  })
);

type UnbScrollableFormContentProps = {
  onSubmit: VoidFunction;
  dialogContent: React.ReactNode;
  anotherDialogContent?: React.ReactNode;
  optionalDialogContent?: React.ReactNode;
  editDilaogActions: React.ReactNode;
};

const UnbScrollableFormContent: React.FC<UnbScrollableFormContentProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.displayContent}>
      <div className={classes.scrollForm}>
        {props.anotherDialogContent ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div>
                {props.dialogContent}
                {props.optionalDialogContent}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <div className={classes.fixedContent}>{props.anotherDialogContent}</div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <div>
            {props.dialogContent}
            {props.optionalDialogContent}
          </div>
        )}
      </div>
      <form onSubmit={props.onSubmit} className={classes.displayContent}>
        {props.editDilaogActions}
      </form>
    </div>
  );
};

export default UnbScrollableFormContent;
