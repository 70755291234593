import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const uiTaskActions = {
  setOpenAlertDialog: actionCreator<boolean>('SET_TASK_OPEN_ALERT_DIALOG'),
  setOpenDialog: actionCreator<boolean>('SET_TASK_OPEN_DIALOG'),
  setOkBtnText: actionCreator<string>('SET_TASK_OK_BTN_TEXT'),
  setDisplayDelete: actionCreator<boolean>('SET_TASK_DELETE_DISPLAY'),
  setAction: actionCreator<'ADD' | 'UPDATE' | undefined>('SET_TASK_ACTION'),
  setIsDisplayCopyBtn: actionCreator<boolean>('SET_TASK_IS_DISPLAY_COPY_BTN'),
  setOpenCopyAlertDialog: actionCreator<boolean>('SET_TASK_OPEN_COPY_ALERT_DIALOG'),
};
