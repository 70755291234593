import { FormContextValues } from 'react-hook-form/dist/contextTypes';
import { AxiosError } from 'axios';
import { convertNum2Bool } from 'utils/Converter';
import { Column as RuleConditionColumn } from 'components/pages/editor/scenario/RuleConditionGroupComponent';
import { Column as TaskColumn } from 'components/pages/editor/scenario/TaskComponent';
import { Column as VerbDefinitionColumn } from 'components/pages/editor/scenario/VerbDefinitionComponent';

/**
 * エラーのあるフィールドにエラーメッセージ表示
 * @param err AxiosError
 * @param methods コンポーネントで使用しているmethods
 */
export const setError = (err: any, methods: FormContextValues<any>): void => {
  const axiosErr = err as AxiosError;
  if (axiosErr.response !== null && axiosErr.response !== undefined) {
    if (axiosErr.response.data) {
      //console.log('in setError() axiosErr.response.data.errors:' + axiosErr.response.data.errors);
    } else {
      if (axiosErr.response.status !== 404) {
        //console.log('in setError() axiosErr.response.data is undefined or null', axiosErr);
      } else {
        //console.log('in setError() receive 404', axiosErr);
      }
      return;
    }

    const errFieldNames: Array<string> = [];
    Object.entries(axiosErr.response.data?.errors).forEach(([key, val]) => {
      if (typeof (val as Array<unknown>)[0] === 'string') {
        // 通常項目の場合
        methods.setError(key, '', val as string);
        errFieldNames.push(key);
      } else {
        Object.entries((val as Array<unknown>)[0] as object).forEach(([k, v]) => {
          if (key === 'state_actions') {
            // State Actionsの場合
            Object.entries((v as Array<unknown>)[0] as object).forEach(([kk, vv]) => {
              const name = kk + '[' + k + '].name';
              methods.setError(name, '', vv as string);
              errFieldNames.push(name);
            });
          } else {
            // FieldArrayの場合 項目数可変のフィールドのキー対応。例：before_rule_id[0].name
            const name = key + '[' + k + '].name';
            methods.setError(name, '', v as string);
            errFieldNames.push(name);
          }
        });
      }
    });
    focusFirstField(errFieldNames);
  }
};

const focusFirstField = (fields: Array<string>): void => {
  const inputElms = document.querySelectorAll('#input-dialog input, #input-dialog textarea');
  const firstElm = Array.prototype.filter.call(inputElms, (elm) => {
    const targetName = elm.getAttribute('name');
    if (targetName != null) {
      if (fields.includes(targetName)) {
        return elm;
      }
    }
  });

  if (firstElm.length > 0) {
    (firstElm[0] as HTMLElement).focus();
  }
};

/**
 * 複数項目の空白チェック
 * @param err AxiosError
 * @param methods コンポーネントで使用しているmethods
 * @return エラー有無 true:バリデートNG, false:バリデートOK
 */
export const requiredForMultiple = (arr: any[] | undefined, index: number, v: string): boolean => {
  if (arr && arr.length <= 1) return false; // 1つの場合はチェックOK
  if (v !== null && v !== undefined && v !== '') return false; // 空でなければチェックOK

  // 複数項目かつ、空ならチェックNG
  return true;
};

export const generateScenarioChatRuleQuery = (data: Record<string, string | number>): string => {
  let qAnd = '';
  const qRuleID = data.rule_id === '' ? '' : 'rule_id:' + data.rule_id;
  if (qRuleID !== '') {
    qAnd += qRuleID;
  }
  const qReply = data.reply === '' ? '' : 'reply:' + data.reply;
  if (qReply !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qReply;
  }
  const qStartingFlag =
    +data.starting_flag === 2
      ? ''
      : 'starting_flag:' + String(convertNum2Bool(data.starting_flag as 0 | 1 | 2));
  if (qStartingFlag !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qStartingFlag;
  }
  return qAnd;
};

export const generateRuleConditionGroupQuery = (
  data: Record<string, string | number>,
  columns: readonly RuleConditionColumn[]
): string | null => {
  let qOr: null | string = '';
  if (data.value !== '') {
    columns
      .filter((c: RuleConditionColumn) => c.id !== 'created_at' && c.id !== 'updated_at')
      .forEach((c: RuleConditionColumn) => {
        qOr += c.id + ':' + data.value;
        if (c.id !== 'condition') {
          qOr += ',';
        }
      });
  }
  return qOr === '' ? null : qOr;
};

export const generateTaskAndQuery = (data: Record<string, string | number>): string => {
  let qAnd = '';
  const qTimeDirection =
    data.time_direction_type === 'all' ? '' : 'time_direction_type:' + data.time_direction_type;
  qAnd += qTimeDirection;
  const qTaskType = data.task_type === 'all' ? '' : 'task_type:' + data.task_type;
  if (qTaskType !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qTaskType;
  }
  const qRequired =
    data.required === 2 ? '' : 'required:' + String(convertNum2Bool(data.required as 0 | 1 | 2));
  if (qRequired !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qRequired;
  }
  const qPermitOtherValue =
    data.is_permit_other_value === 2
      ? ''
      : 'is_permit_other_value:' + String(convertNum2Bool(data.is_permit_other_value as 0 | 1 | 2));
  if (qPermitOtherValue !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qPermitOtherValue;
  }
  const qNeedNextAction =
    data.is_need_next_action === 2
      ? ''
      : 'is_need_next_action:' + String(convertNum2Bool(data.is_need_next_action as 0 | 1 | 2));
  if (qNeedNextAction !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qNeedNextAction;
  }
  return qAnd;
};

export const generateTaskOrQuery = (
  data: Record<string, string | number>,
  columns: readonly TaskColumn[]
): string | null => {
  let qOr: null | string = '';
  if (data.value !== '') {
    columns
      .filter(
        (c: TaskColumn) =>
          c.id !== 'created_at' &&
          c.id !== 'updated_at' &&
          c.id !== 'task_id' &&
          c.id !== 'time_direction_type' &&
          c.id !== 'task_type' &&
          c.id !== 'required' &&
          c.id !== 'is_permit_other_value' &&
          c.id !== 'is_need_next_action'
      )
      .map((c: TaskColumn): string | null => {
        qOr += c.id + ':' + data.value;
        if (c.id !== 'time_confirmation') {
          qOr += ',';
        }
        return qOr;
      });
  }
  return qOr === '' ? null : qOr;
};

export const generateTemplateVariableAndQuery = (data: Record<string, string | number>): string => {
  let qAnd = data.template_id === '' ? '' : 'template_id:' + data.template_id;
  if (data.name !== '') {
    qAnd += qAnd === '' ? '' : ',';
    qAnd += data.name === '' ? '' : 'name:' + data.name;
  }
  return qAnd;
};

export const generateTemplateVariableOrQuery = (
  data: Record<string, string | number>
): string | null => {
  let qOr: null | string = '';
  if (data.value !== '') {
    for (let i = 1; i <= 6; i++) {
      qOr += 'v' + i + ':' + data.value;
      if (i < 6) {
        qOr += ',';
      }
    }
  }
  return qOr === '' ? null : qOr;
};

export const generateVerbDefinitinAndQuery = (data: Record<string, string | number>): string => {
  let qAnd = '';
  const qTimeDirection =
    data.time_direction === 'all' ? '' : 'time_direction:' + data.time_direction;
  qAnd += qTimeDirection;
  const qReplyType = data.reply_type === 'all' ? '' : 'reply_type:' + data.reply_type;
  if (qReplyType !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qReplyType;
  }
  const qRequired =
    data.is_required === 2
      ? ''
      : 'is_required:' + String(convertNum2Bool(data.is_required as 0 | 1 | 2));
  if (qRequired !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qRequired;
  }
  const qAbbreviate =
    data.is_abbreviate === 2
      ? ''
      : 'is_abbreviate:' + String(convertNum2Bool(data.is_abbreviate as 0 | 1 | 2));
  if (qAbbreviate !== '') {
    if (qAnd !== '') {
      qAnd += ',';
    }
    qAnd += qAbbreviate;
  }
  return qAnd;
};

export const generateVerbDefinitinOrQuery = (
  data: Record<string, string | number>,
  columns: readonly VerbDefinitionColumn[]
): string | null => {
  let qOr: null | string = '';
  if (data.value !== '') {
    columns
      .filter(
        (c: VerbDefinitionColumn) =>
          c.id !== 'created_at' &&
          c.id !== 'updated_at' &&
          c.id !== 'verb_definition_id' &&
          c.id !== 'time_direction' &&
          c.id !== 'reply_type' &&
          c.id !== 'is_required' &&
          c.id !== 'is_abbreviate'
      )
      .map((c: VerbDefinitionColumn): string | null => {
        qOr += c.id + ':' + data.value;
        if (c.id !== 'rule_id') {
          qOr += ',';
        }
        return qOr;
      });
  }
  return qOr === '' ? null : qOr;
};
