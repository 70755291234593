import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { PropTypes } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

export type UnbIconButtonProps = {
  icon?: OverridableComponent<SvgIconTypeMap>;
  ariaLabel?: string;
  ariaControls?: string;
  ariaHaspopup?: boolean;
  onClickHandler?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  color?: PropTypes.Color;
  edge?: 'start' | 'end' | false;
  postContent?: string;
  projectId?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postContent: {
      fontSize: '0.9rem',
      marginLeft: '5px',
      marginRight: '16px',
    },
    iconButton: {
      '& button': {
        borderRadius: 0,
      },
    },
  })
);

const UnbIconButton: React.FC<UnbIconButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.iconButton}>
      <IconButton
        aria-label={props.ariaLabel}
        aria-controls={props.ariaControls}
        aria-haspopup={props.ariaHaspopup}
        onClick={props.onClickHandler}
        color={props.color}
        edge={props.edge}
      >
        {props.icon ? <props.icon /> : <div></div>}
        {props.postContent ? (
          <div>
            <div className={classes.postContent}>{props.postContent}</div>
            <div className={classes.postContent}>{props.projectId}</div>
          </div>
        ) : (
          <div></div>
        )}
      </IconButton>
    </div>
  );
};

export default UnbIconButton;
