import { ChatRule } from 'api/models/ChatRule';
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestGetCommon from 'api/models/requests/RequestGetCommon';
import RequestGetScenarioRelationships from 'api/models/requests/RequestGetScenarioRelationships';
import RequestGetTemplateVariable from 'api/models/requests/RequestGetTemplateVariable';
import RequestGetWordMaster from 'api/models/requests/RequestGetWordMaster';
import RequestPatchChatRule from 'api/models/requests/RequestPatchChatRule';
import RequestPatchTemplateVariable from 'api/models/requests/RequestPatchTemplateVariable';
import RequestPatchWordMaster from 'api/models/requests/RequestPatchWordMaster';
import RequestPostChatRule from 'api/models/requests/RequestPostChatRule';
import RequestPostTemplateVariable from 'api/models/requests/RequestPostTemplateVariable';
import RequestPostWordMaster from 'api/models/requests/RequestPostWordMaster';
import RequestSearchCommon from 'api/models/requests/RequestSearchCommon';
import RequestTask from 'api/models/requests/RequestTask';
import RequestVerbDefinition from 'api/models/requests/RequestVerbDefinition';
import { RuleConditionGroup } from 'api/models/RuleConditionGroup';
import { Task } from 'api/models/Task';
import { TemplateVariable } from 'api/models/TemplateVariable';
import { VerbDefinition } from 'api/models/VerbDefinition';
import { WordMaster } from 'api/models/WordMaster';
import axios, { AxiosError, AxiosResponse, CancelToken, AxiosInstance } from 'axios';
import RequestRuleConditionGroup from 'api/models/requests/RequestRuleConditionGroup';
import { MultiConversation } from 'api/models/MultiConversation';
import { ChatReply } from 'api/models/ChatReply';
import { UserInfo } from 'api/models/UserInfo';
import { ScenarioRelationships } from 'api/models/ScenarioRelationships';

const BASE_URL_V1 = process.env.REACT_APP_BACKEND + '/api/v1';
const BASE_URL_V2 = process.env.REACT_APP_BACKEND + '/nlp/api/v2';
const BASE_OIDC_API = process.env.REACT_APP_AUTH_URL;

const axiosInstance = (token: string, cancelToken?: CancelToken): AxiosInstance => {
  const instance = axios.create({
    cancelToken: cancelToken,
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  instance.interceptors.request.use((req) => {
    //console.log('Starting Request: ', req);
    return req;
  });
  instance.interceptors.response.use((response) => {
    //console.log('Response: ', response);
    return response;
  });

  return instance;
};

export const fetchMasterWords = async (
  params: RequestGetWordMaster,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<WordMaster>>>
  >(BASE_URL_V1 + '/nlp/master/words', {
    params,
  });
};

export const patchMasterWord = async (
  params: RequestPatchWordMaster,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<WordMaster>>(
    BASE_URL_V1 + '/nlp/master/words',
    params
  );
};

export const postMasterWord = async (
  params: RequestPostWordMaster,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<WordMaster>>(
    BASE_URL_V1 + '/nlp/master/words',
    params
  );
};

export const deleteMasterWord = async (
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(BASE_URL_V1 + '/nlp/master/words/' + params.id);
};

export const fetchChatRule = async (
  params: RequestGetCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<ChatRule>>>
  >(BASE_URL_V1 + '/nlp/scenario/chat-rules', {
    params,
  });
};

export const searchChatRule = async (
  params: RequestSearchCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<ChatRule>>>
  >(BASE_URL_V1 + '/nlp/scenario/chat-rules/search', {
    params,
  });
};

export const deleteChatRule = async (
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(
    BASE_URL_V1 + '/nlp/scenario/chat-rules/' + params.id
  );
};

export const patchChatRule = async (
  params: RequestPatchChatRule,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<ChatRule>>(
    BASE_URL_V1 + '/nlp/scenario/chat-rules',
    params
  );
};

export const postChatRule = async (
  params: RequestPostChatRule,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<ChatRule>>(
    BASE_URL_V1 + '/nlp/scenario/chat-rules',
    params
  );
};

export const fetchPrivateChatRule = async (
  projectId: number,
  params: RequestGetCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  const url = BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules`;
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<ChatRule>>>
  >(url, {
    params,
  });
};

export const deletePrivateChatRule = async (
  projectId: number,
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules/` + params.id
  );
};

export const searchPrivateChatRule = async (
  projectId: number,
  params: RequestSearchCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<ChatRule>>>
  >(BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules/search`, {
    params,
  });
};

export const postPrivateChatRule = async (
  projectId: number,
  params: RequestPostChatRule,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<ChatRule>>(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules`,
    params
  );
};

export const patchPrivateChatRule = async (
  projectId: number,
  params: RequestPatchChatRule,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<ChatRule>>(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules`,
    params
  );
};

export const reloadPrivateChatRule = async (
  projectId: number,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules/reload`
  );
};

export const fetchTemplateVariables = async (
  params: RequestGetTemplateVariable,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<TemplateVariable>>>
  >(BASE_URL_V1 + '/nlp/scenario/template-variables', {
    params,
  });
};

export const searchTemplateVariables = async (
  params: RequestSearchCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<TemplateVariable>>>
  >(BASE_URL_V1 + '/nlp/scenario/template-variables/search', {
    params,
  });
};

export const deleteTemplateVariable = async (
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(
    BASE_URL_V1 + '/nlp/scenario/template-variables/' + params.id
  );
};

export const patchTemplateVariable = async (
  params: RequestPatchTemplateVariable,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<TemplateVariable>>(
    BASE_URL_V1 + '/nlp/scenario/template-variables',
    params
  );
};

export const postTemplateVariable = async (
  params: RequestPostTemplateVariable,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<TemplateVariable>>(
    BASE_URL_V1 + '/nlp/scenario/template-variables',
    params
  );
};

export const fetchVerbDefinition = async (
  params: RequestGetCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<VerbDefinition>>>
  >(BASE_URL_V1 + '/nlp/scenario/verb-definitions', {
    params,
  });
};

export const patchVerbDefinition = async (
  params: RequestVerbDefinition,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<VerbDefinition>>(
    BASE_URL_V1 + '/nlp/scenario/verb-definitions',
    params
  );
};

export const postVerbDefinition = async (
  params: RequestVerbDefinition,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<VerbDefinition>>(
    BASE_URL_V1 + '/nlp/scenario/verb-definitions',
    params
  );
};

export const deleteVerbDefinition = async (
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(
    BASE_URL_V1 + '/nlp/scenario/verb-definitions/' + params.id
  );
};

export const searchVerbDefinition = async (
  params: RequestSearchCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<VerbDefinition>>>
  >(BASE_URL_V1 + '/nlp/scenario/verb-definitions/search', {
    params,
  });
};

export const fetchTask = async (
  params: RequestGetCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<CommonAPIResponse<CommonFetchResponse<Array<Task>>>>(
    BASE_URL_V1 + '/nlp/scenario/tasks',
    {
      params,
    }
  );
};

export const patchTask = async (
  params: RequestTask,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<Task>>(
    BASE_URL_V1 + '/nlp/scenario/tasks',
    params
  );
};

export const postTask = async (
  params: RequestTask,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<Task>>(
    BASE_URL_V1 + '/nlp/scenario/tasks',
    params
  );
};

export const deleteTask = async (
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(BASE_URL_V1 + '/nlp/scenario/tasks/' + params.id);
};

export const searchTask = async (
  params: RequestSearchCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<CommonAPIResponse<CommonFetchResponse<Array<Task>>>>(
    BASE_URL_V1 + '/nlp/scenario/tasks/search',
    {
      params,
    }
  );
};

export const fetchRuleConditionGroup = async (
  params: RequestGetCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<RuleConditionGroup>>>
  >(BASE_URL_V1 + '/nlp/scenario/rule-condition-groups', {
    params,
  });
};

export const patchRuleConditionGroup = async (
  params: RequestRuleConditionGroup,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).patch<CommonAPIResponse<RequestRuleConditionGroup>>(
    BASE_URL_V1 + '/nlp/scenario/rule-condition-groups',
    params
  );
};

export const postRuleConditionGroup = async (
  params: RequestRuleConditionGroup,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<CommonAPIResponse<RuleConditionGroup>>(
    BASE_URL_V1 + '/nlp/scenario/rule-condition-groups',
    params
  );
};

export const deleteRuleConditionGroup = async (
  params: RequestDeleteCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).delete(
    BASE_URL_V1 + '/nlp/scenario/rule-condition-groups/' + params.id
  );
};

export const searchRuleConditionGroup = async (
  params: RequestSearchCommon,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<
    CommonAPIResponse<CommonFetchResponse<Array<RuleConditionGroup>>>
  >(BASE_URL_V1 + '/nlp/scenario/rule-condition-groups/search', {
    params,
  });
};

export const exportPrivateChatRule = async (
  projectId: number,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules/export`,
    {
      responseType: 'blob',
    }
  );
};

export const importPrivateChatRule = async (
  projectId: number,
  formData: FormData,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).put(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules/import`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

export const getScenarioRelationships = async (
  projectId: number,
  params: RequestGetScenarioRelationships,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<CommonAPIResponse<ScenarioRelationships>>(
    BASE_URL_V1 + `/nlp/scenario/${projectId}/chat-rules/relationships`,
    {
      params,
    }
  );
};

export const postChatV2 = async (
  params: MultiConversation,
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).post<ChatReply>(BASE_URL_V2 + '/chat', params);
};

export const fetchUserInfo = async (
  token: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse> => {
  return axiosInstance(token, cancelToken).get<UserInfo>(BASE_OIDC_API + '/userinfo');
};

export const handleError = (e: AxiosError): void => {
  /* setIsRequesting(false); */
  throw e;
  /* throw new Error(error.response?.data.userMessage); */
};

export const execRequest = async (
  requestFn: (...params: any[]) => Promise<AxiosResponse>,
  ...args: any[]
): Promise<void | AxiosResponse<any>> => {
  const res = await requestFn(...args).catch((e: any) => handleError(e));
  return res;
};
