import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Route, Switch, Router } from 'react-router';
import UnbAppbar from 'components//templates/UnbAppbar';
import UnbAuth from 'components/templates/UnbAuth';
import UnbDrawer from 'components/templates/UnbDrawer';
import UnbProtectedRouter from 'components/templates/UnbProtectedRouter';
import UnbLogin from 'components/pages/UnbLogin';
import { createBrowserHistory } from 'history';
import UnbAuthCallback from 'components/templates/UnbAuthCallback';
import UnbAuthLogout from 'components/templates/UnbAuthLogout';
import UnbAuthLogoutCallback from 'components/templates/UnbAuthLogoutCallback';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width: '70%',
    },
  })
);

const UnbApp: React.FC = () => {
  const classes = useStyles();
  const position = 'fixed';
  const hist = createBrowserHistory();
  return (
    <Router history={hist}>
      <Switch>
        <Route exact path="/login" component={UnbLogin} />
        <Route exact path="/logout" component={UnbAuthLogout} />
        <Route exact path="/logout/callback" component={UnbAuthLogoutCallback} />
        <Route path="/authentication/callback" exact component={UnbAuthCallback} />;
        <UnbAuth>
          <div className={classes.root}>
            <CssBaseline />
            <UnbAppbar position={position} />
            <UnbDrawer />
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <UnbProtectedRouter />
            </main>
          </div>
        </UnbAuth>
      </Switch>
    </Router>
  );
};

export default UnbApp;
