import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    addButton: {
      margin: '0 0 0 auto',
    },
  })
);

type UnbTemplateVariableBarProps = {
  handleSearch: (data: Record<string, any>) => void;
};

const UnbTemplateVariableHeadBar: React.FC<UnbTemplateVariableBarProps> = (props) => {
  const classes = useStyles();
  const { handleSubmit, register } = useFormContext();

  return (
    <form className={classes.root} onSubmit={handleSubmit(props.handleSearch)}>
      <TextField
        id="search-template-id"
        label="Template ID"
        type="search"
        margin="dense"
        variant="outlined"
        name="template_id"
        inputRef={register}
      />
      <TextField
        id="search-name"
        label="テンプレート変数名"
        type="search"
        margin="dense"
        variant="outlined"
        name="name"
        inputRef={register}
      />
      <TextField
        id="search-value"
        label="値"
        type="search"
        margin="dense"
        variant="outlined"
        name="value"
        inputRef={register}
      />
      <Button
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
        startIcon={<SearchIcon />}
      >
        検索
      </Button>
    </form>
  );
};

export default UnbTemplateVariableHeadBar;
