/**
 * 引数に与えられたStringの配列内で
 * 空の配列を削除する
 */
export const cleanStringArray = (data: Array<string>): string[] => {
  return data ? data.filter((s: string) => s !== null && s !== undefined && s !== '') : data;
};

/**
 * FieldArrayのFormデータ配列を文字列配列に変換する
 * @param data FieldArrayのFormデータ
 * @return 文字列配列
 * @example
 * orgArray[{name: 'aaa'}, {name: 'bbb'}, ]
 * call convertFieldArrayFormData(array, 'name')
 * // ['aaa', 'bbb']
 */
export const convertFieldArrayFormData = (data: Array<{ name: string }>): Array<string> => {
  return data.map((x) => x['name']);
};
