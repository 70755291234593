/** @fileOverview チャットテストコンポーネント */
import React, { useCallback, ChangeEvent, useEffect, useMemo } from 'react';
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  FormGroup,
} from '@material-ui/core';
import UnbChat from 'components/organisms/UnbChat';
import { useDispatch, useSelector } from 'react-redux';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { AppState } from 'store';
import { uiChatToolsActions } from 'store/uis/tools/chat/action';
import { chatToolsActions, postChatV2Action } from 'store/domains/tools/chat/action';
import { Conversation } from 'api/models/Conversation';
import { MultiConversation } from 'api/models/MultiConversation';
import { ChatReply } from 'api/models/ChatReply';
import ReactJson from 'react-json-view';
import UnbSwitchLabel from 'components/molecules/UnbSwitchLabel';
import { getNoticeErrorMessage, NoticeMessages } from 'constants/Messages';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperTitle: {
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    nestedPaper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      maxWidth: '100%',
    },
    chat: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '75vh',
      position: 'relative',
    },
    info: {
      marginRight: 'auto',
      textAlign: 'left',
      display: 'block',
      maxWidth: '100%',
      height: '60vh',
      overflowY: 'scroll',
    },
    config: {
      marginRight: 'auto',
      textAlign: 'left',
      display: 'block',
      maxWidth: '100%',
      height: '5vh',
      overflow: 'hidden',
    },
  })
);

const UnbChatPageComponent: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const conversationValue = useSelector((state: AppState) => state.uiChatTools.conversationValue);
  const loading = useSelector((state: AppState) => state.uiChatTools.loading);
  const privateOnly = useSelector((state: AppState) => state.uiChatTools.privateOnly);
  const selectedReply = useSelector((state: AppState) => state.uiChatTools.selectedReply);
  const responseHistory = useSelector((state: AppState) => state.chatTools.responseHistory);
  const requestHistory = useSelector((state: AppState) => state.chatTools.requestHistory);
  const messages = useSelector((state: AppState) => state.chatTools.messages);
  const selectedProjectId = useSelector((state: AppState) => state.app.selectedProjectId);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    scrollBottom();
  }, [responseHistory, requestHistory]);

  useEffect(() => {
    displayChatReply(responseHistory[responseHistory.length - 1]);
  }, [responseHistory]);

  const postChat = async (req: MultiConversation): Promise<void> => {
    if (loading) {
      return;
    }
    dispatch(uiChatToolsActions.setLoading(true));
    try {
      dispatch(chatToolsActions.addRequestHistory(req));
      await asyncDispatch(postChatV2Action(req));
    } catch (e) {
      //console.error(e);
      const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_SENDMSG);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      dispatch(uiChatToolsActions.setLoading(false));
    }
  };

  const scrollBottom = (): void => {
    const t = document.getElementById('unb-message-container');
    if (t) {
      t.scrollTop = t.scrollHeight;
    }
  };

  const createChatRequest = (text: string): MultiConversation | null => {
    if (text === '') {
      return null;
    }
    if (requestHistory.length <= 0) {
      return createInitialChatRequest(text);
    }
    const secondConversation = {
      userSentence: text,
      systemSentence: '',
      ruleId: '',
    } as Conversation;

    const beforeReq = requestHistory[requestHistory.length - 1];
    const beforeConversations = beforeReq.conversations;
    if (beforeConversations === undefined) {
      return null;
    }
    const beforeConversation = beforeConversations[beforeConversations.length - 1];
    const beforeRes = responseHistory[responseHistory.length - 1];
    const firstReqConversation = {
      userSentence: beforeConversation.userSentence,
      systemSentence: '',
      ruleId: beforeRes.ruleId,
    } as Conversation;

    const conversations = [firstReqConversation, secondConversation];

    const req = {
      language: beforeReq.language === undefined ? null : beforeReq.language,
      userAge: beforeReq.userAge === undefined ? null : beforeReq.userAge,
      userGender: beforeReq.userGender === undefined ? null : beforeReq.userGender,
      dateTime: beforeReq.dateTime === undefined ? null : beforeReq.dateTime,
      conversations: conversations,
      chatState: beforeRes.chatState === undefined ? null : beforeRes.chatState,
      privateOnly: privateOnly,
      projectId: selectedProjectId,
    } as MultiConversation;

    return req;
  };

  const createInitialChatRequest = (text: string): MultiConversation => {
    const initConversation = {
      userSentence: text,
      systemSentence: '',
      ruleId: '',
    } as Conversation;

    const initRequest = {
      language: 'JA',
      conversations: [initConversation],
      privateOnly: privateOnly,
      projectId: selectedProjectId,
    } as MultiConversation;

    return initRequest;
  };

  const handleSend = useCallback(() => {
    const req = createChatRequest(conversationValue);
    if (req !== null) {
      postChat(req);
      dispatch(uiChatToolsActions.setConversationValue(''));
    }
  }, [conversationValue, responseHistory, requestHistory, privateOnly, selectedProjectId]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(uiChatToolsActions.setConversationValue(event.currentTarget.value));
    },
    [conversationValue, responseHistory, requestHistory, privateOnly, selectedProjectId]
  );

  const handleClickInfo = useCallback(
    (reply: ChatReply) => {
      displayChatReply(reply);
    },
    [responseHistory]
  );

  const displayChatReply = (reply: ChatReply): void => {
    dispatch(uiChatToolsActions.setSelectedReply(reply));
  };

  const handlePrivateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      dispatch(uiChatToolsActions.setPrivateOnly(event.target.checked));
    },
    [conversationValue, responseHistory, requestHistory, privateOnly, selectedProjectId]
  );

  const render = useMemo(() => {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.paperTitle} variant="h6" gutterBottom>
                チャット
              </Typography>
              <div className={classes.chat}>
                <UnbChat
                  messages={messages}
                  value={conversationValue}
                  onClickSend={handleSend}
                  onChange={handleChange}
                  onClickInfo={handleClickInfo}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs container direction="column">
              <Paper className={classes.nestedPaper}>
                <Typography className={classes.paperTitle} variant="h6" gutterBottom>
                  詳細情報
                </Typography>
                <div className={classes.info}>
                  <ReactJson src={selectedReply} indentWidth={2} displayDataTypes={false} />
                </div>
              </Paper>
              <Paper className={classes.nestedPaper}>
                <Typography className={classes.paperTitle} variant="h6" gutterBottom>
                  設定
                </Typography>
                <div className={classes.config}>
                  <FormGroup row>
                    <UnbSwitchLabel
                      onChange={handlePrivateChange}
                      label={'プライベートシナリオのみ使用する'}
                      isChecked={privateOnly}
                    />
                  </FormGroup>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }, [messages, conversationValue, selectedReply, privateOnly, selectedProjectId]);

  return render;
};

export default UnbChatPageComponent;
