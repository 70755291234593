/** @fileOverview 条件グループアクションのテスト */
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestGetCommon from 'api/models/requests/RequestGetCommon';
import RequestRuleConditionGroup from 'api/models/requests/RequestRuleConditionGroup';
import RequestSearchCommon from 'api/models/requests/RequestSearchCommon';
import { RuleConditionGroup } from 'api/models/RuleConditionGroup';
import {
  deleteRuleConditionGroup,
  execRequest,
  fetchRuleConditionGroup,
  patchRuleConditionGroup,
  postRuleConditionGroup,
  searchRuleConditionGroup,
} from 'api/Requests';
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const ruleConditionGroupActions = {
  setLoading: actionCreator<boolean>('SET_RULE_CONDITION_GROUP_LOADING'),
  setCurrentValue: actionCreator<RuleConditionGroup>('SET_RULE_CONDITION_GROUP_CURRENT_VALUE'),
  setFetchOption: actionCreator<{ limit: number; page: number }>(
    'SET_RULE_CONDITION_GROUP_FETCH_OPTION'
  ),
  setSearchParams: actionCreator<{
    fetchOption: {
      limit: number;
      page: number;
    };
    searchParams: Record<string, string>;
  }>('SET_RULE_CONDITION_GROUP_SEARCH_PARAMS'),
  getRuleConditionGroup: actionCreator.async<
    RequestGetCommon,
    CommonAPIResponse<CommonFetchResponse<Array<RuleConditionGroup>>>
  >('GET_RULE_CONDITION_GROUP'),
  searchRuleConditionGroup: actionCreator.async<
    RequestSearchCommon,
    CommonAPIResponse<CommonFetchResponse<Array<RuleConditionGroup>>>
  >('SEARCH_RULE_CONDITION_GROUP'),
  addRuleConditionGroup: actionCreator.async<
    RequestRuleConditionGroup,
    CommonAPIResponse<RuleConditionGroup>
  >('ADD_RULE_CONDITION_GROUP'),
  updateRuleConditionGroup: actionCreator.async<
    RequestRuleConditionGroup,
    CommonAPIResponse<RuleConditionGroup>
  >('UPDATE_RULE_CONDITION_GROUP'),
  deleteRuleConditionGroup: actionCreator.async<RequestDeleteCommon, AxiosResponse>(
    'DELETE_RULE_CONDITION_GROUP'
  ),
};

export const getRuleConditionGroupAction = () => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const fetchOption = getState().ruleConditionGroup.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestGetCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
    };

    dispatch(ruleConditionGroupActions.getRuleConditionGroup.started(params));
    if (accessToken) {
      return execRequest(fetchRuleConditionGroup, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              ruleConditionGroupActions.getRuleConditionGroup.done({
                params: params,
                result: res.data,
              })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            ruleConditionGroupActions.getRuleConditionGroup.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

export const addRuleConditionGroupAction = (params: RequestRuleConditionGroup) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(ruleConditionGroupActions.addRuleConditionGroup.started(params));
    if (accessToken) {
      return execRequest(postRuleConditionGroup, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              ruleConditionGroupActions.addRuleConditionGroup.done({
                params: params,
                result: res.data,
              })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            ruleConditionGroupActions.addRuleConditionGroup.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

export const updateRuleConditionGroupAction = (params: RequestRuleConditionGroup) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(ruleConditionGroupActions.updateRuleConditionGroup.started(params));
    if (accessToken) {
      return execRequest(patchRuleConditionGroup, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              ruleConditionGroupActions.updateRuleConditionGroup.done({
                params: params,
                result: res.data,
              })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            ruleConditionGroupActions.updateRuleConditionGroup.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

export const deleteRuleConditionGroupAction = (id: number) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<AxiosResponse | undefined> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestDeleteCommon = {
      id: id,
    };
    dispatch(ruleConditionGroupActions.deleteRuleConditionGroup.started(params));
    if (accessToken) {
      return execRequest(deleteRuleConditionGroup, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              ruleConditionGroupActions.deleteRuleConditionGroup.done({
                params: params,
                result: res,
              })
            );
            return res;
          }
        })
        .catch((e) => {
          dispatch(
            ruleConditionGroupActions.deleteRuleConditionGroup.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

export const searchRuleConditionGroupAction = (
  query: null | string = null,
  queryOr: null | string = null
) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const fetchOption = getState().ruleConditionGroup.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestSearchCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
    };

    if (query !== null) {
      params.q = query;
    }
    if (queryOr !== null) {
      params.q_or = queryOr;
    }

    dispatch(ruleConditionGroupActions.searchRuleConditionGroup.started(params));
    if (accessToken) {
      return execRequest(searchRuleConditionGroup, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              ruleConditionGroupActions.searchRuleConditionGroup.done({
                params: params,
                result: res.data,
              })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            ruleConditionGroupActions.searchRuleConditionGroup.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};
