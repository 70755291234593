import { VerbDefinition } from 'api/models/VerbDefinition';
import { verbDefinitionActions } from 'store/domains/verb-definition/action';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export type VerbDefinitionState = {
  rows: Array<VerbDefinition>;
  count: number;
  fetchOption: {
    limit: number;
    page: number;
  };
  loading: boolean;
  error?: any;
  currentValue: VerbDefinition;
  searchParams: Record<string, string | number>;
};

export const initialState: VerbDefinitionState = {
  fetchOption: {
    limit: 20,
    page: 0,
  },
  rows: [],
  count: 0,
  loading: false,
  currentValue: {} as VerbDefinition,
  searchParams: {
    value: '',
    time_direction: 'all',
    reply_type: 'all',
    is_required: 2,
    is_abbreviate: 2,
  } as Record<string, string | number>,
};

export const verbDefinitionReducer = reducerWithInitialState(initialState)
  .case(verbDefinitionActions.setLoading, (state, payload) => {
    return {
      ...state,
      loading: payload,
    };
  })
  .case(verbDefinitionActions.setCurrentValue, (state, payload) => {
    return {
      ...state,
      currentValue: payload,
    };
  })
  .case(verbDefinitionActions.setSearchParams, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.fetchOption.limit,
        page: payload.fetchOption.page,
      },
      searchParams: payload.searchParams,
    };
  })
  .case(verbDefinitionActions.setFetchOption, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.limit,
        page: payload.page,
      },
    };
  })
  .case(verbDefinitionActions.getVerbDefinition.started, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.getVerbDefinition.failed, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.getVerbDefinition.done, (state, payload) => {
    //console.log(JSON.stringify(payload.result));
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(verbDefinitionActions.searchVerbDefinition.started, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.searchVerbDefinition.failed, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.searchVerbDefinition.done, (state, payload) => {
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(verbDefinitionActions.addVerbDefinition.started, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.addVerbDefinition.failed, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.addVerbDefinition.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows;
    const newRows = rows.concat([result]);
    return {
      ...state,
      rows: newRows,
    };
  })
  .case(verbDefinitionActions.updateVerbDefinition.started, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.updateVerbDefinition.failed, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.updateVerbDefinition.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows.slice();
    const index = rows.findIndex(
      (r: VerbDefinition) => r.verb_definition_id === result.verb_definition_id
    );
    rows[index] = result;
    return {
      ...state,
      rows: rows,
    };
  })
  .case(verbDefinitionActions.deleteVerbDefinition.started, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.deleteVerbDefinition.failed, (state, payload) => {
    return state;
  })
  .case(verbDefinitionActions.deleteVerbDefinition.done, (state, payload) => {
    const id = payload.params.id;
    const deletedRows = state.rows.filter((r: VerbDefinition) => r.verb_definition_id !== id);
    return {
      ...state,
      rows: deletedRows,
    };
  });
