import React, { useEffect } from 'react';
import { TextFieldProps, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import UnbInlineTextField from 'components/molecules/UnbInlineTextField';
import clsx from 'clsx';
import UnbInlineIconButton, {
  inlineIconButtonProps,
} from 'components/molecules/UnbInlineIconButton';

export type UnbListInlineTextFiledRowType = {
  key: string;
  textFieldPropsArray: TextFieldProps[];
  btns: inlineIconButtonProps[];
};

type UnbListInlineTextFiledProps = {
  title: string;
  titleClassName: string;
  rows: Array<UnbListInlineTextFiledRowType>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    inline: {
      display: 'flex',
      marginTop: 20,
      marginLeft: 10,
      marginRight: 10,
      width: '100%',
    },
  })
);

const UnbListInlineTextFiled: React.FC<UnbListInlineTextFiledProps> = (
  props: UnbListInlineTextFiledProps
) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <Typography className={props.titleClassName}>{props.title}</Typography>
      {props.rows.map((item, index: number) => {
        return (
          <div key={item.key} className={clsx(classes.inline)}>
            <UnbInlineTextField
              key={item.key + '-text'}
              ele_id={item.key + '-text'}
              textFieldPropsArray={item.textFieldPropsArray}
            />
            <UnbInlineIconButton
              key={item.key + '-btn'}
              ele_id={item.key + '-btn'}
              btns={item.btns}
            />
          </div>
        );
      })}
    </div>
  );
};

export default UnbListInlineTextFiled;
