import {
  createStyles,
  makeStyles,
  PropTypes,
  Theme,
  Typography,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      margin: 0,
      padding: theme.spacing(2),
    },
    copyButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: '50%',
      transform: 'translateY(-50%)',
    },
  })
);

type UnbEditDialogTitleProps = {
  id: string;
  children: React.ReactNode;
  onCopy: () => void;
  copyBtnColor?: PropTypes.Color;
  copyBtnText: string;
};

const UnbEditDialogTitle: React.FC<UnbEditDialogTitleProps> = (props) => {
  const classes = useStyles();
  const { children, onCopy, copyBtnColor, copyBtnText, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onCopy ? (
        <Tooltip title={copyBtnText} aria-label="add">
          <IconButton onClick={onCopy} color={copyBtnColor} className={classes.copyButton}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
};

export default UnbEditDialogTitle;
