import { SurfaceCaseEnum } from 'api/types/SemanticTypes';
import { ValidationErrorMessages } from 'constants/Messages';
import { requiredForMultiple } from 'utils/ComponentUtil';
import { ArrayField } from 'react-hook-form/dist/types';

export const verbGroupNameRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const verbNameRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const verbsRegister = (
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      return true;
    },
  };
};

export const excludeVerbsRegister = (
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      return true;
    },
  };
};

export const verbsRegexRegister = {
  required: false,
};

export const excludeVerbRegexRegister = {
  required: false,
};

export const timeDirectionRegister = {
  custom: {
    name: 'time_direction',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const isRequiredRegister = {
  custom: {
    name: 'is_required',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const replyTypeRegister = {
  custom: {
    name: 'reply_type',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const kakujyoshiRegister = (
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      if (v === '') {
        return true;
      }
      if ((Object.values(SurfaceCaseEnum) as string[]).includes(v)) {
        return true;
      }
      return (
        '格助詞の範囲で入力してください。[ ' + Object.values(SurfaceCaseEnum).join(', ') + ' ]'
      );
    },
  };
};

export const isAbbreviateRegister = {
  custom: {
    name: 'is_abbreviate',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const valueTypesRegister = {
  required: false,
  pattern: {
    value: /^(が|を|に|から|へ|と|より|まで|で|の|にて)=req:.+/,
    message: ValidationErrorMessages.PATTERN.format(
      '/^(が|を|に|から|へ|と|より|まで|で|の|にて)=req:.+/'
    ),
  },
};

export const excludeNounsRegister = (
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      return true;
    },
  };
};

export const decisionConditionRegister = {
  required: false,
};

export const decisionCommandRegister = {
  required: false,
};

export const weightRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
  pattern: {
    value: /[0-9]/,
    message: ValidationErrorMessages.PATTERN.format('/[0-9]/'),
  },
};

export const confirmationReplyTaskIdRegister = {
  required: false,
};

export const ruleIDRegister = {
  required: false,
};
