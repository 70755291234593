import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    requiredTypo: {
      color: theme.palette.error.main,
    },
  })
);

const UnbRequiredSpan: React.FC = () => {
  const classes = useStyles();
  return <span className={classes.requiredTypo}>*</span>;
};

export default UnbRequiredSpan;
