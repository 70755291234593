import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { TooltipPosition, uiChatRuleActions } from 'store/uis/chat-rule/action';
import { Network } from 'vis-network';
import { UnbChatRuleTooltipContentProps } from 'components/molecules/UnbChatRuleTooltipContent';

export type UIChatRuleState = {
  openCopyAlertDialog: boolean;
  openReloadAlertDialog: boolean;
  isDisplayCopyBtn: boolean;
  network: Network | null;
  networkLoading: boolean;
  tooltipContent: UnbChatRuleTooltipContentProps;
  tooltipPosition: TooltipPosition;
  tooltipOpen: boolean;
};

export const initialState: UIChatRuleState = {
  openCopyAlertDialog: false,
  openReloadAlertDialog: false,
  isDisplayCopyBtn: false,
  network: null,
  networkLoading: true,
  tooltipContent: {
    rule_id: '',
    decision_condition: '',
    decision_command: '',
    weight: null,
  },
  tooltipPosition: { top: 0, left: 0 },
  tooltipOpen: false,
};

export const uiChatRuleReducer = reducerWithInitialState(initialState)
  .case(uiChatRuleActions.setOpenCopyAlertDialog, (state, payload) => {
    return {
      ...state,
      openCopyAlertDialog: payload,
    };
  })
  .case(uiChatRuleActions.setOpenReloadAlertDialog, (state, payload) => {
    return {
      ...state,
      openReloadAlertDialog: payload,
    };
  })
  .case(uiChatRuleActions.setIsDisplayCopyBtn, (state, payload) => {
    return {
      ...state,
      isDisplayCopyBtn: payload,
    };
  })
  // マップ図
  .case(uiChatRuleActions.setNetwork, (state, payload) => {
    return {
      ...state,
      network: payload,
    };
  })
  .case(uiChatRuleActions.setNetworkLoading, (state, payload) => {
    return {
      ...state,
      networkLoading: payload,
    };
  })
  // UnbScenarioRelationshipsNetworkのツールチップ設定
  .case(uiChatRuleActions.setTooltipPosition, (state, payload) => {
    return {
      ...state,
      tooltipPosition: payload,
    };
  })
  .case(uiChatRuleActions.setTooltipContent, (state, payload) => {
    return {
      ...state,
      tooltipContent: payload,
    };
  })
  .case(uiChatRuleActions.setTooltipOpen, (state, payload) => {
    return {
      ...state,
      tooltipOpen: payload,
    };
  });
