import { ValidationErrorMessages } from 'constants/Messages';

export const taskNameRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const conversationRegister = {
  required: false,
};

export const taskSubIDRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
  min: { value: 1, message: ValidationErrorMessages.MIN.format('1') },
};

export const orderRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
  min: { value: 1, message: ValidationErrorMessages.MIN.format('1') },
};

export const repeatLimitRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
  min: { value: 1, message: ValidationErrorMessages.MIN.format('1') },
};

export const decisionConditionRegister = {
  required: false,
};

export const decisionConditionCommandRegister = {
  required: false,
};

export const variableRegister = {
  required: false,
};

export const acquisitionMethodRegister = {
  custom: {
    name: 'acquisition_method',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const lastAcquisitionMethodRegister = {
  custom: {
    name: 'last_acquisition_method',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const nullConfirmationReplyRegister = {
  required: false,
};

export const confirmationReplyRegister = {
  required: false,
};

export const negativeWordsRegister = {
  required: false,
};

export const negativeWordsReplyRegister = {
  required: false,
};

export const okConfirmationReplyRegister = {
  required: false,
};

export const replyAfterConfirmationRegister = {
  required: false,
};

export const replyRegister = {
  required: false,
};

export const nodReplyRegister = {
  required: false,
};

export const stopConditionRegister = {
  required: false,
};

export const stopReplyRuleIDRegister = {
  required: false,
};

export const variableFromRegister = {
  required: false,
};

export const variableToRegister = {
  required: false,
};

export const timeConfirmationRegister = {
  required: false,
};

export const timeDirectionRegister = {
  custom: {
    name: 'time_direction_type',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const isRequiredRegister = {
  custom: {
    name: 'required',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const isPermitOtherValueRegister = {
  custom: {
    name: 'is_permit_other_value',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const isNeedNextActionRegister = {
  custom: {
    name: 'is_need_next_action',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const taskTypeRegister = {
  custom: {
    name: 'task_type',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};
