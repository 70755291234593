/** @fileOverview 会話データダイアログ */
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  ActionCodeEnum,
  ChatRule,
  FacialExpressionCodeEnum,
  ProcTypeEnum,
  RuleTypeEnum,
} from 'api/models/ChatRule';
import { ReplyCommand } from 'api/models/ReplyCommand';
import UnbRequiredSpan from 'components/atoms/UnbRequiredSpan';
import {
  actionCodeRegister,
  decisionConditionRegister,
  decisionCommandRegister,
  facialExpressionCodeRegister,
  functionCodeRegister,
  functionNameRegister,
  functionParametersRegister,
  nextPeriodRegister,
  procTypeRegister,
  qaQueriesRegister,
  replyRegister,
  ruleIDArrayRegister,
  ruleIDRegister,
  ruleTypeRegister,
  sentimentRegister,
  startingFlagRegister,
  taskNameRegister,
  templateIDsRegister,
  templateVariableResetConditionRegister,
  weightRegistr,
} from 'components/form/validator/ChatRuleRegister';
import UnbListTextField from 'components/organisms/UnbListTextField';
import { Commands } from 'constants/Commands';
import React, { useMemo, useCallback } from 'react';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { convertBool2Num } from 'utils/Converter';
import UnbStateActions from './UnbStateActions';
import UnbTheme from 'themes/UnbStyle';

type UnbChatRuleDialogContentProps = {
  dividers?: boolean;
  chatRule: ChatRule;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    errorHelperText: {
      color: theme.palette.error.main,
    },
  })
);

/**
 * 会話データダイアログ
 *
 * @component
 */
const UnbChatRuleDialogContent: React.FC<UnbChatRuleDialogContentProps> = (props) => {
  const classes = useStyles(UnbTheme);
  const {
    register,
    errors,
    watch,
    setValue,
    control,
    setError,
    triggerValidation,
    clearError,
  } = useFormContext();
  const [currentChatRule, setCurrentChatRule] = React.useState(props.chatRule);
  /**
   * OptionalなStateをそのままFormのvalueに適用すると
   * undefindだった場合、controled componentからuncontrolledになってしまう
   * が発生するため、ローカルでstateに初期値を与える
   * DBから取得したModelはEnumなどで型定義されているため、空文字を入れるなどの
   * 対応が取り辛いため
   * 良い対応方法があり次第リファクタ
   */
  const [selectValues, setSelectValues] = React.useState({
    rule_type: '',
    action_code: '',
    facial_expression_code: '',
    proc_type: '',
    function_name: '',
  });

  const watchRuleID = watch('rule_id');

  // FieldArrays
  // Function Parameters(Scenario Parameters)
  const {
    fields: paramFields,
    append: paramAppend,
    remove: paramRemove,
    insert: paramInsert,
  } = useFieldArray({
    control,
    name: 'parameters',
  });

  // Before Rule ID
  const {
    fields: beforeRuleIdFields,
    append: beforeRuleIdAppend,
    remove: beforeRuleIdRemove,
    insert: beforeRuleIdInsert,
  } = useFieldArray({
    control,
    name: 'before_rule_id',
  });

  // Next Rule ID
  const {
    fields: nextRuleIdFields,
    append: nextRuleIdAppend,
    remove: nextRuleIdRemove,
    insert: nextRuleIdInsert,
  } = useFieldArray({
    control,
    name: 'next_rule_id',
  });

  // Template IDs
  const {
    fields: templateIdFields,
    append: templateIdAppend,
    remove: templateIdRemove,
    insert: templateIdInsert,
  } = useFieldArray({
    control,
    name: 'template_ids',
  });

  // QA Query
  const {
    fields: queryFields,
    append: queryAppend,
    remove: queryRemove,
    insert: queryInsert,
  } = useFieldArray({
    control,
    name: 'qa_query',
  });

  const inputStartFlagLabel = React.useRef<HTMLLabelElement>(null);
  const [startFlagLabelWidth, setStartFlagLabelWidth] = React.useState(0);
  const handleStartingFlagChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      setValue('starting_flag', (event.target.value as boolean) ? 1 : 0);
      currentChatRule.starting_flag = event.target.value as boolean;
      setCurrentChatRule(Object.assign({}, currentChatRule));
    },
    [currentChatRule]
  );

  const inputRuleTypeLabel = React.useRef<HTMLLabelElement>(null);
  const [ruleTypeLabelWidth, setRuleTypeLabelWidth] = React.useState(0);
  const handleRuleTypeChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      currentChatRule.rule_type = event.target.value as RuleTypeEnum;
      setCurrentChatRule(Object.assign({}, currentChatRule));

      selectValues.rule_type = event.target.value as string;
      setSelectValues(Object.assign({}, selectValues));

      setValue('rule_type', event.target.value as RuleTypeEnum);
    },
    [selectValues, currentChatRule]
  );

  const inputProcTypeLabel = React.useRef<HTMLLabelElement>(null);
  const [procTypeLabelWidth, setProcTypeLabelWidth] = React.useState(0);
  const handleProcTypeChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      currentChatRule.proc_type = event.target.value as ProcTypeEnum;
      setCurrentChatRule(Object.assign({}, currentChatRule));

      selectValues.proc_type = event.target.value as string;
      setSelectValues(Object.assign({}, selectValues));

      setValue('proc_type', event.target.value as ProcTypeEnum);
    },
    [currentChatRule, selectValues]
  );

  const inputWeightLabel = React.useRef<HTMLLabelElement>(null);
  const [weightLabelWidth, setWeightLabelWidth] = React.useState(0);
  const handleWeightChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      currentChatRule.weight = event.target.value as number;
      setCurrentChatRule(Object.assign({}, currentChatRule));
    },
    [currentChatRule]
  );

  const inputFunctionNameLabel = React.useRef<HTMLLabelElement>(null);
  const [functionNameLabelWidth, setFunctionNameLabelWidth] = React.useState(0);
  const handleFunctionNameChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      if (currentChatRule.reply_command) {
        currentChatRule.reply_command.function_name = event.target.value as string;
        setCurrentChatRule(Object.assign({}, currentChatRule));
      }
      selectValues.function_name = event.target.value as string;
      setSelectValues(Object.assign({}, selectValues));

      setValue('function_name', event.target.value as string);
    },
    [currentChatRule, selectValues]
  );

  const inputActionCodeLabel = React.useRef<HTMLLabelElement>(null);
  const [actionCodeLabelWidth, setActionCodeLabelWidth] = React.useState(0);
  const handleActionCodeChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      currentChatRule.action_code = event.target.value as ActionCodeEnum;
      setCurrentChatRule(Object.assign({}, currentChatRule));

      selectValues.action_code = event.target.value as string;
      setSelectValues(Object.assign({}, selectValues));

      setValue('action_code', event.target.value as ActionCodeEnum);
    },
    [currentChatRule, selectValues]
  );

  const inputFacialExpressionCodeLabel = React.useRef<HTMLLabelElement>(null);
  const [facialExpressionCodeLabelWidth, setFacialExpressionCodeLabelWidth] = React.useState(0);
  const handleFacialExpressionCodeChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      currentChatRule.facial_expression_code = event.target.value as FacialExpressionCodeEnum;
      setCurrentChatRule(Object.assign({}, currentChatRule));

      selectValues.facial_expression_code = event.target.value as string;
      setSelectValues(Object.assign({}, selectValues));

      setValue('facial_expression_code', event.target.value as FacialExpressionCodeEnum);
    },
    [currentChatRule, selectValues]
  );

  React.useEffect(() => {
    clearError('function_name');
    paramFields.map((_, index: number) => {
      return triggerValidation(`parameters[${index}].name`);
    });
  }, [currentChatRule.reply_command, selectValues]);

  // 初期化
  React.useEffect(() => {
    //console.log('call React.useEffect()', currentChatRule);
    // カスタムregister
    register(startingFlagRegister.custom, startingFlagRegister.rule);
    register(ruleTypeRegister.custom, ruleTypeRegister.rule);
    register(functionNameRegister.custom, functionNameRegister.rule);
    register(actionCodeRegister.custom, actionCodeRegister.rule);
    register(facialExpressionCodeRegister.custom, facialExpressionCodeRegister.rule);
    register(procTypeRegister.custom, procTypeRegister.rule);

    // Formに初期値設定
    setValue('starting_flag', currentChatRule.starting_flag ? 1 : 0);
    setValue('rule_type', currentChatRule.rule_type);
    setValue('function_name', currentChatRule.reply_command?.function_name);
    setValue('action_code', currentChatRule.action_code);
    setValue('facial_expression_code', currentChatRule.facial_expression_code);
    setValue('proc_type', currentChatRule.proc_type);

    // 初期値設定
    const newBeforeRuleIDArray =
      currentChatRule.before_rule_id === null || currentChatRule.before_rule_id === undefined
        ? ['']
        : currentChatRule.before_rule_id;
    const newNextRuleIDArray =
      currentChatRule.next_rule_id === null || currentChatRule.next_rule_id === undefined
        ? ['']
        : currentChatRule.next_rule_id;
    const newStateActionArray =
      currentChatRule.state_actions === null ||
      currentChatRule.state_actions === undefined ||
      currentChatRule.state_actions.length === 0
        ? [{ state_mod_action: '', modification_command: '' }]
        : currentChatRule.state_actions;
    const newTemplateIDArray =
      currentChatRule.template_ids === null || currentChatRule.template_ids === undefined
        ? ['']
        : currentChatRule.template_ids;
    const newQAQueriesArray =
      currentChatRule.qa_query === null || currentChatRule.qa_query === undefined
        ? ['']
        : currentChatRule.qa_query;
    const newReplyCommand =
      currentChatRule.reply_command === null || currentChatRule.reply_command === undefined
        ? ({ parameters: [], function_name: '' } as ReplyCommand)
        : currentChatRule.reply_command;
    const newParametersArray =
      newReplyCommand.parameters !== null &&
      newReplyCommand.parameters &&
      newReplyCommand.parameters.length > 0
        ? newReplyCommand.parameters
        : [''];

    currentChatRule.before_rule_id = newBeforeRuleIDArray;
    currentChatRule.next_rule_id = newNextRuleIDArray;
    currentChatRule.state_actions = newStateActionArray;
    currentChatRule.template_ids = newTemplateIDArray;
    currentChatRule.qa_query = newQAQueriesArray;
    currentChatRule.reply_command = newReplyCommand;
    currentChatRule.reply_command.parameters = newParametersArray;

    // FieldArraysの初期化
    // Function Parameters(Scenario Parameters)
    if (paramFields.length === 0) {
      const newFields = currentChatRule.reply_command.parameters.map(
        (id: string, index: number) => {
          return { name: id };
        }
      );
      if (newFields.length === 0) newFields.push({ name: '' });
      paramAppend(newFields);
    }
    // Before Rule ID
    if (beforeRuleIdFields.length === 0) {
      const newFields = currentChatRule.before_rule_id.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      beforeRuleIdAppend(newFields);
    }
    // Next Rule ID
    if (nextRuleIdFields.length === 0) {
      const newFields = currentChatRule.next_rule_id.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      nextRuleIdAppend(newFields);
    }
    // Template IDs
    if (templateIdFields.length === 0) {
      const newFields = currentChatRule.template_ids.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      templateIdAppend(newFields);
    }
    // QA Query
    if (queryFields.length === 0) {
      const newFields = currentChatRule.qa_query.map((id: string, index: number) => {
        return { name: id };
      });
      if (newFields.length === 0) newFields.push({ name: '' });
      queryAppend(newFields);
    }
    setCurrentChatRule(Object.assign({}, currentChatRule));

    // ラベルサイズ設定
    if (inputStartFlagLabel.current) {
      setStartFlagLabelWidth(inputStartFlagLabel.current.offsetWidth);
    }
    if (inputRuleTypeLabel.current) {
      setRuleTypeLabelWidth(inputRuleTypeLabel.current.offsetWidth);
    }
    if (inputRuleTypeLabel.current) {
      setProcTypeLabelWidth(inputRuleTypeLabel.current.offsetWidth);
    }
    if (inputWeightLabel.current) {
      setWeightLabelWidth(inputWeightLabel.current.offsetWidth);
    }
    if (inputFunctionNameLabel.current) {
      setFunctionNameLabelWidth(inputFunctionNameLabel.current.offsetWidth);
    }
    if (inputActionCodeLabel.current) {
      setActionCodeLabelWidth(inputActionCodeLabel.current.offsetWidth);
    }
    if (inputFacialExpressionCodeLabel.current) {
      setFacialExpressionCodeLabelWidth(inputFacialExpressionCodeLabel.current.offsetWidth);
    }

    selectValues.rule_type =
      currentChatRule.rule_type === null || currentChatRule.rule_type === undefined
        ? ''
        : currentChatRule.rule_type.toString();
    selectValues.action_code = currentChatRule.action_code
      ? currentChatRule.action_code.toString()
      : '';
    selectValues.facial_expression_code = currentChatRule.facial_expression_code
      ? currentChatRule.facial_expression_code.toString()
      : '';
    selectValues.proc_type = currentChatRule.proc_type ? currentChatRule.proc_type.toString() : '';
    selectValues.function_name = '';
    if (currentChatRule.reply_command) {
      selectValues.function_name = currentChatRule.reply_command.function_name
        ? currentChatRule.reply_command.function_name
        : '';
    }
    setSelectValues(Object.assign({}, selectValues));
  }, []);

  const onClickAddBeforeRule = useCallback(
    (_id: string, index: number): void => {
      beforeRuleIdInsert(index + 1, {});
    },
    [beforeRuleIdFields]
  );

  const onClickRemoveBeforeRule = useCallback(
    (_id: string, index: number): void => {
      // 残りが1つなら削除しない
      if (beforeRuleIdFields.length !== 1) {
        beforeRuleIdRemove(index);
      }
    },
    [beforeRuleIdFields]
  );

  const onClickAddNextRule = useCallback(
    (_id: string, index: number): void => {
      nextRuleIdInsert(index + 1, {});
    },
    [nextRuleIdFields]
  );

  const onClickRemoveNextRule = useCallback(
    (_id: string, index: number): void => {
      // 残りが1つなら削除しない
      if (nextRuleIdFields.length !== 1) {
        nextRuleIdRemove(index);
      }
    },
    [nextRuleIdFields]
  );

  const onClickAddTemplateID = useCallback(
    (_id: string, index: number): void => {
      templateIdInsert(index + 1, {});
    },
    [templateIdFields]
  );

  const onClickRemoveTemplateID = useCallback(
    (_id: string, index: number): void => {
      // 残りが1つなら削除しない
      if (templateIdFields.length !== 1) {
        templateIdRemove(index);
      }
    },
    [templateIdFields]
  );

  const onClickAddQAQueries = useCallback(
    (_id: string, index: number): void => {
      queryInsert(index + 1, {});
    },
    [queryFields]
  );

  const onClickRemoveQAQueries = useCallback(
    (_id: string, index: number): void => {
      // 残りが1つなら削除しない
      if (queryFields.length !== 1) {
        queryRemove(index);
      }
    },
    [queryFields]
  );

  const onClickAddParams = useCallback(
    (_id: string, index: number): void => {
      paramInsert(index + 1, {});
    },
    [paramFields]
  );

  const onClickRemoveParams = useCallback(
    (_id: string, index: number): void => {
      // 残りが1つなら削除しない
      if (paramFields.length !== 1) {
        paramRemove(index);
      }
    },
    [paramFields]
  );

  const setErrorFunctionName = (message: string): void => {
    setError('function_name', 'required', message);
  };

  const renderRuleIDForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Rule ID
          <UnbRequiredSpan />
        </Typography>
        <TextField
          inputRef={register(ruleIDRegister)}
          error={!!errors.rule_id}
          label="Rule ID"
          name="rule_id"
          id="rule-id"
          className={classes.formControl}
          helperText={
            errors.rule_id
              ? (errors.rule_id as FieldError)['message']
              : 'シナリオを識別するためのID'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.rule_id}
        />
      </div>
    ),
    [errors.rule_id, currentChatRule.rule_id]
  );

  const renderBeforeRuleIDForm = (
    <div className={classes.row}>
      <Typography className={classes.inputLabel}>Before Rule ID</Typography>
      <div className={classes.formControl}>
        {beforeRuleIdFields.map((item, index: number) => {
          return (
            <UnbListTextField
              key={item.id + '-' + index}
              inputRef={register(ruleIDArrayRegister(watchRuleID, beforeRuleIdFields, index))}
              error={!!(errors.before_rule_id && (errors.before_rule_id as FieldError[])[index])}
              helperText={
                errors.before_rule_id &&
                (errors.before_rule_id as { name: FieldError }[])[index] &&
                (errors.before_rule_id as { name: FieldError }[])[index].name?.message
                  ? (errors.before_rule_id as { name: FieldError }[])[index].name?.message
                  : '前のRule ID'
              }
              multiline
              name={`before_rule_id[${index}].name`}
              id={'before-rule-id-' + index}
              labelText="Before Rule ID"
              defaultText={item.name}
              index={index}
              onClickAdd={onClickAddBeforeRule}
              onClickRemove={onClickRemoveBeforeRule}
            />
          );
        })}
      </div>
    </div>
  );

  const renderNextRuleIDForm = (
    <div className={classes.row}>
      <Typography className={classes.inputLabel}>Next Rule ID</Typography>
      <div className={classes.formControl}>
        {nextRuleIdFields.map((item, index: number) => {
          return (
            <UnbListTextField
              key={item.id + '-' + index}
              inputRef={register(ruleIDArrayRegister(watchRuleID, nextRuleIdFields, index))}
              error={!!(errors.next_rule_id && (errors.next_rule_id as FieldError[])[index])}
              helperText={
                errors.next_rule_id &&
                (errors.next_rule_id as { name: FieldError }[])[index] &&
                (errors.next_rule_id as { name: FieldError }[])[index].name?.message
                  ? (errors.next_rule_id as { name: FieldError }[])[index].name?.message
                  : '次のRule ID'
              }
              multiline
              name={`next_rule_id[${index}].name`}
              id={'next-rule-id-' + index}
              labelText="Next Rule ID"
              defaultText={item.name}
              index={index}
              onClickAdd={onClickAddNextRule}
              onClickRemove={onClickRemoveNextRule}
            />
          );
        })}
      </div>
    </div>
  );

  const renderStartingFlagForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Starting Flag
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="starting-flag-label" ref={inputStartFlagLabel}>
            Starting Flag
          </InputLabel>
          <Select
            name="starting_flag"
            inputRef={register}
            value={convertBool2Num(currentChatRule.starting_flag)}
            labelId="starting-flag-label"
            id="starting-flag"
            onChange={handleStartingFlagChange}
            labelWidth={startFlagLabelWidth}
          >
            <MenuItem value={1}>TRUE</MenuItem>
            <MenuItem value={0}>FALSE</MenuItem>
          </Select>
          {errors.starting_flag ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.starting_flag as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>会話の始まりかどうか</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [errors.starting_flag, currentChatRule.starting_flag, startFlagLabelWidth]
  );

  const renderRuleTypeForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Rule Type
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="rule-type-label" ref={inputRuleTypeLabel}>
            Rule Type
          </InputLabel>
          <Select
            inputRef={register}
            value={selectValues.rule_type}
            error={!!errors.rule_type}
            labelId="rule-type-label"
            id="rule-type"
            name="rule_type"
            onChange={handleRuleTypeChange}
            labelWidth={ruleTypeLabelWidth}
          >
            {Object.values(RuleTypeEnum).map((ruleType) => {
              return (
                <MenuItem key={ruleType} value={ruleType}>
                  {ruleType}
                </MenuItem>
              );
            })}
          </Select>
          {errors.rule_type ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.rule_type as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>ルール種別</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [errors.rule_type, selectValues.rule_type, ruleTypeLabelWidth]
  );

  const renderDecisionConditionForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Decision Condition</Typography>
        <TextField
          label="Decision Condition"
          id="decision-condition"
          name="decision_condition"
          inputRef={register(decisionConditionRegister)}
          error={!!errors.decision_condition}
          className={classes.formControl}
          defaultValue={currentChatRule.decision_condition}
          helperText={
            errors.decision_condition
              ? (errors.decision_condition as FieldError)['message']
              : '発火条件'
          }
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [errors.decision_condition, currentChatRule.decision_condition]
  );

  const renderDecisionCommandForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Decision Command</Typography>
        <TextField
          label="Decision Command"
          id="decision-command"
          name="decision_command"
          inputRef={register(decisionCommandRegister)}
          error={!!errors.decision_command}
          className={classes.formControl}
          defaultValue={currentChatRule.decision_command}
          helperText={
            errors.decision_command
              ? (errors.decision_command as FieldError)['message']
              : '条件拡張コマンド'
          }
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [errors.decision_command, currentChatRule.decision_command]
  );

  const renderReplyForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Reply</Typography>
        <TextField
          label="Reply"
          inputRef={register(replyRegister)}
          error={!!errors.reply}
          id="reply"
          name="reply"
          className={classes.formControl}
          helperText={errors.reply ? (errors.reply as FieldError)['message'] : '返答内容'}
          defaultValue={currentChatRule.reply}
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [errors.reply, currentChatRule.reply]
  );

  const renderWeightForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Weight
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel htmlFor="weight" ref={inputWeightLabel} error={!!errors.weight}>
            Weight
          </InputLabel>
          <OutlinedInput
            inputRef={register(weightRegistr)}
            error={!!errors.weight}
            id="weight"
            type="number"
            name="weight"
            defaultValue={currentChatRule.weight}
            onChange={handleWeightChange}
            labelWidth={weightLabelWidth}
          />
          {errors.weight ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.weight as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>重み</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [errors.weight, currentChatRule.weight, weightLabelWidth]
  );

  const renderFunctionNameForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Function Name</Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel htmlFor="function-name" id="function-name-label" ref={inputFunctionNameLabel}>
            Function Name
          </InputLabel>
          <Select
            value={selectValues.function_name}
            labelId="function-name-label"
            inputRef={register}
            error={!!errors.function_name}
            id="function-name"
            name="function_name"
            onChange={handleFunctionNameChange}
            labelWidth={functionNameLabelWidth}
          >
            <MenuItem value={''}>選択なし</MenuItem>
            {Commands.map((command) => {
              return (
                <MenuItem key={command} value={command}>
                  {command}
                </MenuItem>
              );
            })}
          </Select>
          {errors.function_name ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.function_name as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>ユニボへのコマンド</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [errors.function_name, selectValues.function_name, functionNameLabelWidth]
  );

  const renderFunctionParametersForm = (
    <div className={classes.row}>
      <Typography className={classes.inputLabel}>Scenario Parameters</Typography>
      <div className={classes.formControl}>
        {paramFields.map((item, index: number) => {
          return (
            <UnbListTextField
              key={item.id + '-' + index}
              inputRef={register(
                functionParametersRegister(
                  currentChatRule.reply_command?.function_name,
                  paramFields,
                  index,
                  setErrorFunctionName
                )
              )}
              error={!!(errors.parameters && (errors.parameters as FieldError[])[index])}
              helperText={
                errors.parameters &&
                (errors.parameters as { name: FieldError }[])[index] &&
                (errors.parameters as { name: FieldError }[])[index].name?.message
                  ? (errors.parameters as { name: FieldError }[])[index].name?.message
                  : '会話エンジンへのコマンド引数'
              }
              multiline
              name={`parameters[${index}].name`}
              id={'reply-command-function-params-' + index}
              labelText="Scenario Parameters"
              defaultText={item.name}
              index={index}
              onClickAdd={onClickAddParams}
              onClickRemove={onClickRemoveParams}
            />
          );
        })}
      </div>
    </div>
  );

  const renderFunctionCodeForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Function Code</Typography>
        <TextField
          label="Function Code"
          inputRef={register(functionCodeRegister)}
          error={!!errors.function_code}
          id="function-code"
          name="function_code"
          className={classes.formControl}
          helperText={
            errors.function_code
              ? (errors.function_code as FieldError)['message']
              : 'ユニボのへのコマンド呼び出し定義'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.function_code}
        />
      </div>
    ),
    [errors.function_code, currentChatRule.function_code]
  );

  const renderActionCodeForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Action Code</Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="action-code-label" ref={inputActionCodeLabel}>
            Action Code
          </InputLabel>
          <Select
            value={selectValues.action_code}
            inputRef={register}
            error={!!errors.action_code}
            labelId="action-code-label"
            id="action-code"
            name="action_code"
            onChange={handleActionCodeChange}
            labelWidth={actionCodeLabelWidth}
          >
            <MenuItem value={''}>選択なし</MenuItem>
            {Object.values(ActionCodeEnum).map((actionCode) => {
              return (
                <MenuItem key={actionCode} value={actionCode}>
                  {actionCode}
                </MenuItem>
              );
            })}
          </Select>
          {errors.action_code ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.action_code as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>ユニボのアクション指定</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [errors.action_code, selectValues.action_code, actionCodeLabelWidth]
  );

  const renderFacialExpressionCodeForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Facial Expression Code</Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="facial-expression-code-label" ref={inputFacialExpressionCodeLabel}>
            Facial Expression Code
          </InputLabel>
          <Select
            inputRef={register}
            error={!!errors.facial_expression_code}
            value={selectValues.facial_expression_code}
            labelId="facial-expression-code-label"
            id="facial-expression-code"
            name="facial_expression_code"
            onChange={handleFacialExpressionCodeChange}
            labelWidth={facialExpressionCodeLabelWidth}
          >
            <MenuItem value={''}>選択なし</MenuItem>
            {Object.values(FacialExpressionCodeEnum).map((facialExpressionCode) => {
              return (
                <MenuItem key={facialExpressionCode} value={facialExpressionCode}>
                  {facialExpressionCode}
                </MenuItem>
              );
            })}
          </Select>
          {errors.facial_expression_code ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.facial_expression_code as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>ユニボの表情定義</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [
      errors.facial_expression_code,
      selectValues.facial_expression_code,
      facialExpressionCodeLabelWidth,
    ]
  );

  const renderSystemSentimentForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>System Sentiment</Typography>
        <TextField
          label="System Sentiment"
          inputRef={register(sentimentRegister)}
          error={!!errors.system_sentiment}
          id="system-sentiment"
          name="system_sentiment"
          className={classes.formControl}
          helperText={
            errors.system_sentiment
              ? (errors.system_sentiment as FieldError)['message']
              : 'ユニボの感情値'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.system_sentiment}
        />
      </div>
    ),
    [errors.system_sentiment, currentChatRule.system_sentiment]
  );

  const renderUserSentimentForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>User Sentiment</Typography>
        <TextField
          label="User Sentiment"
          inputRef={register(sentimentRegister)}
          error={!!errors.user_sentiment}
          id="user-sentiment"
          name="user_sentiment"
          className={classes.formControl}
          helperText={
            errors.user_sentiment
              ? (errors.user_sentiment as FieldError)['message']
              : 'ユーザの感情値'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.user_sentiment}
        />
      </div>
    ),
    [errors.user_sentiment, currentChatRule.user_sentiment]
  );

  const renderStateActions = useMemo(() => <UnbStateActions chatRule={currentChatRule} />, [
    currentChatRule.state_actions,
    errors,
  ]);

  const renderTemplateIDsForm = (
    <div className={classes.row}>
      <Typography className={classes.inputLabel}>Template IDs</Typography>
      <div className={classes.formControl}>
        {templateIdFields.map((item, index: number) => {
          return (
            <UnbListTextField
              key={item.id + '-' + index}
              inputRef={register(templateIDsRegister(templateIdFields, index))}
              error={!!(errors.template_ids && (errors.template_ids as FieldError[])[index])}
              helperText={
                errors.template_ids &&
                (errors.template_ids as { name: FieldError }[])[index] &&
                (errors.template_ids as { name: FieldError }[])[index].name?.message
                  ? (errors.template_ids as { name: FieldError }[])[index].name?.message
                  : 'Template ID'
              }
              multiline
              name={`template_ids[${index}].name`}
              id={'template-id-' + index}
              labelText="Template IDs"
              defaultText={item.name}
              index={index}
              onClickAdd={onClickAddTemplateID}
              onClickRemove={onClickRemoveTemplateID}
            />
          );
        })}
      </div>
    </div>
  );

  const renderTemplateVariableResetConditionForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Template Variable Reset Condition</Typography>
        <TextField
          label="Template Variable Reset Condition"
          inputRef={register(templateVariableResetConditionRegister)}
          error={!!errors.template_variable_reset_condition}
          id="template-variable-reset-condition"
          name="template_variable_reset_condition"
          className={classes.formControl}
          helperText={
            errors.template_variable_reset_condition
              ? (errors.template_variable_reset_condition as FieldError)['message']
              : 'テンプレート初期化条件を指定'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.template_variable_reset_condition}
        />
      </div>
    ),
    [errors.template_variable_reset_condition, currentChatRule.template_variable_reset_condition]
  );

  const renderNextPeriodForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Next Period</Typography>
        <TextField
          label="Next Period"
          inputRef={register(nextPeriodRegister)}
          error={!!errors.next_period}
          id="next-period"
          name="next_period"
          className={classes.formControl}
          helperText={
            errors.next_period ? (errors.next_period as FieldError)['message'] : '発火間隔の定義'
          }
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.next_period}
        />
      </div>
    ),
    [errors.next_period, currentChatRule.next_period]
  );

  const renderTaskNameForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Task Name</Typography>
        <TextField
          label="Task Name"
          id="task-name"
          name="task_name"
          className={classes.formControl}
          inputRef={register(taskNameRegister)}
          error={!!errors.task_name}
          helperText={errors.task_name ? (errors.task_name as FieldError)['message'] : 'タスク名'}
          margin="dense"
          variant="outlined"
          defaultValue={currentChatRule.task_name}
        />
      </div>
    ),
    [errors.task_name, currentChatRule.task_name]
  );

  const renderQAQueriesForm = (
    <div className={classes.row}>
      <Typography className={classes.inputLabel}>QA Queries</Typography>
      <div className={classes.formControl}>
        {queryFields.map((item, index: number) => {
          return (
            <UnbListTextField
              key={item.id + '-' + index}
              inputRef={register(qaQueriesRegister(queryFields, index))}
              error={!!(errors.qa_query && (errors.qa_query as FieldError[])[index])}
              helperText={
                errors.qa_query &&
                (errors.qa_query as { name: FieldError }[])[index] &&
                (errors.qa_query as { name: FieldError }[])[index].name?.message
                  ? (errors.qa_query as { name: FieldError }[])[index].name?.message
                  : 'QA Query'
              }
              multiline
              name={`qa_query[${index}].name`}
              id={'qa-query-' + index}
              labelText="QA Queries"
              defaultText={item.name}
              index={index}
              onClickAdd={onClickAddQAQueries}
              onClickRemove={onClickRemoveQAQueries}
            />
          );
        })}
      </div>
    </div>
  );

  const renderProcTypeForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Proc Type
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="proc-type-label" ref={inputProcTypeLabel}>
            Proc Type
          </InputLabel>
          <Select
            value={selectValues.proc_type}
            inputRef={register}
            error={!!errors.proc_type}
            labelId="proc-type-label"
            id="proc-type"
            name="proc_type"
            onChange={handleProcTypeChange}
            labelWidth={procTypeLabelWidth}
          >
            {Object.values(ProcTypeEnum).map((procType) => {
              return (
                <MenuItem key={procType} value={procType}>
                  {procType}
                </MenuItem>
              );
            })}
          </Select>
          {errors.proc_type ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.proc_type as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>シナリオ種別</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [errors.proc_type, selectValues.proc_type, procTypeLabelWidth]
  );

  return (
    <DialogContent dividers={props.dividers}>
      {renderRuleIDForm}
      {renderBeforeRuleIDForm}
      {renderNextRuleIDForm}
      {renderStartingFlagForm}
      {renderRuleTypeForm}
      {renderDecisionConditionForm}
      {renderDecisionCommandForm}
      {renderReplyForm}
      {renderWeightForm}
      {renderFunctionNameForm}
      {renderFunctionParametersForm}
      {renderFunctionCodeForm}
      {renderActionCodeForm}
      {renderFacialExpressionCodeForm}
      {renderSystemSentimentForm}
      {renderUserSentimentForm}
      {renderStateActions}
      {renderTemplateIDsForm}
      {renderTemplateVariableResetConditionForm}
      {renderNextPeriodForm}
      {renderTaskNameForm}
      {renderQAQueriesForm}
      {renderProcTypeForm}
    </DialogContent>
  );
};

export default UnbChatRuleDialogContent;
