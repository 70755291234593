import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import React, { ReactNode } from 'react';
import { PropTypes } from '@material-ui/core';

type UnbStyledTableCellProps = {
  backgroundColor: string;
  color: string;
  align?: PropTypes.Alignment;
  children: ReactNode;
  style?: {};
};

const UnbStyledTableCell: React.FC<UnbStyledTableCellProps> = (props: UnbStyledTableCellProps) => {
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: props.backgroundColor,
        color: props.color,
      },
      body: {
        fontSize: 14,
      },
    })
  )(TableCell);

  return (
    <StyledTableCell align={props.align} style={props.style}>
      {props.children}
    </StyledTableCell>
  );
};

export default UnbStyledTableCell;
