import { StateAction } from 'api/models/StateAction';
import { ValidationErrorMessages } from 'constants/Messages';
import { ArrayField } from 'react-hook-form/dist/types';
import { requiredForMultiple } from 'utils/ComponentUtil';
import 'utils/StringUtil.ts';

export const ruleIDRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const startingFlagRegister = {
  custom: {
    name: 'starting_flag',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const ruleTypeRegister = {
  custom: {
    name: 'rule_type',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const decisionConditionRegister = {
  required: false,
};

export const decisionCommandRegister = {
  required: false,
};

export const replyRegister = {
  required: false,
};

export const functionNameRegister = {
  custom: {
    name: 'function_name',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const actionCodeRegister = {
  custom: {
    name: 'action_code',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const facialExpressionCodeRegister = {
  custom: {
    name: 'facial_expression_code',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const stateModActionRegister = (arr: StateAction[] | undefined, index: number): object => {
  return {
    required: false,
    /** State Mod ActionとModification Commandの両方が空ならエラー */
    validate: (v: string): boolean | string => {
      if (arr?.length === 1) return true; // 1つの場合はチェックOK
      if (v !== null && v !== undefined && v !== '') return true; // 空でなければチェックOK

      // modification_commandの空チェック
      const mc = arr ? arr[index].modification_command : null;
      if (mc !== null && mc !== undefined && mc !== '') return true; // 空でなければチェックOK

      // エラー
      return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
    },
  };
};

export const modificationCommandRegister = (
  arr: StateAction[] | undefined,
  index: number
): object => {
  return {
    required: false,
    /** State Mod ActionとModification Commandの両方が空ならエラー */
    validate: (v: string): boolean | string => {
      if (arr?.length === 1) return true; // 1つの場合はチェックOK
      if (v !== null && v !== undefined && v !== '') return true; // 空でなければチェックOK

      // state_mod_actionの空チェック
      const mc = arr ? arr[index].state_mod_action : null;
      if (mc !== null && mc !== undefined && mc !== '') return true; // 空でなければチェックOK

      // エラー
      return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
    },
  };
};

export const templateIDsRegister = (
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      return true;
    },
  };
};

export const taskNameRegister = {
  required: false,
};

export const qaQueriesRegister = (
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      return true;
    },
  };
};

export const procTypeRegister = {
  custom: {
    name: 'proc_type',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const ruleIDArrayRegister = (
  ruleID: string,
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number
): object => {
  return {
    required: false,
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      if (ruleID === null || ruleID === undefined || ruleID === '') {
        return true;
      } else if (ruleID === v) {
        return 'ルールIDと同じIDは指定できません';
      }
      return true;
    },
  };
};

export const weightRegistr = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
  pattern: {
    value: /[0-9]/,
    message: ValidationErrorMessages.PATTERN.format('/[0-9]/'),
  },
};

export const functionCodeRegister = {
  required: false,
  pattern: {
    value: /^skillpack@.*.@[0-9]/,
    message: ValidationErrorMessages.PATTERN.format('/^skillpack@.*.@[0-9]/'),
  },
};

export const functionParametersRegister = (
  functionName: null | undefined | string,
  arr: Partial<ArrayField<Record<string, string>, 'id'>>[] | undefined,
  index: number,
  handleError: (message: string) => void
): object => {
  return {
    required: false,
    pattern: {
      value: /[a-zA-Z0-9]*.[=].+/,
      message: ValidationErrorMessages.PATTERN.format('/[a-zA-Z0-9]*.[=].+/'),
    },
    validate: (v: string): boolean | string => {
      if (requiredForMultiple(arr, index, v)) {
        return ValidationErrorMessages.REQUIRED_FOR_MULTIPLE;
      }
      if (
        (functionName === null || functionName === undefined || functionName === '') &&
        v !== null &&
        v !== undefined &&
        v !== ''
      ) {
        handleError('Scenario Parametersが存在する時、Function Nameは必須です');
      }
      return true;
    },
  };
};

export const sentimentRegister = {
  required: false,
  pattern: {
    value: /(喜び|信頼|心配|予測)[:][0-9].[0-9]/,
    message: ValidationErrorMessages.PATTERN.format('/(喜び|信頼|心配|予測)[:][0-9].[0-9]/'),
  },
};

export const templateVariableResetConditionRegister = {
  required: false,
  pattern: {
    value: /[a-zA-Z_.0-9]*.=.*/,
    message: ValidationErrorMessages.PATTERN.format('/[a-zA-Z_.0-9]*.=.*/'),
  },
};

/**
 * mi(minute) | h(hour) | d(day) | w(week) | m(month) | y(year)
 */
export const nextPeriodRegister = {
  required: false,
  pattern: {
    value: /[0-9].*(mi|h|d|w|m|y)/,
    message: ValidationErrorMessages.PATTERN.format('/[0-9].*(mi|h|d|w|m|y)/'),
  },
};

export const searchStartingFlagRegister = {
  custom: {
    name: 'starting_flag',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};
