import React, { useMemo, useCallback } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { TestResult, TestResultStatusEnum } from 'api/models/TestResult';
import UnbRequiredSpan from 'components/atoms/UnbRequiredSpan';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useFormContext, FieldError } from 'react-hook-form';
import {
  scenarioCreatorRegister,
  testResultStatusRegister,
  testerRegister,
  uniboIDRegister,
  noteRegister,
  reflectedDateRegister,
  testedDateRegister,
} from 'components/form/validator/TestResultRegister';
import UnbTheme from 'themes/UnbStyle';

type UnbTestResultDialogContentProps = {
  dividers?: boolean;
  testResult: TestResult;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    inputLabel: {
      flex: 1,
    },
    formControl: {
      flex: 1,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    errorHelperText: {
      color: theme.palette.error.main,
    },
  })
);

const UnbTestResultDialogContent: React.FC<UnbTestResultDialogContentProps> = (props) => {
  const classes = useStyles(UnbTheme);

  const [currentTestResult, setCurrentTestResult] = React.useState(props.testResult);
  const { register, errors, setValue } = useFormContext();

  const inputTestResultStatusLabel = React.useRef<HTMLLabelElement>(null);
  const [testResultStatusLabelWidth, setTestResultStatusLabelWidth] = React.useState(0);
  const handleTestResultStatusChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      currentTestResult.test_result_status = event.target.value as TestResultStatusEnum;
      setValue('test_result_status', event.target.value as TestResultStatusEnum);
      setCurrentTestResult(Object.assign({}, currentTestResult));
    },
    [currentTestResult]
  );

  React.useEffect(() => {
    // カスタムレジスター
    register(testResultStatusRegister.custom, testResultStatusRegister.rule);
    register(reflectedDateRegister.custom, reflectedDateRegister.rule);
    register(testedDateRegister.custom, testedDateRegister.rule);
    setValue('test_result_status', currentTestResult.test_result_status);
    setValue('reflected_date', currentTestResult.reflected_date);
    setValue('tested_date', currentTestResult.tested_date);
    setCurrentTestResult(Object.assign({}, currentTestResult));

    if (inputTestResultStatusLabel.current) {
      setTestResultStatusLabelWidth(inputTestResultStatusLabel.current.offsetWidth);
    }
  }, []);

  const handleReflectedDateChange = useCallback(
    (date: Date | null): void => {
      if (date !== null) {
        currentTestResult.reflected_date = date;
        setValue('reflected_date', date);
        const obj = Object.assign({}, currentTestResult);
        setCurrentTestResult(obj);
      }
    },
    [currentTestResult]
  );

  const handleTestedDateChange = useCallback(
    (date: Date | null): void => {
      if (date !== null) {
        currentTestResult.tested_date = date;
        setValue('tested_date', date);
        const obj = Object.assign({}, currentTestResult);
        setCurrentTestResult(obj);
      }
    },
    [currentTestResult]
  );

  const renderTestResultStatusForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Test Result Status
          <UnbRequiredSpan />
        </Typography>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="test-result-status-label" ref={inputTestResultStatusLabel}>
            Test Result Status
          </InputLabel>
          <Select
            inputRef={register}
            value={currentTestResult.test_result_status}
            labelId="test-result-status-label"
            id="test-result-status"
            name="test_result_status"
            onChange={handleTestResultStatusChange}
            labelWidth={testResultStatusLabelWidth}
          >
            {Object.values(TestResultStatusEnum).map((status) => {
              return (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              );
            })}
          </Select>
          {errors.test_result_status ? (
            <FormHelperText className={classes.errorHelperText}>
              {(errors.test_result_status as FieldError)['message']}
            </FormHelperText>
          ) : (
            <FormHelperText>シナリオテストの結果</FormHelperText>
          )}
        </FormControl>
      </div>
    ),
    [currentTestResult.test_result_status, errors.test_result_status, testResultStatusLabelWidth]
  );

  const renderScenarioCreatorForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>
          Scenario Creator
          <UnbRequiredSpan />
        </Typography>
        <TextField
          label="Scenario Creator"
          inputRef={register(scenarioCreatorRegister)}
          error={!!errors.scenario_creator}
          id="scenario-creator"
          name="scenario_creator"
          className={classes.formControl}
          defaultValue={currentTestResult.scenario_creator}
          helperText={
            errors.scenario_creator
              ? (errors.scenario_creator as FieldError)['message']
              : 'シナリオ作成者'
          }
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [currentTestResult.scenario_creator, errors.scenario_creator]
  );

  const renderReflectedDateForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Reflected Date</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            inputRef={register}
            format="yyyy/MM/dd"
            margin="dense"
            className={classes.formControl}
            InputAdornmentProps={{ position: 'end' }}
            id="reflected-date"
            name="reflected_date"
            label="Reflected Date"
            helperText={
              errors.reflected_date ? (errors.reflected_date as FieldError)['message'] : '反映日'
            }
            value={currentTestResult.reflected_date}
            onChange={handleReflectedDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    ),
    [currentTestResult.reflected_date, errors.reflected_date]
  );

  const renderTesterForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Tester</Typography>
        <TextField
          label="Tester"
          id="tester"
          name="tester"
          inputRef={register(testerRegister)}
          error={!!errors.tester}
          className={classes.formControl}
          defaultValue={currentTestResult.tester}
          helperText={errors.tester ? (errors.tester as FieldError)['message'] : 'テスト担当者'}
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [currentTestResult.tester, errors.tester]
  );

  const renderTestedDateForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Tested Date</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant={'outlined'}
            inputRef={register}
            format="yyyy/MM/dd"
            margin="dense"
            className={classes.formControl}
            InputAdornmentProps={{ position: 'end' }}
            id="tested-date"
            name="tested_date"
            label="Tested Date"
            helperText={
              errors.tested_date ? (errors.tested_date as FieldError)['message'] : 'テスト実施日'
            }
            value={currentTestResult.tested_date}
            onChange={handleTestedDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    ),
    [currentTestResult.tested_date, errors.tested_date]
  );

  const renderUnboIDForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Unibo ID</Typography>
        <TextField
          label="Unibo ID"
          inputRef={register(uniboIDRegister)}
          error={!!errors.unibo_id}
          id="unibo-id"
          name="unibo_id"
          className={classes.formControl}
          defaultValue={currentTestResult.unibo_id}
          helperText={errors.unibo_id ? (errors.unibo_id as FieldError)['message'] : 'ユニボID'}
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [currentTestResult.unibo_id, errors.unibo_id]
  );

  const renderNoteForm = useMemo(
    () => (
      <div className={classes.row}>
        <Typography className={classes.inputLabel}>Note</Typography>
        <TextField
          label="Note"
          inputRef={register(noteRegister)}
          error={!!errors.note}
          id="note"
          name="note"
          className={classes.formControl}
          defaultValue={currentTestResult.note}
          helperText={errors.note ? (errors.note as FieldError)['message'] : '備考'}
          multiline
          margin="dense"
          variant="outlined"
        />
      </div>
    ),
    [currentTestResult.note, errors.note]
  );

  return (
    <DialogContent dividers={props.dividers}>
      {renderTestResultStatusForm}
      {renderScenarioCreatorForm}
      {renderReflectedDateForm}
      {renderTesterForm}
      {renderTestedDateForm}
      {renderUnboIDForm}
      {renderNoteForm}
    </DialogContent>
  );
};

export default UnbTestResultDialogContent;
