import { ValidationErrorMessages } from 'constants/Messages';
import 'utils/StringUtil.ts';

export const scenarioCreatorRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const testerRegister = {
  required: false,
};

export const uniboIDRegister = {
  required: false,
};

export const noteRegister = {
  required: false,
  maxLength: { value: 300, message: ValidationErrorMessages.MAX_LEN.format('300') },
};

export const testResultStatusRegister = {
  custom: {
    name: 'test_result_status',
    type: 'custom',
  },
  rule: {
    required: { value: true, message: ValidationErrorMessages.REQUIRED },
  },
};

export const reflectedDateRegister = {
  custom: {
    name: 'reflected_date',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};

export const testedDateRegister = {
  custom: {
    name: 'tested_date',
    type: 'custom',
  },
  rule: {
    required: false,
  },
};
