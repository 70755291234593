import { UnbChatRuleTooltipContentProps } from 'components/molecules/UnbChatRuleTooltipContent';
import actionCreatorFactory from 'typescript-fsa';
import { Network } from 'vis-network';

export type TooltipPosition = {
  top: number;
  left: number;
};

const actionCreator = actionCreatorFactory();

export const uiChatRuleActions = {
  setOpenCopyAlertDialog: actionCreator<boolean>('SET_CHAT_RULE_OPEN_COPY_ALERT_DIALOG'),
  setOpenReloadAlertDialog: actionCreator<boolean>('SET_CHAT_RULE_OPEN_RELOAD_ALERT_DIALOG'),
  setIsDisplayCopyBtn: actionCreator<boolean>('SET_CHAT_RULE_IS_DISPLAY_COPY_BTN'),
  setNetwork: actionCreator<Network | null>('SET_NETWORK'),
  setNetworkLoading: actionCreator<boolean>('SET_NETWORK_LOADING'),
  setTooltipPosition: actionCreator<TooltipPosition>('SET_TOOLTIP_POSITION'),
  setTooltipContent: actionCreator<UnbChatRuleTooltipContentProps>('SET_TOOLTIP_CONTENT'),
  setTooltipOpen: actionCreator<boolean>('SET_TOOLTIP_OPEN'),
};
