import { PropTypes } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    delete: {
      marginRight: 'auto',
    },
  })
);

export enum Action {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

type UnbEditDialogActionsProps = {
  okHandler: () => void;
  okBtnColor?: PropTypes.Color;
  okBtnText: string;
  cancelHandler: () => void;
  cancelBtnColor?: PropTypes.Color;
  cancelBtnText: string;
  deleteHandler: () => void;
  deleteBtnColor?: PropTypes.Color;
  deleteBtnText: string;
  isDisplayDelete?: boolean;
  testIdPostFix?: string;
};

const UnbEditDialogActions: React.FC<UnbEditDialogActionsProps> = (props) => {
  const classes = useStyles();
  const testIdPostFix = props.testIdPostFix ? props.testIdPostFix : '';
  return (
    <DialogActions>
      {props.isDisplayDelete && (
        <Button
          onClick={props.deleteHandler}
          color={props.deleteBtnColor}
          className={classes.delete}
          data-testid={'btn-dialog-delete' + testIdPostFix}
        >
          {props.deleteBtnText}
        </Button>
      )}
      <Button
        onClick={props.cancelHandler}
        color={props.cancelBtnColor}
        data-testid={'btn-dialog-cancel' + testIdPostFix}
      >
        {props.cancelBtnText}
      </Button>
      <Button
        type="submit"
        onClick={props.okHandler}
        color={props.okBtnColor}
        data-testid={'btn-dialog-submit' + testIdPostFix}
      >
        {props.okBtnText}
      </Button>
    </DialogActions>
  );
};

export default UnbEditDialogActions;
