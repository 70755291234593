/** @fileOverview テンプレート変数アクション */
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestGetTemplateVariable from 'api/models/requests/RequestGetTemplateVariable';
import RequestPatchTemplateVariable from 'api/models/requests/RequestPatchTemplateVariable';
import RequestPostTemplateVariable from 'api/models/requests/RequestPostTemplateVariable';
import RequestSearchCommon from 'api/models/requests/RequestSearchCommon';
import { TemplateVariable } from 'api/models/TemplateVariable';
import {
  deleteTemplateVariable,
  execRequest,
  fetchTemplateVariables,
  patchTemplateVariable,
  postTemplateVariable,
  searchTemplateVariables,
} from 'api/Requests';
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const templateVariableActions = {
  setLoading: actionCreator<boolean>('SET_TEMPLATE_VARIABLE_LOADING'),
  setCurrentValue: actionCreator<TemplateVariable>('SET_TEMPLATE_VARIABLE_CURRENT_VALUE'),
  setFetchOption: actionCreator<{ limit: number; page: number }>(
    'SET_TEMPLATE_VARIABLE_FETCH_OPTION'
  ),
  setSearchParams: actionCreator<{
    fetchOption: {
      limit: number;
      page: number;
    };
    searchParams: Record<string, string>;
  }>('SET_TEMPLATE_VARIABLE_SEARCH_PARAMS'),
  getTemplateVariable: actionCreator.async<
    RequestGetTemplateVariable,
    CommonAPIResponse<CommonFetchResponse<Array<TemplateVariable>>>
  >('GET_TEMPLATE_VARIABLE'),
  searchTemplateVariable: actionCreator.async<
    RequestSearchCommon,
    CommonAPIResponse<CommonFetchResponse<Array<TemplateVariable>>>
  >('SEARCH_TEMPLATE_VARIABLE'),
  addTemplateVariable: actionCreator.async<
    RequestPostTemplateVariable,
    CommonAPIResponse<TemplateVariable>
  >('ADD_TEMPLATE_VARIABLE'),
  updateTemplateVariable: actionCreator.async<
    RequestPatchTemplateVariable,
    CommonAPIResponse<TemplateVariable>
  >('UPDATE_TEMPLATE_VARIABLE'),
  deleteTemplateVariable: actionCreator.async<RequestDeleteCommon, AxiosResponse>(
    'DELETE_TEMPLATE_VARIABLE'
  ),
};

/** テンプレート変数取得 */
export const getTemplateVariableAction = () => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const fetchOption = getState().templateVariable.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    // TODO: Add search params
    const params: RequestGetTemplateVariable = {
      limit: fetchOption.limit,
      page: fetchOption.page,
    };
    dispatch(templateVariableActions.getTemplateVariable.started(params));
    if (accessToken) {
      return execRequest(fetchTemplateVariables, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              templateVariableActions.getTemplateVariable.done({ params: params, result: res.data })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            templateVariableActions.getTemplateVariable.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

/** テンプレート変数検索 */
export const searchTemplateVariableAction = (query: string, queryOr: null | string = null) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const fetchOption = getState().templateVariable.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestSearchCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
      q: query,
    };
    if (queryOr !== null) {
      params.q_or = queryOr;
    }

    dispatch(templateVariableActions.searchTemplateVariable.started(params));
    if (accessToken) {
      return execRequest(searchTemplateVariables, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              templateVariableActions.searchTemplateVariable.done({
                params: params,
                result: res.data,
              })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            templateVariableActions.searchTemplateVariable.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

/** テンプレート変数追加 */
export const addTemplateVariableAction = (params: RequestPostTemplateVariable) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(templateVariableActions.addTemplateVariable.started(params));

    if (accessToken) {
      return execRequest(postTemplateVariable, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              templateVariableActions.addTemplateVariable.done({ params: params, result: res.data })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            templateVariableActions.addTemplateVariable.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

/** テンプレート変数更新 */
export const updateTemplateVariableAction = (params: RequestPatchTemplateVariable) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(templateVariableActions.updateTemplateVariable.started(params));

    if (accessToken) {
      return execRequest(patchTemplateVariable, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              templateVariableActions.updateTemplateVariable.done({
                params: params,
                result: res.data,
              })
            );
            return res.data;
          }
        })
        .catch((e) => {
          dispatch(
            templateVariableActions.updateTemplateVariable.failed({ params: params, error: e })
          );
          throw e;
        });
    }
  };
};

/** テンプレート変数削除 */
export const deleteTemplateVariableAction = (id: number) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<void | AxiosResponse> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestDeleteCommon = {
      id: id,
    };
    dispatch(templateVariableActions.deleteTemplateVariable.started(params));
    if (accessToken) {
      return execRequest(deleteTemplateVariable, params, accessToken, signal.token)
        .then((res) => {
          if (res) {
            dispatch(
              templateVariableActions.deleteTemplateVariable.done({ params: params, result: res })
            );
            return res;
          }
        })
        .catch((e) => {
          dispatch(
            templateVariableActions.deleteTemplateVariable.failed({ params: params, error: e })
          );
          throw e;
        });
    }
    return Promise.reject(accessToken);
  };
};
