import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Redirect } from 'react-router-dom';

const UnbAuthLogoutCallback: React.FC = () => {
  const authService = useSelector((state: AppState) => state.app.authService);
  useEffect(() => {
    authService.signoutRedirectCallback();
  }, []);

  return <Redirect to={'/'} />;
};

export default UnbAuthLogoutCallback;
