/** @fileOverview 会話タスクアクション */
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestGetCommon from 'api/models/requests/RequestGetCommon';
import RequestSearchCommon from 'api/models/requests/RequestSearchCommon';
import RequestTask from 'api/models/requests/RequestTask';
import { Task } from 'api/models/Task';
import { deleteTask, execRequest, fetchTask, patchTask, postTask, searchTask } from 'api/Requests';
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const taskActions = {
  setLoading: actionCreator<boolean>('SET_TASK_LOADING'),
  setCurrentValue: actionCreator<Task>('SET_TASK_CURRENT_VALUE'),
  setFetchOption: actionCreator<{ limit: number; page: number }>('SET_TASK_FETCH_OPTION'),
  setSearchParams: actionCreator<{
    fetchOption: {
      limit: number;
      page: number;
    };
    searchParams: Record<string, string | number>;
  }>('SET_TASK_FETCH_OPTION_AND_SEARCH_PARAMS'),
  getTask: actionCreator.async<
    RequestGetCommon,
    CommonAPIResponse<CommonFetchResponse<Array<Task>>>
  >('GET_TASK'),
  searchTask: actionCreator.async<
    RequestSearchCommon,
    CommonAPIResponse<CommonFetchResponse<Array<Task>>>
  >('SEARCH_TASK'),
  addTask: actionCreator.async<RequestTask, CommonAPIResponse<Task>>('ADD_TASK'),
  updateTask: actionCreator.async<RequestTask, CommonAPIResponse<Task>>('UPDATE_TASK'),
  deleteTask: actionCreator.async<RequestDeleteCommon, AxiosResponse>('DELETE_TASK'),
};

export const getTaskAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const fetchOption = getState().task.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestGetCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
    };

    dispatch(taskActions.getTask.started(params));
    return execRequest(fetchTask, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(taskActions.getTask.done({ params: params, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(taskActions.getTask.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const addTaskAction = (params: RequestTask) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(taskActions.addTask.started(params));
    return execRequest(postTask, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(taskActions.addTask.done({ params: params, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(taskActions.addTask.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const updateTaskAction = (params: RequestTask) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(taskActions.updateTask.started(params));
    return execRequest(patchTask, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(taskActions.updateTask.done({ params: params, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(taskActions.updateTask.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const deleteTaskAction = (id: number) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestDeleteCommon = {
      id: id,
    };
    dispatch(taskActions.deleteTask.started(params));
    return execRequest(deleteTask, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(taskActions.deleteTask.done({ params: params, result: res }));
          return res;
        }
      })
      .catch((e) => {
        dispatch(taskActions.deleteTask.failed({ params: params, error: e }));
        throw e;
      });
  };
};

export const searchTaskAction = (query: string, queryOr: null | string = null) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const fetchOption = getState().task.fetchOption;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const params: RequestSearchCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
      q: query,
    };
    if (queryOr !== null) {
      params.q_or = queryOr;
    }

    dispatch(taskActions.searchTask.started(params));
    return execRequest(searchTask, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(taskActions.searchTask.done({ params: params, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(taskActions.searchTask.failed({ params: params, error: e }));
        throw e;
      });
  };
};
