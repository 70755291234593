import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

type UnbSwitchLabelProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  label: string;
};

const UnbSwitchLabel: React.FC<UnbSwitchLabelProps> = (props) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={props.isChecked}
            onChange={props.onChange}
            name="privateOnly"
            color="primary"
          />
        }
        label={props.label}
        labelPlacement={'start'}
      />
    </div>
  );
};

export default UnbSwitchLabel;
