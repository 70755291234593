export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL + '/oauth2/auth', //(string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_AUTH_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_PUBLIC_URL + '/authentication/callback', //The URI of your client application to receive a response from the OIDC provider.
  login: process.env.REACT_APP_AUTH_URL + '/oauth2/login',
  automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: process.env.REACT_APP_PUBLIC_URL + '/authentication/silent_callback', //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: process.env.REACT_APP_PUBLIC_URL, // (string): The OIDC post-logout redirect URI.
  responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTH_SCOPE, //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: process.env.REACT_APP_AUTH_WEB_RESPONSE_TYPE,
};

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_AUTH_URL,
  jwks_uri: process.env.REACT_APP_AUTH_URL + '/.well-known/keys.json',
  authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth2/auth',
  token_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth2/token',
  userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/userinfo',
  end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth2/sessions/logout',
  check_session_iframe: process.env.REACT_APP_AUTH_URL + '/connect/checksession',
  revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth2/revoke',
  introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/oauth2/introspect',
};
