/** @fileOverview 会話データフロー図ダイアログ */
import DialogContent from '@material-ui/core/DialogContent';
import { ChatRule } from 'api/models/ChatRule';
import { ScenarioNode, ScenarioRelation } from 'api/models/ScenarioRelationships';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { chatRuleActions, getScenarioRelationshipsAction } from 'store/domains/chat-rule/action';
import { getNoticeErrorMessage, NoticeMessages } from 'constants/Messages';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { isEmptyObject } from 'utils/ObjectUtil';
import UnbCircularProgressForRightSide from 'components/molecules/UnbCircularProgressForRightSide';
import UnbScenarioRelationshipsNetwork from 'components/organisms/UnbScenarioRelationshipsNetwork';
import { Edge, Node } from 'vis-network';

type UnbChatRuleDialogContentForNetworkProps = {
  dividers?: boolean;
  chatRule: ChatRule;
};

/**
 * シナリオフロー図のダイアログ
 *
 * @component
 */
const UnbChatRuleDialogContentForNetwork: React.FC<UnbChatRuleDialogContentForNetworkProps> = (
  props
) => {
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const relationships = useSelector((state: AppState) => state.chatRule.relationships);
  const [loading, setLoading] = useState(false);
  const [visNodes, setVisNodes] = useState<Array<Node>>([]);
  const [visEdges, setVisEdges] = useState<Array<Edge>>([]);

  const rootRuleId = JSON.parse(JSON.stringify(props.chatRule)).rule_id;

  const fetchScenarioRelationships = async (rule_id: string): Promise<void> => {
    setLoading(true);
    try {
      await asyncDispatch(getScenarioRelationshipsAction(rule_id));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const axiosErr = e as AxiosError;
      console.log(axiosErr);
      const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_GET_RELATION);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const convertToVisNodes = (
    scenarioNodes: Array<ScenarioNode>,
    rootRuleId: string
  ): Array<Node> => {
    const visNodes = scenarioNodes.map((scenarioNode) => {
      let visNode: Node;
      if (scenarioNode.rule_id === rootRuleId) {
        visNode = {
          id: scenarioNode.rule_id,
          label: scenarioNode.rule_id,
          color: {
            background: '#c6e2ff',
            border: '#1976d2',
            highlight: {
              background: '#c6e2ff',
              border: '#1976d2',
            },
            hover: {
              background: '#c6e2ff',
              border: '#1976d2',
            },
          },
        };
        return visNode;
      } else {
        visNode = {
          id: scenarioNode.rule_id,
          label: scenarioNode.rule_id,
          color: '#ffffff',
        };
        return visNode;
      }
    });
    return visNodes;
  };

  const convertToVisEdges = (scenarioRelations: Array<ScenarioRelation>): Array<Edge> => {
    const visEdges = scenarioRelations.map((scenarioRelation) => {
      const visEdge: Edge = {
        id: scenarioRelation.id,
        from: scenarioRelation.source,
        to: scenarioRelation.target,
      };
      return visEdge;
    });
    return visEdges;
  };

  useEffect(() => {
    if (isEmptyObject(relationships)) {
      fetchScenarioRelationships(rootRuleId);
    } else {
      setVisNodes(convertToVisNodes(relationships.nodes, rootRuleId));
      setVisEdges(convertToVisEdges(relationships.relations));
    }
  }, [relationships]);

  useEffect(() => {
    return (): void => {
      dispatch(chatRuleActions.clearScenarioRelationships({}));
    };
  }, []);

  return (
    <DialogContent dividers={props.dividers}>
      {loading ? (
        <UnbCircularProgressForRightSide open={loading} />
      ) : (
        <UnbScenarioRelationshipsNetwork
          nodes={visNodes}
          edges={visEdges}
          rootRuleId={rootRuleId}
        />
      )}
    </DialogContent>
  );
};

export default UnbChatRuleDialogContentForNetwork;
