import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { UnbMenuItemProps } from 'components/atoms/UnbMenuItem';

export type UnbMenuProps = {
  id: string;
  anchorEl?: null | HTMLElement;
  items: UnbMenuItemProps[];
  onCloseHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const UnbMenu: React.FC<UnbMenuProps> = (props) => {
  const open = Boolean(props.anchorEl);

  const items = props.items.map((p) => (
    <MenuItem
      key={p.text}
      onClick={(e): void => {
        if (p.onClickHandlerForParent) {
          p.onClickHandlerForParent(p.action);
        }
        if (p.onClickHandler) {
          p.onClickHandler(p.action);
        }
      }}
    >
      {p.text}
    </MenuItem>
  ));

  return (
    <Menu
      id={props.id}
      anchorEl={props.anchorEl}
      getContentAnchorEl={null} // メニューをボタンの下に表示するための設定。参考：https://ja.coder.work/so/reactjs/199822
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={props.onCloseHandler}
      open={open}
    >
      {items}
    </Menu>
  );
};

export default UnbMenu;
