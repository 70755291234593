import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import UnbRenderLink from 'components/molecules/UnbRenderLink';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { uiDrawerActions } from 'store/uis/drawer/action';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'white',
    },
    toolbar: theme.mixins.toolbar,
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const UnbDrawer: React.FC = () => {
  const classes = useStyles();
  const [openScenario, setOpenScenario] = React.useState(false);
  const [openTools, setOpenTools] = React.useState(false);

  const dispatch = useDispatch();
  const selected = useSelector((state: AppState) => state.uiDrawer.selected);

  const handleClickScenario = (): void => {
    setOpenScenario(!openScenario);
  };
  const handleClickFlow = (): void => {
    setOpenTools(!openTools);
  };

  const handleSelect = (index: number): void => {
    dispatch(uiDrawerActions.setSelectedItem(index));
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem button onClick={handleClickScenario}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary="シナリオ" />
          {openScenario ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openScenario} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={(): void => handleSelect(0)}
              selected={selected === 0}
              className={classes.nested}
              component={UnbRenderLink({ to: '/scenario/chat-rule' })}
            >
              <ListItemText primary="会話データ" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={handleClickFlow}>
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText primary="ツール" />
          {openTools ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openTools} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={(): void => handleSelect(6)}
              selected={selected === 6}
              className={classes.nested}
              component={UnbRenderLink({ to: '/tools/chat' })}
            >
              <ListItemText primary="チャットテスト" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default UnbDrawer;
