export type SurfaceCaseEnum =
  | 'が'
  | 'を'
  | 'に'
  | 'から'
  | 'へ'
  | 'と'
  | 'より'
  | 'まで'
  | 'で'
  | 'の'
  | 'にて';
export const SurfaceCaseEnum = {
  GA: 'が' as SurfaceCaseEnum,
  WO: 'を' as SurfaceCaseEnum,
  NI: 'に' as SurfaceCaseEnum,
  KARA: 'から' as SurfaceCaseEnum,
  HE: 'へ' as SurfaceCaseEnum,
  TO: 'と' as SurfaceCaseEnum,
  YORI: 'より' as SurfaceCaseEnum,
  MADE: 'まで' as SurfaceCaseEnum,
  DE: 'で' as SurfaceCaseEnum,
  NO: 'の' as SurfaceCaseEnum,
  NITE: 'にて' as SurfaceCaseEnum,
};
