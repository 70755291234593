export const Commands = [
  'anniversary',
  'art_beat',
  'birthstone',
  'bmi',
  'call_function',
  'call_http',
  'datetime',
  'dinner_recommendation_from_foodstuff',
  'ekispert',
  'extraction',
  'geo_ranking',
  'hanakotoba',
  'hatena_news',
  'image_view',
  'initialization',
  'keep_web_view',
  'meal',
  'mealthy_menu',
  'memory',
  'menu_recommendation',
  'menu_recommendation_retry',
  'new_reply',
  'nod',
  'partially_deletion',
  'place_with_postal_code',
  'qr_code',
  'quiz',
  'region_datetime',
  'repeat',
  'schedule',
  'seasonal_food',
  'seasonal_food_month',
  'shiritori',
  'specified_period_value',
  'switching_topic',
  'system_birthday',
  'time_remaining',
  'tmdb_movie',
  'tour_spot',
  'tour_spot_genre_query',
  'tour_spot_place_query',
  'tour_spot_retry',
  'tv_program',
  'unibo_function_guide',
  'unknown_question',
  'weather_skillpack',
  'weather_yumake',
  'web_view',
  'wikipedia',
  'wikipedia_pre_search',
  'yolp',
  'youtube',
];
