import actionCreatorFactory from 'typescript-fsa';
import { ChatReply } from 'api/models/ChatReply';

const actionCreator = actionCreatorFactory();

export const uiChatToolsActions = {
  setLoading: actionCreator<boolean>('SET_UI_CHAT_TOOLS_LOADING'),
  setPrivateOnly: actionCreator<boolean>('SET_UI_CHAT_TOOLS_PRIVATE_ONLY'),
  setConversationValue: actionCreator<string>('SET_CHAT_TOOLS_CONVERSATION_VALUE'),
  setSelectedReply: actionCreator<ChatReply>('SET_CHAT_TOOLS_SELECTED_REPLY'),
};
