/** @fileOverview マスター ノイズ定義コンポーネント */
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { WordMaster } from 'api/models/WordMaster';
import UnbStyledTableCell from 'components/atoms/UnbStyledTableCell';
import UnbStyledTableRow from 'components/atoms/UnbStyledTableRow';
import { MasterWordsFormData } from 'components/form/UnbMasterWordsForms';
import UnbCircularProgress from 'components/molecules/UnbCircularProgress';
import UnbEditDialogActions, { Action } from 'components/organisms/UnbEditDialogActions';
import UnbTableToolbar from 'components/organisms/UnbTableToolbar';
import UnbWordMasterDialogContent from 'components/organisms/UnbWordMasterDialogContent';
import UnbWordMasterHeadBar from 'components/organisms/UnbWordMasterHeadBar';
import UnbSimpleAlertDialog from 'components/templates/UnbSimpleAlertDialog';
import { getNoticeErrorMessage, LabelText, NoticeMessages } from 'constants/Messages';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  addWordMasterAction,
  deleteWordMasterAction,
  getWordMasterAction,
  masterWordsActions,
  updateWordMasterAction,
} from 'store/domains/master-word/action';
import { setError } from 'utils/ComponentUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '80vh',
      overflowX: 'auto',
    },
    container: {
      maxHeight: '60vh',
    },
  })
);

interface Column {
  id: 'word_master_id' | 'value' | 'updated_at' | 'created_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'word_master_id', label: 'ID', minWidth: 100 },
  { id: 'value', label: 'Noise', minWidth: 170 },
  {
    id: 'updated_at',
    label: 'Updated At',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'created_at',
    label: 'Created At',
    minWidth: 170,
    align: 'right',
  },
];

const MasterWordsComponent: React.FC = () => {
  const classes = useStyles();
  const themes = useTheme();
  const [openDialog, setDialog] = React.useState(false);
  const [openAlertDialog, setAlertDialog] = React.useState(false);
  const [closeAlertDialog, setCloseAlertDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [displayDelete, setDisplayDelete] = useState(true);
  const [okBtnText, setOkBtnText] = useState(LabelText.UPDATE_LABEL);
  const [action, setAction] = useState(Action.UPDATE);

  const methods = useForm<MasterWordsFormData>({ mode: 'onChange' });
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const loading = useSelector((state: AppState) => state.masterWords.loading);
  const fetchOption = useSelector((state: AppState) => state.masterWords.fetchOption);
  const currentValues = useSelector((state: AppState) => state.masterWords.currentValues);
  const rows = useSelector((state: AppState) => state.masterWords.rows);
  const count = useSelector((state: AppState) => state.masterWords.count);

  const handleChangePage = useCallback(
    (event: unknown, newPage: number): void => {
      console.log('handleChangePage');
      fetchOption.page = newPage;
      dispatch(masterWordsActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      console.log('handleChangeRowsPerPage');
      fetchOption.limit = +event.target.value;
      fetchOption.page = 0;
      dispatch(masterWordsActions.setFetchOption(fetchOption));
    },
    [fetchOption]
  );

  const handleClickRow = (key: number, scrollType: DialogProps['scroll']): void => {
    const target = rows.find((r: WordMaster) => r.word_master_id === key);
    if (target !== undefined && target !== null) {
      currentValues.noiseValue = target.value === undefined ? '' : target.value;
      currentValues.wordMasterID = target.word_master_id === undefined ? 0 : target.word_master_id;
      dispatch(masterWordsActions.setCurrentValues(currentValues));
    }
    setAction(Action.UPDATE);
    setDisplayDelete(true);
    setOkBtnText(LabelText.UPDATE_LABEL);
    setDialog(true);
    setScroll(scrollType);
  };

  const onSubmit = useCallback(
    async (data: MasterWordsFormData): Promise<void> => {
      dispatch(masterWordsActions.setLoading(true));
      switch (action) {
        case Action.UPDATE:
          try {
            await asyncDispatch(updateWordMasterAction(data.noise));
            setDialog(false);
            enqueueSnackbar(NoticeMessages.COMPLETE_UPDATE, { variant: 'success' });
          } catch (e) {
            setError(e, methods);
            const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_UPDATE);
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(masterWordsActions.setLoading(false));
          }
          break;
        case Action.ADD:
          try {
            await asyncDispatch(addWordMasterAction(data.noise));
            setDialog(false);
            enqueueSnackbar(NoticeMessages.COMPLETE_ADD, { variant: 'success' });
          } catch (e) {
            setError(e, methods);
            const message = getNoticeErrorMessage(e, NoticeMessages.ERROR_ADD);
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(masterWordsActions.setLoading(false));
          }
          break;
        default:
          return;
      }
    },
    [action, loading, openDialog]
  );

  const handleClose = (): void => {
    setCloseAlertDialog(true);
    methods.clearError();
  };

  const handleCloseOk = (): void => {
    setCloseAlertDialog(false);
    setDialog(false);
  };

  const handleCloseCancel = (): void => {
    setCloseAlertDialog(false);
  };

  const handleOk = (): void => {
    // nop
  };

  const handleCancel = (): void => {
    setCloseAlertDialog(true);
  };

  const handleAlertOk = async (): Promise<void> => {
    dispatch(masterWordsActions.setLoading(true));
    setAlertDialog(false);

    try {
      await asyncDispatch(deleteWordMasterAction());
      enqueueSnackbar(NoticeMessages.COMPLETE_DELETE, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(NoticeMessages.ERROR_DELETE, { variant: 'error' });
    } finally {
      dispatch(masterWordsActions.setLoading(false));
    }
  };

  const handleAlertCancel = (): void => {
    setAlertDialog(false);
  };

  const handleDelete = (): void => {
    setDialog(false);
    setAlertDialog(true);
  };

  const handleAdd = async (): Promise<void> => {
    setAction(Action.ADD);
    currentValues.noiseValue = '';
    currentValues.wordMasterID = 0;
    dispatch(masterWordsActions.setCurrentValues(currentValues));
    setOkBtnText(LabelText.ADD_LABEL);
    setDisplayDelete(false);
    setDialog(true);
  };

  const handleSearch = useCallback(
    async (data: Record<string, any>): Promise<void> => {
      console.log('handleSearch');
      currentValues.searchValue = data.search;
      fetchOption.page = 0;
      dispatch(
        masterWordsActions.setSearchParams({
          currentValues: currentValues,
          fetchOption: fetchOption,
        })
      );
    },
    [loading, fetchOption]
  );

  useEffect(() => {
    const fn = async (): Promise<void> => {
      //console.log('call useEffect');
      fetchData();
      const t = document.getElementsByClassName('MuiTableContainer-root')[0];
      if (t) {
        t.scrollTop = 0;
      }
    };
    fn();
  }, [fetchOption]);

  const fetchData = async (): Promise<void> => {
    dispatch(masterWordsActions.setLoading(true));
    try {
      asyncDispatch(getWordMasterAction());
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(masterWordsActions.setLoading(false));
    }
  };

  const renderDialog = useMemo(
    () => (
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
        scroll={scroll}
      >
        <DialogTitle id="form-dialog-title">ノイズ定義</DialogTitle>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <UnbWordMasterDialogContent dividers noiseValue={currentValues.noiseValue} />
          <UnbEditDialogActions
            okBtnColor="primary"
            cancelBtnColor="primary"
            okBtnText={okBtnText}
            cancelBtnText="キャンセル"
            okHandler={handleOk}
            cancelHandler={handleCancel}
            deleteBtnColor="secondary"
            deleteBtnText="削除"
            deleteHandler={handleDelete}
            isDisplayDelete={displayDelete}
          />
        </form>
      </Dialog>
    ),
    [openDialog, scroll, currentValues, okBtnText, displayDelete]
  );

  const renderAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleAlertOk}
        okBtnText={LabelText.ALERT_OK_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleAlertCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_DELETE]}
        title={LabelText.CONFIRM}
        open={openAlertDialog}
      />
    ),
    [openAlertDialog]
  );

  const renderCloseAlertDialog = useMemo(
    () => (
      <UnbSimpleAlertDialog
        okHandler={handleCloseOk}
        okBtnText={LabelText.CLOSE_LABEL}
        okBtnColor={'primary'}
        cancelHandler={handleCloseCancel}
        cancelBtnText={LabelText.ALERT_CANCEL_LABEL}
        cancelBtnColor={'primary'}
        textValue={[LabelText.CONFIRM_CLOSE]}
        title={LabelText.CONFIRM}
        open={closeAlertDialog}
      />
    ),
    [closeAlertDialog]
  );

  const renderTable = useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <UnbStyledTableCell
                  backgroundColor={'white'}
                  color={'black'}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </UnbStyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: WordMaster, i: number) => {
              return (
                <UnbStyledTableRow
                  backgroundColor={themes.palette.grey['50']}
                  color={'black'}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={i}
                  onClick={(): void => {
                    if (row.word_master_id) {
                      handleClickRow(row.word_master_id, 'paper');
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        <div>{value}</div>
                      </TableCell>
                    );
                  })}
                </UnbStyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [rows]
  );

  const renderPagination = useMemo(
    () => (
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        labelRowsPerPage={'ページ毎の件数'}
        component="div"
        count={count}
        rowsPerPage={fetchOption.limit}
        page={fetchOption.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ),
    [count, fetchOption]
  );

  const renderLoading = useMemo(() => <UnbCircularProgress open={loading} />, [loading]);

  return (
    <div>
      <FormContext {...methods}>
        <UnbWordMasterHeadBar handleSearch={handleSearch} />
        <Paper className={classes.root}>
          <UnbTableToolbar title={'ノイズ一覧'} onClickAdd={handleAdd} />
          {renderDialog}
          {renderAlertDialog}
          {renderCloseAlertDialog}
          {renderTable}
          {renderPagination}
        </Paper>
        {loading && renderLoading}
      </FormContext>
    </div>
  );
};

export default MasterWordsComponent;
