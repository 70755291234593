/** @fileOverview 条件グループレデューサー */
import { RuleConditionGroup } from 'api/models/RuleConditionGroup';
import { ruleConditionGroupActions } from 'store/domains/rule-condition-group/action';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export type RuleConditionGroupState = {
  rows: Array<RuleConditionGroup>;
  count: number;
  fetchOption: {
    limit: number;
    page: number;
  };
  loading: boolean;
  error?: void;
  currentValue: RuleConditionGroup;
  searchParams: Record<string, string>;
};

export const initialState: RuleConditionGroupState = {
  fetchOption: {
    limit: 20,
    page: 0,
  },
  rows: [],
  count: 0,
  loading: false,
  currentValue: {} as RuleConditionGroup,
  searchParams: {
    value: '',
  } as Record<string, string>,
};

export const ruleConditionGroupReducer = reducerWithInitialState(initialState)
  .case(ruleConditionGroupActions.setLoading, (state, payload) => {
    return {
      ...state,
      loading: payload,
    };
  })
  .case(ruleConditionGroupActions.setCurrentValue, (state, payload) => {
    return {
      ...state,
      currentValue: payload,
    };
  })
  .case(ruleConditionGroupActions.setFetchOption, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.limit,
        page: payload.page,
      },
    };
  })
  // 検索設定
  .case(ruleConditionGroupActions.setSearchParams, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.fetchOption.limit,
        page: payload.fetchOption.page,
      },
      searchParams: payload.searchParams,
    };
  })
  .case(ruleConditionGroupActions.getRuleConditionGroup.started, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.getRuleConditionGroup.failed, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.getRuleConditionGroup.done, (state, payload) => {
    //console.log(JSON.stringify(payload.result));
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(ruleConditionGroupActions.searchRuleConditionGroup.started, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.searchRuleConditionGroup.failed, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.searchRuleConditionGroup.done, (state, payload) => {
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(ruleConditionGroupActions.addRuleConditionGroup.started, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.addRuleConditionGroup.failed, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.addRuleConditionGroup.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows;
    const newRows = rows.concat([result]);
    return {
      ...state,
      rows: newRows,
    };
  })
  .case(ruleConditionGroupActions.updateRuleConditionGroup.started, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.updateRuleConditionGroup.failed, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.updateRuleConditionGroup.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows.slice();
    const index = rows.findIndex(
      (r: RuleConditionGroup) => r.rule_condition_group_id === result.rule_condition_group_id
    );
    rows[index] = result;
    return {
      ...state,
      rows: rows,
    };
  })
  .case(ruleConditionGroupActions.deleteRuleConditionGroup.started, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.deleteRuleConditionGroup.failed, (state, payload) => {
    return state;
  })
  .case(ruleConditionGroupActions.deleteRuleConditionGroup.done, (state, payload) => {
    const id = payload.params.id;
    const deletedRows = state.rows.filter(
      (r: RuleConditionGroup) => r.rule_condition_group_id !== id
    );
    return {
      ...state,
      rows: deletedRows,
    };
  });
