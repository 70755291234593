import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LabelText } from 'constants/Messages';
import { uiRuleConditionGroupActions } from 'store/uis/rule-condition-group/action';

export type UIRuleConditionGroupState = {
  error?: any;
  openAlertDialog: boolean;
  openDialog: boolean;
  okBtnText: string;
  displayDelete: boolean;
  action?: 'ADD' | 'UPDATE';
  isDisplayCopyBtn: boolean;
  openCopyAlertDialog: boolean;
};

export const initialState: UIRuleConditionGroupState = {
  openAlertDialog: false,
  openDialog: false,
  okBtnText: LabelText.UPDATE_LABEL,
  displayDelete: false,
  isDisplayCopyBtn: false,
  openCopyAlertDialog: false,
};

export const uiRuleConditionGroupReducer = reducerWithInitialState(initialState)
  .case(uiRuleConditionGroupActions.setOpenCopyAlertDialog, (state, payload) => {
    return {
      ...state,
      openCopyAlertDialog: payload,
    };
  })
  .case(uiRuleConditionGroupActions.setIsDisplayCopyBtn, (state, payload) => {
    return {
      ...state,
      isDisplayCopyBtn: payload,
    };
  })
  .case(uiRuleConditionGroupActions.setOkBtnText, (state, payload) => {
    return {
      ...state,
      okBtnText: payload,
    };
  })
  .case(uiRuleConditionGroupActions.setOpenDialog, (state, payload) => {
    return {
      ...state,
      openDialog: payload,
    };
  })
  .case(uiRuleConditionGroupActions.setOpenAlertDialog, (state, payload) => {
    return {
      ...state,
      openAlertDialog: payload,
    };
  })
  .case(uiRuleConditionGroupActions.setDisplayDelete, (state, payload) => {
    return {
      ...state,
      displayDelete: payload,
    };
  })
  .case(uiRuleConditionGroupActions.setAction, (state, payload) => {
    return {
      ...state,
      action: payload,
    };
  });
