/**
 * @fileOverview 会話データAPI
 * nlp-server API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReplyCommand } from 'api/models/ReplyCommand';
import { TestResult } from 'api/models/TestResult';
import { StateAction } from 'api/models/StateAction';

/** 会話データ */
export interface ChatRule {
  /**
   * 識別子
   */
  chat_rule_id?: number;
  /**
   * 会話の開始フラグ
   */
  starting_flag?: boolean;
  /**
   * シナリオタイプ
   */
  rule_type?: RuleTypeEnum;
  /**
   * 発火条件
   */
  decision_condition?: string;
  /**
   * 発火コマンド
   */
  decision_command?: string;
  /**
   * コマンドサマリ
   */
  command_summary?: string;
  /**
   * シナリオの一意のルールID
   */
  rule_id?: string;
  /**
   * シナリオの一意のルールID
   */
  project_id?: string;
  /**
   * 発火優先度
   */
  weight?: number;
  /**
   * 前のシナリオのルールID
   */
  before_rule_id?: Array<string>;
  /**
   * 次のシナリオのルールID
   */
  next_rule_id?: Array<string>;
  /**
   * 返答内容
   */
  reply?: string;
  /**
   * 返答コマンドのID
   */
  reply_command_id?: number;
  /**
   * functionコード
   */
  function_code?: string;
  /**
   * actionコード
   */
  action_code?: ActionCodeEnum;
  /**
   * 表情コード
   */
  facial_expression_code?: FacialExpressionCodeEnum;
  /**
   * システムの感情値
   */
  system_sentiment?: string;
  /**
   * ユーザの感情値
   */
  user_sentiment?: string;
  /**
   * 状態変更情報
   */
  state_actions?: Array<StateAction>;
  /**
   * テンプレートのID
   */
  template_ids?: Array<string>;
  /**
   * リセット条件
   */
  template_variable_reset_condition?: string;
  /**
   * 発火間隔
   */
  next_period?: string;
  /**
   * タスク名
   */
  task_name?: string;
  /**
   * 質問
   */
  qa_query?: Array<string>;
  /**
   * プロセスタイプ
   */
  proc_type?: ProcTypeEnum;
  /**
   * should_keep_user
   */
  should_keep_user?: boolean;
  /**
   * テスト結果ID
   */
  test_result_id?: number;
  reply_command?: ReplyCommand;
  test_result?: TestResult;
  /**
   * 登録日付
   */
  created_at?: string;
  /**
   * 更新日付
   */
  updated_at?: string;
}

export type RuleTypeEnum =
  | 'reply'
  | 'state'
  | 'sys_qa'
  | 'user_qa'
  | 'pre_linkage'
  | 'post_linkage'
  | 'chatting'
  | 'request'
  | 'anniversary';
export const RuleTypeEnum = {
  Reply: 'reply' as RuleTypeEnum,
  State: 'state' as RuleTypeEnum,
  SysQa: 'sys_qa' as RuleTypeEnum,
  UserQa: 'user_qa' as RuleTypeEnum,
  PreLinkage: 'pre_linkage' as RuleTypeEnum,
  PostLinkage: 'post_linkage' as RuleTypeEnum,
  Chatting: 'chatting' as RuleTypeEnum,
};
export type ActionCodeEnum =
  | 'action_1'
  | 'action_2'
  | 'action_3'
  | 'action_4'
  | 'action_5'
  | 'action_6'
  | 'action_7'
  | 'action_8'
  | 'action_9'
  | 'action_10'
  | 'action_11'
  | 'action_12'
  | 'action_13'
  | 'action_14'
  | 'action_15'
  | 'action_16'
  | 'action_17'
  | 'action_18'
  | 'action_19'
  | 'action_20'
  | 'action_21'
  | 'action_22'
  | 'action_23'
  | 'action_24'
  | 'action_25'
  | 'action_26'
  | 'action_27'
  | 'action_28'
  | 'action_29'
  | 'action_30'
  | 'action_31'
  | 'action_32'
  | 'homepos'
  | 'left_arm_front';
export const ActionCodeEnum = {
  Action1: 'action_1' as ActionCodeEnum,
  Action2: 'action_2' as ActionCodeEnum,
  Action3: 'action_3' as ActionCodeEnum,
  Action4: 'action_4' as ActionCodeEnum,
  Action5: 'action_5' as ActionCodeEnum,
  Action6: 'action_6' as ActionCodeEnum,
  Action7: 'action_7' as ActionCodeEnum,
  Action8: 'action_8' as ActionCodeEnum,
  Action9: 'action_9' as ActionCodeEnum,
  Action10: 'action_10' as ActionCodeEnum,
  Action11: 'action_11' as ActionCodeEnum,
  Action12: 'action_12' as ActionCodeEnum,
  Action13: 'action_13' as ActionCodeEnum,
  Action14: 'action_14' as ActionCodeEnum,
  Action15: 'action_15' as ActionCodeEnum,
  Action16: 'action_16' as ActionCodeEnum,
  Action17: 'action_17' as ActionCodeEnum,
  Action18: 'action_18' as ActionCodeEnum,
  Action19: 'action_19' as ActionCodeEnum,
  Action20: 'action_20' as ActionCodeEnum,
  Action21: 'action_21' as ActionCodeEnum,
  Action22: 'action_22' as ActionCodeEnum,
  Action23: 'action_23' as ActionCodeEnum,
  Action24: 'action_24' as ActionCodeEnum,
  Action25: 'action_25' as ActionCodeEnum,
  Action26: 'action_26' as ActionCodeEnum,
  Action27: 'action_27' as ActionCodeEnum,
  Action28: 'action_28' as ActionCodeEnum,
  Action29: 'action_29' as ActionCodeEnum,
  Action30: 'action_30' as ActionCodeEnum,
  Action31: 'action_31' as ActionCodeEnum,
  Action32: 'action_32' as ActionCodeEnum,
  Homepos: 'homepos' as ActionCodeEnum,
  LeftArmFront: 'left_arm_front' as ActionCodeEnum,
};
export type FacialExpressionCodeEnum =
  | 'EYES_JOY'
  | 'EYES_SADNESS'
  | 'EYES_TRUST'
  | 'EYES_DISGUST'
  | 'EYES_FEAR'
  | 'EYES_ANGER'
  | 'EYES_SURPRISE'
  | 'EYES_ANTICIPATION'
  | 'EYES_SLEEP'
  | 'EYES_NORMAL';
export const FacialExpressionCodeEnum = {
  JOY: 'EYES_JOY' as FacialExpressionCodeEnum,
  SADNESS: 'EYES_SADNESS' as FacialExpressionCodeEnum,
  TRUST: 'EYES_TRUST' as FacialExpressionCodeEnum,
  DISGUST: 'EYES_DISGUST' as FacialExpressionCodeEnum,
  FEAR: 'EYES_FEAR' as FacialExpressionCodeEnum,
  ANGER: 'EYES_ANGER' as FacialExpressionCodeEnum,
  SURPRISE: 'EYES_SURPRISE' as FacialExpressionCodeEnum,
  ANTICIPATION: 'EYES_ANTICIPATION' as FacialExpressionCodeEnum,
  SLEEP: 'EYES_SLEEP' as FacialExpressionCodeEnum,
  NORMAL: 'EYES_NORMAL' as FacialExpressionCodeEnum,
};
export type ProcTypeEnum = 'PRE' | 'POST' | 'NORMAL';
export const ProcTypeEnum = {
  PRE: 'PRE' as ProcTypeEnum,
  POST: 'POST' as ProcTypeEnum,
  NORMAL: 'NORMAL' as ProcTypeEnum,
};
