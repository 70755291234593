import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { PropTypes } from '@material-ui/core';

type UnbConfirmDialogActionsProps = {
  okHandler: () => void;
  okBtnColor?: PropTypes.Color;
  okBtnText: string;
  cancelHandler: () => void;
  cancelBtnColor?: PropTypes.Color;
  cancelBtnText: string;
  testIdPostFix?: string;
};

const UnbConfirmDialogActions: React.FC<UnbConfirmDialogActionsProps> = (props) => {
  const testIdPostFix = props.testIdPostFix ? props.testIdPostFix : '';
  return (
    <DialogActions>
      <Button onClick={props.cancelHandler} color={props.cancelBtnColor}>
        {props.cancelBtnText}
      </Button>
      <Button
        onClick={props.okHandler}
        color={props.okBtnColor}
        data-testid={'btn-conf-dialog-ok' + testIdPostFix}
      >
        {props.okBtnText}
      </Button>
    </DialogActions>
  );
};

export default UnbConfirmDialogActions;
