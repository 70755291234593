import { Tooltip } from '@material-ui/core';
import {
  UnbChatRuleTooltipContent,
  UnbChatRuleTooltipContentProps,
} from 'components/molecules/UnbChatRuleTooltipContent';
import UnbRightHalfCircularProgress from 'components/molecules/UnbCircularProgressForRightSide';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { Network, Edge, Node, Options, FocusOptions } from 'vis-network';
import { uiChatRuleActions } from 'store/uis/chat-rule/action';
import { ScenarioNode } from 'api/models/ScenarioRelationships';
import { isEmptyObject } from 'utils/ObjectUtil';

type UnbScenarioRelationshipsNetworkProps = {
  nodes: Array<Node>;
  edges: Array<Edge>;
  rootRuleId: string;
};

/**
 * シナリオフロー図
 *
 * @component
 */

const UnbScenarioRelationshipsNetwork: React.FC<UnbScenarioRelationshipsNetworkProps> = (props) => {
  const relationships = useSelector((state: AppState) => state.chatRule.relationships);
  const visJsRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const network = useSelector((state: AppState) => state.uiChatRule.network);
  const networkloading = useSelector((state: AppState) => state.uiChatRule.networkLoading);
  const tooltipPosition = useSelector((state: AppState) => state.uiChatRule.tooltipPosition);
  const tooltipContent = useSelector((state: AppState) => state.uiChatRule.tooltipContent);
  const tooltipOpen = useSelector((state: AppState) => state.uiChatRule.tooltipOpen);
  const nodes = props.nodes;
  const edges = props.edges;
  const rootRuleId = props.rootRuleId;
  const networkCommonStyle = { height: '100%' };
  const networkOptins: Options = {
    autoResize: true,
    nodes: {
      shape: 'box',
      color: '#ffffff',
    },
    edges: {
      arrows: 'to',
      color: {
        highlight: '#1976d2',
        inherit: false,
      },
    },
    interaction: {
      hover: true,
    },
    layout: {
      hierarchical: {
        levelSeparation: 50,
        sortMethod: 'hubsize',
      },
    },
    physics: {
      hierarchicalRepulsion: {
        nodeDistance: 80,
        centralGravity: 0.0,
        springLength: 0,
        springConstant: 0.01,
        avoidOverlap: 1.0,
      },
    },
  };
  const focusOptions: FocusOptions = {
    scale: 0.8,
    offset: { x: 0, y: 0 },
    animation: true,
  };

  const drawNetwork = (nodes: Array<Node>, edges: Array<Edge>): void => {
    const container = visJsRef.current;
    if (container) {
      dispatch(
        uiChatRuleActions.setNetwork(new Network(container, { nodes, edges }, networkOptins))
      );
    }
  };

  const createTooltipContent = (node: ScenarioNode): UnbChatRuleTooltipContentProps => {
    const tooltipContent: UnbChatRuleTooltipContentProps = {
      rule_id: node.rule_id as string,
      decision_condition: node.decision_condition as string,
      decision_command: node.decision_command as string,
      weight: node.weight as number,
    };
    return tooltipContent;
  };

  useEffect(() => {
    if (isEmptyObject(relationships) || isEmptyObject(props.nodes)) {
      dispatch(uiChatRuleActions.setNetworkLoading(false));
    } else {
      network?.once('stabilizationIterationsDone', () => {
        dispatch(uiChatRuleActions.setNetworkLoading(false));
        network?.focus(rootRuleId, focusOptions);
      });

      network?.on('hoverNode', (params) => {
        const x = params.event.offsetX - 50;
        const y = params.event.offsetY;
        dispatch(uiChatRuleActions.setTooltipPosition({ left: x, top: y }));

        const hoveredNodeId = params.node;
        const targetNode = relationships.nodes.find((node) => node.rule_id === hoveredNodeId);
        const tContent = createTooltipContent(targetNode as ScenarioNode);
        dispatch(uiChatRuleActions.setTooltipContent(tContent));
        dispatch(uiChatRuleActions.setTooltipOpen(true));
      });
      network?.on('blurNode', (params) => {
        dispatch(uiChatRuleActions.setTooltipOpen(false));
      });
    }
  }, [network]);

  useEffect(() => {
    drawNetwork(nodes, edges);

    return (): void => {
      dispatch(uiChatRuleActions.setNetworkLoading(true));
    };
  }, [visJsRef]);

  return (
    <div style={networkCommonStyle}>
      <div style={networkCommonStyle}>
        <Tooltip
          open={tooltipOpen}
          style={{ position: 'absolute', top: tooltipPosition?.top, left: tooltipPosition?.left }}
          title={
            <UnbChatRuleTooltipContent
              rule_id={tooltipContent?.rule_id as string}
              decision_condition={tooltipContent?.decision_condition as string}
              decision_command={tooltipContent?.decision_command as string}
              weight={tooltipContent?.weight as number}
            />
          }
        >
          <div></div>
        </Tooltip>
        <div ref={visJsRef} style={networkCommonStyle} />
      </div>
      {networkloading && <UnbRightHalfCircularProgress open={networkloading} />}
    </div>
  );
};

export default UnbScenarioRelationshipsNetwork;
