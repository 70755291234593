/** Stringにformat関数追加 */

export {};

// 拡張メソッドの定義
declare global {
  interface String {
    format(...replacements: string[]): string;
  }
}

if (!String.prototype.format) {
  // eslint-disable-next-line no-extend-native
  String.prototype.format = function (): string {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}
