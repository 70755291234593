import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff',
    },
  })
);

type UnbCircularProgressProps = {
  open: boolean;
};

const UnbCircularProgress: React.FC<UnbCircularProgressProps> = (props) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default UnbCircularProgress;
