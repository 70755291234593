export type AcquisitionMethodTypeEnum =
  | 'user.tmp'
  | 'req:semantic_time'
  | 'req:st_date'
  | 'req:NP'
  | 'req:VP'
  | 'req:number'
  | 'req:topic'
  | 'req:格助詞が'
  | 'req:格助詞の'
  | 'req:格助詞を'
  | 'req:格助詞に'
  | 'req:格助詞から'
  | 'req:格助詞と'
  | 'req:飲み物_全体'
  | 'req:果物_全体'
  | 'req:prefecture_city'
  | 'req:gnavi_region'
  | 'req:gnavi_category'
  | 'req:yolp_category'
  | 'req:food'
  | 'req:s_food'
  | 'req:region'
  | 'req:region_1st'
  | 'req:region_time_zone'
  | 'req:sports'
  | 'req:s_sports'
  | 'req:recreation'
  | 'req:human'
  | 'req:plant'
  | 'req:person_name'
  | 'req:person_full_name'
  | 'req:movie'
  | 'req:野球チーム'
  | 'req:サッカーチーム'
  | 'req:バスケチーム'
  | 'req'
  | 'null'
  | 'req:犬'
  | 'req:鳥'
  | 'req:猫'
  | 'req:爬虫類'
  | 'req:ウサギ'
  | 'req:魚類'
  | 'req:げっ歯類'
  | 'req:昆虫'
  | 'req:植物'
  | 'req:両生類'
  | 'req:花'
  | 'req:野菜'
  | 'req:フルーツ'
  | 'req:観賞魚'
  | 'req:食用魚'
  | 'req:観賞甲殻類'
  | 'req:穀物'
  | 'req:漢方植物'
  | 'req:色'
  | 'req:海藻'
  | 'req:食肉'
  | 'req:肉料理'
  | 'req:茸'
  | 'req:魚介料理'
  | 'req:食文化'
  | 'req:国家'
  | 'req:国と地域'
  | 'req:鋏角類'
  | 'req:多足類'
  | 'req:食用甲殻類'
  | 'req:甲殻類'
  | 'req:環形動物'
  | 'req:飼いウサギ'
  | 'req:黄道十二星座'
  | 'req:その他星座'
  | 'req:星群'
  | 'req:大陸'
  | 'req:大洋'
  | 'req:テクトニックプレート'
  | 'req:言語'
  | 'req:六曜'
  | 'req:九星'
  | 'req:五節句'
  | 'req:二十四節気'
  | 'req:雑節'
  | 'req:六十干支'
  | 'req:茶葉茶'
  | 'req:茶外茶'
  | 'req:ブレンド茶'
  | 'req:コーヒー豆'
  | 'req:レギュラーコーヒー'
  | 'req:パスタ'
  | 'req:宗教'
  | 'req:音楽ジャンル'
  | 'req:トップス'
  | 'req:ボトムス'
  | 'req:スカート'
  | 'req:ドレス'
  | 'req:背広と制服'
  | 'req:上着'
  | 'req:下着'
  | 'req:寝間着'
  | 'req:着物'
  | 'req:帽子'
  | 'req:SNS'
  | 'req:診療科目'
  | 'req:癌'
  | 'req:温泉質'
  | 'req:風邪'
  | 'req:酒類'
  | 'req:ウイスキー'
  | 'req:ブランデー'
  | 'req:焼酎'
  | 'req:ビール'
  | 'req:ワイン'
  | 'req:赤ワイン'
  | 'req:白ワイン'
  | 'req:パスタ料理'
  | 'req:中華麺'
  | 'req:日本麺'
  | 'req:ピッツァ料理'
  | 'req:ピッツァ'
  | 'req:動物'
  | 'req:ジュース'
  | 'req:家族'
  | 'req:アケビ'
  | 'req:アセロラ'
  | 'req:アボカド'
  | 'req:アンズ'
  | 'req:イチゴ'
  | 'req:イチジク'
  | 'req:ウメ'
  | 'req:みかん'
  | 'req:オレンジ'
  | 'req:柿'
  | 'req:カリン'
  | 'req:カンキツ類'
  | 'req:キウイフルーツ'
  | 'req:キワノ'
  | 'req:栗'
  | 'req:グアバ'
  | 'req:グレープフルーツ'
  | 'req:サクランボ'
  | 'req:ザクロ'
  | 'req:スイカ'
  | 'req:スターフルーツ'
  | 'req:スモモ'
  | 'req:西洋ナシ'
  | 'req:チェリモヤ'
  | 'req:中国ナシ'
  | 'req:ドラゴンフルーツ'
  | 'req:ドリアン'
  | 'req:梨'
  | 'req:ネクタリン'
  | 'req:バナナ'
  | 'req:パイナップル'
  | 'req:パッションフルーツ'
  | 'req:パパイア'
  | 'req:ビワ'
  | 'req:ブドウ'
  | 'req:ブルーベリー'
  | 'req:プルーン'
  | 'req:ベリー類'
  | 'req:マルメロ'
  | 'req:マンゴスチン'
  | 'req:マンゴー'
  | 'req:メロン'
  | 'req:モモ'
  | 'req:ライチ'
  | 'req:リンゴ'
  | 'req:レモン';
export const AcquisitionMethodTypeEnum = {
  tmp: 'user.tmp' as AcquisitionMethodTypeEnum,
  semantic_time: 'req:semantic_time' as AcquisitionMethodTypeEnum,
  st_date: 'req:st_date' as AcquisitionMethodTypeEnum,
  NP: 'req:NP' as AcquisitionMethodTypeEnum,
  VP: 'req:VP' as AcquisitionMethodTypeEnum,
  number: 'req:number' as AcquisitionMethodTypeEnum,
  topic: 'req:topic' as AcquisitionMethodTypeEnum,
  格助詞が: 'req:格助詞が' as AcquisitionMethodTypeEnum,
  格助詞の: 'req:格助詞の' as AcquisitionMethodTypeEnum,
  格助詞を: 'req:格助詞を' as AcquisitionMethodTypeEnum,
  格助詞に: 'req:格助詞に' as AcquisitionMethodTypeEnum,
  格助詞から: 'req:格助詞から' as AcquisitionMethodTypeEnum,
  格助詞と: 'req:格助詞と' as AcquisitionMethodTypeEnum,
  飲み物_全体: 'req:飲み物_全体' as AcquisitionMethodTypeEnum,
  果物_全体: 'req:果物_全体' as AcquisitionMethodTypeEnum,
  prefecture_city: 'req:prefecture_city' as AcquisitionMethodTypeEnum,
  gnavi_region: 'req:gnavi_region' as AcquisitionMethodTypeEnum,
  gnavi_category: 'req:gnavi_category' as AcquisitionMethodTypeEnum,
  yolp_category: 'req:yolp_category' as AcquisitionMethodTypeEnum,
  food: 'req:food' as AcquisitionMethodTypeEnum,
  s_food: 'req:s_food' as AcquisitionMethodTypeEnum,
  region: 'req:region' as AcquisitionMethodTypeEnum,
  region_1st: 'req:region_1st' as AcquisitionMethodTypeEnum,
  region_time_zone: 'req:region_time_zone' as AcquisitionMethodTypeEnum,
  sports: 'req:sports' as AcquisitionMethodTypeEnum,
  s_sports: 'req:s_sports' as AcquisitionMethodTypeEnum,
  recreation: 'req:recreation' as AcquisitionMethodTypeEnum,
  human: 'req:human' as AcquisitionMethodTypeEnum,
  plant: 'req:plant' as AcquisitionMethodTypeEnum,
  person_name: 'req:person_name' as AcquisitionMethodTypeEnum,
  person_full_name: 'req:person_full_name' as AcquisitionMethodTypeEnum,
  movie: 'req:movie' as AcquisitionMethodTypeEnum,
  野球チーム: 'req:野球チーム' as AcquisitionMethodTypeEnum,
  サッカーチーム: 'req:サッカーチーム' as AcquisitionMethodTypeEnum,
  バスケチーム: 'req:バスケチーム' as AcquisitionMethodTypeEnum,
  req: 'req' as AcquisitionMethodTypeEnum,
  null: 'null' as AcquisitionMethodTypeEnum,
  犬: 'req:犬' as AcquisitionMethodTypeEnum,
  鳥: 'req:鳥' as AcquisitionMethodTypeEnum,
  猫: 'req:猫' as AcquisitionMethodTypeEnum,
  爬虫類: 'req:爬虫類' as AcquisitionMethodTypeEnum,
  ウサギ: 'req:ウサギ' as AcquisitionMethodTypeEnum,
  魚類: 'req:魚類' as AcquisitionMethodTypeEnum,
  げっ歯類: 'req:げっ歯類' as AcquisitionMethodTypeEnum,
  昆虫: 'req:昆虫' as AcquisitionMethodTypeEnum,
  植物: 'req:植物' as AcquisitionMethodTypeEnum,
  両生類: 'req:両生類' as AcquisitionMethodTypeEnum,
  花: 'req:花' as AcquisitionMethodTypeEnum,
  野菜: 'req:野菜' as AcquisitionMethodTypeEnum,
  フルーツ: 'req:フルーツ' as AcquisitionMethodTypeEnum,
  観賞魚: 'req:観賞魚' as AcquisitionMethodTypeEnum,
  食用魚: 'req:食用魚' as AcquisitionMethodTypeEnum,
  観賞甲殻類: 'req:観賞甲殻類' as AcquisitionMethodTypeEnum,
  穀物: 'req:穀物' as AcquisitionMethodTypeEnum,
  漢方植物: 'req:漢方植物' as AcquisitionMethodTypeEnum,
  色: 'req:色' as AcquisitionMethodTypeEnum,
  海藻: 'req:海藻' as AcquisitionMethodTypeEnum,
  食肉: 'req:食肉' as AcquisitionMethodTypeEnum,
  肉料理: 'req:肉料理' as AcquisitionMethodTypeEnum,
  茸: 'req:茸' as AcquisitionMethodTypeEnum,
  魚介料理: 'req:魚介料理' as AcquisitionMethodTypeEnum,
  食文化: 'req:食文化' as AcquisitionMethodTypeEnum,
  国家: 'req:国家' as AcquisitionMethodTypeEnum,
  国と地域: 'req:国と地域' as AcquisitionMethodTypeEnum,
  鋏角類: 'req:鋏角類' as AcquisitionMethodTypeEnum,
  多足類: 'req:多足類' as AcquisitionMethodTypeEnum,
  食用甲殻類: 'req:食用甲殻類' as AcquisitionMethodTypeEnum,
  甲殻類: 'req:甲殻類' as AcquisitionMethodTypeEnum,
  環形動物: 'req:環形動物' as AcquisitionMethodTypeEnum,
  飼いウサギ: 'req:飼いウサギ' as AcquisitionMethodTypeEnum,
  黄道十二星座: 'req:黄道十二星座' as AcquisitionMethodTypeEnum,
  その他星座: 'req:その他星座' as AcquisitionMethodTypeEnum,
  星群: 'req:星群' as AcquisitionMethodTypeEnum,
  大陸: 'req:大陸' as AcquisitionMethodTypeEnum,
  大洋: 'req:大洋' as AcquisitionMethodTypeEnum,
  テクトニックプレート: 'req:テクトニックプレート' as AcquisitionMethodTypeEnum,
  言語: 'req:言語' as AcquisitionMethodTypeEnum,
  六曜: 'req:六曜' as AcquisitionMethodTypeEnum,
  九星: 'req:九星' as AcquisitionMethodTypeEnum,
  五節句: 'req:五節句' as AcquisitionMethodTypeEnum,
  二十四節気: 'req:二十四節気' as AcquisitionMethodTypeEnum,
  雑節: 'req:雑節' as AcquisitionMethodTypeEnum,
  六十干支: 'req:六十干支' as AcquisitionMethodTypeEnum,
  茶葉茶: 'req:茶葉茶' as AcquisitionMethodTypeEnum,
  茶外茶: 'req:茶外茶' as AcquisitionMethodTypeEnum,
  ブレンド茶: 'req:ブレンド茶' as AcquisitionMethodTypeEnum,
  コーヒー豆: 'req:コーヒー豆' as AcquisitionMethodTypeEnum,
  レギュラーコーヒー: 'req:レギュラーコーヒー' as AcquisitionMethodTypeEnum,
  パスタ: 'req:パスタ' as AcquisitionMethodTypeEnum,
  宗教: 'req:宗教' as AcquisitionMethodTypeEnum,
  音楽ジャンル: 'req:音楽ジャンル' as AcquisitionMethodTypeEnum,
  トップス: 'req:トップス' as AcquisitionMethodTypeEnum,
  ボトムス: 'req:ボトムス' as AcquisitionMethodTypeEnum,
  スカート: 'req:スカート' as AcquisitionMethodTypeEnum,
  ドレス: 'req:ドレス' as AcquisitionMethodTypeEnum,
  背広と制服: 'req:背広と制服' as AcquisitionMethodTypeEnum,
  上着: 'req:上着' as AcquisitionMethodTypeEnum,
  下着: 'req:下着' as AcquisitionMethodTypeEnum,
  寝間着: 'req:寝間着' as AcquisitionMethodTypeEnum,
  着物: 'req:着物' as AcquisitionMethodTypeEnum,
  帽子: 'req:帽子' as AcquisitionMethodTypeEnum,
  SNS: 'req:SNS' as AcquisitionMethodTypeEnum,
  診療科目: 'req:診療科目' as AcquisitionMethodTypeEnum,
  癌: 'req:癌' as AcquisitionMethodTypeEnum,
  温泉質: 'req:温泉質' as AcquisitionMethodTypeEnum,
  風邪: 'req:風邪' as AcquisitionMethodTypeEnum,
  酒類: 'req:酒類' as AcquisitionMethodTypeEnum,
  ウイスキー: 'req:ウイスキー' as AcquisitionMethodTypeEnum,
  ブランデー: 'req:ブランデー' as AcquisitionMethodTypeEnum,
  焼酎: 'req:焼酎' as AcquisitionMethodTypeEnum,
  ビール: 'req:ビール' as AcquisitionMethodTypeEnum,
  ワイン: 'req:ワイン' as AcquisitionMethodTypeEnum,
  赤ワイン: 'req:赤ワイン' as AcquisitionMethodTypeEnum,
  白ワイン: 'req:白ワイン' as AcquisitionMethodTypeEnum,
  パスタ料理: 'req:パスタ料理' as AcquisitionMethodTypeEnum,
  中華麺: 'req:中華麺' as AcquisitionMethodTypeEnum,
  日本麺: 'req:日本麺' as AcquisitionMethodTypeEnum,
  ピッツァ料理: 'req:ピッツァ料理' as AcquisitionMethodTypeEnum,
  ピッツァ: 'req:ピッツァ' as AcquisitionMethodTypeEnum,
  動物: 'req:動物' as AcquisitionMethodTypeEnum,
  ジュース: 'req:ジュース' as AcquisitionMethodTypeEnum,
  家族: 'req:家族' as AcquisitionMethodTypeEnum,
  アケビ: 'req:アケビ' as AcquisitionMethodTypeEnum,
  アセロラ: 'req:アセロラ' as AcquisitionMethodTypeEnum,
  アボカド: 'req:アボカド' as AcquisitionMethodTypeEnum,
  アンズ: 'req:アンズ' as AcquisitionMethodTypeEnum,
  イチゴ: 'req:イチゴ' as AcquisitionMethodTypeEnum,
  イチジク: 'req:イチジク' as AcquisitionMethodTypeEnum,
  ウメ: 'req:ウメ' as AcquisitionMethodTypeEnum,
  みかん: 'req:みかん' as AcquisitionMethodTypeEnum,
  オレンジ: 'req:オレンジ' as AcquisitionMethodTypeEnum,
  柿: 'req:柿' as AcquisitionMethodTypeEnum,
  カリン: 'req:カリン' as AcquisitionMethodTypeEnum,
  カンキツ類: 'req:カンキツ類' as AcquisitionMethodTypeEnum,
  キウイフルーツ: 'req:キウイフルーツ' as AcquisitionMethodTypeEnum,
  キワノ: 'req:キワノ' as AcquisitionMethodTypeEnum,
  栗: 'req:栗' as AcquisitionMethodTypeEnum,
  グアバ: 'req:グアバ' as AcquisitionMethodTypeEnum,
  グレープフルーツ: 'req:グレープフルーツ' as AcquisitionMethodTypeEnum,
  サクランボ: 'req:サクランボ' as AcquisitionMethodTypeEnum,
  ザクロ: 'req:ザクロ' as AcquisitionMethodTypeEnum,
  スイカ: 'req:スイカ' as AcquisitionMethodTypeEnum,
  スターフルーツ: 'req:スターフルーツ' as AcquisitionMethodTypeEnum,
  スモモ: 'req:スモモ' as AcquisitionMethodTypeEnum,
  西洋ナシ: 'req:西洋ナシ' as AcquisitionMethodTypeEnum,
  チェリモヤ: 'req:チェリモヤ' as AcquisitionMethodTypeEnum,
  中国ナシ: 'req:中国ナシ' as AcquisitionMethodTypeEnum,
  ドラゴンフルーツ: 'req:ドラゴンフルーツ' as AcquisitionMethodTypeEnum,
  ドリアン: 'req:ドリアン' as AcquisitionMethodTypeEnum,
  梨: 'req:梨' as AcquisitionMethodTypeEnum,
  ネクタリン: 'req:ネクタリン' as AcquisitionMethodTypeEnum,
  バナナ: 'req:バナナ' as AcquisitionMethodTypeEnum,
  パイナップル: 'req:パイナップル' as AcquisitionMethodTypeEnum,
  パッションフルーツ: 'req:パッションフルーツ' as AcquisitionMethodTypeEnum,
  パパイア: 'req:パパイア' as AcquisitionMethodTypeEnum,
  ビワ: 'req:ビワ' as AcquisitionMethodTypeEnum,
  ブドウ: 'req:ブドウ' as AcquisitionMethodTypeEnum,
  ブルーベリー: 'req:ブルーベリー' as AcquisitionMethodTypeEnum,
  プルーン: 'req:プルーン' as AcquisitionMethodTypeEnum,
  ベリー類: 'req:ベリー類' as AcquisitionMethodTypeEnum,
  マルメロ: 'req:マルメロ' as AcquisitionMethodTypeEnum,
  マンゴスチン: 'req:マンゴスチン' as AcquisitionMethodTypeEnum,
  マンゴー: 'req:マンゴー' as AcquisitionMethodTypeEnum,
  メロン: 'req:メロン' as AcquisitionMethodTypeEnum,
  モモ: 'req:モモ' as AcquisitionMethodTypeEnum,
  ライチ: 'req:ライチ' as AcquisitionMethodTypeEnum,
  リンゴ: 'req:リンゴ' as AcquisitionMethodTypeEnum,
  レモン: 'req:レモン' as AcquisitionMethodTypeEnum,
};
