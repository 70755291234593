import { Task } from 'api/models/Task';
import { taskActions } from 'store/domains/task/action';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export type TaskState = {
  rows: Array<Task>;
  count: number;
  fetchOption: {
    limit: number;
    page: number;
  };
  loading: boolean;
  error?: any;
  currentValue: Task;
  searchParams: Record<string, string | number>;
};

export const initialState: TaskState = {
  fetchOption: {
    limit: 20,
    page: 0,
  },
  rows: [],
  count: 0,
  loading: false,
  currentValue: {} as Task,
  searchParams: {
    value: '',
    time_direction_type: 'all',
    task_type: 'all',
    required: 2,
    is_permit_other_value: 2,
    is_need_next_action: 2,
  } as Record<string, string | number>,
};

export const taskReducer = reducerWithInitialState(initialState)
  .case(taskActions.setLoading, (state, payload) => {
    return {
      ...state,
      loading: payload,
    };
  })
  .case(taskActions.setCurrentValue, (state, payload) => {
    return {
      ...state,
      currentValue: payload,
    };
  })
  .case(taskActions.setFetchOption, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.limit,
        page: payload.page,
      },
    };
  })
  .case(taskActions.setSearchParams, (state, payload) => {
    return {
      ...state,
      fetchOption: {
        limit: payload.fetchOption.limit,
        page: payload.fetchOption.page,
      },
      searchParams: payload.searchParams,
    };
  })
  .case(taskActions.getTask.started, (state, payload) => {
    return state;
  })
  .case(taskActions.getTask.failed, (state, payload) => {
    return state;
  })
  .case(taskActions.getTask.done, (state, payload) => {
    //console.log(JSON.stringify(payload.result));
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(taskActions.searchTask.started, (state, payload) => {
    return state;
  })
  .case(taskActions.searchTask.failed, (state, payload) => {
    return state;
  })
  .case(taskActions.searchTask.done, (state, payload) => {
    return {
      ...state,
      rows: payload.result.result.row,
      count: payload.result.result.count,
    };
  })
  .case(taskActions.addTask.started, (state, payload) => {
    return state;
  })
  .case(taskActions.addTask.failed, (state, payload) => {
    return state;
  })
  .case(taskActions.addTask.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows;
    const newRows = rows.concat([result]);
    return {
      ...state,
      rows: newRows,
    };
  })
  .case(taskActions.updateTask.started, (state, payload) => {
    return state;
  })
  .case(taskActions.updateTask.failed, (state, payload) => {
    return state;
  })
  .case(taskActions.updateTask.done, (state, payload) => {
    const result = payload.result.result;
    const rows = state.rows.slice();
    const index = rows.findIndex((r: Task) => r.task_id === result.task_id);
    rows[index] = result;
    return {
      ...state,
      rows: rows,
    };
  })
  .case(taskActions.deleteTask.started, (state, payload) => {
    return state;
  })
  .case(taskActions.deleteTask.failed, (state, payload) => {
    return state;
  })
  .case(taskActions.deleteTask.done, (state, payload) => {
    const id = payload.params.id;
    const deletedRows = state.rows.filter((r: Task) => r.task_id !== id);
    return {
      ...state,
      rows: deletedRows,
    };
  });
