import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LabelText } from 'constants/Messages';
import { uiTaskActions } from 'store/uis/task/action';

export type UITaskState = {
  error?: any;
  openAlertDialog: boolean;
  openDialog: boolean;
  okBtnText: string;
  displayDelete: boolean;
  action?: 'ADD' | 'UPDATE';
  isDisplayCopyBtn: boolean;
  openCopyAlertDialog: boolean;
};

export const initialState: UITaskState = {
  openAlertDialog: false,
  openDialog: false,
  okBtnText: LabelText.UPDATE_LABEL,
  displayDelete: false,
  isDisplayCopyBtn: false,
  openCopyAlertDialog: false,
};

export const uiTaskReducer = reducerWithInitialState(initialState)
  .case(uiTaskActions.setOpenCopyAlertDialog, (state, payload) => {
    return {
      ...state,
      openCopyAlertDialog: payload,
    };
  })
  .case(uiTaskActions.setIsDisplayCopyBtn, (state, payload) => {
    return {
      ...state,
      isDisplayCopyBtn: payload,
    };
  })
  .case(uiTaskActions.setOkBtnText, (state, payload) => {
    return {
      ...state,
      okBtnText: payload,
    };
  })
  .case(uiTaskActions.setOpenDialog, (state, payload) => {
    return {
      ...state,
      openDialog: payload,
    };
  })
  .case(uiTaskActions.setOpenAlertDialog, (state, payload) => {
    return {
      ...state,
      openAlertDialog: payload,
    };
  })
  .case(uiTaskActions.setDisplayDelete, (state, payload) => {
    return {
      ...state,
      displayDelete: payload,
    };
  })
  .case(uiTaskActions.setAction, (state, payload) => {
    return {
      ...state,
      action: payload,
    };
  });
