import React from 'react';
import { TextFieldProps, TextField, makeStyles, createStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

type UnbInlineTextFieldProps = {
  textFieldPropsArray: TextFieldProps[];
  key: string;
  ele_id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
    },
    inlineText: {
      flex: 1,
      marginLeft: 5,
    },
  })
);

const UnbInlineTextField: React.FC<UnbInlineTextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <div key={props.ele_id} className={clsx(classes.root)}>
      {props.textFieldPropsArray.map((tfProps, index: number) => {
        return (
          <TextField
            key={props.ele_id + '-' + index}
            {...tfProps}
            className={clsx(classes.inlineText)}
          />
        );
      })}
    </div>
  );
};

export default UnbInlineTextField;
