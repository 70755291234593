import UnbTheme from 'themes/UnbStyle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';
import React, { useEffect } from 'react';

// TODO ボタンの活性非活性
type UnbListTextFieldProps = {
  onClickAdd: (id: string, index: number) => void;
  onClickRemove: (id: string, index: number) => void;
  labelText: string;
  helperText?: string | React.ReactElement;
  defaultText: string;
  id: string;
  index: number;
  multiline?: boolean;
  name?: string;
  inputRef?: React.Ref<any>;
  error?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    errorHelperText: {
      color: theme.palette.error.main,
    },
  })
);

const UnbListTextField: React.FC<UnbListTextFieldProps> = (props: UnbListTextFieldProps) => {
  const classes = useStyles(UnbTheme);
  const helperID = props.id + '-helper-text';
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputLabel = React.useRef<HTMLLabelElement>(null);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl className={clsx(classes.form)} variant="outlined">
      <InputLabel htmlFor={props.id} margin="dense" error={props.error} ref={inputLabel}>
        {props.labelText}
      </InputLabel>
      <OutlinedInput
        id={props.id}
        inputRef={props.inputRef}
        error={props.error}
        type="text"
        margin="dense"
        name={props.name}
        multiline={props.multiline}
        defaultValue={props.defaultText}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={(): void => props.onClickAdd(props.defaultText, props.index)}
              edge="end"
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={(): void => props.onClickRemove(props.defaultText, props.index)}
              edge="end"
            >
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth}
        aria-describedby={helperID}
      />
      {props.helperText && props.error ? (
        <FormHelperText className={classes.errorHelperText} id={helperID}>
          {props.helperText}
        </FormHelperText>
      ) : (
        <FormHelperText id={helperID}>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default UnbListTextField;
