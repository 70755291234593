import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReduxDispatch } from 'hooks/UseReduxDispatch';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { AppState } from 'store';
import { authActions, getUserInfoAction } from 'store/domains/auth/action';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      width: 'auto',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  })
);

/** ログイン画面 */
const UnbLogin: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const authService = useSelector((state: AppState) => state.app.authService);
  const dispatch = useDispatch();
  const asyncDispatch = useReduxDispatch();
  const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);

  const handleClickLogin = async (): Promise<void> => {
    console.log('call handleClickLogin');
    await authService.signinRedirect();
  };

  useEffect(() => {
    getAuthState();
  }, []);

  const getAuthState = async (): Promise<void> => {
    const isAuthenticated = authService.isAuthenticated();
    dispatch(authActions.setAuthenticated(isAuthenticated));

    if (isAuthenticated) {
      const u = await authService.getUser();
      if (u) {
        // ログイン成功後、プロジェクトIDを取得するため
        // OIDCにUserInfoを問い合わせる
        dispatch(authActions.setUser(u));
        try {
          await asyncDispatch(getUserInfoAction());
        } catch (e) {
          console.error('Failed to get a project ID');
          dispatch(authActions.setAuthenticated(false));
        }
      }
    }
  };

  return (
    <div>
      {isAuthenticated && <Redirect to={'/scenario/chat-rule'} />}
      <main className={classes.content}>
        <div className={classes.root}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClickLogin}
            startIcon={<LaunchIcon />}
          >
            Unibo Storeでログイン
          </Button>
        </div>
      </main>
    </div>
  );
};

export default withRouter(UnbLogin);
