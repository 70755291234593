import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { AppState } from 'store/index';
import {
  execRequest,
  fetchMasterWords,
  patchMasterWord,
  postMasterWord,
  deleteMasterWord,
} from 'api/Requests';
import RequestGetWordMaster from 'api/models/requests/RequestGetWordMaster';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestPatchWordMaster from 'api/models/requests/RequestPatchWordMaster';
import RequestPostWordMaster from 'api/models/requests/RequestPostWordMaster';
import { AxiosResponse } from 'axios';
import { WordMaster, WordTypeEnum } from 'api/models/WordMaster';
import axios from 'axios';
import { CurrentValueType } from 'store/domains/master-word/reducer';
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';

const actionCraator = actionCreatorFactory();

export const masterWordsActions = {
  getWordMaster: actionCraator.async<
    RequestGetWordMaster,
    CommonAPIResponse<CommonFetchResponse<Array<WordMaster>>>
  >('GET_WORD_MASTER'),
  deleteWordMaster: actionCraator.async<RequestDeleteCommon, AxiosResponse>('DELETE_WORD_MASTER'),
  updateWordMaster: actionCraator.async<RequestPatchWordMaster, CommonAPIResponse<WordMaster>>(
    'UPDATE_WORD_MASTER'
  ),
  addWordMaster: actionCraator.async<RequestPostWordMaster, CommonAPIResponse<WordMaster>>(
    'ADD_WORD_MASTER'
  ),
  setFetchOption: actionCraator<RequestGetWordMaster>('SET_MASTER_WORD_FETCH_OPTION'),
  setSearchParams: actionCraator<{
    fetchOption: RequestGetWordMaster;
    currentValues: CurrentValueType;
  }>('SET_SEARCH_PARAMS'),
  setLoading: actionCraator<boolean>('SET_WORD_MASTER_LOADING'),
  setCurrentValues: actionCraator<CurrentValueType>('SET_MASER_WORD_CURRENT_VALUES'),
};

export const getWordMasterAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const fetchOption = getState().masterWords.fetchOption;
    const currentValues = getState().masterWords.currentValues;
    const signal = axios.CancelToken.source();
    const accessToken = getState().auth.user.access_token;
    const params: RequestGetWordMaster = {
      limit: fetchOption.limit,
      page: fetchOption.page,
      type: fetchOption.type,
      value: currentValues.searchValue,
    };
    dispatch(masterWordsActions.getWordMaster.started(params));
    return execRequest(fetchMasterWords, params, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            masterWordsActions.getWordMaster.done({
              params: params,
              result: res.data,
            })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(
          masterWordsActions.getWordMaster.failed({
            error: e,
            params: params,
          })
        );
        throw e;
      });
  };
};

export const updateWordMasterAction = (noiseVal: string) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const currentValues = getState().masterWords.currentValues;
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    const patchParams: RequestPatchWordMaster = {
      word_master_id: currentValues.wordMasterID,
      value: noiseVal,
      word_type: WordTypeEnum.NOISE,
    };

    dispatch(masterWordsActions.updateWordMaster.started(patchParams));
    return execRequest(patchMasterWord, patchParams, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(
            masterWordsActions.updateWordMaster.done({ params: patchParams, result: res.data })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(masterWordsActions.updateWordMaster.failed({ error: e, params: patchParams }));
        throw e;
      });
  };
};

export const addWordMasterAction = (noiseVal: string) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const postParams: RequestPostWordMaster = {
      word_type: WordTypeEnum.NOISE,
      value: noiseVal,
    };

    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();

    dispatch(masterWordsActions.addWordMaster.started(postParams));
    return execRequest(postMasterWord, postParams, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(masterWordsActions.addWordMaster.done({ params: postParams, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(masterWordsActions.addWordMaster.failed({ error: e, params: postParams }));
        throw e;
      });
  };
};

export const deleteWordMasterAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const currentValues = getState().masterWords.currentValues;
    const param: RequestDeleteCommon = { id: currentValues.wordMasterID };
    const accessToken = getState().auth.user.access_token;
    const signal = axios.CancelToken.source();
    dispatch(masterWordsActions.deleteWordMaster.started(param));
    return execRequest(deleteMasterWord, param, accessToken, signal.token)
      .then((res) => {
        if (res) {
          dispatch(masterWordsActions.deleteWordMaster.done({ params: param, result: res.data }));
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(masterWordsActions.deleteWordMaster.failed({ error: e, params: param }));
        throw e;
      });
  };
};
