import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Redirect } from 'react-router-dom';

const UnbAuthLogout: React.FC = () => {
  const authService = useSelector((state: AppState) => state.app.authService);

  useEffect(() => {
    authService.logout();
  });

  return <Redirect to={'/login'} />;
};

export default UnbAuthLogout;
