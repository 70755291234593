import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const uiVerbDefinitionActions = {
  setOpenAlertDialog: actionCreator<boolean>('SET_VERB_DEFINITION_OPEN_ALERT_DIALOG'),
  setOpenDialog: actionCreator<boolean>('SET_VERB_DEFINITION_OPEN_DIALOG'),
  setOkBtnText: actionCreator<string>('SET_VERB_DEFINITION_OK_BTN_TEXT'),
  setDisplayDelete: actionCreator<boolean>('SET_VERB_DEFINITION_DELETE_DISPLAY'),
  setAction: actionCreator<'ADD' | 'UPDATE' | undefined>('SET_VERB_DEFINITION_ACTION'),
  setIsDisplayCopyBtn: actionCreator<boolean>('SET_VERB_DEFINITION_IS_DISPLAY_COPY_BTN'),
  setOpenCopyAlertDialog: actionCreator<boolean>('SET_VERB_DEFINITION_OPEN_COPY_ALERT_DIALOG'),
};
