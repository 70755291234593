/** @fileOverview 会話データアクション */
import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { AppState } from 'store/index';
import {
  execRequest,
  fetchChatRule,
  searchChatRule,
  patchChatRule,
  postChatRule,
  deleteChatRule,
  fetchPrivateChatRule,
  searchPrivateChatRule,
  patchPrivateChatRule,
  deletePrivateChatRule,
  postPrivateChatRule,
  reloadPrivateChatRule,
  exportPrivateChatRule,
  importPrivateChatRule,
  getScenarioRelationships,
} from 'api/Requests';
import axios, { AxiosResponse } from 'axios';
import { CommonAPIResponse, CommonFetchResponse } from 'api/models/Common';
import { ChatRule } from 'api/models/ChatRule';
import RequestPostChatRule from 'api/models/requests/RequestPostChatRule';
import RequestPatchChatRule from 'api/models/requests/RequestPatchChatRule';
import RequestDeleteCommon from 'api/models/requests/RequestDeleteCommon';
import RequestGetCommon from 'api/models/requests/RequestGetCommon';
import RequestSearchCommon from 'api/models/requests/RequestSearchCommon';
import RequestGetScenarioRelationships from 'api/models/requests/RequestGetScenarioRelationships';
import { ScenarioRelationships } from 'api/models/ScenarioRelationships';

const actionCreator = actionCreatorFactory();
export const chatRuleActions = {
  getChatRule: actionCreator.async<
    RequestGetCommon,
    CommonAPIResponse<CommonFetchResponse<Array<ChatRule>>>
  >('GET_CHAT_RULE'),
  searchChatRule: actionCreator.async<
    RequestSearchCommon,
    CommonAPIResponse<CommonFetchResponse<Array<ChatRule>>>
  >('SEARCH_CHAT_RULE'),
  updateChatRule: actionCreator.async<RequestPatchChatRule, CommonAPIResponse<ChatRule>>(
    'UPDATE_CHAT_RULE'
  ),
  addChatRule: actionCreator.async<RequestPostChatRule, CommonAPIResponse<ChatRule>>(
    'ADD_CHAT_RULE'
  ),
  deleteChatRule: actionCreator.async<RequestDeleteCommon, AxiosResponse>('DELETE_CHAT_RULE'),
  exportChatRule: actionCreator.async<number, AxiosResponse>('EXPORT_CHAT_RULE'),
  importChatRule: actionCreator.async<FormData, AxiosResponse>('IMPORT_CHAT_RULE'),
  getScenarioRelationships: actionCreator.async<
    RequestGetScenarioRelationships,
    CommonAPIResponse<ScenarioRelationships>
  >('GET_SCENARIO_RELATIONSHIPS'),
  clearScenarioRelationships: actionCreator<{}>('CLEAR_SCENARIO_RELATIONSHIPS'),
  reloadPrivateChatRule: actionCreator.async<number, AxiosResponse>('RELOAD_PRIVATE_CHAT_RULE'),
  setFetchOption: actionCreator<{ limit: number; page: number }>('SET_CHAT_RULE_FETCH_OPTION'),
  setLoading: actionCreator<boolean>('SET_CHAT_RULE_LOADING'),
  setCurrentChatRule: actionCreator<ChatRule>('SET_CHAT_RULE_CURRENT_CHAT_RULE'),
  setSearchParams: actionCreator<{
    fetchOption: {
      limit: number;
      page: number;
    };
    searchParams: Record<string, string | number>;
  }>('SET_CHAT_RULE_SEARCH_PARAMS'),
};

/** 会話データ取得 */
export const getChatRuleAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const accessToken = getState().auth.user.access_token;
    const isPrivate = getState().auth.isPrivate;
    const projectId = getState().app.selectedProjectId;
    const fetchOption = getState().chatRule.fetchOption;
    const signal = axios.CancelToken.source();

    const params: RequestGetCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
    };

    let execFunc;
    if (isPrivate && projectId !== null && projectId !== undefined) {
      execFunc = execRequest(fetchPrivateChatRule, projectId, params, accessToken, signal.token);
    } else {
      execFunc = execRequest(fetchChatRule, params, accessToken, signal.token);
    }
    dispatch(chatRuleActions.getChatRule.started(params));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(
            chatRuleActions.getChatRule.done({
              params: params,
              result: res.data,
            })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.getChatRule.failed({ params: params, error: e }));
        throw e;
      });
  };
};

/** 会話データ検索 */
export const searchChatRuleAction = (query: string, queryOr: null | string = null) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const fetchOption = getState().chatRule.fetchOption;
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();
    const params: RequestSearchCommon = {
      limit: fetchOption.limit,
      page: fetchOption.page,
      q: query,
    };
    if (queryOr !== null) {
      params.q_or = queryOr;
    }

    let execFunc;
    if (isPrivate && projectId !== null && projectId !== undefined) {
      execFunc = execRequest(searchPrivateChatRule, projectId, params, accessToken, signal.token);
    } else {
      execFunc = execRequest(searchChatRule, params, accessToken, signal.token);
    }
    dispatch(chatRuleActions.searchChatRule.started(params));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(
            chatRuleActions.searchChatRule.done({
              params: params,
              result: res.data,
            })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.searchChatRule.failed({ params: params, error: e }));
        throw e;
      });
  };
};

/** 会話データ更新 */
export const updateChatRuleAction = (params: RequestPatchChatRule) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    let execFunc;
    if (isPrivate && projectId !== null && projectId !== undefined) {
      execFunc = execRequest(patchPrivateChatRule, projectId, params, accessToken, signal.token);
    } else {
      execFunc = execRequest(patchChatRule, params, accessToken, signal.token);
    }
    dispatch(chatRuleActions.updateChatRule.started(params));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(
            chatRuleActions.updateChatRule.done({
              params: params,
              result: res.data,
            })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.updateChatRule.failed({ params: params, error: e }));
        throw e;
      });
  };
};

/** 会話データ追加 */
export const addChatRuleAction = (params: RequestPatchChatRule) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    let execFunc;
    if (isPrivate && projectId !== null && projectId !== undefined) {
      execFunc = execRequest(postPrivateChatRule, projectId, params, accessToken, signal.token);
    } else {
      execFunc = execRequest(postChatRule, params, accessToken, signal.token);
    }
    dispatch(chatRuleActions.addChatRule.started(params));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(
            chatRuleActions.addChatRule.done({
              params: params,
              result: res.data,
            })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.addChatRule.failed({ params: params, error: e }));
        throw e;
      });
  };
};

/** 会話データエクスポート */
export const exportChatRuleAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    if (!isPrivate) {
      throw new Error('Export is available to private users only');
    }

    if (projectId === undefined || projectId === null) {
      throw new Error(`couldn't get a project ID`);
    }

    const execFunc = execRequest(exportPrivateChatRule, projectId, accessToken, signal.token);
    dispatch(chatRuleActions.exportChatRule.started(projectId));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(chatRuleActions.exportChatRule.done({ params: projectId, result: res }));
          return res;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.exportChatRule.failed({ params: projectId, error: e }));
        throw e;
      });
  };
};

/** 会話データのインポート */
export const importChatRuleAction = (formData: FormData) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    if (!isPrivate) {
      throw new Error('Export is available to private users only');
    }

    if (projectId === undefined || projectId === null) {
      throw new Error(`couldn't get a project ID`);
    }

    const execFunc = execRequest(
      importPrivateChatRule,
      projectId,
      formData,
      accessToken,
      signal.token
    );
    dispatch(chatRuleActions.importChatRule.started(formData));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(chatRuleActions.importChatRule.done({ params: formData, result: res }));
          return res;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.importChatRule.failed({ params: formData, error: e }));
        throw e;
      });
  };
};

/** 会話データ削除 */
export const deleteChatRuleAction = (id: number) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const params: RequestDeleteCommon = {
      id: id,
    };
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    let execFunc;
    if (isPrivate && projectId !== null && projectId !== undefined) {
      execFunc = execRequest(deletePrivateChatRule, projectId, params, accessToken, signal.token);
    } else {
      execFunc = execRequest(deleteChatRule, params, accessToken, signal.token);
    }
    dispatch(chatRuleActions.deleteChatRule.started(params));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(chatRuleActions.deleteChatRule.done({ params: params, result: res }));
          return res;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.deleteChatRule.failed({ params: params, error: e }));
        throw e;
      });
  };
};

/** 会話データ反映 */
export const reloadPrivateChatRuleAction = () => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<void | AxiosResponse<any>> => {
    const isPrivate = getState().auth.isPrivate;
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();

    let execFunc;
    if (isPrivate && projectId !== null && projectId !== undefined) {
      execFunc = execRequest(reloadPrivateChatRule, projectId, accessToken, signal.token);
      dispatch(chatRuleActions.reloadPrivateChatRule.started(projectId));
      return execFunc
        .then((res) => {
          if (res) {
            dispatch(
              chatRuleActions.reloadPrivateChatRule.done({
                params: projectId,
                result: res,
              })
            );
            return res;
          }
        })
        .catch((e) => {
          dispatch(
            chatRuleActions.reloadPrivateChatRule.failed({
              params: projectId,
              error: e,
            })
          );
          throw e;
        });
    }
  };
};

export const getScenarioRelationshipsAction = (rule_id: string) => {
  return async (
    dispatch: Dispatch,
    getState: () => AppState
  ): Promise<CommonAPIResponse<ScenarioRelationships>> => {
    const accessToken = getState().auth.user.access_token;
    const projectId = getState().app.selectedProjectId;
    const signal = axios.CancelToken.source();
    const params: RequestGetScenarioRelationships = {
      rule_id: rule_id,
    };

    const execFunc = execRequest(
      getScenarioRelationships,
      projectId,
      params,
      accessToken,
      signal.token
    );
    dispatch(chatRuleActions.getScenarioRelationships.started(params));
    return execFunc
      .then((res) => {
        if (res) {
          dispatch(
            chatRuleActions.getScenarioRelationships.done({
              params: params,
              result: res.data,
            })
          );
          return res.data;
        }
      })
      .catch((e) => {
        dispatch(chatRuleActions.getScenarioRelationships.failed({ params: params, error: e }));
        throw e;
      });
  };
};
