import { ValidationErrorMessages } from 'constants/Messages';

export const templateIDRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const nameRegister = {
  required: { value: true, message: ValidationErrorMessages.REQUIRED },
};

export const valueRegister = {
  required: false,
};
