import React from 'react';
import { Fragment } from 'react';
import { Typography } from '@material-ui/core';

export type UnbChatRuleTooltipContentProps = {
  rule_id: string;
  decision_condition: string;
  decision_command: string;
  weight: number | null;
};

export const UnbChatRuleTooltipContent: React.FC<UnbChatRuleTooltipContentProps> = (
  props: UnbChatRuleTooltipContentProps
) => {
  return (
    <Fragment>
      <Typography color="inherit">Rule ID</Typography>
      {props.rule_id}
      <br />
      <br />
      <Typography color="inherit">Decision Condition</Typography>
      {props.decision_condition || 'None'}
      <br />
      <br />
      <Typography color="inherit">Decision Command</Typography>
      {props.decision_command || 'None'}
      <br />
      <br />
      <Typography color="inherit">Weight</Typography>
      {props.weight || 'None'}
    </Fragment>
  );
};
