export const UI_CONFIG = {
  getAppTitle: (): string => {
    switch (process.env.REACT_APP_ENV_UI) {
      case 'PRD':
        return 'SCENARIO EDITOR';
      case 'STG':
        return 'SCENARIO EDITOR STAGING';
      case 'DEV':
        return 'SCENARIO EDITOR DEVELOPMENT';
      default:
        return 'SCENARIO EDITOR';
    }
  },
  getAppPrimaryColor: (): string => {
    switch (process.env.REACT_APP_ENV_UI) {
      case 'PRD':
        return '#009bdc';
      case 'STG':
        return '#d6635b';
      case 'DEV':
        return '#40A48E';
      default:
        return '#009bdc';
    }
  },
};
