import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LabelText } from 'constants/Messages';
import { uiVerbDefinitionActions } from 'store/uis/verb-definition/action';

export type UIVerbDefinitionState = {
  error?: any;
  openAlertDialog: boolean;
  openDialog: boolean;
  okBtnText: string;
  displayDelete: boolean;
  action?: 'ADD' | 'UPDATE';
  isDisplayCopyBtn: boolean;
  openCopyAlertDialog: boolean;
};

export const initialState: UIVerbDefinitionState = {
  openAlertDialog: false,
  openDialog: false,
  okBtnText: LabelText.UPDATE_LABEL,
  displayDelete: false,
  isDisplayCopyBtn: false,
  openCopyAlertDialog: false,
};

export const uiVerbDefinitionReducer = reducerWithInitialState(initialState)
  .case(uiVerbDefinitionActions.setOpenCopyAlertDialog, (state, payload) => {
    return {
      ...state,
      openCopyAlertDialog: payload,
    };
  })
  .case(uiVerbDefinitionActions.setIsDisplayCopyBtn, (state, payload) => {
    return {
      ...state,
      isDisplayCopyBtn: payload,
    };
  })
  .case(uiVerbDefinitionActions.setOkBtnText, (state, payload) => {
    return {
      ...state,
      okBtnText: payload,
    };
  })
  .case(uiVerbDefinitionActions.setOpenDialog, (state, payload) => {
    return {
      ...state,
      openDialog: payload,
    };
  })
  .case(uiVerbDefinitionActions.setOpenAlertDialog, (state, payload) => {
    return {
      ...state,
      openAlertDialog: payload,
    };
  })
  .case(uiVerbDefinitionActions.setDisplayDelete, (state, payload) => {
    return {
      ...state,
      displayDelete: payload,
    };
  })
  .case(uiVerbDefinitionActions.setAction, (state, payload) => {
    return {
      ...state,
      action: payload,
    };
  });
