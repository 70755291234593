import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { authReducer, AuthState } from 'store/domains/auth/reducer';
import { chatRuleReducer, ChatRuleState } from 'store/domains/chat-rule/reducer';
import { masterWordsReducer, MasterWordState } from 'store/domains/master-word/reducer';
import { taskReducer, TaskState } from 'store/domains/task/reducer';
import {
  templateVariableReducer,
  TemplateVariableState,
} from 'store/domains/template-variable/reducer';
import { verbDefinitionReducer, VerbDefinitionState } from 'store/domains/verb-definition/reducer';
import { uiDrawerReducer, UIDrawerState } from 'store/uis/drawer/reducer';
import { uiTaskReducer, UITaskState } from 'store/uis/task/reducer';
import {
  uiTemplateVacriableReducer,
  UITemplateVariableState,
} from 'store/uis/template-variable/reducer';
import { uiVerbDefinitionReducer, UIVerbDefinitionState } from 'store/uis/verb-definition/reducer';
import {
  RuleConditionGroupState,
  ruleConditionGroupReducer,
} from 'store/domains/rule-condition-group/reducer';
import {
  UIRuleConditionGroupState,
  uiRuleConditionGroupReducer,
} from 'store/uis/rule-condition-group/reducer';

import { UIChatRuleState, uiChatRuleReducer } from 'store/uis/chat-rule/reducer';
import { CommonState, commonReducer } from 'store/app/reducer';
import { UIChatToolsState, uiChatToolsReducer } from 'store/uis/tools/chat/reducer';
import { ChatToolsState, chatToolsReducer } from 'store/domains/tools/chat/reducer';

export type AppState = {
  app: CommonState;
  auth: AuthState;
  masterWords: MasterWordState;
  chatRule: ChatRuleState;
  templateVariable: TemplateVariableState;
  verbDefinition: VerbDefinitionState;
  task: TaskState;
  ruleConditionGroup: RuleConditionGroupState;
  chatTools: ChatToolsState;
  uiChatRule: UIChatRuleState;
  uiTemplateVariable: UITemplateVariableState;
  uiVerbDefinition: UIVerbDefinitionState;
  uiTask: UITaskState;
  uiRuleConditionGroup: UIRuleConditionGroupState;
  uiDrawer: UIDrawerState;
  uiChatTools: UIChatToolsState;
};

const Store = createStore(
  combineReducers<AppState>({
    app: commonReducer,
    auth: authReducer,
    masterWords: masterWordsReducer,
    chatRule: chatRuleReducer,
    templateVariable: templateVariableReducer,
    verbDefinition: verbDefinitionReducer,
    task: taskReducer,
    ruleConditionGroup: ruleConditionGroupReducer,
    chatTools: chatToolsReducer,
    uiChatRule: uiChatRuleReducer,
    uiTemplateVariable: uiTemplateVacriableReducer,
    uiVerbDefinition: uiVerbDefinitionReducer,
    uiTask: uiTaskReducer,
    uiRuleConditionGroup: uiRuleConditionGroupReducer,
    uiDrawer: uiDrawerReducer,
    uiChatTools: uiChatToolsReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

export default Store;
